const mappingApi = {
  getList: '/kbms/mapping/list', // 获取列表
  editMapping: '/kbms/mapping/update', // 更新映射
  getVocabularyList: '/kbms/mapping/vocabulary/list', // 获取知识类型列表
  getProjectList: '/kbms/mapping/project/list\n', // 项目来源列表
  audit: '/kbms/mapping/audit', // 提交审核
  import: '/kbms/mapping/import', // 导入
  export: '/kbms/mapping/export', // 导出
  getParams: '/kbms/config/param/list', // 获取参数
  getTemplate: '/kbms/mapping/template', // 获取模板
  // 同义词
  getThesaurusList: '/kbms/thesaurus/list', // 获取词汇列表
  insertThesaurus: '/kbms/thesaurus/insert', // 新增同义词
  deleteThesaurus: '/kbms/thesaurus/delete', // 删除同义词
  exportThesaurus: '/kbms/thesaurus/export', // 导出同义词
  getThesaurusDetail: '/kbms/thesaurus/listDetail', // 获取同义词列表
  listDetailAll: '/kbms/thesaurus/listDetailAll', // 获取同义词列表
  verifyThesaurus: '/kbms/thesaurus/verify', // 审核同义词
}

export default mappingApi
