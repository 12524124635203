/*
 * 数据映射
 * */
import React, { useEffect, useState, useRef } from 'react'
import {
  Form,
  Row,
  Col,
  Input,
  Table,
  Tabs,
  Tooltip,
  message,
  Button,
} from 'antd'
import styles from './index.module.less'
import usePagination from '@hooks/usePagination'
import fetch from '@utils/request'
import api from '@api'
import EditModal from '../components/editModal'
import Big from 'big.js'
import { useWindowSize } from '@hooks/useLayout'
import debounce from 'lodash/debounce'
import { MAP_TYPE } from '../constants'
import KeySelect from '../components/keySelect'
import Highlighter from 'react-highlight-words'
import {permissionStyle, permissionAuth} from '@utils/permission'

const MappingData = () => {
  const [, , scale] = useWindowSize()
  const editModalRef = useRef(null)
  const [form] = Form.useForm()
  // eslint-disable-next-line no-unused-vars
  const [page, setPage, setTotal, pagination, total] = usePagination()
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [list, setList] = useState([])
  const [vocabularyList, setVocabularyList] = useState([])
  const [currentTab, setCurrentTab] = useState('1')
  const [modalTitle, setModalTitle] = useState('')
  const [keyword, setKeyword] = useState('')
  const [vocabulary, setVocabulary] = useState(['all'])

  const renderHighLight = (text, record, searchOutText) => {
    if (searchOutText && text) {
      return (
        <Highlighter
          highlightClassName="YourHighlightClass"
          searchWords={Array.from(new String(searchOutText))}
          autoEscape={true}
          textToHighlight={text}
        />
      )
    } else {
      return <div>{text}</div>
    }
  }

  const fetchVocabularyList = async () => {
    const data = await fetch({
      url: api.mappingApi.getParams,
      method: 'POST',
    })
    const result = data.find((item) => item.type === 'vocabulary')
    setVocabularyList([
      { label: '全部', value: 'all' },
      ...result.valueNameVOS.map((item) => {
        return {
          label: item.name,
          value: item.value,
        }
      }),
    ])
  }

  const handleChange = (value) => {
    setVocabulary(value)
    setPage({
      pageIndex: 1,
      pageSize: 10,
    })
  }

  useEffect(() => {
    fetchVocabularyList()
  }, [])

  const handleTableChange = (pagination, filters, sorter, extra) => {
    setPage({
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
    })
  }

  /*
   * Tabs change
   * */
  const onTabsChange = (value) => {
    setCurrentTab(value)
    setPage({
      pageIndex: 1,
      pageSize: 10,
    })
  }
  const getList = async () => {
    const pager = {
      pageNumber: page.pageIndex,
      pageSize: page.pageSize,
    }
    setLoading(true)
    try {
      const res = await fetch({
        method: 'POST',
        url: api.omopApi.getOmopList,
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          ...pager,
          keyword,
          valid: currentTab === '1',
          vocabularyList: vocabulary.filter((n) => n !== 'all'),
        },
      })
      setLoading(false)
      setList(res.content)
      setTotal(res.totalCount)
    } catch (e) {
      setLoading(false)
    }
  }

  const showEditModal = (record, title) => {
    if (!permissionAuth('OMOP_MAP')) return
    setModalTitle(`编辑${title}`)
    editModalRef.current.show(record)
  }

  const renderColumn = (text, record, title, style) => {
    return (
      <div
        style={{
          minWidth: 200,
          minHeight: 40,
          display: 'flex',
          alignItems: 'center',
          ...style,
        }}
        onClick={() => showEditModal(record, title)}
      >
        {renderHighLight(text, record, keyword)}
      </div>
    )
  }

  const columns = [
    {
      title: '相似度',
      dataIndex: 'similarity',
      key: 'similarity',
      width: 120 * scale,
      render(value, record) {
        const result = new Big(value || 0)
        return (
          <div className={styles.match}>
            {result.times(100).toNumber()}%
            {record.status === 2 && (
              <Tooltip
                title="由未映射列表提交到已映射列表中被标记的数据。"
                placement="bottom"
              >
                <div className={styles.dot}>手动</div>
              </Tooltip>
            )}
          </div>
        )
      },
    },
    {
      title: '映射方式',
      dataIndex: 'mapType',
      key: 'mapType',
      render(value, record) {
        return MAP_TYPE[value] || '-'
      },
    },
    {
      title: '标准字典编码',
      dataIndex: 'standardConceptCode',
      key: 'standardConceptCode',
      width: 150 * scale,
      render: (text, record) => renderHighLight(text, record, keyword),
    },
    {
      title: '标准字典名称',
      dataIndex: 'standardConceptName',
      key: 'standardConceptName',
      width: 220 * scale,
      render: (text, record) => renderHighLight(text, record, keyword),
    },
    {
      title: 'OMOP字典编码',
      dataIndex: 'omopConceptCode',
      key: 'omopConceptCode',
      width: 200 * scale,
      render: (text, record) => renderColumn(text, record, 'OMOP字典编码'),
    },
    {
      title: 'OMOP字典名称',
      dataIndex: 'omopConceptName',
      key: 'omopConceptName',
      width: 200 * scale,
      render: (text, record) => renderColumn(text, record, 'OMOP字典名称'),
    },
    {
      title: '知识类型',
      dataIndex: 'vocabulary',
      key: 'vocabulary',
      width: 200 * scale,
      render(value) {
        const item = vocabularyList.find((item) => item.value === value)
        return item ? item.label : ''
      },
    },
    {
      title: '编辑人',
      dataIndex: 'updatedBy',
      key: 'updatedBy',
      width: 120 * scale,
    },
    {
      title: '修改时间',
      dataIndex: 'updatedTime',
      key: 'updatedTime',
      width: 165 * scale,
    },
  ]

  const audit = async (record) => {
    await fetch({
      method: 'POST',
      url: api.omopApi.audit,
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        id: record.id,
      },
    })
    message.success('提交成功')
    getList()
  }

  const actionColumn = [
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      width: 100 * scale,
      fixed: 'right',
      render: (text, record) => {
        return (
          <div>
            <Button
              onClick={() => audit(record)}
              type="link"
              style={{ margin: 0, padding: 0, ...permissionStyle('OMOP_AUDIT')}}
              disabled={!record.id}
            >
              提交
            </Button>
          </div>
        )
      },
    },
  ]

  const TableComponent = () => (
    <Table
      scroll={{ x: 'max-content' }}
      columns={columns.concat(currentTab === '1' ? [] : actionColumn)}
      dataSource={list}
      pagination={pagination}
      onChange={handleTableChange}
      loading={loading}
    />
  )

  const tabItems = [
    {
      label: '已映射数据',
      key: '1',
      children: <TableComponent />,
    },
    {
      label: '未映射数据',
      key: '2',
      children: <TableComponent />,
    },
  ]

  useEffect(() => {
    if (vocabularyList.length > 0) {
      getList()
    }
  }, [page, keyword, currentTab, vocabularyList, vocabulary])

  return (
    <>
      {/* 标题栏 */}
      <div className="head">
        <h3 className="head-title">OMOP字典映射</h3>
      </div>
      <Form
        className={styles.searchBox}
        form={form}
        labelCol={{ style: { width: 108 * scale } }}
        labelAlign="left"
      >
        <Row gutter={60}>
          <Col span={18}>
            <Row>
              <Col span={12}>
                <Form.Item
                  name="keyword"
                  label="搜索条件"
                  rules={[
                    {
                      max: 30,
                    },
                  ]}
                >
                  <Input
                    placeholder="请输入..."
                    onChange={debounce((e) => {
                      setKeyword(e.target.value)
                      setPage({
                        pageIndex: 1,
                        pageSize: 10,
                      })
                    }, 500)}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <KeySelect
            options={vocabularyList}
            onChange={handleChange}
            value={vocabulary}
          />
        </Row>
      </Form>
      <div className={styles.content}>
        <Tabs onChange={onTabsChange} activeKey={currentTab} items={tabItems} />
      </div>
      <EditModal
        ref={editModalRef}
        onSuccess={getList}
        title={modalTitle}
        type="omop"
      />
    </>
  )
}

export default MappingData
