// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect, useRef } from 'react'
// eslint-disable-next-line no-unused-vars
import { Button, Col, Divider, message, Row, Space, Spin, Tooltip } from 'antd'
import { permissionAuth, permissionStyle } from '@utils/permission'
import styles from '@views/dictionary/index.module.less'
import MenuTree from '@components/tree/menuTree'
import KeySearch from '@components/form/search'
import DicEditModall from '@views/dictionary/components/dicEditModall'
import usePagination from '@hooks/usePagination'
import fetch from '@utils/request'
import api from '@api'
import TreeShape from '@views/dictionary/components/treeShape'
import ImportDict from './components/importDict'

const Dictionary = () => {
  const dicEditRef = useRef(null)
  const treeShapeRef = useRef(null)
  const searchRef = useRef(null)
  const menuRef = useRef(null)
  const importRef = useRef(null)
  // eslint-disable-next-line no-unused-vars
  const [page, setPage, setTotal, pagination, total] = usePagination()
  // eslint-disable-next-line no-unused-vars
  const [tree, setTree] = useState([])
  const [curTreeNode, setCurTreeNode] = useState(null)
  const [searchText, setSearchText] = useState(null)
  const [refresh, setRefresh] = useState(false)
  const authItemKeys = [
    permissionAuth('DICTIONARY_LIST_DIR_ADD'),
    permissionAuth('DICTIONARY_LIST_DIR_ADD'),
    permissionAuth('DICTIONARY_LIST_DIR_EDIT'),
    permissionAuth('DICTIONARY_LIST_DIR_DELETE'),
  ]

  // 获取字典列表
  const getList = async () => {
    const res = await fetch({
      method: 'POST',
      url: api.dictionaryApi.treeList,
      data: {},
    })
    console.log('res::', res)
    if (res && res.length > 0) {
      setTree(res)
    }
  }
  // 关键词搜索
  const keySearchClick = async (e) => {
    //
    setSearchText(e)
    // treeShapeRef.current.clearIcdTreeTableData()
    treeShapeRef.current.initialPager()
    console.log('keySearchClick', e)
  }
  // 树节点选择
  const treeSelect = async (e) => {
    setCurTreeNode(e.node)
    treeShapeRef.current.clearIcdTreeTableData()
    treeShapeRef.current.initialPager()
    setSearchText('')
  }
  /*
   * 编辑字典弹窗
   * */
  const editDic = (record) => {
    setRefresh(false)
    if (!curTreeNode) {
      return message.warn('请先选择字典分类！')
    }
    dicEditRef.current.handleOpen(record)
  }
  // 新增or编辑
  const submit = async (values) => {
    console.log('submit:', values)
    const secondCurTreeNode = treeShapeRef.current.exposeCurTreeNode()
    let parentConceptCodeObj = {
      parentConceptCode: null,
    }
    if (secondCurTreeNode) {
      parentConceptCodeObj = { parentConceptCode: secondCurTreeNode.key }
    }
    await fetch({
      method: 'POST',
      url: api.dictionaryApi.dictionarySave,
      data: {
        vocabulary: curTreeNode.code,
        ...parentConceptCodeObj,
        ...values,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    })
    setRefresh(true)
    message.success(values.id ? '编辑成功！' : '新增成功！')
    dicEditRef.current.handleCancel()
  }

  const onCheck = () => {
    setRefresh(false)
    setRefresh(true)
    message.success('操作成功！')
    dicEditRef.current.handleCancel()
  }

  useEffect(() => {
    getList()
  }, [])

  return (
    <>
      {/* 标题栏 */}
      <div className="headmin">
        <h3 className="head-title">数据字典</h3>
      </div>
      <Divider className={styles.divider} />
      {/* 标签库 */}
      <Row className={styles.tableSpace} gutter={16}>
        <Col span={4} className={styles.over}>
          <MenuTree
            authItemKeys={authItemKeys}
            ref={menuRef}
            type="standardVocabulary"
            treeSelect={treeSelect}
            draggable={false}
          />
        </Col>
        <Col span={20}>
          {/* 搜索栏 */}
          <div className={styles.search}>
            <Space>
              <KeySearch ref={searchRef} keySearchClick={keySearchClick} />
              {
                curTreeNode?.version && <span>版本号：{curTreeNode?.version}</span>
              }
            </Space>
            
            <Space>
              {/* {curTreeNode && !curTreeNode.isCategory && (
                <Button
                  style={permissionStyle('DICTIONARY_LIST_ADD')}
                  type="primary"
                  onClick={() => {
                    editDic()
                  }}
                >
                  新增
                </Button>
              )} */}
              <Button onClick={() => importRef.current.show()} style={permissionStyle('DICTIONARY_LIST_IMPORT')}>导入</Button>
            </Space>
          </div>
          <TreeShape
            node={curTreeNode}
            ref={treeShapeRef}
            onEdit={editDic}
            searchText={searchText}
            refresh={refresh}
          />
        </Col>
      </Row>
      <DicEditModall ref={dicEditRef} onSubmit={submit} onCheck={onCheck} />
      <ImportDict ref={importRef} onSuccess={()=>menuRef.current.refreshTree()}/>
    </>
  )
}

export default Dictionary
