let baseHttpURL
const baseImgURL = 'http://192.168.1.228:9010'

if (process.env.NODE_ENV === 'production') {
  baseHttpURL = '/api'
} else {
  baseHttpURL = '/api'
}

export {
  baseHttpURL,
  baseImgURL
}
