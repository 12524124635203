import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { patientTreatmentMap } from '@constants/patient'
import { renderHighLight } from '../index'
import styles from './index.module.less'

const DetailCard = ({ base = {}, visit = {}, hitValue = '' }) => {
  const data = { ...base, ...visit }
  const visitTime = visit.type === 2 ? visit.admitTime : visit.visitDate
  const infoArray = [
    {
      label: '患者ID',
      value: data.patientId,
    },
    {
      label: '姓名',
      value: data.patientName,
    },
    {
      label: '性别',
      value: data.gender,
    },
    {
      label: '年龄',
      value: data.age ? data.age + '岁' : '',
    },
    // {
    //   label: '电话',
    //   value: data.phone,
    // },
    // {
    //   label: '身份证号',
    //   value: data.idCardNo,
    // },
    // {
    //   label: '住址',
    //   value: data.homeAddress,
    // },
    {
      label: '婚姻状况',
      value: data.marital,
    },
    {
      label: '民族',
      value: data.ethnic,
    },
    {
      label: '省份名称',
      value: data.provinceName,
    },
    {
      label: '监护人',
      value: data.guardianName,
    },
    // {
    //   label: '监护人电话',
    //   value: data.guardianPhone,
    // },
    {
      label: '出院时间',
      value: data.dischargeTime ? moment(data.dischargeTime).format('YYYY-MM-DD') : '',
    },
    {
      label: '就诊类型',
      value: patientTreatmentMap[data.type] || '',
    },
    {
      label: '就诊科室',
      value: data.visitDeptName,
    },
    {
      label: '就诊时间',
      value: visitTime ? moment(visitTime).format('YYYY-MM-DD') : '',
    },
  ]
  return (
    <div className={styles.detailCard}>
      {infoArray.map((item, index) => {
        return (
          <div className={styles.column} key={index}>
            <div className={styles.label}>{item.label}</div>
            <div className={styles.value}>{renderHighLight(item.value, {}, hitValue)}</div>
          </div>
        )
      })}
    </div>
  )
}

export default DetailCard

DetailCard.propTypes = {
  base: PropTypes.object,
  visit: PropTypes.object,
}
