import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import routesConfig from '@routes/routes'

const useMenu = () => {
  const privileges = useSelector((state) => state.app.privileges)
  const [authMenu, setAuthMenu] = useState([])
  const { privateRoutes } = routesConfig
  const tree2Array = (tree, childrenName) => {
    let arr = []
    tree?.forEach((item) => {
      arr.push(item)
      if (item[childrenName]) {
        arr = arr.concat(tree2Array(item[childrenName], childrenName))
      }
    })
    return arr
  }
  const permissionAuth = (code) => {
    const permissionList = tree2Array(privileges, 'childrenPrivileges')
    const permissionCodes = permissionList.map((item) => item.code)
    return permissionCodes.includes(code)
  }
  const filterRoutes = (routes) => {
    if (!routes) return []
    return routes.filter((route) => {
      if (route.children) {
        route.children = filterRoutes(route.children)
        if (route.children.length === 0) delete route.children
      }
      if (route.hidden) return false
      return (permissionAuth(route.key) && route.auth) || !route.auth
    })
  }

  useEffect(() => {
    const authPrivateRoutes = filterRoutes(privateRoutes())
    setAuthMenu(authPrivateRoutes)
  }, [privileges])
  return [authMenu]
}

export default useMenu
