import React, { useState, useImperativeHandle, forwardRef, useRef } from 'react'
import { message, Form, Modal } from 'antd'
import PropTypes from 'prop-types'
import { ReactComponent as AddIcon } from '@assets/images/icons/import_add.svg'
import FileUpload from '@components/fileUpload'
import fetch from '@utils/request'
import api from '@api'
import styles from './index.module.less'
import { useWindowSize } from '@hooks/useLayout'
import {ExclamationCircleOutlined} from '@ant-design/icons'

const ImportTags = ({ onSuccess, projectId }, ref) => {
  const uploadRef = useRef(null)
  const [visible, setVisible] = useState(false)
  const [error, setError] = useState(false)
  const [file, setFile] = useState(null)
  const [uploading, setUploading] = useState(false)
  const [form] = Form.useForm()
  const [, , scale] = useWindowSize()

  const hide = () => {
    uploadRef.current.clear()
    setFile(null)
    setUploading(false)
    setVisible(false)
    setError(false)
  }
  const show = () => {
    setVisible(true)
  }
  useImperativeHandle(ref, () => ({
    show,
    hide,
  }))

  const handleSubmit = async () => {
    const validate = await form.validateFields()
    if (!validate) return
    if (!file) {
      setError(true)
      return
    }

    Modal.confirm({
      title: '导入标签',
      icon: <ExclamationCircleOutlined />,
      content: '导入标签后，会导致当前所有标签被删除，是否继续',
      width: '700px',
      onOk: async() => {
        setUploading(true)
        const formData = new FormData()
        formData.append('file', file)
        formData.append('projectId', projectId)
        try {
          await fetch({
            url: api.projectApi.ruleCrfProjectImport,
            data: formData,
            method: 'POST',
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          setFile(null)
          setVisible(false)
          hide()
          message.success('导入成功')
          typeof onSuccess === 'function' && onSuccess()
        } catch (error) {
          setUploading(false)
        }
      }
    })

  }

  return (
    <Modal
      open={visible}
      onCancel={hide}
      title="文件导入"
      onOk={handleSubmit}
      okButtonProps={{ loading: uploading }}
      width={640 * scale}
    >
      <Form layout="vertical" form={form}>
        <Form.Item label="导入文件" required>
          <FileUpload
            ref={uploadRef}
            uploadProps={{
              accept: '.xlsx,.xls',
              children: (
                <>
                  <div className={styles.btn}>
                    <AddIcon style={{ color: '#031f47', marginRight: 5 }} />
                    点击导入文件
                  </div>
                  <div className={styles.text}>
                    仅支持 xlsx 文件，单次最多上传 1 个
                  </div>
                </>
              ),
            }}
            maxCount={1}
            onChange={(files) => {
              setFile(files[0])
              setError(false)
            }}
          />
          {error && <div className={styles.error}>请选择文件</div>}
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default forwardRef(ImportTags)

ImportTags.propTypes = {
  onSuccess: PropTypes.func,
}
