/*
* 逻辑运算符控件
* */

import {Form, Select} from 'antd'
import React from 'react'
import {useSelector} from 'react-redux'

const LogicWidget = ({namePath}) => {
  const sysConst = useSelector((state) => state.app.sysConst)
  const logicOptions = sysConst.filter(item => item.type === 'engine_logic_type')[0].valueNameVOS
  return (
    <>
      <Form.Item
        label="选择运算符"
        name={[...namePath, 'operator']}
      >
        <Select
          placeholder="请选择运算符"
          options={logicOptions}
          fieldNames={{label: 'name', value: 'value'}}
        />
      </Form.Item>
    </>
  )
}

export default LogicWidget
