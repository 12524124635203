/*
* 常数控件
* */
import React, {useEffect, useMemo, useRef, useState} from 'react'
import {connect, useDispatch, useSelector} from 'react-redux'
import DicModal from '@views/tag/engineV2/components/label-from-dic/dicModal'
import fetch from '@utils/request'
import api from '@api'
import {change, Field, formValueSelector} from 'redux-form'
import {ADatePicker, AInputNumber, ASelect, ATable, ATextarea} from '@components/redux-form-antd'
import {Button} from 'antd'
import PrimeTag from '@components/base/tag'
import {useWindowSize} from '@hooks/useLayout'
import moment from 'moment/moment'
import {debounce} from '@utils/common'
import {cloneDeep} from 'lodash'


let ConstantWidget = ({namePath, constantType, stringValue, selectedDictCode, changeArray, selectedDictData, ...restProps}) => {
  console.count('ConstantWidget')
  const [,, scale] = useWindowSize()
  const dicModalRef = useRef(null)
  const [dicList, setDicList] = useState([])
  // eslint-disable-next-line no-unused-vars
  const dispatch = useDispatch()

  const sysConst = useSelector((state) => state.app.sysConst)
  // const vocabularyOptions = sysConst.filter(item => item.type === 'vocabulary')[0].valueNameVOS
  const constantOptions = sysConst.filter(item => item.type === 'engine_constant_type')[0].valueNameVOS

  const required = value => (!value && !selectedDictCode ?'文本和字典不能都为空': undefined)
  const required1 = value => (!value && !stringValue ?'文本和字典不能都为空': undefined)

  const fetchDicList = async () => {
    const res = await fetch({
      method: 'POST',
      url: api.dictionaryApi.treeList,
      data: {}
    })
    setDicList(res)
  }
  /*
  * 获取同义词
  * */
  const fetchSynonym = async (params) => {
    const res = await fetch({
      method: 'POST',
      url: api.tagApi.listValidDetailAll,
      data: params,
      headers: {
        'Content-Type': 'application/json'
      },
    })
    return res
  }
  const tableData = useMemo(() => {
    return cloneDeep(selectedDictData)
  }, [selectedDictData])
  const doneCallback = async (data) => {
    const conceptCodes = data?.map((item) => item.key)
    const synonymData = await fetchSynonym({conceptCodes, vocabulary: selectedDictCode})
    debounce( changeArray(
      'tag',
      `${namePath}.selectedDictList[0].data`,
      synonymData[0].data
    ), 1000)
  }
  const editSynonym = (data) => {
    changeArray(
      'tag',
      `${namePath}.selectedDictList[0].data`,
      data
    )
  }
  const dictionaryType = useMemo(() => {
    let type
    const selectedDic = dicList.filter((item) => item.code === selectedDictCode)
    type = selectedDic[0]?.structure
    return type
  }, [selectedDictCode, dicList])


  useEffect(() => {
    fetchDicList()
  }, [])

  /*
  * 表单联动，根据常数类型渲染不同表单类型
  * */
  const renderConstantItem = () => {
    let label = '请输入'
    if (constantType === 'num') {
      return (
        <Field
          label={label}
          name={`${namePath}.numberValue`}
          component={AInputNumber}
          style={{
            width: '100%',
          }}
          onFocus={e => e.preventDefault()}
          onBlur={e => e.preventDefault()}
        />
      )
    } else if (constantType === 'date') {
      return (
        <>
          <Field
            label={label}
            name={`${namePath}.dateTimeValue`}
            component={ADatePicker}
            placeholder={label}
            style={{
              width: '100%',
            }}
            hasFeedback
            normalize={ value => moment(value).format('YYYY-MM-DD HH:mm:ss')}
            onFocus={e => e.preventDefault()}
            onBlur={e => e.preventDefault()}
          />
        </>
      )
    } else if (constantType === 'datetime') {
      return (
        <Field
          label={label}
          name={`${namePath}.dateTimeValue`}
          component={ADatePicker}
          placeholder={label}
          showTime
          style={{
            width: '100%',
          }}
          normalize={ value => moment(value).format('YYYY-MM-DD HH:mm:ss')}
          onFocus={e => e.preventDefault()}
          onBlur={e => e.preventDefault()}
        />
      )
    } else if (constantType === 'str') {
      label = '请选择字典'
      return (
        <>
          <Field
            label='输入文本'
            name={`${namePath}.stringValue`}
            component={ATextarea}
            placeholder="请输入文本"
            autoSize={{ minRows: 1}}
            validate={[required]}
          />
          <Field
            label={label}
            name={`${namePath}.selectedDictList[0].code`}
            component={ASelect}
            changeEvent={() => {
              debounce( changeArray(
                'tag',
                `${namePath}.selectedDictList[0].data`,
                []
              ), 1000)
              dicModalRef.current.clear()
            }}
            validate={[required1]}
            value={null}
            placeholder="请选择字典"
            fieldNames={{label: 'name', value: 'code'}}
            showSearch={true}
            allowClear
            onClear={() => {
              debounce( changeArray(
                'tag',
                `${namePath}.selectedDictList[0].code`,
                ''
              ), 1000)
              dicModalRef.current.clear()
            }}
            optionFilterProp='name'
            options={dicList}
            onFocus={(e) => e.preventDefault()}
            onBlur={(e) => e.preventDefault()}
          >
            fdsa
          </Field>
          {selectedDictCode && (
            <>
              <div style={{display: 'flex', justifyContent: 'flex-end', marginBottom: '16px'}}>
                <Button
                  ghost
                  type='primary'
                  size='middle'
                  onClick={() => {
                    dicModalRef.current.show()
                  }}
                >
                            选择字典
                </Button>
              </div>
              <Field
                name={`${namePath}.selectedDictList[0].data`}
                valuePropName='dataSource'
                component={ATable}
                rowKey='key'
                columns={[
                  {
                    title: '标准名',
                    dataIndex: 'title',
                    key: 'title',
                    width: 140*scale
                  },
                  {
                    title: '同义词名',
                    dataIndex: 'sameTitles',
                    key: 'sameTitles',
                    render: (text, record) => {
                      return (
                        <PrimeTag sourceData={text} onEdit={editSynonym}/>
                      )
                    }
                  },
                ]}
                pagination={false}
                style={{
                  width: '100%',
                }}
              />
            </>
          )}
        </>
      )
    }
  }
  return (
    <>
      <Field
        label="选择常数类型"
        name={`${namePath}.type`}
        component={ASelect}
        value={null}
        placeholder="请选择常数类型"
        fieldNames={{label: 'name', value: 'value'}}
        showSearch={true}
        optionFilterProp='name'
        options={constantOptions}
        onFocus={(e) => e.preventDefault()}
        onBlur={(e) => e.preventDefault()}
      />
      {renderConstantItem()}
      {constantType === 'str' && (
        <DicModal ref={dicModalRef} doneCallback={doneCallback} dictionaryCode={selectedDictCode} selectedDictData={tableData} dictionaryType={dictionaryType}/>
      )}
    </>
  )
}


const selector = formValueSelector('tag') // <-- same as form name
const mapStateToProps = (state, ownProps) => {
  const {namePath} = ownProps
  const constantType = selector(state, `${namePath}.type`)
  const stringValue = selector(state, `${namePath}.stringValue`)
  const selectedDictCode = selector(state, `${namePath}.selectedDictList[0].code`)
  const selectedDictData = selector(state, `${namePath}.selectedDictList[0].data`)
  return {
    constantType,
    selectedDictCode,
    selectedDictData,
    stringValue,
  }
}
const mapDispatchToProps = {
  // NOTE: This MUST be aliased or it will not work. Thanks Jack!
  changeArray: change
}
ConstantWidget = connect(mapStateToProps, mapDispatchToProps)(ConstantWidget)
export default ConstantWidget
