/*
* 药品说明书，text格式
* */
import React from 'react'
import styles from './index.module.less'
const DESC = (props) => {
  const {
    dverseReaction, // 不良反应	string
    approvalDate, // 核准日期	string  (****原型里没有的字段****)
    approvalNo, // 批准文号	string
    attentionMatter, // 注意事项	string
    childrenUsage, // 儿童用药	string
    elderlyUsage, // 老年用药	string
    englishName, // 英文名称	string
    executiveStandard, // 执行标准	string
    indication, // 适应症	string
    ingredients, // 成分	string
    interaction, // 相互作用	string
    lastRevisionDate, // 最新修订日期	string  (****原型里没有的字段****)
    manufacturer, // 生产厂家	string
    normalName, // 通用名称	string
    overdose, // 药物过量	string
    pack, // 包装	string
    pharmacokinetics, // 药代动力学	string
    pharmacologyAndToxicology, // 药理毒理	string
    pregnantAndLactating, // 妊娠及哺乳期妇女用药	string
    revisionDate, // 修订日期	string (****原型里没有的字段****)
    specs, // 规格	string
    storage, // 贮藏	string
    taboo, // 禁忌	string
    termOfValidity, // 有效期	string
    tradeName, // 商品名称	string
    usageDosage, // 用法用量	string
  } = props.sourceData
  return (
    <div>
      <div className={styles.titleWrapper}>
        药品说明书
      </div>
      <div className={styles.section}>
        <p>{`${normalName}说明书`}</p>
        <p> 请仔详细阅读说明书并在医师指导下使用 </p>
        <p>
          <div className={styles.bold}>【药品名称】</div>
          <div>通用名称：</div>
          <div dangerouslySetInnerHTML={{ __html: normalName }} />
        </p>
        <p>
          <div>商品名称：</div>
          <div dangerouslySetInnerHTML={{ __html: tradeName }} />
        </p>
        <p>
          <div>英文名称：</div>
          <div dangerouslySetInnerHTML={{ __html: englishName }} />
        </p>
        <p>
          <div className={styles.bold}>【成份】</div>
          <div dangerouslySetInnerHTML={{ __html: ingredients }} />
        </p>
        {/* <p> */}
        {/*  <div>化学名称：{ingredients}</div> */}
        {/*  <div>化学结构式：{ingredients}</div> */}
        {/*  <div>分子量：{ingredients}</div> */}
        {/*  <div>辅料：{ingredients}</div> */}
        {/* </p> */}
        {/* <p> */}
        {/*  <div>【性状】</div> */}
        {/*  <div>{ingredients}</div> */}
        {/* </p> */}
        <p>
          <div className={styles.bold}>【适应症】</div>
          <div dangerouslySetInnerHTML={{ __html: indication }} />
        </p>
        <p>
          <div className={styles.bold}>【规格】</div>
          <div dangerouslySetInnerHTML={{ __html: specs }} />
        </p>
        <p>
          <div className={styles.bold}>【用法用量】</div>
          <div dangerouslySetInnerHTML={{ __html: usageDosage }} />
        </p>
        <p>
          <div className={styles.bold}>【不良反应】</div>
          <div dangerouslySetInnerHTML={{ __html: dverseReaction }} />
        </p>
        <p>
          <div className={styles.bold}>【禁忌】</div>
          <div dangerouslySetInnerHTML={{ __html: taboo }} />
        </p>
        <p>
          <div className={styles.bold}>【注意事项】</div>
          <div dangerouslySetInnerHTML={{ __html: attentionMatter }} />
        </p>
        {/* <p> */}
        {/*  <div>【对驾驶和机器操作能力的影响】</div> */}
        {/*  <div>{attentionMatter}</div> */}
        {/* </p> */}
        <p>
          <div className={styles.bold}>【孕妇及哺乳期妇女用药】</div>
          <div dangerouslySetInnerHTML={{ __html: pregnantAndLactating }} />
        </p>
        <p>
          <div className={styles.bold}>【儿童用药】</div>
          <div dangerouslySetInnerHTML={{ __html: childrenUsage }} />
        </p>
        <p>
          <div className={styles.bold}>【老年用药】</div>
          <div dangerouslySetInnerHTML={{ __html: elderlyUsage }} />
        </p>
        <p>
          <div className={styles.bold}>【药物相互作用】</div>
          <div dangerouslySetInnerHTML={{ __html: interaction }} />
        </p>
        <p>
          <div className={styles.bold}>【药物过量】</div>
          <div dangerouslySetInnerHTML={{ __html: overdose }} />
        </p>
        <p>
          <div className={styles.bold}>【药理毒理】</div>
          <div dangerouslySetInnerHTML={{ __html: pharmacologyAndToxicology }} />
        </p>
        <p>
          <div className={styles.bold}>【药代动力学】</div>
          <div dangerouslySetInnerHTML={{ __html: pharmacokinetics }} />
        </p>
        <p>
          <div className={styles.bold}>【贮藏】</div>
          <div dangerouslySetInnerHTML={{ __html: storage }} />
        </p>
        <p>
          <div className={styles.bold}>【包装】</div>
          <div dangerouslySetInnerHTML={{ __html: pack }} />
        </p>
        <p>
          <div className={styles.bold}>【有效期】</div>
          <div dangerouslySetInnerHTML={{ __html: termOfValidity }} />
        </p>
        <p>
          <div className={styles.bold}>【执行标准】</div>
          <div dangerouslySetInnerHTML={{ __html: executiveStandard }} />
        </p>
        <p>
          <div className={styles.bold}>【批准文号】</div>
          <div dangerouslySetInnerHTML={{ __html: approvalNo }} />
        </p>
        <p>
          <div className={styles.bold}>【生产企业】</div>
          <div dangerouslySetInnerHTML={{ __html: manufacturer }} />
        </p>
        {/* 原型里没有的字段  */}
        <p>
          <div className={styles.bold}>【核准日期】</div>
          <div dangerouslySetInnerHTML={{ __html: approvalDate }} />
        </p>
        <p>
          <div className={styles.bold}>【修订日期】</div>
          <div dangerouslySetInnerHTML={{ __html: revisionDate }} />
        </p>
        <p>
          <div className={styles.bold}>【最新修订日期】</div>
          <div dangerouslySetInnerHTML={{ __html: lastRevisionDate }} />
        </p>
      </div>
    </div>
  )
}

export default DESC
