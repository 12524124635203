import accountApi from '@api/account'
import appApi from '@api/app'
import projectApi from '@api/project'
import permissionApi from '@api/permission'
import tagApi from '@api/tag'
import dictionaryApi from '@api/dictionary'
import desensitizationApi from '@api/desensitization'
import knowledgeApi from '@api/knowledge'
import mappingApi from '@api/mapping'
import markApi from '@api/mark'
import commonApi from '@api/common'
import aeApi from './ae'
import feedbackApi from './feedback'
import menuApi from './menu'
import omopApi from './omop'
import patientApi from './patient'
import cdiscApi from './cdisc'
import omopControlApi from './omopControl'

const api = {
  accountApi,
  appApi,
  projectApi,
  permissionApi,
  tagApi,
  dictionaryApi,
  desensitizationApi,
  knowledgeApi,
  mappingApi,
  markApi,
  commonApi,
  aeApi,
  feedbackApi,
  menuApi,
  omopApi,
  patientApi,
  cdiscApi,
  omopControlApi,
}

export default api
