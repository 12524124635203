/*
* 输出控件
* */

// eslint-disable-next-line no-unused-vars
import {Form, Select, Input, Checkbox, Row, Col, Space, Button} from 'antd'
import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {get} from 'lodash'
import fetch from '@utils/request'
import api from '@api'

const dicTypes = [{
  key: 'prime',
  title: 'Prime 标准字典'
}, {
  key: 'omop',
  title: 'OMOP 标准字典'
}]
const ExportWidget = ({namePath, ...restProps}) => {
  console.count('ExportWidget')
  console.log('ExportWidget:namePath', namePath)
  const [dicList, setDicList] = useState([])
  const [dicList1, setDicList1] = useState([])
  const sysConst = useSelector((state) => state.app.sysConst)
  const exportOptions = sysConst.filter(item => item.type === 'engine_output_type')[0]?.valueNameVOS
  const form = Form.useFormInstance()
  const fetchOMOPDicList = async () => {
    const res = await fetch({
      method: 'POST',
      url: api.dictionaryApi.treeList,
      data: {standardType: 'omop'}
    })
    // v1.8 版本，OMOP字典类型只支持icd10(诊断)
    setDicList1(res)
  }
  const fetchDicList = async () => {
    const res = await fetch({
      method: 'POST',
      url: api.dictionaryApi.treeList,
      data: {}
    })
    setDicList(res)
  }
  useEffect(() => {
    fetchDicList()
    fetchOMOPDicList()
  }, [restProps.itemKey])
  return (
    <>
      <Form.Item
        label="选择输出类型"
        name={[...namePath, 'outputType']}
        rules={[
          {
            required: true,
            message: '选择输出类型',
          },
        ]}
      >
        <Select
          placeholder="选择输出类型"
          options={exportOptions}
          fieldNames={{label: 'name'}}
        />
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) => get(prevValues, [...namePath, 'outputType']) !== get(currentValues, [...namePath, 'outputType'])}
      >
        {({ getFieldValue }) => {
          if (getFieldValue([...namePath, 'outputType']) === 'custom') {
            return (
              <Form.Item
                label="输出内容"
                name={[...namePath, 'defaultValue']}
                rules={[
                  {
                    required: true,
                    message: '请输入输出内容',
                  },
                ]}
              >
                <Input placeholder="请输入输出内容"/>
              </Form.Item>
            )
          }
          if (getFieldValue([...namePath, 'outputType']) === 'str') {
            return (
              <>
                <p>输出标准</p>
                <Form.List
                  name={[...namePath, 'standardOutputs']}
                  initialValue={dicTypes}
                >
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <Row key={key} gutter={16}>
                          <Col span={9}>
                            <Form.Item
                              getValueProps={ value => ({ checked: !!value, value }) }
                              getValueFromEvent={ e => e.target.checked ? dicTypes[name].key : null }
                              {...restField}
                              name={[name, 'type']}
                            >
                              <Checkbox>
                                {dicTypes[name].title}
                              </Checkbox>
                            </Form.Item>
                          </Col>
                          <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) => get(prevValues, [...namePath, 'standardOutputs', name, 'type']) !== get(currentValues, [...namePath, 'standardOutputs', name, 'type'])}
                          >
                            {
                              ({ getFieldValue })=> {
                                const checkedFlag = getFieldValue([...namePath, 'standardOutputs', name, 'type'])
                                if (!checkedFlag) {
                                  form.setFieldValue([...namePath, 'standardOutputs', name, 'vocabulary'], null)
                                  form.setFieldValue([...namePath, 'standardOutputs', name, 'sources'], [])
                                  return null
                                }

                                return (
                                  <>
                                    <Col span={7}>
                                      <Form.Item
                                        {...restField}
                                        name={[name, 'vocabulary']}
                                        style={{flex: 1}}
                                        rules={[
                                          {
                                            required: true,
                                            message: '请选择类型',
                                          },
                                        ]}
                                      >
                                        <Select
                                          showSearch={true}
                                          optionFilterProp='name'
                                          placeholder="请选择类型"
                                          options={name?dicList1:dicList}
                                          fieldNames={{label: 'name', value: 'code'}}
                                          onChange={() => {

                                            setTimeout(() => {
                                              form.setFieldValue([...namePath, 'standardOutputs', name, 'sources'], [])
                                              restProps.asyncData()
                                              // form.setFieldValue([...namePath, 'selectedDictList', 0, 'data'], [])
                                            }, 0)
                                          }}
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                      <Form.Item
                                        noStyle
                                        shouldUpdate={(prevValues, currentValues) => get(prevValues, [...namePath, 'standardOutputs', name, 'vocabulary']) !== get(currentValues, [...namePath, 'standardOutputs', name, 'vocabulary'])}
                                      >
                                        {({ getFieldValue }) => {
                                          const curTypeKey = getFieldValue([...namePath, 'standardOutputs', name, 'vocabulary'])
                                          const curType = (name?dicList1: dicList).filter(item => item.code === curTypeKey)[0]
                                          const versionOptions = curType?.sources.map( item => ({label: item, value: item}))
                                          return (
                                            <Form.Item
                                              {...restField}
                                              name={[name, 'sources', 0]}
                                              rules={[
                                                {
                                                  required: true,
                                                  message: '请选择版本',
                                                },
                                              ]}
                                            >
                                              <Select
                                                showSearch={true}
                                                optionFilterProp='label'
                                                placeholder="请选择版本"
                                                options={versionOptions}
                                                onChange={() => {
                                                  setTimeout(() => {
                                                    // form.setFieldValue([...namePath, 'selectedDictList', 0, 'data'], [])
                                                  }, 200)
                                                }}
                                              />
                                            </Form.Item>
                                          )
                                        }}
                                      </Form.Item>
                                    </Col>
                                  </>
                                )
                              }
                            }
                          </Form.Item>
                        </Row>
                      ))}
                    </>
                  )}
                </Form.List>
              </>
            )
          }
          return null
        }}
      </Form.Item>
    </>
  )
}
export default ExportWidget
