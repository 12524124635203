
import React, {useEffect, useState} from 'react'
import {Tag, Space, Card} from 'antd'
const PrimeTag = ({sourceData, nameKey, itemKey, onEdit, ...restProps}) => {
  const [source, setSource] = useState([])
  const closeEvent = (index) => {
    source.splice(index, 1)
    onEdit(source)
  }

  const TagList = () => {
    return (
      source?.map((item, index) => (
        <Tag key={index}
          closable
          onClose={(e) => {
            closeEvent(index)
          }}
        >
          {itemKey? item?.[itemKey]: item}
        </Tag>
      ))
    )
  }
  useEffect(() => {
    sourceData && setSource(sourceData)
  }, [sourceData])
  if (!source?.length) return null
  if (!itemKey) return TagList
  return (

    <Card bodyStyle={{padding: '8px 12px'}}>
      <Space wrap >
        <TagList/>
      </Space>
    </Card>
  )
}



export default PrimeTag
