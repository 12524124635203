/*
* 相关api(登录、登出、密码)
* */
const desensitizationApi = {
  add: '/kbms/desensitization/add', // 新增
  delete: '/kbms/desensitization/delete', // 删除
  list: '/kbms/desensitization/list', // 列表
  update: '/kbms/desensitization/update', // 修改
}

export default desensitizationApi
