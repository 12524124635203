
import React from 'react'
import {Button} from 'antd'
import styles from '@views/tag/edit/components/index.module.less'
// import iconSelectAll from '@assets/images/flow-diagram/icon-select-all.png'
import iconCopy from '@assets/images/flow-diagram/icon-copy.png'
import iconPaste from '@assets/images/flow-diagram/icon-paste.png'
import iconRedo from '@assets/images/flow-diagram/icon-redo.png'
import iconUndo from '@assets/images/flow-diagram/icon-undo.png'
import iconDelete from '@assets/images/flow-diagram/icon-delete.png'
const Toolbar = ({diagramInstance}) => {
  return (
    <div className="btnGroup">
      {/* <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}} > */}
      {/*  <Button className={styles.btnItem} style={{width: '40px', height: '32px'}} id="SelectAll" onClick={() => { */}
      {/*    diagramInstance.commandHandler.selectAll() */}
      {/*  }}> */}
      {/*    <img style={{width: '16px', height: '16px'}} src={iconSelectAll} className={styles.iconImg} /> */}
      {/*  </Button> */}
      {/*  <div style={{fontSize: '12px', marginTop: '6px', color: '#3D3D3D', fontWeight: 400}}>全选</div> */}
      {/* </div> */}
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}} >
        <Button className={styles.btnItem} style={{width: '40px', height: '32px'}} id="Copy" onClick={() => {
          diagramInstance.commandHandler.copySelection()
        }}>
          <img style={{width: '16px', height: '16px'}} src={iconCopy} className={styles.iconImg} />
        </Button>
        <div style={{fontSize: '12px', marginTop: '6px', color: '#3D3D3D', fontWeight: 400}}>复制</div>
      </div>

      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}} >
        <Button className={styles.btnItem} style={{width: '40px', height: '32px'}} id="Paste" onClick={() => {
          diagramInstance.commandHandler.pasteSelection()
        }}>
          <img style={{width: '16px', height: '16px'}} src={iconPaste} className={styles.iconImg} />
        </Button>
        <div style={{fontSize: '12px', marginTop: '6px', color: '#3D3D3D', fontWeight: 400}}>粘贴</div>
      </div>

      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}} >
        <Button className={styles.btnItem} style={{width: '40px', height: '32px'}} id="Undo" onClick={() => {
          diagramInstance.commandHandler.undo()
        }}>
          <img style={{width: '16px', height: '16px'}} src={iconRedo} className={styles.iconImg} />
        </Button>
        <div style={{fontSize: '12px', marginTop: '6px', color: '#3D3D3D', fontWeight: 400}}>撤销</div>
      </div>
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}} >
        <Button className={styles.btnItem} style={{width: '40px', height: '32px'}} id="Redo" onClick={() => {
          diagramInstance.commandHandler.redo()
        }}>
          <img style={{width: '16px', height: '16px'}} src={iconUndo} className={styles.iconImg} />
        </Button>
        <div style={{fontSize: '12px', marginTop: '6px', color: '#3D3D3D', fontWeight: 400}}>恢复</div>
      </div>
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}} >
        <Button className={styles.btnItem} style={{width: '40px', height: '32px'}} id="Delete" onClick={() => {
          diagramInstance.commandHandler.deleteSelection()
        }}>
          <img style={{width: '16px', height: '16px'}} src={iconDelete} className={styles.iconImg} />
        </Button>
        <div style={{fontSize: '12px', marginTop: '6px', color: '#3D3D3D', fontWeight: 400}}>删除</div>
      </div>
    </div>
  )
}

export default Toolbar
