/*
* 运算符控件
* */

import React, {useMemo} from 'react'
import {connect, useSelector} from 'react-redux'
import {Field, formValueSelector} from 'redux-form'
import {ASelect} from '@components/redux-form-antd'

let OperatorWidget = ({namePath, outputType}) => {
  const sysConst = useSelector((state) => state.app.sysConst)
  const exportOptions = sysConst.filter(item => item.type === 'engine_operator_output')[0]?.valueNameVOS

  const oprOptions = useMemo(() => {
    return sysConst.filter(item => item.type === `engine_operator_${outputType}`)[0]?.valueNameVOS
  }, [outputType])
  return (
    <>
      <Field
        label="输出类型"
        name={`${namePath}.leftType`}
        component={ASelect}
        value={null}
        placeholder="选择输出类型"
        fieldNames={{label: 'name'}}
        options={exportOptions}
        onFocus={(e) => e.preventDefault()}
        onBlur={(e) => e.preventDefault()}
      />
      <Field
        label="选择运算符"
        name={`${namePath}.operator`}
        component={ASelect}
        value={null}
        placeholder="选择运算符"
        fieldNames={{label: 'name', value: 'value'}}
        options={oprOptions}
        onFocus={(e) => e.preventDefault()}
        onBlur={(e) => e.preventDefault()}
      />
    </>
  )
}

const selector = formValueSelector('tag') // <-- same as form name
const mapStateToProps = (state, ownProps) => {
  const {namePath} = ownProps
  const outputType = selector(state, `${namePath}.leftType`)
  return {
    outputType,
  }
}

OperatorWidget = connect(mapStateToProps, null)(OperatorWidget)
export default OperatorWidget
