/*
 * 标签管理
 * */
const tagApi = {
  getMetaList: '/kbms/meta/list', // 元数据树
  getNodeConfig: '/kbms/meta/detail', // 元数据画布配置
  category: '/kbms/label/category', // 获取项目标签 - 弹框
  listByCategory: '/kbms/label/listByCategory', // 获取分类下标签列表
  listAllByCategory: '/kbms/label/listAllByCategory', // 获取分类下标签列表 - 全部
  labelSave: '/kbms/label/save', // 保存-更新标签
  getCheckStatusMap: '/kbms/label/getCheckStatusMap', // 获取标签审核状态枚举
  getLabelDetail: '/kbms/label/get', // 获取标签详情
  auditTag: '/kbms/label/updateCheckStatus', // 标签审核
  labelCount: '/kbms/label/labelCount', // 标签统计
  deleteLabel: '/kbms/label/deleteLabel', // 标签删除
  detailLabel: '/kbms/label/get', // 获取标签详情

  editTree: '/kbms/label/saveTree', // 保存-更新标签树节点
  deleteTree: '/kbms/label/deleteTree', // 删除标签树节点

  statisticsTime: '/kbms/rule/statistics/now', // 管理看板-获取系统时间
  labelDownload: '/kbms/label/download', // 模板下载
  labelImport: '/kbms/label/import', // 标签导入

  stockRule: '/kbms/label/getStockRule', // 分配标签-标签库列表
  projectAll: '/kbms/project/all', // 分配标签-项目列表

  stockRuleRecommend: '/kbms/label/stockRuleRecommend', // 分配标签-标签库列表-搜索词推荐
  listMetaAttributes: '/kbms/meta/listMetaAttributes', // 获取元数据属性列表
  listMetaSearch: 'kbms/meta/list', // 元数据搜索
  labelDistribute: '/kbms/label/labelDistribute', // 分配标签-标签库列表-搜索词推荐
  generalContentTree: '/kbms/generalContent/tree', // 获取目录树
  getTagsByCategory: '/kbms/rule/crfProject/ruleList', // crf项目标签列表

  aiList: '/kbms/ai/list', // 算法控件
  aiModelProperties: '/kbms/ai/model/properties', // 取算法属性
  crfProjectList: '/kbms/rule/crfProject/list', // crf项目列表
  listDataElement: '/kbms/metaDataInfo/listDataElement', // 数据元素列表+查询
  listDataSource: '/kbms/metaDataInfo/listDataSource', // 数据元素列表+查询
  listValidDetailAll: '/kbms/thesaurus/listValidDetailAll', // 同义词列表
}

export default tagApi
