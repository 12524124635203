import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react'
import { Modal, Form, TreeSelect, message } from 'antd'
import fetch from '@utils/request'
import api from '@api'

const DicModal = (props, ref) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [recordId, setRecordId] = useState(null)
  const [value, setValue] = useState()
  const [treeData, setTreeData] = useState([])
  const [form] = Form.useForm()

  const handleOpen = (e) => {
    setRecordId(e)
    setIsModalVisible(true)
  }

  useEffect(() => {
    if (!isModalVisible) return
    setTreeData(props.tree)
  }, [isModalVisible])

  const onChange = (newValue) => {
    setValue(newValue)
  }

  const handleOk = async() => {
    await form.submit()
  }

  const handleCancel = () => {
    form.resetFields()
    setIsModalVisible(false)
  }

  const onFinish = async(values) => {
    await fetch({
      method: 'POST',
      url: api.dictionaryApi.updatePath,
      data: {
        currentConceptId: recordId,
        targetConceptId: values.code
      }
    })
    message.success('操作成功！')
    props.onSuccess()
    handleCancel()
  }

  useImperativeHandle(ref, () => ({
    handleOpen
  }))

  const updateTreeData = (list, key, children) =>
    list.map((node) => {
      if (node.id === key) {
        return { ...node, children }
      }

      if (node.children) {
        return {
          ...node,
          children: updateTreeData(node.children, key, children),
        }
      }
      return node
    })

  const onLoadData = async (e) => {
    const {content, totalCount} = await fetch({
      method: 'POST',
      url: api.dictionaryApi.listDirectDescendant,
      data: {
        parentCode: e.keyCopy,
        dictionaryCode: props.node?.code,
      }
    })
    if (totalCount && totalCount > 0) {
      setTreeData((origin) => updateTreeData(origin, e.key, content))
    }
  }

  return (
    <Modal title="选择分类" open={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
      <Form
        form= {form}
        labelCol={{
          span: 6,
        }}
        wrapperCol={{
          span: 16,
        }}
        onFinish={onFinish}
      >
        <Form.Item
          label="选择分类"
          name="code"
          rules={[
            {
              required: true,
              message: '请选择分类',
            },
          ]}
        >
          <TreeSelect
            showSearch
            style={{
              width: '100%',
            }}
            fieldNames={{
              label: 'title',
              value: 'id',
            }}
            value={value}
            placeholder="请选择"
            allowClear
            onChange={onChange}
            loadData={onLoadData}
            treeData={treeData}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default forwardRef(DicModal)

