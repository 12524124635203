/*
* 知识图谱控件
* */

import {Form, Button, Tag, Card, Space} from 'antd'
import React, {useEffect, useRef, useState} from 'react'
import {cloneDeep} from 'lodash'
import KnowledgeModal from '@views/tag/edit/components/label-from-dic/knowledgeModal'
import fetch from '@utils/request'
import api from '@api'
import {change, Field, FieldArray, formValueSelector} from 'redux-form'
import {ASelect} from '@components/redux-form-antd'
import {connect} from 'react-redux'
import {useWindowSize} from '@hooks/useLayout'

let KnowledgeWidget = ({namePath, selectedDictCode, changeArray, ...restProps}) => {
  const [dicList, setDicList] = useState([])
  const dicModalRef = useRef(null)
  // eslint-disable-next-line no-unused-vars
  const [relationOptions, setRelationOptions] = useState([])
  const [,, scale] = useWindowSize()

  const fetchDicList = async () => {
    const res = await fetch({
      method: 'GET',
      url: api.dictionaryApi.getRelationAllType,
    })
    setDicList(res)
  }
  /*
  * 获取同义词
  * */
  const fetchSynonym = async (params) => {
    const res = await fetch({
      method: 'GET',
      url: api.dictionaryApi.getRelationAll,
      params,
    })
    return res
  }
  const doneCallback = async (data) => {
    const convertData = cloneDeep(data)
    const tempConvertData = convertData.map(({entityConceptCode, entityConceptName}) => ({
      key: entityConceptCode,
      title: entityConceptName
    }))
    changeArray(
      'tag',
      `${namePath}.selectedDictList[0].data`,
      tempConvertData
    )
    const conceptCodes = data?.map((item) => item.entityConceptId)
    const synonymData = await fetchSynonym({entityConceptIdList: conceptCodes.join(',')})
    setRelationOptions(synonymData.map((item) => ({label: item, value: item})))
  }

  const renderTagList = ({ fields, meta: { touched, error } }) => (
    <Form.Item
      label='选择实体'
      labelCol={{
        span: 6,
      }}
    >
      <Card bodyStyle={{padding: '8px 12px', minHeight: `${32*scale}px`}}>
        <Space wrap >
          {fields.map((tag, index) => {
            const item = fields.get(index)
            console.log('item', item)
            return (
              <div key={index}>
                <Tag
                  closable
                  onClose={(e) => fields.remove(index)}
                >
                  {item.title}
                </Tag>
              </div>
            )
          }
          )}
        </Space>
      </Card>
    </Form.Item>
  )
  useEffect(() => {
    fetchDicList()
  }, [])

  return (
    <>
      <Field
        label='选择类型'
        name={`${namePath}.selectedDictList[0].code`}
        component={ASelect}
        value={null}
        placeholder="请选择类型"
        fieldNames={{label: 'name', value: 'code'}}
        showSearch={true}
        optionFilterProp='name'
        options={dicList}
        onFocus={(e) => e.preventDefault()}
        onBlur={(e) => e.preventDefault()}
      />
      {selectedDictCode && (
        <>
          <div style={{display: 'flex', justifyContent: 'flex-end', marginBottom: '16px'}}>
            <Button
              ghost
              type='primary'
              size='middle'
              onClick={() => {
                dicModalRef.current.show()
              }}
            >
                    选择实体
            </Button>
          </div>
          <KnowledgeModal ref={dicModalRef} doneCallback={doneCallback} dictionaryCode={selectedDictCode} dictionaryType='flat'/>
        </>
      )}
      <FieldArray name={`${namePath}.selectedDictList[0].data`} component={renderTagList}/>
      <Field
        label='选择关系'
        name={`${namePath}.relations`}
        component={ASelect}
        value={null}
        placeholder="请选择关系"
        mode="multiple"
        options={relationOptions}
        onFocus={(e) => e.preventDefault()}
        onBlur={(e) => e.preventDefault()}
      />
      <Field
        label='数据类型'
        name={`${namePath}.type`}
        disabled
        component={ASelect}
        defaultValue='text'
        placeholder="请选择数据类型"
        options={[{
          label: '文本',
          value: 'text'
        }]}
        onFocus={(e) => e.preventDefault()}
        onBlur={(e) => e.preventDefault()}
      />
    </>
  )
}


const selector = formValueSelector('tag') // <-- same as form name
const mapStateToProps = (state, ownProps) => {
  const {namePath} = ownProps
  const selectedDictCode = selector(state, `${namePath}.selectedDictList[0].code`)
  return {
    selectedDictCode,
  }
}
const mapDispatchToProps = {
  // NOTE: This MUST be aliased or it will not work. Thanks Jack!
  changeArray: change
}
KnowledgeWidget = connect(mapStateToProps, mapDispatchToProps)(KnowledgeWidget)


export default KnowledgeWidget
