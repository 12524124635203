import React, { useState, forwardRef, useImperativeHandle } from 'react'
import { Input, Button, Space } from 'antd'

const KeySearch = (props, ref) => {
  const [key, setKey] = useState('')
  const getValue = () => {
    return key
  }
  const setValue = () => {
    return setKey('')
  }
  const onClick = () => {
    props.keySearchClick(key)
  }
  useImperativeHandle(ref, () => ({
    getValue,
    setValue
  }))
  return (
    <>
      <Space>
        <div>关键词：</div>
        <Input onBlur={() => {
          props.keySearchClick(key)
        }} enterButton value={key} placeholder="请输入关键词搜索" allowClear onChange={(e) => {
          setKey(e.target.value)
        }}/>
        <Button type="primary" onClick={onClick}>搜索</Button>
      </Space>
    </>
  )
}

export default forwardRef(KeySearch)
