import React, { useState, useContext, useEffect } from 'react'
import { Table } from 'antd'
import { formatDateFromTimestamp, withEmptyRender } from '@utils/common'
import fetch from '@utils/request'
import api from '@api'
import usePagination from '@hooks/usePagination'
import PatientContext from '../context'

const Inspect = ({ targetPage, render }) => {
  const { activeVisit, projectId, patientId } = useContext(PatientContext)
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [page, setPage, setTotal, pagination] = usePagination({
    initialPage: {
      pageIndex: targetPage || 1,
      pageSize: 10,
    },
  })

  // useEffect(() => {
  //   if (targetPage > 1) {
  //     setPage({
  //       pageIndex: targetPage,
  //       pageSize: 1,
  //     })
  //   }
  // }, [targetPage])

  const columns = withEmptyRender([
    {
      title: '申请单号',
      dataIndex: 'exam_request_no',
      render,
    },
    {
      title: '检查分类名称',
      dataIndex: 'exam_category_name',
      render,
    },
    {
      title: '检查分类编码',
      dataIndex: 'exam_category_code',
      render,
    },
    {
      title: '标准检查项目名称',
      dataIndex: 'standard_exam_item_name',
      render,
    },
    {
      title: '标准检查项目编码',
      dataIndex: 'standard_exam_item_code',
      render,
    },
    {
      title: '源检查项目名称',
      dataIndex: 'origin_exam_item_name',
      render,
    },
    {
      title: '源检查项目编码',
      dataIndex: 'origin_exam_item_code',
      render,
    },
    {
      title: '检查时间',
      dataIndex: 'exam_time',
      render: (text, record) => render(formatDateFromTimestamp(text) || '-', record),
    },
    {
      title: '报告时间',
      dataIndex: 'report_time',
      render: (text, record) => render(formatDateFromTimestamp(text) || '-', record),
    },
    {
      title: '检查部位',
      dataIndex: 'exam_part_name',
      render,
    },
    {
      title: '检查描述',
      dataIndex: 'exam_desc',
      render,
      width: 600,
    },
    {
      title: '检查结果',
      dataIndex: 'exam_result',
      width: 280,
      render,
    },
  ])

  const handleTableChange = (pagination) => {
    setPage({
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
    })
  }

  const fetchRecord = async () => {
    const pager = {
      pageNumber: page.pageIndex,
      pageSize: page.pageSize,
    }
    setLoading(true)
    const { indexNo, type } = activeVisit
    const { content, totalCount } = await fetch({
      url: api.patientApi.patientRecords,
      method: 'GET',
      params: {
        patientId,
        indexNo,
        type: 3,
        category: type,
        proId: projectId,
        ...pager,
      },
    })
    setData(content)
    setLoading(false)
    setTotal(totalCount)
  }

  useEffect(() => {
    if (patientId) {
      fetchRecord()
    }
  }, [activeVisit, page])

  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        rowKey={(record) => record.id}
        size="small"
        pagination={pagination}
        loading={loading}
        onChange={handleTableChange}
        scroll={{ x: 'max-content' }}
      />
    </>
  )
}

export default Inspect
