
import React from 'react'
import {Table, Transfer} from 'antd'
import difference from 'lodash/difference'

const TableTransfer = ({ leftColumns, rightColumns, ...restProps }) => (
  <Transfer
    rowKey={(record) => record.id}
    {...restProps}>
    {({
      direction,
      filteredItems,
      onItemSelectAll,
      onItemSelect,
      selectedKeys: listSelectedKeys,
      disabled: listDisabled,
    }) => {
      const columns = direction === 'left' ? leftColumns : rightColumns
      if (direction === 'left') {
        const rowSelection = {
          type: 'Checkbox',
          getCheckboxProps: (item) => ({
            disabled: listDisabled || item.disabled,
          }),
          onSelectAll(selected, selectedRows) {
            const treeSelectedKeys = selectedRows
              .filter((item) => !item.disabled)
              .map(({ id }) => id)
            const diffKeys = selected
              ? difference(treeSelectedKeys, listSelectedKeys)
              : difference(listSelectedKeys, treeSelectedKeys)
            onItemSelectAll(diffKeys, selected)
          },
          onSelect({ id }, selected) {
            onItemSelect(id, selected)
          },
          selectedRowKeys: listSelectedKeys,
        }
        return (
          <Table
            rowSelection={rowSelection}
            columns={columns}
            showHeader={false}
            dataSource={filteredItems}
            size="small"
            style={{
              pointerEvents: listDisabled ? 'none' : undefined,
            }}
            onRow={({ id, disabled: itemDisabled }) => ({
              onClick: () => {
                if (itemDisabled || listDisabled) return
                onItemSelect(id, !listSelectedKeys.includes(id))
              },
            })}
            pagination={restProps.pagination}
            onChange={restProps.handleTableChange}
          />
        )
      }
    }}
  </Transfer>
)

export default TableTransfer
