import React, { useState, useEffect } from 'react'
import Highlighter from 'react-highlight-words'
import { useSearchParams, useNavigate, useLocation } from 'react-router-dom'
import { Button, Tabs, message, Row, Col, Space, Popover, Spin } from 'antd'
import cls from 'classnames'
import moment from 'moment'
import fetch from '@utils/request'
import api from '@api'
import { moduleMap } from '../constants'
import DetailCard from './components/detailCard'
import PatientContext from './context'
import styles from './index.module.less'
import Diagnosis from './tabs/diagnosis'
import Examine from './tabs/examine'
import Inspect from './tabs/inspect'
import Medicine from './tabs/medicine'
import Pathology from './tabs/pathology'
import ProgressNote from './tabs/progressNote'
import Surgery from './tabs/surgery'

// 高亮
export const renderHighLight = (text, record, searchOutText) => {
  if (searchOutText && text) {
    return (
      <Highlighter
        highlightClassName="record-highlight"
        searchWords={[searchOutText]}
        autoEscape={true}
        textToHighlight={text}
      />
    )
  } else {
    return <div>{text === 0 ? text : text || '-'}</div>
  }
}

export const domDfs = (dom, query) => {
  if (!dom) return
  if (dom.childNodes.length > 0) {
    dom.childNodes.forEach((item) => {
      if (item.nodeType === 3) {
        if (query) {
          let reg = new RegExp(query, 'g')
          item.textContent = item.textContent.replace(
            reg,
            '<span style="color: #000; background-color: #FFDD82">' + query + '</span>'
          )
        }
      } else {
        domDfs(item, query)
      }
    })
  }
}

/**
 * @description 定位标签数据源
 */
const tabInfo = [
  'dwd_patient_info',
  'dwd_lab_result',
  'dwd_exam_report',
  'dwd_ip_drug_detail, dwd_op_prescription_detail',
  'dwd_pathology_report',
  'dwd_surgery_exec',
  'dwd_diagnosis',
]

export const secTabInfo = {
  dwd_doc_admit_record: '入院记录',
  dwd_doc_first_course_info: '首次病程记录',
  dwd_doc_daily_course_info: '日常病程记录',
  dwd_doc_doctor_round_info: '查房记录',
  dwd_doc_phase_sum_info: '阶段小结',
  dwd_doc_discharge_sum_info: '出院小结',
  dwd_doc_outpat_case_record: '门急诊病历',
  dwd_nursing_record: '护理记录表',
}

const PatientRecord = ({patientId, ruleIdAndVersionList, ...restProps}) => {
  const location = useLocation()
  const navigate = useNavigate()
  const [baseInfo, setBaseInfo] = useState({})
  const [params] = useSearchParams()
  const projectId = params.get('projectId')
  const from = params.get('from')
  const type = params.get('type')
  const id = params.get('id')
  const indexNo = params.get('indexNo')
  const queryLabelId = params.get('labelId')
  const searchVal = params.get('searchVal') || ''
  const [visits, setVisits] = useState([])
  const [activeVisit, setActiveVisit] = useState({})
  const [active, setActive] = useState(null)
  const [tabSelect, setTabSelect] = useState(null)
  const [loadings, setLoadings] = useState([])
  const [labelId, setLabelId] = useState(null)
  const [infoShow, setInfoShow] = useState(true)
  const [targetPage, setTargetPage] = useState(1)
  const [itemType, setItemType] = useState('非培养类')
  const [loading, setLoading] = useState(false)

  const showNav = true

  /* eslint-disable */
  const breadcrumb =
    from === 'dashBoardPatientList'
      ? [
          {
            name: '项目列表',
          },
          {
            name: '项目详情',
          },
          {
            name: '看板数据',
          },
          {
            name: '患者档案',
          },
        ]
      : [
          {
            name: '项目列表',
          },
          {
            name: '项目详情',
          },
          {
            name: '患者档案',
          },
        ]
  /* eslint-enable */

  const getPatientVisits = async () => {
    const patientVisits = await fetch({
      url: api.patientApi.patientVisits,
      method: 'GET',
      params: {
        patientId,
      },
    })
    setVisits(
      patientVisits.map((item) => ({
        ...item,
        label: item.desc,
        value: item.id,
      }))
    )
  }

  // 命中标签值
  const [hitData, setHitData] = useState({})
  // 命中表名
  const [hitTableName, setHitTableName] = useState('')
  // const setHighHtml = (dom, query) => {
  //   let wrap = document.getElementById(dom)
  //   if (!wrap) return
  //   let innerHTML = wrap.innerHTML
  //   if (query) {
  //     let reg = new RegExp(query, 'g')
  //     innerHTML = innerHTML.replace(
  //       reg,
  //       '<span style="color: #000; background-color: #FFDD82">' + query + '</span>'
  //     )
  //   }
  //   wrap.innerHTML = innerHTML
  //   // setHitValue(query)
  // }
  // const clearHighHtml = (dom) => {
  //   let wrap = document.getElementById(dom)
  //   if (!wrap) return
  //   let innerHTML = wrap.innerHTML
  //   if (hitValue) {
  //     let preReg = new RegExp(
  //       '<span style="color: #000; background-color: #FFDD82">' + hitValue + '</span>',
  //       'g'
  //     )
  //     innerHTML = innerHTML.replace(preReg, hitValue)
  //   }
  //   wrap.innerHTML = innerHTML
  // }

  // 左侧病诊例次切换
  const tabChange = (index, fromLabel) => {
    setActive(index)
    const activeVisit = visits[index]
    setActiveVisit(activeVisit)
    if (fromLabel) return
    if (visits[index].labType) {
      let current = visits[index].labType.split(',')[0]
      setTabSelect(current)
    }
    // 清除高亮
    if (labelId) {
      setLabelId(null)
      setHitData({})
    }
  }

  // tab切换
  const tabSelectChange = (e) => {
    setTabSelect(e)
    // 清除高亮
    if (labelId) {
      setLabelId(null)
      setHitData({})
    }
  }
  // 根据indexNo定位
  const indexPosition = (no, fromLabel = false) => {
    if (no === 'null') {
      tabChange(0)
      return
    }
    let _index = 0
    let flag = false
    for (let i = 0; i < visits.length; i++) {
      if (visits[i].indexNo === no) {
        _index = i
        flag = true
        break
      }
    }
    if (!flag) {
      setActive(null)
      setActiveVisit({
        indexNo: no,
        patientId: patientId,
      })
      return
    }
    flag && tabChange(_index, fromLabel)
  }

  useEffect(() => {
    if (!visits.length) return
    indexPosition(indexNo)
  }, [visits, indexNo])

  const getPatientDetail = async () => {
    setLoading(true)
    let detail = {}
    if (patientId) {
      detail = await fetch({
        url: api.patientApi.patientDetail,
        method: 'POST',
        data: {
          ruleIdAndVersionList: ruleIdAndVersionList,
          patientId,
          id,
          proId: projectId,
          moduleName: moduleMap[from] || '',
          searchVal,
          type,
        },
        headers: {
          'Content-Type': 'application/json'
        },
      })
      setBaseInfo(detail)
    }
    await getPatientVisits()
    setLoading(false)
  }

  useEffect(() => {
    patientId && getPatientDetail()
  }, [patientId])

  const render = (text, record) => {
    return renderHighLight(text, record, hitData.tableName !== tabInfo[0] && hitData.hitValue)
  }

  const tabs = [
    {
      key: '1',
      label: '病历',
      children: (
        <ProgressNote render={render} hitValue={hitData.hitValue} hitTable={hitTableName} />
      ),
    },
    {
      key: '2',
      label: '检验',
      children: (
        <div id="tabPosition">
          <Examine
            targetPage={targetPage}
            render={render}
            itemTypeName={itemType}
            hitValue={hitData.hitValue}
          />
        </div>
      ),
    },
    {
      key: '3',
      label: '检查',
      children: (
        <div id="tabPosition">
          <Inspect targetPage={targetPage} render={render} />
        </div>
      ),
    },
    {
      key: '4',
      label: '给药',
      children: (
        <div id="tabPosition">
          <Medicine targetPage={targetPage} render={render} />
        </div>
      ),
    },
    {
      key: '5',
      label: '病理',
      children: (
        <div id="tabPosition">
          <Pathology targetPage={targetPage} render={render} />
        </div>
      ),
    },
    {
      key: '6',
      label: '手术信息',
      children: (
        <div id="tabPosition">
          <Surgery targetPage={targetPage} render={render} />
        </div>
      ),
    },
    {
      key: '7',
      label: '诊断信息',
      children: (
        <div id="tabPosition">
          <Diagnosis targetPage={targetPage} render={render} />
        </div>
      ),
    },
  ]

  const getPreOrNext = async (storageParam, e) => {
    const record = await fetch({
      url: api.storeApi.preOrNextRecord,
      method: 'GET',
      params: {
        ...storageParam,
        currentId: id,
        preOeNext: e,
        labelId: queryLabelId,
        type,
        patientId,
      },
    })
    return record
  }

  const infoChange = async (e) => {
    setInfoShow(false)
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings]
      newLoadings[e - 1] = true
      return newLoadings
    })
    const storageParam = JSON.parse(localStorage.getItem('params'))
    let record = await getPreOrNext(storageParam, e)
    if (!record) {
      if (e === 1) {
        if (storageParam.pageNumber > 1) {
          storageParam.pageNumber--
          localStorage.setItem('params', JSON.stringify(storageParam))
          record = await getPreOrNext(storageParam, e)
        }
      } else {
        storageParam.pageNumber++
        record = await getPreOrNext(storageParam, e)
        if (!record) {
          storageParam.pageNumber--
        }
        localStorage.setItem('params', JSON.stringify(storageParam))
      }
    }

    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings]
      newLoadings[e - 1] = false
      return newLoadings
    })
    setInfoShow(true)
    if (!record) {
      if (e === 1) {
        message.warning('当前是第一个，无上一个')
      } else {
        message.warning('当前是最后一个，无下一个')
      }
      return
    }
    const paramsList = location.search.split('?')[1].split('&')
    const searchKey = paramsList.map((item) => {
      if (item.indexOf('id=') !== -1) {
        return `id=${record.id || -1}`
      } else if (item.indexOf('patientId=') !== -1) {
        return `patientId=${record.patientId}`
      } else if (item.indexOf('indexNo=') !== -1) {
        return `indexNo=${record.indexNo}`
      } else {
        return item
      }
    })
    const url = searchKey.join('&')
    // setTabSelect(null)
    navigate(`/project/patientRecord?${url}`, {
      replace: true,
    })
  }

  const addStore = async (record) => {
    const formData = {
      patientId: id,
      proId: projectId,
      details: 1,
      moduleName: moduleMap['patientRecord'],
      searchVal,
      type,
    }
    if (type === '4') {
      formData.patientIdStr = patientId
    }
    await fetch({
      url: api.storeApi.addStore,
      method: 'POST',
      data: formData,
    })
    message.success(`${record.patientName}已通过初筛`)
    getPatientDetail()
  }

  const getTargetPage = async (searchParams) => {
    const res = await fetch({
      url: api.patientApi.getHitValuePageNumber,
      method: 'GET',
      params: searchParams,
    })
    return res
  }

  const labPosition = async (item) => {
    setHitData(item)

    if (item.tableName === tabInfo[0]) {
      // 患者基本信息
    } else {
      setTabSelect(null)
      let index = null
      tabInfo.forEach((val, i) => {
        if (val.indexOf(item.tableName) !== -1) index = i
      })
      if (index) {
        const targetTab = index + 1
        const { patientId, type } = activeVisit
        const searchParams = {
          hitValue: item.hitValue,
          patientId,
          indexNo: item.indexNo,
          type: String(targetTab),
          category: type,
          proId: projectId,
          pageSize: 10,
          pageNumber: 1,
        }
        if (targetTab > 1) {
          let tPage = 0
          if (targetTab === 2) {
            let itemTypeName = '微生物培养类'
            tPage = await getTargetPage({ ...searchParams, itemTypeName })
            if (!tPage) {
              itemTypeName = '非培养类'
              tPage = await getTargetPage({ ...searchParams, itemTypeName })
            }
            setItemType(itemTypeName)
          } else {
            tPage = await getTargetPage(searchParams)
          }
          if (tPage) {
            setTargetPage(tPage)
          }
        }
        setTabSelect(String(targetTab))
        indexPosition(item.indexNo, true)
      } else {
        setTimeout(() => {
          setTabSelect('1')
          indexPosition(item.indexNo, true)
          setHitTableName(item.tableName)
        }, 10)
      }
    }
  }

  /**
   * @description 标签切换
   */
  // const labelCheck = (item) => {
  //   if (!(item.matchs && item.matchs.length > 0)) return
  //   setLabelId(item.labelId)
  //   labPosition(item)
  // }

  const labelSource = (item) => {
    setLabelId(item.labelId)
    labPosition(item)
  }

  return (
    <PatientContext.Provider value={{ activeVisit, baseInfo, projectId, patientId }}>
      {restProps.children}
      <Spin spinning={loading}>
        <div className={styles.patientRecordHeader}>
          <div className="head-title">患者档案</div>
          <Space size="middle">
            {showNav && (
              <>
                <Button
                  type="primary"
                  className="custom-btn"
                  ghost
                  loading={loadings[0]}
                  onClick={() => infoChange(1)}
                >
                上一个
                </Button>
                <Button
                  type="primary"
                  className="custom-btn"
                  ghost
                  loading={loadings[1]}
                  onClick={() => infoChange(2)}
                >
                下一个
                </Button>
              </>
            )}

            {patientId && (
              <Button
                type="primary"
                className="custom-btn"
                disabled={baseInfo.storeStatus}
                onClick={() => addStore(baseInfo)}
              // onClick={handlePush}
              >
              通过初筛
              </Button>
            )}
          </Space>
        </div>

        <div className={styles.labelWrap}>
          {baseInfo.includeLabelDiagList &&
          baseInfo.includeLabelDiagList.map((item, index) => {
            return (
              // <Tooltip
              //   overlayClassName={'tooltipStyle'}
              //   key={index}
              //   color="#031F47CC"
              //   placement="bottom"
              //   title={
              //     item.matchs && item.matchs.length > 0
              //       ? item.matchs.map((item) => item.hitValue).join('; ')
              //       : `当前病历详情中不包含${item.labelName}`
              //   }
              // >
              //   <div
              //     className={cls(
              //       item.labelType === 1 && 'include-label',
              //       item.labelType === 2 && 'exclude-label',
              //       labelId === item.labelId && 'active-label',
              //       styles.labelTable
              //     )}
              //     onClick={() => labelCheck(item)}
              //   >
              //     {item.labelType === 2 ? `非 "${item.labelName}"` : item.labelName}
              //   </div>
              // </Tooltip>
              <Popover
                // overlayClassName={'tooltipStyle'}
                key={index}
                // color="#031F47CC"
                placement="bottom"
                content={
                  // eslint-disable-next-line multiline-ternary
                  item.matchs && item.matchs.length > 0 ? (
                    <div className={styles.hitList}>
                      {item.matchs.map((match, i) => {
                        const uuid = `${index}-${i}`
                        match.uuid = uuid
                        return (
                          <span
                            key={i}
                            className={cls(
                              styles.hitItem,
                              match.uuid === hitData.uuid &&
                                labelId === item.labelId &&
                                styles.active
                            )}
                            onClick={() =>
                              labelSource({
                                labelId: item.labelId,
                                ...match,
                              })
                            }
                          >
                            来源{i + 1}：{match.hitValue}
                          </span>
                        )
                      })}
                    </div>
                  ) : (
                    `当前病历详情中不包含${item.labelName}`
                  )
                }
              >
                <div
                  className={cls(
                    item.labelType === 1 && 'include-label',
                    item.labelType === 2 && 'exclude-label',
                    labelId === item.labelId && 'active-label',
                    styles.labelTable
                  )}
                  // onClick={() => labelCheck(item)}
                >
                  {item.labelType === 2 ? `非 "${item.labelName}"` : item.labelName}
                </div>
              </Popover>
            )
          })}
        </div>
        {infoShow && (
          <div id="info">
            <DetailCard
              base={baseInfo}
              visit={activeVisit}
              hitValue={hitData.tableName === tabInfo[0] ? hitData.hitValue : ''}
            />
          </div>
        )}

        {
          visits.length > 0 && <div className={styles.tabWrap}>
            <Row wrap={false}>
              <Col flex="212px">
                <div className={styles.selected}>
                  {visits.map((item, index) => {
                    return (
                      <div
                        className={cls(styles.tabList, active === index && styles.tabActive)}
                        key={index}
                        onClick={() => tabChange(index)}
                      >
                        <div className={styles.tabItem}>
                          {item.visitDate ? moment(item.visitDate).format('YYYY-MM-DD') : ''}
                        </div>
                        <div className={styles.tabLog}>{item.type === 1 ? '门诊' : '住院'}</div>
                        <div className={styles.tabItem}>{item.siteName}</div>
                        <div>{item.visitDeptName}</div>
                      </div>
                    )
                  })}
                </div>
              </Col>
              <Col flex="1">
                <Tabs
                  items={tabs}
                  activeKey={tabSelect}
                  destroyInactiveTabPane
                  onChange={(e) => tabSelectChange(e)}
                />
              </Col>
            </Row>
          </div>
        }
      </Spin>
    </PatientContext.Provider>
  )
}

export default PatientRecord
