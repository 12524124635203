import React from 'react'
import TagWidget from '@views/tag/engine/components/widget/tag'
import AlgAttrsWidget from '@views/tag/engine/components/widget/algAttrs'
import DataWidget from '@views/tag/engine/components/widget/data'
import AlgWidget from '@views/tag/engine/components/widget/alg'
import ConstantWidget from '@views/tag/engine/components/widget/constant'
import OperatorWidget from '@views/tag/engine/components/widget/operator'
import LogicWidget from '@views/tag/engine/components/widget/logic'
import ExportWidget from '@views/tag/engine/components/widget/export'
import ScriptWidget from '@views/tag/engine/components/widget/script'
import KnowledgeWidget from '@views/tag/engine/components/widget/knowledge'
import {isEqual} from 'lodash'
const ItemWidget = ({item: {type, key, sortIndex: index}, ...restProps}) => {
  let widget = null
  let namePathPrefix=['nodeDataArray', index, 'data', 'v2Expression']
  switch (type) {
    case 'RULE': // 标签控件
      widget = <TagWidget namePath={[...namePathPrefix, 'ruleExpression']} itemKey={key} index={index} {...restProps}/>
      break
    case 'EXTRACT':
      widget = <AlgAttrsWidget namePath={[...namePathPrefix, 'extractExpression']} itemKey={key} index={index} {...restProps} />
      break
    case 'DATA':
      widget = <DataWidget namePath={[...namePathPrefix, 'dataExpression']} itemKey={key} index={index} {...restProps}/>
      break
    case 'AI':
      widget = <AlgWidget namePath={[...namePathPrefix, 'aiExpression']} itemKey={key} index={index} {...restProps} />
      break
    case 'CONSTANT':
      widget = <ConstantWidget namePath={[...namePathPrefix, 'constantExpression']} itemKey={key} index={index} {...restProps}/>
      break
    case 'OPERATION':
      widget = <OperatorWidget namePath={[...namePathPrefix, 'operationExpression']} itemKey={key} index={index} {...restProps}/>
      break
    case 'LOGIC':
      widget = <LogicWidget namePath={[...namePathPrefix, 'logicExpression']} itemKey={key} index={index} {...restProps}/>
      break
    case 'OUTPUT':
      widget = <ExportWidget namePath={[...namePathPrefix, 'outputExpression']} itemKey={key} index={index} {...restProps}/>
      break
    case 'SCRIPT':
      widget = <ScriptWidget namePath={[...namePathPrefix, 'scriptExpression']} itemKey={key} index={index} {...restProps}/>
      break
    case 'KNOWLEDGE':
      widget = <KnowledgeWidget namePath={[...namePathPrefix, 'knowledgeExpression']} itemKey={key} index={index} {...restProps}/>
      break
    default:
      widget = null
      break
  }
  console.count('widget')
  return widget
}
// eslint-disable-next-line no-unused-vars
const shouldUpdate = (prevProps, nextProps) => {
  const {item: {key: prevSource}, items: prevItems, linkDataArray: preLinkDataArray } = prevProps
  const {item: {key: nextSource}, items: nextItems, linkDataArray: nextLinkDataArray } = nextProps
  return isEqual(prevSource, nextSource) && isEqual(prevItems, nextItems)&& isEqual(preLinkDataArray, nextLinkDataArray)
}
export default React.memo(ItemWidget, shouldUpdate)
