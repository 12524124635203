import React, { useState, useContext, useEffect } from 'react'
import { Table } from 'antd'
import { formatDateFromTimestamp, withEmptyRender } from '@utils/common'
import fetch from '@utils/request'
import api from '@api'
import usePagination from '@hooks/usePagination'
import PatientContext from '../context'

const Medicine = ({ targetPage, render }) => {
  const { activeVisit, projectId, patientId } = useContext(PatientContext)
  const { type } = activeVisit
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [page, setPage, setTotal, pagination] = usePagination({
    initialPage: {
      pageIndex: targetPage || 1,
      pageSize: 10,
    },
  })
  const columns = withEmptyRender([
    {
      title: '处方日期',
      dataIndex: type === 1 ? 'pres_order_time' : 'order_time',
      render: (value, record) => render(formatDateFromTimestamp(value) || '-', record),
    },
    {
      title: '药物名词',
      dataIndex: 'origin_drug_name',
      render,
    },
    {
      title: '标准药品通用代码',
      dataIndex: 'standard_drug_normal_code',
      render,
    },
    {
      title: '标准药品通用名称',
      dataIndex: 'standard_drug_normal_name',
      render,
    },
    {
      title: '标准药品商品代码',
      dataIndex: 'standard_drug_trade_code',
      render,
    },
    {
      title: '标准药品商品名称',
      dataIndex: 'standard_drug_trade_name',
      render,
    },
    {
      title: '规格',
      dataIndex: type === 1 ? 'pres_item_specifications' : 'order_spec',
      render,
    },
    {
      title: '给药方式',
      dataIndex: type === 1 ? 'origin_admin_route_name' : 'standard_admin_route_name',
      render,
    },
    {
      title: '用药天数',
      dataIndex: 'dose_days',
      render,
    },
    {
      title: '用药剂量',
      dataIndex: 'drug_use_one_dosage',
      render,
    },
    {
      title: '用药频次',
      dataIndex: 'origin_frequency_name',
      render,
    },
    {
      title: '剂型',
      dataIndex: 'dosage_form',
      render,
    },
    {
      title: '包装规格数量',
      dataIndex: 'pack_spec_amount',
      render,
    },
  ])

  const handleTableChange = (pagination) => {
    setPage({
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
    })
  }
  const fetchRecord = async () => {
    const pager = {
      pageNumber: page.pageIndex,
      pageSize: page.pageSize,
    }
    setLoading(true)
    const { indexNo, type } = activeVisit
    const { content, totalCount } = await fetch({
      url: api.patientApi.patientRecords,
      method: 'GET',
      params: {
        patientId,
        indexNo,
        type: 4,
        category: type,
        proId: projectId,
        ...pager,
      },
    })
    setData(content)
    setLoading(false)
    setTotal(totalCount)
  }

  useEffect(() => {
    if (patientId) {
      fetchRecord()
    }
  }, [activeVisit, page])

  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        rowKey={(record) => record.id}
        size="small"
        pagination={pagination}
        loading={loading}
        onChange={handleTableChange}
        scroll={{ x: 'max-content' }}
      />
    </>
  )
}

export default Medicine
