import { Button, Form, Input } from 'antd'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
// import md5 from 'md5'
import styles from './index.module.css'
import {loginAsync, setPrivateHomeRoute, setSysConst} from '@store/app'
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import useMenu from '@hooks/useMenu'
import routesConfig from '@routes/routes'
import fetch from '@utils/request'
import api from '@api'

const LoginForm = (props) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [authMenu] = useMenu()
  const { privateRoutes } = routesConfig

  /*
   * 过滤出权限列表，第一项作为登录首页
   * */
  const filterAuthMenu = (key, items) => {
    const menu = items.filter((item) => {
      return item.key === key
    })
    return menu[0].children[0].path
  }

  const fetchSysConst = async () => {
    const res = await fetch({
      method: 'POST',
      url: api.commonApi.paramList,
    })
    console.log('commonDic', res)
    dispatch(setSysConst(res))
  }
  /*
   * 登录事件
   * */
  const onFinish = async (values) => {
    const { account, pwd } = values
    try {
      setLoading(true)
      const resposnse = await dispatch(
        loginAsync({ username: account, password: pwd })
      )
      if (resposnse.payload.code === 0) {
        setLoading(false)
        return
      }

      const { privileges } = resposnse.payload
      const path = filterAuthMenu(
        privileges[0].code,
        privateRoutes()[0].children
      )
      dispatch(setPrivateHomeRoute(path))
      setTimeout( async () => {
        await fetchSysConst()
        if (typeof props.onSuccess === 'function') {
          props.onSuccess(path)
          setLoading(false)
          return
        }
        console.log('authMenu', authMenu)
        navigate(path)
        setLoading(false)
      }, 200)
    } catch (e) {
      setLoading(false)
    }
  }
  const onFinishFailed = (errorInfo) => {
    // TODO
  }

  return (
    <Form
      name="normal_login"
      className={styles.loginForm}
      size="large"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item
        name="account"
        rules={[
          {
            required: true,
            message: '请输入用户名!',
          },
        ]}
      >
        <Input
          prefix={<UserOutlined className="site-form-item-icon" />}
          placeholder="请输入用户名"
        />
      </Form.Item>
      <Form.Item
        name="pwd"
        rules={[
          {
            required: true,
            message: '请输入密码!',
          },
        ]}
      >
        <Input
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          placeholder="请输入密码"
        />
      </Form.Item>

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className={styles.btnLogin}
          loading={loading}
        >
          登录
        </Button>
      </Form.Item>
      {/* <Form.Item> */}
      {/*  <Divider> */}
      {/*    <div className={styles.forgetPwd} onClick={() => { */}
      {/*      props.toggleLoginType(true) */}
      {/*    }}>忘记密码？ */}
      {/*    </div> */}
      {/*  </Divider> */}
      {/* </Form.Item> */}
    </Form>
  )
}

export default LoginForm
