import { Button, Form, Input, notification, Popover, Progress } from 'antd'
import React, { useState} from 'react'
import md5 from 'md5'
import { scorePassword } from '@utils/common'
import styles from './index.module.css'
import {UserOutlined, LockOutlined, LeftOutlined} from '@ant-design/icons'
import fetch from '@utils/request'
import api from '@api'
const levelNames = {
  0: '强度：太短',
  1: '强度：低',
  2: '强度：中',
  3: '强度：强'
}
const levelClass = {
  0: 'error',
  1: 'error',
  2: 'warning',
  3: 'success'
}
const levelColor = {
  0: '#ff0000',
  1: '#ff0000',
  2: '#ff7e05',
  3: '#52c41a'
}
const ForgetForm = (props) => {
  const [form] = Form.useForm()
  const [smsSendBtn, setSmsSendBtn] = useState(false)
  const [snsBtnText] = useState('获取验证码')
  let [time, setTime] = useState(60)
  const [popoverObj, setPopoverObj] = useState({
    passwordLevel: '',
    percent: 0,
    passwordLevelColor: levelColor[0],
    passwordLevelClass: levelClass[0],
    passwordLevelName: levelNames[0]
  })
  /*
    * 登录事件
    * */
  const onFinish = async (values) => {
    const {email, pwd, code} = values
    const response = await fetch(
      api.accountApi.updatePassword,
      {email, pw: md5(pwd), code}
    )
    if (response.status !== '200'){
      notification['error']({
        message: '提示',
        description: response.data,
        duration: 8
      })
    } else {
      notification['success']({
        message: '提示',
        description: response.data,
        duration: 4
      })
      props.toggleLoginType(false)
    }
  }

  const onFinishFailed = (errorInfo) => {
    // TODO
  }

  /*
  * 密码强度逻辑
  * */
  const handlePasswordLevel = (rule, value, callback) => {
    let level = 0
    if (value === '') {
      return callback()
    }
    if (value.length >= 6) {
      if (scorePassword(value) >= 30) {
        level = 1
      }
      if (scorePassword(value) >= 60) {
        level = 2
      }
      if (scorePassword(value) >= 80) {
        level = 3
      }
    } else {
      level = 0
      callback(new Error('密码强度不够'))
    }
    setPopoverObj({
      passwordLevel: level,
      percent: level * 33,
      passwordLevelColor: levelColor[level],
      passwordLevelClass: levelClass[level],
      passwordLevelName: levelNames[level]
    })
    callback()
  }

  /*
  * 获取验证码
  * */
  const getCaptcha = async () => {
    const { validateFields } = form
    validateFields(['email'])
      .then(async (values) => {
        setSmsSendBtn(true)
        const response = await fetch(
          api.accountApi.EmailCode,
          {
            email: values.email
          }
        )
        if (response.status !== '200'){
          notification['error']({
            message: '提示',
            description: response.data,
            duration: 8
          })
          setSmsSendBtn(false)
        } else {
          notification['success']({
            message: '提示',
            description: '验证码获取成功',
            duration: 8
          })
          const timer = setInterval(() => {
            setTime(time--)
            if (time-- <= 0) {
              setTime(60)
              setSmsSendBtn(false)
              window.clearInterval(timer)
            }
          }, 1000)
        }
      })
      .catch((errorInfo ) => {
        setSmsSendBtn(false)
      })

  }
  /* 气泡弹窗jsx */
  const popoverContent = (
    <div className={styles.popoverWrapper}>
      <div className={styles[popoverObj.passwordLevelClass]}>{popoverObj.passwordLevelName}</div>
      <Progress className={styles.popoverProgress} percent={popoverObj.percent} strokeColor={popoverObj.passwordLevelColor} showInfo={false}/>
      <div>请至少输入 8 个字符。请不要使用容易被猜到的密码。</div>
    </div>
  )

  return (
    <Form
      name="normal_login"
      form={form}
      className={styles.loginForm}
      size="large"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <div onClick={() => {
        props.toggleLoginType(false)
      }} className={styles.forgetPwdTitle}><LeftOutlined />忘记密码</div>
      <Form.Item
        name="email"
        rules={[
          {
            required: true,
            message: '请输入正确的邮箱',
            pattern: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/,
            // validateTrigger: 'blur'
          },
        ]}
      >
        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="请输入邮箱！" />
      </Form.Item>
      <Form.Item
        name="code"
        rules={[
          {
            required: true,
            message: '请输入验证码!',
          },
        ]}
      >
        <div className={styles.snsCode}>
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="请输入验证码"
          />
          <Button className={styles.codeBtn} onClick={getCaptcha}>{smsSendBtn?time: snsBtnText}</Button>
        </div>
      </Form.Item>
      <Popover
        placement="rightTop"
        trigger="focus"
        content={popoverContent}>
        <Form.Item
          name="pwd"
          rules={[
            { required: true, message: '请输入新密码' },
            { pattern: /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[~!@#$%^&*])[\da-zA-Z~!@#$%^&*]{8,}$/, message: '至少为8位包含数字、英文字母、特殊符号组合' },
            { validator: handlePasswordLevel }
          ]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="请输入密码"
          />
        </Form.Item>
      </Popover>

      <Form.Item>
        <Button type="primary" htmlType="submit" className={styles.btnLogin}>
            确定修改
        </Button>
      </Form.Item>

    </Form>
  )
}

export default ForgetForm
