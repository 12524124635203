import {createSlice } from '@reduxjs/toolkit'

const initialState = {
  curTreeNode: {}, // 选中的标准字典树节点，记忆上一次选中的
}

export const mappingIndex = createSlice({
  name: 'mapping',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setItemCurTreeNode: (state, action) => {
      state.curTreeNode = action.payload
    },
  },
})

export const { setItemCurTreeNode } = mappingIndex.actions

export default mappingIndex.reducer
