import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react'
import { Modal, Col, Row, Tree, Empty } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import styles from './index.module.less'
import classNames from 'classnames'
import fetch from '@utils/request'
import api from '@api'
import {useWindowSize} from '@hooks/useLayout'

const LabelSelectModal = (props, ref) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [index, setIndex] = useState()
  const [list, setList] = useState([])
  const [checks, setChecks] = useState([])
  const [tree, setTree] = useState([])
  const [select, setSelect] = useState([])
  const [,, scale] = useWindowSize()

  const getCategory = async() => {
    const res = await fetch({
      method: 'POST',
      url: api.menuApi.treeList,
      data: {
        type: props.type,
      }
    })


    if (res && res.length>0) {
      setTree(res)
      setSelect([])
      setList([])
    }
  }

  const handleOpen = (e) => {
    setIsModalVisible(true)
    setIndex(e)
  }

  // 反显
  const handleValue = (e) => {
    setChecks(e)
  }

  // 取消关闭弹窗
  const handleCancel = () => {
    setChecks([])
    setIsModalVisible(false)
  }

  // 确定保存
  const handleOk = async() => {
    props.onSure(checks, index)
    handleCancel()
  }

  // 标签双击选中
  const onDoubleClick = (e) => {
    let arr = checks.slice()
    const index = arr.findIndex(item => item.id === e.id)
    if (index === -1) {
      arr.push(e)
      setChecks(arr)
    }
  }

  // 标签删除
  const onRemoveClick = (e) => {
    let arr = checks.slice()
    arr.splice(e, 1)
    setChecks(arr)
  }

  // 树节点选择
  const onTreeSelect = async(e) => {
    setSelect(e)
    if (e.length === 0) {
      setList([])
      return
    }

    const {content} = await fetch({
      method: 'POST',
      url: api.tagApi.getTagsByCategory,
      data: {
        categoryId: e[0],
        categoryType: props.type,
        checkStatus: '2',
        pageNumber: 1,
        pageSize: 10000
      },
      headers: {
        'Content-Type': 'application/json'
      }
    })
    console.log('onTreeSelect', content)

    if (content && content.length>0) {
      setList(content)
    } else {
      setList([])
    }
  }

  useEffect(() => {
    getCategory()
  }, [index])

  useImperativeHandle(ref, () => ({
    handleOpen,
    handleValue
  }))

  return (
    <Modal
      title="分配标签"
      width={640*scale}
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <Row gutter={16}>
        <Col span={8}>
          <div className={styles.labelBox}>
            <div className={styles.labelTitle}>选择节点</div>
            <div className={styles.labelMain}>
              <Tree
                className={styles.tree}
                treeData={tree}
                fieldNames={{
                  key: 'code',
                  title: 'name'
                }}
                selectedKeys={select}
                onSelect={onTreeSelect}
              />
            </div>
          </div>
        </Col>
        <Col span={8}>
          <div className={styles.labelBox}>
            <div className={styles.labelTitle}>选择标签（双击选中）</div>
            <div className={styles.labelMain}>
              {list.map(node => (
                <div
                  key={node.id}
                  className={styles.labelList}
                  onDoubleClick={() => onDoubleClick(node)}
                >{node.name}</div>
              ))}
              {list.length === 0 && <Empty /> }
            </div>
          </div>
        </Col>
        <Col span={8}>
          <div className={styles.labelBox}>
            <div className={styles.labelTitle}>已选中标签</div>
            <div className={styles.labelMain}>
              {checks.map((node, i) => (
                <div
                  key={node.id}
                  className={classNames([styles.labelList], [styles.labelRemove])}
                >
                  <div>{node.name}</div>
                  <CloseOutlined className={styles.labelIcon} onClick={() => onRemoveClick(i)} />
                </div>
              ))}
            </div>
          </div>
        </Col>
      </Row>
    </Modal>
  )
}

export default forwardRef(LabelSelectModal)
