import {MenuFoldOutlined, MenuUnfoldOutlined} from '@ant-design/icons'
import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {toggleCollapsed} from '@store/app'
import styles from './index.module.css'


const PrimeCollapse = () => {
  const dispatch = useDispatch()
  const collapsed = useSelector((state)=> state.app.collapsed)
  // const [, , scale] = useWindowSize()

  const toggle = (flag) => {
    dispatch(toggleCollapsed(flag))
  }
  return (
    collapsed
      ? <MenuUnfoldOutlined className={styles.collapse} onClick={() => {
        toggle(!collapsed)
      }}/>
      : <MenuFoldOutlined className={styles.collapse} onClick={() => {
        toggle(!collapsed)
      }}/>
  )
}

export default PrimeCollapse
