import React, {
  useCallback,
  useEffect,
  useRef, useState
} from 'react'
import FlowDiagram from '../engineV2/components/index'
import {Field, reduxForm, change, destroy, formValueSelector, arrayPush, arrayRemove} from 'redux-form'
import {Form, Row, Col, Modal, Button} from 'antd'
// import {useWindowSize} from '@hooks/useLayout'
import {AInput} from '@components/redux-form-antd'
import DragList from './components/dragList'
import fetch from '@utils/request'
import api from '@api'
import {connect} from 'react-redux'
import {load} from '@store/tag'
import {permissionStyle} from '@utils/permission'
import SubjectModal from '@views/project/crf-enter/components/subjectModal'
import PatientRecordModal from '@views/project/crf-enter/components/patientRecordModal'
import RadioTag from '@components/radio-tag'
import {traverse1} from '@utils/common'
import scale from '@utils/auto-rem'
// import { produce } from 'immer'
const nodSize ={
  'START': `${50*scale} ${40*scale}`,
  'RULE': `${40*scale} ${40*scale}`,
  'EXTRACT': `${72*scale} ${30*scale}`,
  'DATA': `${40*scale} ${40*scale}`,
  'AI': `${70*scale} ${30*scale}`,
  'CONSTANT': `${60*scale} ${28*scale}`,
  'OPERATION': `${110*scale} ${34*scale}`,
  'LOGIC': `${90*scale} ${30*scale}`,
  'OUTPUT': `${76*scale} ${30*scale}`,
  'KNOWLEDGE': `${60*scale} ${30*scale}`,
  'SCRIPT': `${76*scale} ${30*scale}`,

}

let TagEngineModal = ({open, onCancel, afterClose, changeArray, pushArray, removeArray, loadTag, destroyTag, handleSubmit, linkDataArray, nodeDataArray, tagName, remark, ...restProps}) => {
  const flowDiagramRef = useRef(null)
  // const [,, scale] = useWindowSize()
  const subjectRef = useRef(null)
  const patientRecordRef = useRef(null)
  const [subjects, setSubjects] = useState([])
  const [selectedSubject, setSelectedSubject] = useState([])
  const [selectedNode, setSelectedNode] = useState([])

  const sortArrayByAnther = (array, sortArray) => {
    return [...array].sort(
      (a, b) => {
        if (sortArray.indexOf(a.key) === -1 || sortArray.indexOf(b.key) === -1) {
          return false
        } else {
          return sortArray.indexOf(a.key) - sortArray.indexOf(b.key)
        }

      }
    )
  }
  const onModelChange = (modelJson) => {
    console.log('modelJSON', modelJson)
    console.log('nodeDataArray', nodeDataArray)
    const sortNodeDataArray =modelJson && sortArrayByAnther(modelJson.nodeDataArray, nodeDataArray.map(item => item.key))
    console.log('sortNodeDataArray', sortNodeDataArray)

    modelJson && loadTag({linkDataArray: modelJson.linkDataArray, nodeDataArray: sortNodeDataArray, tagName, remark})
  }
  useEffect(() => {
    console.count('tag')
  }, [])
  /*
* 获取标签详情
* */
  const fetchTagRuleDetail = async () => {
    const {id, version} = restProps.editRow
    const res = await fetch({
      method: 'POST',
      url: api.tagApi.getLabelDetail,
      data: {
        id,
        version
      }
    })
    traverse1(res)

    let {linkDataArray: resLinkDataArray, nodeDataArray: resNodeDataArray} = res.ruleJson
    resNodeDataArray.forEach((item) => {
      item.size = nodSize[item.type]
    })
    loadTag({
      nodeDataArray: resNodeDataArray,
      linkDataArray: resLinkDataArray,
      tagName: restProps.actionType === 'copy'? `${res.name}（1）`:res.name,
      remark: restProps.actionType === 'copy'? `${res.remark}（1）`:res.remark,
    })

  }
  const selectedSubjects = (data) => {
    const {id, version} = restProps.editRow
    const ruleIdAndVersionList = [`${id}#${version}`]
    patientRecordRef.current.handleOpen(ruleIdAndVersionList)
    setSelectedSubject(data[0])
    setSubjects(data)
  }
  const dataValidate = async () => {
    subjectRef.current.handleOpen()
  }
  const onTagClickEvent = (data) => {
    setSelectedSubject(data)
  }
  const findPreNode = useCallback((curNodeKey)=>{
    const preNode = flowDiagramRef.current.findNodesConnected(curNodeKey)
    return preNode
  }, [linkDataArray])
  const auditTag = (type) => {
    restProps.auditTag(type)
  }
  useEffect(() => {
    if (restProps?.editRow) {
      fetchTagRuleDetail()
    } else {
      loadTag({
        nodeDataArray: [],
        linkDataArray: [],
        tagName: '',
        remark: ''
      })
    }

  }, [])
  let footerBtns = [
    <Button
      key="submit"
      type="primary"
      onClick={() => {
        handleSubmit()
      }}
    >
      保存
    </Button>,
  ]

  if (
    restProps.source === 'default' &&
      restProps.actionType === 'edit' &&
      restProps?.editRow?.checkStatus === 1
  ) {
    // eslint-disable-next-line no-unused-vars
    footerBtns = [
      <Button
        key="pass"
        onClick={() => {
          auditTag(2)
        }}
        style={permissionStyle('LABEL_UPDATE_STATUS')}
      >
        审核通过
      </Button>,
      <Button
        key="fail"
        danger
        onClick={() => {
          auditTag(4)
        }}
        style={permissionStyle('LABEL_UPDATE_STATUS')}
      >
        审核拒绝
      </Button>,
      <Button
        key="validate"
        ghost
        type="primary"
        onClick={() => {
          dataValidate()
        }}
        style={permissionStyle('SAVE_LABEL')}
      >
        数据验证
      </Button>,
      <Button
        key="submit"
        type="primary"
        onClick={() => {
          handleSubmit()
        }}
        style={permissionStyle('SAVE_LABEL')}
      >
        保存
      </Button>,
    ]
  }
  return (
    <Modal
      width="100vw"
      open={open}
      onCancel={() => {
        destroyTag('tag')
        onCancel()
      }}
      onOk={onCancel}
      afterClose={afterClose}
      title={restProps.actionType === 'edit' ? '编辑标签' : '新增标签'}
      style={{
        maxWidth: '100vw',
        top: 0,
        paddingBottom: 0
      }}
      bodyStyle={{
        height: `calc(100vh - ${(55+53)*scale}px)`,
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
        padding: 0,
        paddingTop: '16px'
      }}
      footer={footerBtns}
    >
      <Form
      >
        <Form.Item
          style={{marginBottom: '16px', paddingLeft: '24px'}}
        >
          <Row>
            <Col flex={1}>
              <Row gutter={16}>
                <Col span={12}>
                  <Field
                    required={true}
                    label="标签名称"
                    name="tagName"
                    component={AInput}
                    labelAlign="left"
                    labelCol={{}}
                    placeholder="请输入标签名称"
                  />
                </Col>
                <Col span={12}>
                  <Field
                    required={true}
                    label="标签描述"
                    name="remark"
                    labelAlign="left"
                    labelCol={{}}
                    component={AInput}
                    placeholder="请输入标签描述"
                  />
                </Col>
              </Row>
            </Col>
            <Col flex={`${scale*460}px`}></Col>
          </Row>
        </Form.Item>

        <Row>
          <Col flex={1}>
            <FlowDiagram
              ref={flowDiagramRef}
              onModelChange={onModelChange}
              onSelectedChange={(data) => {
                setSelectedNode(data)
              }}
            />
          </Col>
          <Col flex={`${scale*460}px`}>
            <DragList selectedNode={selectedNode} findPreNode={findPreNode}/>
          </Col>
        </Row>
      </Form>
      <SubjectModal ref={subjectRef} done={selectedSubjects}/>
      <PatientRecordModal ref={patientRecordRef} patientId={selectedSubject}>
        <RadioTag seletedData={subjects} onClick={onTagClickEvent}/>
      </PatientRecordModal>
    </Modal>
  )
}
const validate = values => {
  console.log('values::', values)
  const errors = {}
  if (!values.tagName) {
    errors.tagName = '请输入标签名称'
  } else if (!values.remark) {
    errors.remark = '请输入标签描述'
  }

  return errors
}



TagEngineModal = reduxForm({
  form: 'tag', // a unique identifier for this form
  validate,
})(TagEngineModal)
const selector = formValueSelector('tag') // <-- same as form name
const mapStateToProps = (state) => {
  const nodeDataArray = selector(state, 'nodeDataArray')
  const linkDataArray = selector(state, 'linkDataArray')
  const tagName = selector(state, 'tagName')
  const remark = selector(state, 'remark')
  return {
    nodeDataArray,
    linkDataArray,
    tagName,
    remark,
    initialValues: state.tag.data,
    enableReinitialize: true // this is needed!!
  }
}


const mapDispatchToProps = {
  // NOTE: This MUST be aliased or it will not work. Thanks Jack!
  changeArray: change,
  pushArray: arrayPush,
  removeArray: arrayRemove,
  loadTag: load,
  destroyTag: destroy,
}

TagEngineModal = connect(mapStateToProps, mapDispatchToProps)(TagEngineModal)

export default TagEngineModal
