/*
 * 选择字典模态框
 * */
import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from 'react'
// eslint-disable-next-line no-unused-vars
import {Modal, Row, Col, Card, Tree, List, message, Input, Table, Button, Space} from 'antd'
import fetch from '@utils/request'
import api from '@api'
import { CaretDownOutlined } from '@ant-design/icons'
import styles from './index.module.less'
import { getKeys } from '@utils/common'
import usePagination from '@hooks/usePagination'
import ClipboardJS from 'clipboard'
// import DebounceSelect from '@components/DebounceSelect'
import {useWindowSize} from '@hooks/useLayout'
import Highlighter from 'react-highlight-words'
// import debounce from 'lodash/debounce'
// import debounce from 'lodash/debounce'

// eslint-disable-next-line no-unused-vars
const { Search } = Input

const KnowledgeModal = (props, ref) => {
  // const inputRef = useRef(null)
  const [visible, setVisible] = useState(false)
  const [treeData, setTreeData] = useState([])
  const [tableData, setTableData] = useState([])
  const [expandedKeys, setExpandedKeys] = useState([])
  const [checkedKeys, setCheckedKeys] = useState([])
  // eslint-disable-next-line no-unused-vars
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  // eslint-disable-next-line no-unused-vars
  const [selectedRows, setSelectedRows] = useState([])
  // eslint-disable-next-line no-unused-vars
  const [selectedKeys, setSelectedKeys] = useState([])
  // eslint-disable-next-line no-unused-vars
  const [checkedNodes, setCheckedNodes] = useState([])
  // 已选中列表 数据源
  const [checkedList, setCheckedList] = useState([])
  const [checkedTableNodes, setCheckedTableNodes] = useState([])
  const [searched, setSearched] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [, , scale] = useWindowSize()
  // eslint-disable-next-line no-unused-vars
  const [triggerSearch, setTriggerSearch] = useState(false)
  // 搜索框 value 搜索结束
  // eslint-disable-next-line no-unused-vars
  const [searchOutText, setSearchOutText] = useState(null)
  // eslint-disable-next-line no-unused-vars
  const [page, setPage, setTotal, pagination, total] = usePagination({size: 'small', showQuickJumper: false, showSizeChanger: false})

  const renderHighLight = (text, record, searchOutText) => {
    if (searchOutText && text) {
      return (
        <Highlighter
          highlightClassName="YourHighlightClass"
          searchWords={Array.from(new String(searchOutText))}
          autoEscape={true}
          textToHighlight={text}
        />
      )
    } else {
      return (
        <div>
          {text}
        </div>
      )
    }

  }

  const columns = [
    {
      title: 'ID',
      dataIndex: 'entityConceptId',
      key: 'entityConceptId',
      // render: (text, record) => renderHighLight(text, record, searchOutText),
    },
    {
      title: '值',
      dataIndex: 'entityConceptName',
      key: 'entityConceptName',
      render: (text, record) => renderHighLight(text, record, searchOutText),
    }
  ]
  const updateTreeData = (list, key, children) =>
    list.map((node) => {
      if (node.key === key) {
        return { ...node, children }
      }

      if (node.children) {
        return {
          ...node,
          children: updateTreeData(node.children, key, children),
        }
      }

      return node
    })

  /*
   * 异步加载树
   * */
  const onLoadData = async (e) => {
    if (searched) {
      return
    }
    if (e.children) {
      return
    }
    const {content, totalCount} = await fetch({
      method: 'POST',
      url: api.dictionaryApi.listDirectDescendant,
      data: {
        parentCode: e.key,
        dictionaryCode: props.dictionaryCode,
      },
    })
    if (totalCount && totalCount > 0) {
      setTreeData((origin) => updateTreeData(origin, e.key, content))
    }
  }

  /*
   * 初始化树
   * */
  const fetchDic = async (e) => {
    let pageParam = {
      pageNumber: 1,
      pageSize: 1000
    }
    if (props.dictionaryType && props.dictionaryType === 'flat'){
      pageParam = {
        pageNumber: page.pageIndex,
        pageSize: page.pageSize,
      }
    }
    const {content, totalCount} = await fetch({
      method: 'POST',
      url: api.dictionaryApi.getKnowledgeGraph,
      data: {
        vocabulary: props.dictionaryCode,
        ...pageParam,
        keyword: searchOutText
      },
      headers: {
        'Content-Type': 'application/json; charset=utf-8'
      },
    })

    if (!props.dictionaryType){ // props.dictionaryType === null → 默认为树形接口
      setTreeData(content)
    } else { // props.dictionaryType === flat → 分页表格形式
      setTableData(content)
      setTotal(totalCount)
    }
  }
  /*
 * 搜索结果
 * */
  // eslint-disable-next-line no-unused-vars
  const fetchSearch = async () => {
    if (!searchOutText) return
    let url = api.dictionaryApi.searchItem
    let pagerParam = {}
    if (props.dictionaryType && props.dictionaryType === 'flat'){
      url = api.dictionaryApi.searchItemPage
      pagerParam = {
        pageNumber: page.pageIndex,
        pageSize: page.pageSize
      }
    }
    const res = await fetch({
      method: 'POST',
      url,
      data: {
        searchText: searchText,
        dictionaryCode: props.dictionaryCode,
        ...pagerParam
      },
    })
    if (props.dictionaryType && props.dictionaryType === 'flat'){
      setTableData(res.content)
      setTotal(res.totalCount)
    } else {
      // setSearched(!!searchText)
      setTreeData(res)
      if (searchText) {
        const keys = getKeys(res)
        setExpandedKeys(keys)
      } else {
        setExpandedKeys([])
      }
    }
  }


  const onExpand = (expandedKeysValue) => {
    setExpandedKeys(expandedKeysValue)
  }
  const onCheck = (checkedKeysValue, {checked, checkedNodes: curCheckedNodes, node }) => {
    if (props.multiple === false && curCheckedNodes?.length > 1) {
      // setCheckedList(new Set([...checkedList]))
      // setCheckedKeys(checkedKeys)
      return message.error('该字典类型只能单选！')
    } else {
      setCheckedNodes(curCheckedNodes.concat(props.selectedItems))
      setCheckedKeys(checkedKeysValue)
    }
    if (checked) {
      setCheckedList((prevState) => {
        let newState = prevState
        newState.push({entityConceptId: node.entityConceptId, entityConceptName: node.entityConceptName })
        return newState
      })
    } else {
      setCheckedList((prevState) => {
        return prevState.filter((item) => {
          return item.entityConceptId !== node.entityConceptId
        })
      })
    }
  }

  const onSelect = (selectedKeysValue, info) => {
    setSelectedKeys(selectedKeysValue)
  }
  const show = () => {
    setVisible(true)
  }
  const handleOk = () => {
    console.log('checkedList', checkedList)
    if (!checkedList.length) {
      return message.warn('请选择字典！')
    }
    props.doneCallback(checkedList)
    setTimeout(() => {
      setVisible(false)
    }, 200)
  }
  const handleCancel = () => {
    setVisible(false)
  }

  const handleTableChange = (pagination, filters, sorter, extra) => {
    setPage({
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
    })
  }

  const onSelectHandle = ( record, selected, selectedRows, nativeEvent) => {
    if (props.multiple === false && checkedTableNodes?.length >= 1 && selected) {
      return message.error('该字典类型只能单选！')
    } else {
      let allSelectedRowKeys = []
      let allSelectedRows = []
      if (selected) {
        allSelectedRowKeys = Array.from(new Set([...selectedRowKeys, record.entityConceptId]))
        allSelectedRows = Array.from(new Set([...checkedTableNodes, record]))
      } else {
        allSelectedRowKeys = selectedRowKeys.filter((item) => {
          return item !== record.entityConceptId
        })
        allSelectedRows = checkedTableNodes.filter((item) => {
          return item.entityConceptId !== record.entityConceptId
        })
      }
      setSelectedRowKeys(allSelectedRowKeys)
      setCheckedTableNodes(allSelectedRows)
      setCheckedList(allSelectedRows)
    }
  }

  const onSelectAllHandle = (selected, selectedRows, changeRows) => {
    //
  }

  /*
  * copy
  * */
  const onCopy = ({title}) => {
    let copyText = ClipboardJS.copy(`${title}`)
    ClipboardJS.copy(copyText)
  }

  const rowSelection = {
    hideSelectAll: true,
    selectedRowKeys,
    selectedRows,
    onSelect: onSelectHandle,
    onSelectAll: onSelectAllHandle,
  }
  useImperativeHandle(ref, () => ({
    show,
  }))

  // eslint-disable-next-line no-unused-vars
  const handleChange = async (value) => {
    let url = api.dictionaryApi.searchItem
    let pagerParam = {}
    if (props.dictionaryType && props.dictionaryType === 'flat'){
      url = api.dictionaryApi.searchItemPage
      pagerParam = {
        pageNumber: 1,
        pageSize: 10
      }
    }
    setPage({
      pageIndex: 1,
      pageSize: 10
    })
    const text = value
    setSearchText(text)
    const res = await fetch({
      method: 'POST',
      url,
      data: {
        searchText: text,
        dictionaryCode: props.dictionaryCode,
        ...pagerParam
      },
    })
    if (props.dictionaryType && props.dictionaryType === 'flat'){
      setTableData(res.content)
      setTotal(res.totalCount)
    } else {
      setSearched(!!text)
      setTreeData(res)
      if (text) {
        const keys = getKeys(res)
        setExpandedKeys(keys)
      } else {
        fetchDic()
        setExpandedKeys([])
      }
    }
  }
  // eslint-disable-next-line no-unused-vars
  async function fetchSearchList(searchText) {
    const res = await fetch({
      method: 'POST',
      url: api.dictionaryApi.searchItemRecommend,
      data: {
        searchText: searchText,
        dictionaryCode: props.dictionaryCode
      },
    })
    return res.map((item) => ({
      label: item,
      value: item
    }))
  }

  const searchForm = () => {
    return (
    // <div className={styles.searchForm}>

      <Space>

        <Input
          placeholder="输入关键词搜索..."
          value={searchText} onChange={(e) => {
            setSearchText(e.target.value)
          }} />

        <Button type="primary" onClick={() => {
          setPage({
            pageIndex: 1,
            pageSize: 10,
          })
          setSearchOutText(searchText)
          if (!searchText) {
            setExpandedKeys([])
            setTreeData([])
            setTriggerSearch(false)
          } else {
            setTriggerSearch(true)
          }

        }}>搜索</Button>
      </Space>
      // </div>
    )
  }
  useEffect(() => {
    setCheckedList([])
    setCheckedTableNodes([])
    setCheckedKeys([])
    setSelectedRowKeys([])
    setSearchOutText(null)
  }, [props.dictionaryCode])

  useEffect(() => {
    fetchDic()
  }, [props.dictionaryCode, page, triggerSearch, searchOutText])

  useEffect(() => {
    setSearchText('')
  }, [props.dictionaryCode])

  useEffect(() => {
    const { selectedItems } = props
    let defaultCheckedkeys = []
    if (selectedItems && selectedItems?.length) {
      selectedItems.map((item) => {
        defaultCheckedkeys.push(item.key)
      })
    }
    setCheckedList(selectedItems || [])
    if (props.dictionaryType && props.dictionaryType === 'flat'){
      setSelectedRowKeys(defaultCheckedkeys)
      setCheckedTableNodes(selectedItems || [])
    } else {
      setCheckedKeys(defaultCheckedkeys)
      setCheckedNodes(selectedItems || [])
    }
  }, [props.selectedItems])
  return (
    <Modal
      title="选择实体"
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      width="60%"
    >
      <Row>
        <Col span={16}>
          <Card type="inner" size="small" title="实体" extra={searchForm()}>
            {!props.dictionaryType && <Tree
              height={300*scale}
              className={styles.dicTree}
              checkable
              checkStrictly
              expandedKeys={expandedKeys}
              onExpand={onExpand}
              showLine={{ showLeafIcon: false }}
              switcherIcon={<CaretDownOutlined />}
              loadData={onLoadData}
              treeData={treeData}
              onCheck={onCheck}
              onSelect={onSelect}
              checkedKeys={checkedKeys}
              defaultCheckedKeys={checkedKeys}
              titleRender={(data) => {
                const leven = (
                  <div className={styles.nodeWrapper}>
                    {searchOutText
                      ? <Highlighter
                        highlightClassName="YourHighlightClass"
                        searchWords={Array.from(new String(searchOutText))}
                        autoEscape={true}
                        textToHighlight={data.title}
                      />:
                      <span>{data.title}</span>
                    }
                    <Button type='primary' size='small' className={styles.copy} ghost onClick={() => {
                      onCopy(data)
                    }}>复制</Button>
                  </div>
                )
                return <div>{leven}</div>
              }}
            />}
            {props.dictionaryType && props.dictionaryType === 'flat' &&
                <Table
                  rowKey='entityConceptId'
                  size="small"
                  showExpandColumn={false}
                  columns={columns}
                  dataSource={tableData}
                  pagination={pagination}
                  rowSelection={rowSelection}
                  onChange={handleTableChange}
                />}
          </Card>
        </Col>
        <Col span={8}>
          <Card type="inner" size="small" title="已选中">
            <List
              className={styles.dicTree}
              bordered={false}
              dataSource={checkedList}
              // dataSource={!props.dictionaryType?checkedNodes : checkedTableNodes}
              renderItem={(item) => <List.Item>{item.entityConceptName}</List.Item>}
            />
          </Card>
        </Col>
      </Row>
    </Modal>
  )
}

export default forwardRef(KnowledgeModal)
