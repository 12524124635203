const tree2Array = (tree, childrenName) => {
  let arr = []
  tree.forEach((item) => {
    arr.push(item)
    if (item[childrenName]) {
      arr = arr.concat(tree2Array(item[childrenName], childrenName))
    }
  })
  return arr
}

export const permissionAuth = (code) => {
  const storage = JSON.parse(localStorage.getItem('persist:root'))
  const { privileges = [] } = JSON.parse(storage?.app || '{}')
  const permissionList = tree2Array(privileges, 'childrenPrivileges')
  const permissionCodes = permissionList.map((item) => item.code)
  return permissionCodes.includes(code)
}

export const permissionStyle = (code) => {
  return permissionAuth(code) ? {} : { display: 'none' }
}
