
import {combineReducers} from '@reduxjs/toolkit'
import appReducer from './app'
import mappingReducer from './mapping'
import feedbackReducer from './feedback'
import projectReducer from './project'
import tagReducer from './tag'
import { reducer as formReducer } from 'redux-form'

const rootReducer = combineReducers({
  app: appReducer,
  mapping: mappingReducer,
  feedback: feedbackReducer,
  project: projectReducer,
  tag: tagReducer,
  form: formReducer
})

export default rootReducer

