/* AE */

const aeApi = {
  pageQuery: '/kbms/ae/pageQuery', // 分页查询规则
  add: '/kbms/ae/add', // 新增规则
  cancelPush: '/kbms/ae/cancelPushProject', // 取消发布项目
  push: '/kbms/ae/pushProject', // 发布项目
  cancelPushTag: '/kbms/ae/cancelPushTag', // 取消发布标签
  pushTag: '/kbms/ae/pushTag', // 关联标签
  update: '/kbms/ae/update', // 修改规则
  remove: '/kbms/ae/remove', // 删除规则
  getProjectList: '/kbms/ae/getProjectList', // 获取项目列表
  getTagList: '/kbms/ae/getTagList', // 获取标签列表
  getPushedTagList: '/kbms/ae/getPushedTagList', // 获取已发布标签列表
  // 排除药品
  getExcludeConceptList: '/kbms/project/getExcludeConceptList', // 获取已排除字典列表
  deleteExcludeConcept: '/kbms/project/deleteExcludeConcept', // 删除已排除字典
  updateExcludeConcept: '/kbms/project/updateExcludeConcept', // 修改已排除字典
  getExcludeProjectList: '/kbms/project/getExcludeProjectList', // 获取已排除项目列表
  pageExcludeProjectList: '/kbms/project/pageExcludeProjectList', // 分页获取已排除项目列表
}

export default aeApi
