/*
* 检验库详情
* */
import React, {useEffect, useState} from 'react'
import { useSearchParams } from 'react-router-dom'
import fetch from '@utils/request'
import api from '@api'
import DESC from '@views/knowledge/inspect/detail/components/DESC'
const InspectDetail = () => {
  const [params] = useSearchParams()
  const [sourceData, setSourceData] = useState({})
  const inspectId = params.get('id')
  const getDetail = async(param ={}) => {
    const res = await fetch({
      method: 'GET',
      url: api.knowledgeApi.examDetail,
      params: {
        id: inspectId
      }
    })
    console.log('res:', res)
    setSourceData(res)

  }

  useEffect(() => {
    setSourceData({})
    getDetail()
  }, [inspectId])
  return (
    <>
      <DESC sourceData={sourceData}/>
    </>
  )
}

export default InspectDetail
