import React, { useRef, useState, forwardRef, useImperativeHandle } from 'react'
import { Modal, Form, Select, message } from 'antd'
import styles from './index.module.less'
import LabelSelectModal from './labelSelectModal.js'
import fetch from '@utils/request'
import api from '@api'
import {useWindowSize} from '@hooks/useLayout'

const LabelModal = (props, ref) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [intoAtomArr, setIntoAtomArr] = useState([])
  const [intoDeriveArr, setIntoDeriveArr] = useState([])
  const [outAtomArr, setOutAtomArr] = useState([])
  const [outDeriveArr, setOutDeriveArr] = useState([])
  const [form] = Form.useForm()
  const labelSelectRef = useRef(null)
  const [,, scale] = useWindowSize()

  const handleOpen = (e) => {
    setIsModalVisible(true)
  }

  const handleValue = (e) => {
    form.setFieldsValue(e)
  }

  const handleOk = async() => {
    await form.submit()
  }

  const handleCancel = () => {
    form.resetFields()
    setIntoAtomArr([])
    setIntoDeriveArr([])
    setOutAtomArr([])
    setOutDeriveArr([])
    setIsModalVisible(false)
  }

  // 保存
  const onFinish = async(values) => {
    console.log('fds', values)
    const {intoAtom, outAtom} = values
    if ((!intoAtom || !intoAtom?.length) && (!outAtom || !outAtom?.length)) {
      return message.error('入组标签和排除标签不能都为空！')
    }
    const obj = {
      'included': {
        'meta': intoAtomArr.map(node => {
          return {
            id: node.id,
            version: node.version
          }
        }),
        'derived': intoDeriveArr.map(node => {
          return {
            id: node.id,
            version: node.version
          }
        }),
      },
      'excluded': {
        'meta': outAtomArr.map(node => {
          return {
            id: node.id,
            version: node.version
          }
        }),
        'derived': outDeriveArr.map(node => {
          return {
            id: node.id,
            version: node.version
          }
        }),
      }
    }
    await fetch({
      method: 'POST',
      url: api.projectApi.saveLabel,
      data: {
        projectId: props.id,
        projectLabelMapString: JSON.stringify(obj)
      }
    })
    message.success('分配成功')
    props.onSuccess()
    handleCancel()
  }

  // 打开选择弹窗
  const onselect = (e) => {
    labelSelectRef.current.handleOpen(e)
    switch (e) {
      case 1:
        labelSelectRef.current.handleValue(intoAtomArr)
        break
      case 2:
        labelSelectRef.current.handleValue(intoDeriveArr)
        break
      case 3:
        labelSelectRef.current.handleValue(outAtomArr)
        break
      case 4:
        labelSelectRef.current.handleValue(outDeriveArr)
        break
    }
  }

  // 确认选择，接受选中项
  const onSure = (e, i) => {
    const arr = e.map(node => node.name)
    switch (i) {
      case 1:
        form.setFieldsValue({
          intoAtom: arr
        })
        setIntoAtomArr(e)
        break
      case 2:
        form.setFieldsValue({
          intoDerive: arr
        })
        setIntoDeriveArr(e)
        break
      case 3:
        form.setFieldsValue({
          outAtom: arr
        })
        setOutAtomArr(e)
        break
      case 4:
        form.setFieldsValue({
          outDerive: arr
        })
        setOutDeriveArr(e)
        break
    }
  }

  useImperativeHandle(ref, () => ({
    handleOpen,
    handleValue
  }))

  return (
    <>
      <Modal
        width={640*scale}
        title="分配标签" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <Form
          form= {form}
          labelCol={{
            span: 6,
          }}
          wrapperCol={{
            span: 16,
          }}
          onFinish={onFinish}
        >
          <div className={styles.title}>入组标签</div>
          <Form.Item
            label="原子标签"
            name="intoAtom"
          >
            <Select
              className={styles.select}
              mode="multiple"
              showSearch={false}
              placeholder="选择入组原子标签"
              dropdownStyle={{'display': 'none'}}
              onClick={() => onselect(1)}
            ></Select>
          </Form.Item>

          {/* <Form.Item
            label="衍生标签"
            name="intoDerive"
          >
            <Select
              className={styles.select}
              mode="multiple"
              showSearch={false}
              placeholder="选择入组衍生标签"
              dropdownStyle={{'display': 'none'}}
              onClick={() => onselect(2)}
            ></Select>
          </Form.Item> */}

          <div className={styles.title}>排除标签</div>
          <Form.Item
            label="原子标签"
            name="outAtom"
          >
            <Select
              className={styles.select}
              mode="multiple"
              showSearch={false}
              placeholder="选择排除原子标签"
              dropdownStyle={{'display': 'none'}}
              onClick={() => onselect(3)}
            ></Select>
          </Form.Item>
          {/* <Form.Item
            label="衍生标签"
            name="outDerive"
          >
            <Select
              className={styles.select}
              mode="multiple"
              showSearch={false}
              placeholder="选择排除衍生标签"
              dropdownStyle={{'display': 'none'}}
              onClick={() => onselect(4)}
            ></Select>
          </Form.Item> */}
        </Form>
      </Modal>
      <LabelSelectModal ref={labelSelectRef} onSure={onSure} {...props}/>
    </>
  )
}

export default forwardRef(LabelModal)
