import React, { useState, forwardRef, useImperativeHandle } from 'react'
import { Modal, Form, Input } from 'antd'
const { TextArea } = Input
import fetch from '@utils/request'
import api from '@api'

const ExportModal = (props, ref) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [title, setTitle] = useState('编辑')
  const [id, setId] = useState()
  const [form] = Form.useForm()

  const handleOpen = (e) => {
    setIsModalVisible(true)
    if (e) {
      setTitle('新增')
    } else {
      setTitle('编辑')
    }
  }

  const handleValue = (e) => {
    form.setFieldsValue(e)
    setId(e.id)
  }

  const handleOk = async() => {
    await form.submit()
  }

  const handleCancel = () => {
    form.resetFields()
    setIsModalVisible(false)
  }

  const onFinish = async(values) => {
    await fetch({
      method: 'POST',
      url: api.projectApi.save,
      data: {
        id: id,
        proId: props.proId,
        ...values
      }
    })
    handleCancel()
    props.onSuccess()
  }

  useImperativeHandle(ref, () => ({
    handleOpen,
    handleValue
  }))

  return (
    <Modal title={title + '任务'} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
      <Form
        form= {form}
        labelCol={{
          span: 6,
        }}
        wrapperCol={{
          span: 16,
        }}
        onFinish={onFinish}
      >
        <Form.Item
          label="任务名称"
          name="name"
          rules={[
            {
              required: true,
              message: '请输入任务名称',
            },
          ]}
        >
          <Input placeholder='请输入任务名称' />
        </Form.Item>

        <Form.Item
          label="设置密码"
          name="password"
          rules={[
            {
              required: true,
              message: '请输入想要设置的密码',
            },
          ]}
        >
          <Input.Password placeholder='请输入想要设置的密码' />
        </Form.Item>

        <Form.Item
          label="说明"
          name="remarks"
        >
          <TextArea rows={4} placeholder="请输入说明" />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default forwardRef(ExportModal)
