import React, { useEffect } from 'react'
import { Layout, notification } from 'antd'
import { Outlet, useNavigate, useLocation } from 'react-router-dom'
import PrimeSideMenu from './side-menu/index'
import PrimeBreadcrumb from './bread-crumb'
import PrimeHeader from '@layout/main/header'
import api from '@api'
import fetch from '@utils/request'
import { PASSWORD_EXPIRE } from '@constants/common'
const {version} = require('../../../package.json')

const { Content, Footer } = Layout

const endTime = () => new Date(new Date().setHours(23, 59, 59, 999)).getTime()

const MainLayout = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const judgePwd = async () => {
    if (location.pathname === '/login') {
      return
    }
    const res = await fetch({
      url: api.appApi.lastDay,
      method: 'GET',
    })
    if (res >= PASSWORD_EXPIRE - 10) {
      if (res >= PASSWORD_EXPIRE) {
        setTimeout(() => {
          navigate('/login?error=expired')
        }, 600)
      } else {
        const flag = localStorage.getItem('pwdTip') || '{}'
        const now = new Date().getTime()
        const { expired, show } = JSON.parse(flag)
        if (expired > now && show) {
          return
        }
        notification.error({
          message: '提示！',
          description: `您已${res}天未修改密码，请及时修改密码，否则未修改密码90天后无法登录系统`,
        })
        localStorage.setItem(
          'pwdTip',
          JSON.stringify({
            expired: endTime(),
            show: true,
          })
        )
      }
    }
  }
  useEffect(() => {
    judgePwd()
  }, [])
  return (
    <>
      <Layout
        style={{
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        {/* 菜单栏 */}
        <PrimeSideMenu />
        <Layout style={{ display: 'flex', flex: 1 }}>
          <PrimeHeader />
          <Content
            style={{
              margin: '12px 24px 24px',
            }}
          >
            <PrimeBreadcrumb />
            <div
              style={{
                height: '100%',
                background: '#fff',
                marginTop: '12px',
              }}
            >
              <Outlet />
            </div>
          </Content>
          <Footer
            style={{
              textAlign: 'center',
            }}
          >
            Version {version} Copyright © 2019-2023 Prime. All Rights Reserved

          </Footer>
        </Layout>
      </Layout>
    </>
  )
}

export default MainLayout
