/*
* 常数控件
* */

import {Form, Select, Input, InputNumber, DatePicker, Button, Table} from 'antd'
import React, {useEffect, useRef, useState} from 'react'
import {get} from 'lodash'
import {useSelector} from 'react-redux'
import DicModal from '@views/tag/edit/components/label-from-dic/dicModal'
import fetch from '@utils/request'
import api from '@api'
import PrimeTag from '@components/base/tag'
import {useWindowSize} from '@hooks/useLayout'
import moment from 'moment'

const ConstantWidget = ({namePath, ...restProps}) => {
  console.count('ConstantWidget')
  const form = Form.useFormInstance()
  // eslint-disable-next-line no-unused-vars
  const [tableData, setTableData] = useState([])
  const [dicList, setDicList] = useState([])
  const [,, scale] = useWindowSize()

  const sysConst = useSelector((state) => state.app.sysConst)
  // const vocabularyOptions = sysConst.filter(item => item.type === 'vocabulary')[0].valueNameVOS
  const constantOptions = sysConst.filter(item => item.type === 'engine_constant_type')[0].valueNameVOS

  const fetchDicList = async () => {
    const res = await fetch({
      method: 'POST',
      url: api.dictionaryApi.treeList,
      data: {}
    })
    setDicList(res)
  }

  useEffect(() => {
    fetchDicList()
  }, [restProps.itemKey])
  const DynamicFormItem = ({type}) => {
    const dicModalRef = useRef(null)
    const editSynonym = (data) => {
      form.setFieldValue([...namePath, 'selectedDictList', 0, 'data'], data)
    }

    /*
    * 获取同义词
    * */
    const fetchSynonym = async (params) => {
      const res = await fetch({
        method: 'POST',
        url: api.tagApi.listValidDetailAll,
        data: params,
        headers: {
          'Content-Type': 'application/json'
        },
      })
      return res
    }
    const doneCallback = async (data) => {

      const dicCode = form.getFieldValue([...namePath, 'selectedDictList', 0, 'code'])
      const conceptCodes = data?.map((item) => item.key)
      const synonymData = await fetchSynonym({conceptCodes, vocabulary: dicCode})
      setTableData(synonymData)
      form.setFieldValue([...namePath, 'selectedDictList', 0, 'data'], synonymData[0].data)
      restProps.asyncData()
    }
    let formItem = ''
    let label = '请输入'
    switch (type) {
      case 'str':
        formItem =
            <Form.Item
              name={[...namePath, 'stringValue']}
              label={label}
            >
              <Input onBlur={() => {
                restProps.asyncData()
              }}/>
            </Form.Item>
        break
      case 'num':
        formItem =
            <Form.Item
              name={[...namePath, 'numberValue']}
              label={label}
            >
              <InputNumber
                style={{
                  width: '100%',
                }}
              />
            </Form.Item>
        break
      case 'date':
        formItem =
            <Form.Item
              name={[...namePath, 'dateTimeValue']}
              label={label}
              getValueProps={ value => ({value: value && moment(value)}) }
              getValueFromEvent={ value => moment(value).format('YYYY-MM-DD HH:mm:ss')}
            >
              <DatePicker
                style={{
                  width: '100%',
                }}
              />
            </Form.Item>
        break
      case 'datetime':
        formItem =
            <Form.Item
              name={[...namePath, 'dateTimeValue']}
              label={label}
              getValueProps={ value => ({value: value && moment(value)}) }
              getValueFromEvent={ value => moment(value).format('YYYY-MM-DD HH:mm:ss')}
            >
              <DatePicker
                showTime
                style={{
                  width: '100%',
                }}
              />
            </Form.Item>
        break
      case 'dict':
        label = '请选择字典'
        formItem =
            <>
              <Form.Item
                label={label}
                name={[...namePath, 'selectedDictList', 0, 'code']}
              >
                <Select
                  showSearch={true}
                  optionFilterProp='name'
                  placeholder="请选择字典"
                  options={dicList}
                  fieldNames={{label: 'name', value: 'code'}}
                  onChange={() => {
                    setTimeout(() => {
                      form.setFieldValue([...namePath, 'selectedDictList', 0, 'data'], [])
                    }, 200)
                  }}
                />
              </Form.Item>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => get(prevValues, [...namePath, 'selectedDictList', 0, 'code']) !== get(currentValues, [...namePath, 'selectedDictList', 0, 'code'])}
              >
                {({ getFieldValue }) => {
                  const dictionaryCode = getFieldValue([...namePath, 'selectedDictList', 0, 'code'])
                  const selectedDic = dicList.filter((item) => item.code === dictionaryCode)
                  if (!dictionaryCode) return null
                  return (
                    <>
                      <div style={{display: 'flex', justifyContent: 'flex-end', marginBottom: '16px'}}>
                        <Button
                          ghost
                          type='primary'
                          size='middle'
                          onClick={() => {
                            dicModalRef.current.show()
                          }}
                        >
                                选择字典
                        </Button>
                      </div>
                      <DicModal ref={dicModalRef} doneCallback={doneCallback} dictionaryCode={dictionaryCode} dictionaryType={selectedDic[0]?.structure === 'flat'? 'flat': null}/>
                    </>
                  )
                }}
              </Form.Item>

              <Form.Item
                name={[...namePath, 'selectedDictList', 0, 'data']}
                valuePropName='dataSource'
                rules={[
                  {
                    required: true,
                    message: '请选择字典',
                  },
                ]}
              >
                <Table
                  rowKey='key'
                  columns={[
                    {
                      title: '标准名',
                      dataIndex: 'title',
                      key: 'title',
                      width: 140*scale
                    },
                    {
                      title: '同义词名',
                      dataIndex: 'sameTitles',
                      key: 'sameTitles',
                      render: (text, record) => {
                        return (
                          <PrimeTag sourceData={text} onEdit={editSynonym}/>
                        )
                      }
                    },
                  ]}
                  pagination={false}
                />
              </Form.Item>
            </>

        break
    }
    return formItem
  }

  return (
    <>
      <Form.Item
        label="选择常数类型"
        name={[...namePath, 'type']}
      >
        <Select
          placeholder="请选择常数类型"
          options={constantOptions}
          fieldNames={{label: 'name', value: 'value'}}
        />
      </Form.Item>

      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) => get(prevValues, [...namePath, 'type']) !== get(currentValues, [...namePath, 'type'])}
      >
        {({ getFieldValue }) => {
          const constantType = getFieldValue([...namePath, 'type'])
          if (!constantType) return null
          return (
            <DynamicFormItem type={constantType}/>
          )
        }}
      </Form.Item>
    </>
  )
}

export default ConstantWidget
