/*
* 药品说明书，text格式
* */
import React from 'react'
import styles from './index.module.less'
const DESC = (props) => {
  const {
    aliasName, // 别名
    clinicalSignificance, // 临床意义
    clinicalTestCombination, // 临床检验组合
    englishName, // 英文名称
    examDepartGroup, // 检验学科分类
    functionGroup, // 功能指标分类
    indication, // 适应症
    method, // 操作方法
    normalValue, // 正常值
    precautions, // 注意事项
    principle, // 原理
    projectName, // 项目名称
    reagent, // 试剂
    summary // 概述
  } = props.sourceData
  return (
    <div>
      <div className={styles.titleWrapper}>
        检验库详情
      </div>
      <div className={styles.section}>
        <p>
          <div className={styles.bold}>【项目名称】</div>
          <div dangerouslySetInnerHTML={{ __html: projectName }} />
        </p>
        <p>
          <div className={styles.bold}>【英文名称】</div>
          <div dangerouslySetInnerHTML={{ __html: englishName }} />
        </p>
        <p>
          <div className={styles.bold}>【别名】</div>
          <div dangerouslySetInnerHTML={{ __html: aliasName }} />
        </p>
        <p>
          <div className={styles.bold}>【检验学科分类】</div>
          <div dangerouslySetInnerHTML={{ __html: examDepartGroup }} />
        </p>
        <p>
          <div className={styles.bold}>【功能指标分类】</div>
          <div dangerouslySetInnerHTML={{ __html: functionGroup }} />
        </p>

        <p>
          <div className={styles.bold}>【概述】</div>
          <div dangerouslySetInnerHTML={{ __html: summary }} />
        </p>
        <p>
          <div className={styles.bold}>【适应症】</div>
          <div dangerouslySetInnerHTML={{ __html: indication }} />
        </p>
        <p>
          <div className={styles.bold}>【试剂】</div>
          <div dangerouslySetInnerHTML={{ __html: reagent }} />
        </p>
        <p>
          <div className={styles.bold}>【操作方法】</div>
          <div dangerouslySetInnerHTML={{ __html: method }} />
        </p>
        <p>
          <div className={styles.bold}>【原理】</div>
          <div dangerouslySetInnerHTML={{ __html: principle }} />
        </p>
        <p>
          <div className={styles.bold}>【临床意义】</div>
          <div dangerouslySetInnerHTML={{ __html: clinicalSignificance }} />
        </p>
        <p>
          <div className={styles.bold}>【注意事项】</div>
          <div dangerouslySetInnerHTML={{ __html: precautions }} />
        </p>
        <p>
          <div className={styles.bold}>【正常值】</div>
          <div dangerouslySetInnerHTML={{ __html: normalValue }} />
        </p>
        <p>
          <div className={styles.bold}>【临床检验组合】</div>
          <div dangerouslySetInnerHTML={{ __html: clinicalTestCombination }} />
        </p>
      </div>
    </div>
  )
}

export default DESC
