import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react'
import {Modal, Form, Select, message} from 'antd'
import fetch from '@utils/request'
import api from '@api'
import {useWindowSize} from '@hooks/useLayout'

const SubjectModal = (props, ref) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [form] = Form.useForm()
  const [,, scale] = useWindowSize()
  const [subjects, setSubjects] = useState([])

  const getList = async() => {
    const res = await fetch({
      method: 'POST',
      url: api.projectApi.subjectList,
    })
    setSubjects(res)
    console.log(res)
  }

  const handleOpen = (initData) => {
    setIsModalVisible(true)
  }

  const handleValue = (e) => {
    form.setFieldsValue(e)
  }

  const handleOk = async() => {
    await form.submit()
  }

  const handleCancel = () => {
    form.resetFields()
    setIsModalVisible(false)
  }

  // 保存
  const onFinish = async(values) => {
    if (values?.subjects && values.subjects?.length > 20) {
      return message.warn('最多只能选择20个受试者')
    }
    console.log('fds', values)
    props.done(values.subjects)
    setIsModalVisible(false)
  }

  useEffect(() => {
    getList()
  }, [])
  useEffect(() => {
    form.setFieldValue('subjects', props.defaultValue)
  }, [props.defaultValue])

  useImperativeHandle(ref, () => ({
    handleOpen,
    handleCancel,
    handleValue
  }))
  if (!isModalVisible) return null
  return (
    <>
      <Modal
        width={640*scale}
        title="选择进行数据验证的受试者" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <Form
          form= {form}
          labelCol={{
            span: 4,
          }}
          wrapperCol={{
            span: 20,
          }}
          onFinish={onFinish}
        >
          <Form.Item
            name='subjects'
          >
            <Select
              mode="multiple"
              allowClear
              style={{
                width: '100%',
              }}
              options={subjects.map((item) => ({
                value: item,
                label: item,
              }))}
              placeholder="请选择"
            />
          </Form.Item>

        </Form>
      </Modal>
    </>
  )
}

export default forwardRef(SubjectModal)
