/*
* 算法控件 -- TreeSelect
* */
import React, {useEffect, useState} from 'react'
import fetch from '@utils/request'
import api from '@api'
import {Field} from 'redux-form'
import {ASelect} from '@components/redux-form-antd'
import {setTagAlgNode} from '@store/app'
import {useDispatch} from 'react-redux'


const AlgWidget = ({namePath, itemKey}) => {
  const [aiList, setAiList] = useState([])
  const dispatch = useDispatch()
  const fetchAiList = async () => {
    const res = await fetch({
      method: 'GET',
      url: api.tagApi.aiList,
    })
    setAiList(res)
  }

  useEffect(() => {
    fetchAiList()
  }, [])
  return (
    <>
      <Field
        label="选择AI模型"
        name={`${namePath}.modelCode`}
        component={ASelect}
        value={null}
        placeholder="请选择AI模型"
        fieldNames={{label: 'modelName', value: 'modelCode'}}
        showSearch={true}
        optionFilterProp='modelName'
        options={aiList}
        changeEvent={ (value) => {
          dispatch(setTagAlgNode({key: itemKey, value}))
        }}
        onFocus={(e) => e.preventDefault()}
        onBlur={(e) => e.preventDefault()}
      />
    </>
  )
}

export default AlgWidget
