import { Layout } from 'antd'
import React from 'react'
import styles from './index.module.css'
import PrimeCollapse from './components/collapse/index'
import PrimeSettings from '@layout/main/header/components/settings'
const { Header } = Layout
const PrimeHeader = () => {

  return (
    <Header
      theme="light"
      className={styles.headerBg}
    >
      <PrimeCollapse/>
      <PrimeSettings/>
    </Header>
  )
}

export default PrimeHeader
