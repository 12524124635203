import React, { useState, useImperativeHandle, forwardRef } from 'react'
import { Modal, Form, Input, message } from 'antd'
import api from '@api'
import fetch from '@utils/request'
import { useWindowSize } from '@hooks/useLayout'

const { TextArea } = Input

const EditRule = ({ onSuccess }, ref) => {
  const [, , scale] = useWindowSize()
  const [visible, setVisible] = useState(false)
  const [record, setRecord] = useState({})
  const [form] = Form.useForm()
  const hide = () => {
    form.resetFields()
    setRecord({})
    setVisible(false)
  }
  const show = (record) => {
    if (record) {
      form.setFieldsValue(record)
      setRecord(record)
    }
    setVisible(true)
  }

  useImperativeHandle(ref, () => {
    return {
      show,
      hide,
    }
  })

  const onSubmit = async (values) => {
    await fetch({
      url: api.projectApi.updateRuleClass,
      method: 'POST',
      data: values,
      headers: {
        'Content-Type': 'application/json',
      },
    })
    hide()
    message.success('编辑成功')
    const oldKey = record.key
    const newKey = `${values.formId}_${values.ruleClassName}`
    typeof onSuccess === 'function' &&
      onSuccess({
        oldKey,
        newKey,
      })
  }
  return (
    <Modal
      title="编辑规则"
      open={visible}
      onCancel={hide}
      onOk={() => form.submit()}
      width={640 * scale}
    >
      <Form
        labelCol={{
          span: 5,
        }}
        form={form}
        onFinish={onSubmit}
      >
        <Form.Item
          label="规则名称"
          rules={[
            {
              required: true,
              message: '请输入规则名称',
            },
          ]}
          name="ruleClassName"
        >
          <Input maxLength={30} placeholder="用于配置人员区分不同的规则" />
        </Form.Item>
        <Form.Item label="规则描述" name="ruleClassDesc">
          <TextArea
            rows={4}
            maxLength={300}
            showCount
            placeholder="用于提供其他系统展示给用户的说明文案"
          />
        </Form.Item>
        <Form.Item name="formId" hidden>
          <Input />
        </Form.Item>
        <Form.Item hidden name="oldRuleClassName">
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default forwardRef(EditRule)
