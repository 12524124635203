import React, {Suspense} from 'react'
import Routes from './routes/index'

export default function App () {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <div className="App">
        <Routes />
      </div>
    </Suspense>
  )
}
