import React, { useState, useImperativeHandle, forwardRef } from 'react'
import { Upload } from 'antd'
import { ReactComponent as FileZip } from '@assets/images/icons/file.svg'
import { ReactComponent as FileExcel } from '@assets/images/icons/icon_excel.svg'
import { ReactComponent as Success } from '@assets/images/icons/success_filled.svg'
import { ReactComponent as RemoveIcon } from '@assets/images/icons/trash_small.svg'
import { formatFileSize } from '@utils/common'
import styles from './index.module.less'
import { useWindowSize } from '@hooks/useLayout'
import { InfoCircleOutlined } from '@ant-design/icons'
import cls from 'classnames'

const FileUpload = (
  { uploadProps = {}, maxCount, onChange, error = false },
  ref
) => {
  const [files, setFiles] = useState([])
  const [, , scale] = useWindowSize()
  const fileTypeIcon = {
    zip: (
      <FileZip
        style={{ width: `${scale * 40}px`, height: `${scale * 40}px` }}
      />
    ),
    default: (
      <FileExcel
        style={{ width: `${scale * 40}px`, height: `${scale * 40}px` }}
      />
    ),
    'application/x-zip-compressed': (
      <FileZip
        style={{ width: `${scale * 40}px`, height: `${scale * 40}px` }}
      />
    ),
  }
  const beforeUpload = (file) => {
    const list = [...files, file]
    setFiles(list)
    typeof onChange === 'function' && onChange(list)
    return false
  }

  const onRemove = (file) => {
    const newFiles = files.filter((item) => item.uid !== file.uid)
    setFiles(newFiles)
    typeof onChange === 'function' && onChange(newFiles)
  }

  useImperativeHandle(ref, () => {
    return {
      clear() {
        setFiles([])
      },
    }
  })
  return (
    <>
      <div
        className={styles.upload}
        style={maxCount === 1 && files.length ? { display: 'none' } : {}}
      >
        <Upload
          maxCount={maxCount}
          beforeUpload={beforeUpload}
          showUploadList={false}
          {...uploadProps}
        ></Upload>
      </div>
      {files.map((file, index) => {
        return (
          <div className={styles.fileContainer} key={index}>
            <div>{fileTypeIcon[file.type] || fileTypeIcon.default}</div>
            <div className={styles.info}>
              <div className={cls(styles.name, error && styles.error)}>
                {file.name}
                {error
                  ? (
                    <InfoCircleOutlined style={{ color: '#f5222d' }} />
                  )
                  : (
                    <Success />
                  )}
              </div>
              <div className={styles.size}>
                {formatFileSize(file.size || 0)}
              </div>
            </div>
            <RemoveIcon
              onClick={() => onRemove(file)}
              style={{ width: `${scale * 20}px`, height: `${scale * 20}px` }}
            />
          </div>
        )
      })}
    </>
  )
}

export default forwardRef(FileUpload)
