import React, { useState, useImperativeHandle, forwardRef } from 'react'
import { Modal, Form, Input, Select, message } from 'antd'
import fetch from '@utils/request'
import api from '@api'
import { RULE_TYPE } from '../constants'

const ruleTypeOptions = Object.keys(RULE_TYPE).map((key) => ({
  label: RULE_TYPE[key],
  value: parseInt(key),
}))

const Edit = ({ projectType, onSuccess }, ref) => {
  const [form] = Form.useForm()
  const [record, setRecord] = useState({})
  const [visible, setVisible] = useState(false)
  const hide = () => {
    form.resetFields()
    setRecord({})
    setVisible(false)
  }
  const show = (item) => {
    setVisible(true)
    if (!item) return
    setRecord(item)
    form.setFieldsValue(item)
  }
  useImperativeHandle(ref, () => ({
    show,
  }))

  const handleOk = async (values) => {
    const postData = {}
    Object.keys(values).forEach((key) => {
      if (values[key] !== undefined) {
        postData[key] =
          typeof values[key] === 'string' ? values[key].trim() : values[key]
      }
    })
    await fetch({
      url: record.id ? api.aeApi.update : api.aeApi.add,
      method: 'POST',
      data: {
        ...postData,
        projectType,
      },
    })
    message.success(record.id ? '编辑成功' : '新增成功')
    hide()
    typeof onSuccess === 'function' && onSuccess()
  }

  return (
    <Modal
      open={visible}
      onCancel={hide}
      title={record.id ? '编辑规则' : '新增规则'}
      width={640}
      onOk={() => form.submit()}
    >
      <Form
        form={form}
        labelCol={{ span: 5 }}
        labelWrap={true}
        onFinish={handleOk}
      >
        <Form.Item
          label="规则编号"
          name="ruleCode"
          rules={[
            {
              required: true,
              message: '请输入规则编号',
            },
            {
              max: 99,
              message: '规则编号最大长度为99',
            },
          ]}
        >
          <Input placeholder="请输入规则编号" />
        </Form.Item>
        <Form.Item
          label="规则类型"
          name="ruleType"
          rules={[
            {
              required: true,
              message: '请选择规则类型',
            },
          ]}
        >
          <Select placeholder="请选择规则类型" options={ruleTypeOptions} />
        </Form.Item>
        <Form.Item
          label="规则名称"
          name="ruleName"
          rules={[
            {
              required: true,
              message: '请输入规则名称',
            },
            {
              max: 99,
              message: '规则名称最大长度为99',
            },
          ]}
        >
          <Input placeholder="请输入规则名称" />
        </Form.Item>
        <Form.Item
          label="规则描述/智能推荐理由"
          name="ruleDesc"
          rules={[
            {
              required: true,
              message: '请输入规则描述/智能推荐理由',
            },
            {
              max: 300,
              message: '规则描述/智能推荐理由最大长度为300',
            },
          ]}
        >
          <Input.TextArea placeholder="请输入规则描述/智能推荐理由" rows={4} />
        </Form.Item>
        <Form.Item name="id" hidden>
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default forwardRef(Edit)
