import React, {useMemo} from 'react'
import { Divider, Tabs } from 'antd'
import ElementCom from './components/metaData/element'
import SourceCom from './components/metaData/source'
import styles from './index.module.less'
import {permissionAuth} from '@utils/permission'


console.log('permissionAuth', permissionAuth('META_DATA_ELEMENT'))
const Metadata = () => {
  const authItems = useMemo(() => {
    let items = []
    if (permissionAuth('META_DATA_ELEMENT')){
      items.push({

        key: '1',
        label: '数据元素管理',
        children: <ElementCom />,
      })
    }
    if (permissionAuth('META_DATA_SOURCE')){
      items.push({
        key: '2',
        label: '数据来源管理',
        children: <SourceCom />,
      })
    }
    return items
  }, [permissionAuth('META_DATA_ELEMENT'), permissionAuth('META_DATA_SOURCE')])
  return (
    <>
      {/* 标题栏 */}
      <div className="headmin">
        <h3 className="head-title">元数据管理</h3>
      </div>
      {authItems.length
        ? (
          <Divider className={styles.divider} />
        )
        : null}
      {/* 标签库 */}
      <div className={styles.tabWrap}>
        <Tabs defaultActiveKey="1" items={authItems} />
      </div>
    </>
  )
}

export default Metadata

