import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react'
import { Modal, Form, TreeSelect } from 'antd'
import fetch from '@utils/request'
import api from '@api'

const TypeModal = (props, ref) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [record, setRecord] = useState(null)
  const [label, setLabel] = useState('')
  const [value, setValue] = useState()
  const [treeData, setTreeData] = useState()
  const [form] = Form.useForm()

  const handleOpen = (e, record) => {
    setIsModalVisible(true)
    setRecord(record)
    form.setFieldsValue(record)
    switch (e) {
      case 1:
        setLabel('数据元素分类')
        break
      case 2:
        setLabel('标签分类')
        break
      default:
        setLabel('选择分类')
        break
    }
  }

  const getTreeList = async() => {
    const res = await fetch({
      method: 'POST',
      url: api.menuApi.treeList,
      data: {
        type: props.type
      }
    })
    if (res && res.length>0) {
      setTreeData(res)
    }
  }

  useEffect(() => {
    if (!isModalVisible) return
    getTreeList()
  }, [isModalVisible])

  const onChange = (newValue) => {
    setValue(newValue)
  }

  const handleOk = async() => {
    await form.submit()
  }

  const handleCancel = () => {
    form.resetFields()
    setIsModalVisible(false)
  }

  const onFinish = (values) => {
    // console.log(props.type)
    // console.log(record)
    // console.log(values.code)
    props.onSuccess(record, values.code)
    handleCancel()
  }

  useImperativeHandle(ref, () => ({
    handleOpen
  }))

  return (
    <Modal title="选择分类" open={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
      <Form
        form= {form}
        labelCol={{
          span: 6,
        }}
        wrapperCol={{
          span: 16,
        }}
        onFinish={onFinish}
      >
        <Form.Item
          label={label}
          name="code"
          rules={[
            {
              required: true,
              message: '请选择' + label,
            },
          ]}
        >
          <TreeSelect
            showSearch
            style={{
              width: '100%',
            }}
            fieldNames={{
              label: 'name',
              value: 'code',
            }}
            value={value}
            placeholder="请选择"
            allowClear
            onChange={onChange}
            treeData={treeData}
            multiple={props.multiple}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default forwardRef(TypeModal)

