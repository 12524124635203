
/*
* 根据字典类型structure（flat: 表格，tree: 树）动态展示数据结构
* */
import React, {forwardRef, useEffect, useImperativeHandle, useState, useRef} from 'react'
import {Col, Row, Table} from 'antd'
import styles from '@views/dictionary/index.module.less'
import IcdTree from '@components/tree/icdTree'
import fetch from '@utils/request'
import api from '@api'
import classNames from 'classnames'
import DicModal from '@components/typeModal/dicModal'
import usePagination from '@hooks/usePagination'
// import {permissionStyle} from '@utils/permission'

const types = [
  { value: 0, text: '待审核',},
  { value: 1, text: '审核通过',},
  { value: 2, text: '审核拒绝',}
]

const TreeShape = ({node, onEdit, searchText, refresh}, ref) => {
  // eslint-disable-next-line no-unused-vars
  const [page, setPage, setTotal, pagination, total] = usePagination()
  const [icdTreeData, setIcdTreeData] = useState([])
  const [icdTableData, setIcdTableData] = useState([])
  const [icdTreeTable, setIcdTreeTable] = useState([])
  const [curTreeNode, setCurTreeNode] = useState(null)
  const [sourceList, setSourceList] = useState([])
  const [filters, setFilters] = useState({})
  const typeRef = useRef(null)
  const loop = (node) => {
    if (node.length){
      node.forEach((item) => {
        if (!item?.children?.length){
          item.children = null
        } else {
          loop(item.children)
        }
      })
    }
  }

  const getMenu = async() => {
    const pager = {
      pageNumber: page.pageIndex,
      pageSize: page.pageSize,
    }
    const res = await fetch({
      method: 'POST',
      url: api.dictionaryApi.listDirectDescendantByGeneralId,
      data: {
        ...pager,
        generalContentId: node?.categoryId,
        verifyStatusStr: filters.verifyStatus?.join(','),
        valid: filters.validCopy?.join(','),
        sourceStr: filters.source?.join(',')
      }
    })
    setIcdTableData(res ? res.content : [])
    setTotal(res ? res.totalCount : 0)
    setIcdTreeData([])
  }
  // 获取字典详情
  const getDictionary = async(e) => {
    if (node.isCategory) {
      getMenu()
    } else {
      const pager = {
        pageNumber: page.pageIndex,
        pageSize: page.pageSize,
      }
      // eslint-disable-next-line no-unused-vars
      const {content, totalCount} = await fetch({
        method: 'POST',
        url: api.dictionaryApi.listDirectDescendant,
        data: {
          ...pager,
          parentCode: '',
          dictionaryCode: e?.code,
          verifyStatusStr: filters.verifyStatus?.join(','),
          valid: filters.validCopy?.join(','),
          sourceStr: filters.source?.join(',')
        }
      })
      if (node.structure === 'tree') {
        setIcdTreeData(content)
        setIcdTableData([])
        setTotal(0)
      } else {
        setIcdTableData(content)
        setTotal(totalCount)
        setIcdTreeData([])
      }
    }
  }

  // 获取字典详情
  const getDictionaryTable = async(e) => {
    const pager = {
      pageNumber: page.pageIndex,
      pageSize: page.pageSize,
    }
    // eslint-disable-next-line no-unused-vars
    const {content, totalCount} = await fetch({
      method: 'POST',
      url: api.dictionaryApi.listDirectDescendant,
      data: {
        ...pager,
        parentCode: e?.key,
        flat: true,
        dictionaryCode: node?.code,
        verifyStatusStr: filters.verifyStatus?.join(','),
        valid: filters.validCopy?.join(','),
        sourceStr: filters.source?.join(',')
      }
    })
    setIcdTreeTable(content)
    setTotal(totalCount)
  }

  /*
  * 第二列 Tree 缓存当前节点
  * */
  const secondTreeSelect = (e) =>{
    setCurTreeNode(e.node)
    setPage({
      pageIndex: 1,
      pageSize: 10,
    })
    // getDictionaryTable(e.node)
  }

  const handleTableChange = (pagination, filters, sorter, extra) => {
    // console.log(filters)
    setFilters(filters)
    setPage({
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
    })
  }
  // const edit = (record) => {
  //   onEdit(record)
  // }

  // // 分类
  // const setType = (id) => {
  //   typeRef.current.handleOpen(id)
  // }
  
  const getSource = async() => {
    const res = await fetch({
      method: 'get',
      url: api.dictionaryApi.sourceList,
      params: {}
    })
    if (res) {
      let arr = res.map(item => {
        return {
          value: item,
          text: item
        }
      })
      setSourceList(arr)
    } else {
      setSourceList([])
    }
  }

  useEffect(() =>{
    getSource()
  }, [])


  // eslint-disable-next-line no-unused-vars
  const columns = [
    {
      title: 'code',
      dataIndex: 'key',
      key: 'key'
    },
    {
      title: '值',
      dataIndex: 'title',
      key: 'title'
    }
  ]
  const TreeColumns = [
    {
      title: '编码',
      dataIndex: 'keyCopy',
      key: 'keyCopy'
    },
    {
      title: '名称',
      dataIndex: 'titleCopy',
      key: 'titleCopy'
    },
    {
      title: '来源',
      dataIndex: 'source',
      key: 'source',
      filters: sourceList
    },
    {
      title: '分类',
      dataIndex: 'parentName',
      key: 'parentName'
    },
    {
      title: '更新日期',
      dataIndex: 'updatedTime',
      key: 'updatedTime'
    },
    {
      title: '是否生效',
      dataIndex: 'validCopy',
      key: 'validCopy',
      filters: [
        { value: true, text: '生效',},
        { value: false, text: '失效',},
      ],
      render: (text) => {
        if (text) {
          return <div>生效</div>
        } else {
          return <div>失效</div>
        }
      }
    },
    {
      title: '审核状态',
      dataIndex: 'verifyStatus',
      key: 'verifyStatus',
      filters: types,
      render: (text) => {
        const typeObj = types.filter((type) => {
          return type.value === text
        })
        return <div className={classNames({
          [styles.statusColor]: true,
          [styles.statusIn]: text === 0,
          [styles.statusSuccess]: text === 1,
          [styles.statusFail]: text === 2
        })}>{typeObj[0].text}</div>
      }
    },
    // {
    //   title: '操作',
    //   key: 'action',
    //   fixed: 'right',
    //   render: (_, record) => (
    //     <Space size="middle">
    //       <a onClick={() => edit(record)} style={permissionStyle('DICTIONARY_LIST_EDIT')}>编辑</a>
    //       {record.structure === 'tree' && <a onClick={() => setType(record.id)} style={permissionStyle('DICTIONARY_LIST_MOVE')}>分类</a>}
    //     </Space>
    //   ),
    // },
  ]
  /*
  * 向父组件暴露当前树节点对象
  * */
  const exposeCurTreeNode = () => {
    return curTreeNode
  }
  /*
  * 重置表格数据
  * */
  const clearIcdTreeTableData = () => {
    setIcdTreeTable([])
    setCurTreeNode(null)
  }
  const fetchSearchItemPage = async (keyword) => {
    console.log(node)
    // if (!(node && node.code)){
    //   message.error('请先选择字典')
    //   return
    // }
    let pagerParam = {
      pageNumber: page.pageIndex,
      pageSize: page.pageSize,
    }
    const {content, totalCount} = await fetch({
      method: 'POST',
      url: api.dictionaryApi.searchItemPage,
      data: {
        searchText: keyword,
        dictionaryCode: node?.categoryId ? null : node?.code,
        generalContentId: node?.categoryId,
        parentCode: curTreeNode? curTreeNode.key: null,
        flat: true,
        verifyStatusStr: filters.verifyStatus?.join(','),
        valid: filters.validCopy?.join(','),
        sourceStr: filters.source?.join(','),
        ...pagerParam
      }
    })
    loop(content)
    if (!node || node.isCategory || (!curTreeNode && node && node.structure === 'flat')) {
      setIcdTableData(content)
    } else {
      setIcdTreeTable(content)
    }
    setTotal(totalCount)
  }
  const initialPager = () =>{
    setPage({
      pageIndex: 1,
      pageSize: 10
    })
  }

  const fresh = () => {
    getDictionary(node)
    getDictionaryTable(curTreeNode)
  }

  useImperativeHandle(ref, () => ({
    exposeCurTreeNode,
    clearIcdTreeTableData,
    initialPager,
  }))

  useEffect(() =>{
    if (searchText){
      fetchSearchItemPage(searchText)
    } else if (curTreeNode) {
      getDictionaryTable(curTreeNode)
    } else {
      if (node) {
        getDictionary(node)
      } else {
        getMenu()
      }
    }

  }, [node?.code, searchText, page, curTreeNode, refresh])

  
  return (
    <>
      {node?.structure === 'tree' && <Row className={styles.isIcd} gutter={16}>
        <Col span={6}>
          <div className={styles.icdTree}>
            <IcdTree tree={icdTreeData} dictionary={node} treeSelect={secondTreeSelect} showCode/>
          </div>
        </Col>
        <Col span={18}>
          <div className={styles.icdTree}>
            <Table
              rowKey='id'
              size='small'
              scroll={{ x: 'max-content' }}
              columns={TreeColumns}
              dataSource={icdTreeTable}
              pagination={pagination}
              onChange={handleTableChange}
            />
          </div>
        </Col>
      </Row>}
      {node?.structure !== 'tree' && <Table
        rowKey='id'
        columns={TreeColumns}
        dataSource={icdTableData}
        pagination={pagination}
        onChange={handleTableChange}
      />}
      <DicModal ref={typeRef} tree={icdTreeData} node={node} onSuccess={() => fresh()} />
    </>
  )
}

export default forwardRef(TreeShape)
