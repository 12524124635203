import React, { useState, useImperativeHandle, forwardRef } from 'react'
import PropTypes from 'prop-types'
import CustomModal from '@components/customModal'
import {useWindowSize} from '@hooks/useLayout'
import { ReactComponent as IconConfirm } from '@assets/images/tag/icon_confirm.svg'
import styles from './index.module.less'
import {Button, Space} from 'antd'

const ImportStatus = ({ callback }, ref) => {
  const [visible, setVisible] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(false)
  const [errList, setErrList] = useState([])
  const [uploading, setUploading] = useState(false)
  const [, , scale] = useWindowSize()

  const hide = () => {
    setUploading(false)
    setVisible(false)
    setError(false)
  }
  const show = (errList) => {
    setVisible(true)
    setErrList(errList)
  }
  useImperativeHandle(ref, () => ({
    show,
    hide,
  }))

  const handleSubmit = async () => {
    //
  }

  const successDom = () => {
    return (
      <div className={styles.status}>
        <div className={styles.titleWrapper}>
          <IconConfirm style={{ color: '#031f47', marginRight: 16*scale, width: `${scale*20}px`, height: `${scale*20}px`, alignSelf: 'center' }} />
          <div className={styles.title}>{`${errList.length}个标签导入失败`}</div>
        </div>
        <div className={styles.content}>
          <div className={styles.errorTitle}>失败原因：以下标签名称存在重复</div>
          {errList?.map((list, index) => {
            return (
              <div key={index} className={styles.errorItemTitle}>{list}</div>
            )
          })}
        </div>
        <Space className={styles.footer}>
          <Button onClick={() => {
            hide()
          }}>取消</Button>
          <Button type='primary' onClick={() => {
            callback(1)
          }}>覆盖</Button>
          <Button type='primary' onClick={() => {
            callback(2)
          }}>增量新增</Button>
        </Space>
      </div>
    )
  }

  return (
    <CustomModal
      open={visible}
      onCancel={hide}
      onOk={handleSubmit}
      okButtonProps={{ loading: uploading }}
      width={416*scale}
      footer={null}
      closable={false}
    >
      {successDom()}
    </CustomModal>
  )
}

export default forwardRef(ImportStatus)

ImportStatus.propTypes = {
  onSuccess: PropTypes.func,
}
