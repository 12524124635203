import moment from 'moment'
import 'moment/locale/zh-cn'
moment.locale('zh-cn')

export const exportZipcom = (key, data) => {
  const name = key + '_' + moment(new Date().getTime()).format('YYYYMMDD HH:mm:ss')
  let fileName = `${name}.zip`
  let exporturl = window.URL.createObjectURL(new Blob([data], { type: 'application/zip' }))
  let a = document.createElement('a')
  document.body.appendChild(a)
  a.href = exporturl
  a.download = fileName
  a.target = '_blank'
  a.click()
  document.body.removeChild(a)
}

export const exportCsv = (key, data) => {
  const name = key + '_' + moment(new Date().getTime()).format('YYYYMMDD')
  let fileName = `${name}.csv`
  let exporturl = window.URL.createObjectURL(new Blob([data], { type: '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel' }))
  let a = document.createElement('a')
  document.body.appendChild(a)
  a.href = exporturl
  a.download = fileName
  a.target = '_blank'
  a.click()
  document.body.removeChild(a)
}

export function exportExcel (name, data, type = 'xlsx') {
  let fileName = `${name}.${type}`
  let exporturl = window.URL.createObjectURL(new Blob([data], { type: 'application/octet-stream' }))
  let a = document.createElement('a')
  document.body.appendChild(a)
  a.href = exporturl
  a.download = fileName
  a.target = '_blank'
  a.click()
  document.body.removeChild(a)
}

// let str = '<div>Hello World</div>'
// let blob = new Blob([str], {type: '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'})
// console.log(blob)
// const url3 = window.URL.createObjectURL(blob)
// console.log(url3)
// let filename = '文件流下载' + '.csv'
// const link = document.createElement('a')
// link.style.display = 'none'
// link.href = url3
// link.setAttribute('download', filename)
// document.body.appendChild(link)
// link.click()
