/*
* 字典相关api
* */
const dictionaryApi = {
  treeList: '/kbms/dictionary/list', // 获取字典清单
  listDirectDescendant: '/kbms/dictionary/listDirectDescendant', // 获取子节点列表
  listDirectDescendantNoPage: '/kbms/dictionary/listDirectDescendantNoPage', // 获取子节点列表, 不分页
  searchItem: '/kbms/dictionary/searchItem', // 搜索字典值
  searchItemPage: '/kbms/dictionary/searchItemPage', // 搜索字典值， 分页
  list: '/kbms/meta/list', // 元数据树
  listMetaAttributes: '/kbms/meta/listMetaAttributes', // 获取元数据属性列表
  listPageMetaAttributes: '/kbms/meta/listPageMetaAttributes', // 获取元数据属性列表-分页
  searchItemRecommend: '/kbms/dictionary/searchItemRecommend', // 字典输入框，搜索相似度字典
  dictionarySave: '/kbms/dictionary/save', // 新增-编辑字典
  verify: '/kbms/dictionary/verify', // 审核字典
  updatePath: '/kbms/dictionary/updatePath', // 字典分类
  getByGeneralContentId: '/kbms/dictionary/getByGeneralContentId', // 根据目录id获取具体字典
  getDwdTableTree: '/kbms/metaDataInfo/getDwdTableTree', // 获取dwd数据库表字段统计表列表树
  listDictionary: '/kbms/dictionary/list', // 字典列表(元数据-数据元素新增)
  listPageDataSource: '/kbms/metaDataInfo/listPageDataSource', // 字典列表(元数据-数据元素新增)
  deleteDataSource: '/kbms/metaDataInfo/deleteDataSource', // 数据来源-删除
  deleteDataElement: '/kbms/metaDataInfo/deleteDataElement', // 数据元素-删除
  updateDataElement: '/kbms/metaDataInfo/updateDataElement', // 数据元素-修改-分类
  updateDataSource: '/kbms/metaDataInfo/updateDataSource', // 数据来源-修改-分类
  addDataSource: '/kbms/metaDataInfo/addDataSource', // 数据来源-新增
  addDataElement: '/kbms/metaDataInfo/addDataElement', // 数据元素-新增
  listDataSource: '/kbms/metaDataInfo/listDataSource', // 获取全量数据来源列表、搜索
  listPageDataElement: '/kbms/metaDataInfo/listPageDataElement', // 获取目录节点下的数据元素列表
  listDirectDescendantByGeneralId: '/kbms/dictionary/listDirectDescendantByGeneralId', // 点击目录时候，获取数据
  sourceList: '/kbms/dictionary/sourceList',
  getKnowledgeGraph: '/kbms/knowledgeGraph/get/knowledgeGraph',
  getRelationAll: '/kbms/knowledgeGraph/get/getRelationAll',
  getRelationAllType: '/kbms/knowledgeGraph/get/getRelationAllType',
  locationDictionary: '/kbms/dictionary/locationDictionary', // 用来定位已选择字典的位置
  getDataSourceMaxVersion: '/kbms/metaDataInfo/getDataSourceMaxVersion', // 获取数据来源最大版本号
  dictCheck: '/kbms/dictionary/dictCheck',
  importIkgDict: '/kbms/dictionary/importIkgDict'
}

export default dictionaryApi
