import React, { useEffect, useRef, useState} from 'react'
import { useLocation } from 'react-router'
import { Button, Row, Col, Space, Modal, Table, Tooltip, Tabs, message } from 'antd'
import { EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
const { TabPane } = Tabs
import KeySearch from '@components/form/search'
import LabelModal from './components/labelModal'
import styles from './index.module.less'
import fetch from '@utils/request'
import api from '@api'
import {permissionAuth, permissionStyle} from '@utils/permission'
// import TagEdit from '@views/tag/engine/index'
// const TagEngineModal = lazy(() => import('@views/tag/engine/index'))
import TagEngineModal from '@views/tag/engineV2/index'
import {exportZipcom} from '@utils/export'
import {useWindowSize} from '@hooks/useLayout'
import MenuTree from '@components/tree/menuTree'
import usePagination from '@hooks/usePagination'
import ImportModal from './components/importModal'

const tags = [
  {
    value: 'included',
    desc: '入组标签'
  },
  {
    value: 'excluded',
    desc: '排除标签'
  }
]

const ProjectDetail = () => {
  const [open, setOpen] = useState(false)
  const [load, setLoad] = useState(false)
  const { state: {id, projectName} } = useLocation()
  // const [type, setType] = useState('meta') // 标签类型
  const type = 'meta'
  const [actionType, setActionType] = useState('add') // 选择的树节点
  const [current, setCurrent] = useState('included')
  const [treeNode, setTreeNode] = useState('')
  const [select, setSelect] = useState(null) // 选择的树节点
  const [list, setList] = useState([])
  // eslint-disable-next-line no-unused-vars
  const [listKeyword, setListKeyword] = useState('')
  const labelRef = useRef(null)
  const tagEditRef = useRef(null)
  const menuTreeRef = useRef(null)
  const [editRow, setEditRow] = useState(null) // 编辑标签规则
  const [,, scale] = useWindowSize()
  const [page, setPage, setTotal, pagination, total] = usePagination()
  const authItemKeys = [permissionAuth('PROJECT_LIST_DIR_ADD'), permissionAuth('PROJECT_LIST_DIR_ADD'), permissionAuth('PROJECT_LIST_DIR_EDIT'), permissionAuth('PROJECT_LIST_DIR_DELETE')]

  const importRef = useRef(null)

  const getList = async(e, word) => {
    const pager = {
      pageNumber: page.pageIndex,
      pageSize: page.pageSize,
    }
    const {content, totalCount} = await fetch({
      method: 'POST',
      url: api.tagApi.getTagsByCategory,
      data: {
        categoryId: e ? e : treeNode.categoryId,
        categoryType: `project_${current}_${id}`,
        projectId: id,
        ...pager,
        keyword: word
      },
      headers: {
        'Content-Type': 'application/json'
      }
    })
    setList(content)
    setTotal(totalCount)
  }
  const handleTableChange = (pagination) => {
    setPage({
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
    })
  }
  // 任务下载
  const onDoenload = async() => {
    const res = await fetch({
      method: 'POST',
      url: api.projectApi.ruleExport,
      data: {
        proId: id,
      },
      // headers: {
      //   'Content-Type': 'application/x-www-form-urlencoded'
      // },
      responseType: 'blob'
    })
    exportZipcom(projectName, res)
  }


  // 分配标签
  const onLabel = () => {
    labelRef.current.handleOpen()
  }

  // 标签分配成功
  const onSuccess = () => {
    treeNode && getList()
    menuTreeRef.current.refreshTree()
  }

  // tab切换
  const onTabChange = (e) => {
    setCurrent(e)
    setSelect(null)
    setList([])
    setTotal(0)
    setPage({
      pageIndex: 1,
      pageSize: 10,
    })
    setTreeNode('')
    menuTreeRef.current.onSelectClear()
  }

  // 标签类型选择
  // const onTypeChange = (e) => {
  //   setType(e)
  // }

  // 树节点选择
  // const treeSelect = (e) => {
  //   if (e.length >0) {
  //     setTreeNode(e[0])
  //     getList(e[0])
  //   }
  // }


  // 树节点选择
  const treeSelect = async (e) => {
    // setPage({
    //   pageIndex: 1,
    //   pageSize: 10,
    // })
    if (!e.node.code) {
      setSelect(null)
      setList([])
      return
    }
    setSelect(e.node.code)
    setTreeNode(e.node)
  }

  // eslint-disable-next-line no-unused-vars
  const treeSearch = (keyword) => {
    // setKeyword(keyword)
  }
  // 关键词搜索
  const keySearchClick = (e) => {
    if (!treeNode){
      return message.warn('请先选择节点！')
    }
    setListKeyword(e)
    setTimeout(() => {
      getList(null, e)
    }, 100)
  }
  // table编辑
  const onEdit = (record) => {
    setActionType('edit')
    setEditRow(record)
    setLoad(true)
  }

  // table删除
  const onDel = (record) => {
    Modal.confirm({
      title: '确定删除该标签？',
      icon: <ExclamationCircleOutlined />,
      okText: '确认',
      cancelText: '取消',
      onOk: async() => {
        await fetch({
          method: 'POST',
          url: api.tagApi.deleteLabel,
          data: {
            labelId: record.id,
            version: record.version,
            projectId: id
          }
        })
        message.success('删除成功')
        const totalPage = Math.ceil((total - 1) / page.pageSize) // 总页数
        let curPage = page.pageIndex > totalPage ? totalPage : page.pageIndex
        setPage({
          pageIndex: curPage > 1? curPage: 1,
          pageSize: page.pageSize,
        })
        await getList()
        menuTreeRef.current.refreshTree()
      }
    })
  }

  /*
* 标签新增or编辑
* */
  const doneEdit = async (canvasData) => {
    // eslint-disable-next-line no-unused-vars
    const response = await fetch(
      {
        method: 'POST',
        url: api.tagApi.labelSave,
        data: {
          categoryId: editRow.categoryId,
          categoryName: editRow.categoryName,
          id: editRow.id,
          categoryType: type,
          name: canvasData.tagName,
          remark: canvasData.remark,
          ruleJson: {nodeDataArray: canvasData.nodeDataArray, linkDataArray: canvasData.linkDataArray },
          version: editRow.version,
          ruleSet: 'project'
        },
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        }
      }
    )
    setOpen(false)
    treeNode && getList()
  }

  const auditTag = async (type) => {
    // eslint-disable-next-line no-unused-vars
    const response = await fetch(
      {
        method: 'POST',
        url: api.tagApi.auditTag,
        data: {
          version: editRow.version,
          id: editRow.id,
          code: type
        },
      }
    )

  }

  useEffect(() => {
    select && getList()
  }, [select, current, page])
  useEffect(() => {
    setOpen(!!load)
  }, [load])
  const columns = [
    {
      title: '标签名',
      dataIndex: 'name',
      key: 'name',
      render: (text) => {
        return (
          <div title={text} className={styles.ellipse}>{text}</div>
        )
      }
    },
    {
      title: '规则',
      dataIndex: 'remark',
      key: 'remark',
      render: (text) => {
        return (
          <div title={text} className={styles.ellipse}>{text}</div>
        )
      }
    },
    {
      title: '编辑人',
      dataIndex: 'updatedBy',
      key: 'updatedBy',
    },
    {
      title: '编辑时间',
      dataIndex: 'updatedTime',
      key: 'updatedTime',
    },
    {
      title: '操作',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Tooltip mouseEnterDelay="2" title="编辑">
            <a onClick={() => onEdit(record)} style={permissionStyle('PROJECT_SAVE_LABEL2')}><EditOutlined /></a>
          </Tooltip>
          <Tooltip mouseEnterDelay="2" title="删除">
            <a onClick={() => onDel(record)} style={permissionStyle('PROJECT_LABEL_DELETE')}><DeleteOutlined /></a>
          </Tooltip>
        </Space>
      ),
    },
  ]

  return (
    <>
      {/* 标题栏 */}
      <div className="head">
        <h3 className="head-title">项目详情</h3>
        <Space>
          <Button onClick={() => {
            importRef.current.show()
          }} type='primary' style={permissionStyle('PROJECT_RULE_IMPORT')}>导入标签</Button>
          <Button onClick={() => {
            onDoenload()
          }} style={permissionStyle('PROJECT_RULE_EXPORT')}>导出标签</Button>
        </Space>

      </div>
      {/* 搜索，分配标签 */}
      <Tabs className={styles.tab} activeKey={current} onChange={onTabChange}>
        {tags.map(node => (
          <TabPane tab={node.desc} key={node.value}></TabPane>
        ))}
      </Tabs>
      {/* 标签库 */}
      {/* <div className={styles.rowSpace}>
        <Select
          className={styles.searchWidth}
          value={type}
          onChange={onTypeChange}
        >
          <Select.Option value='meta'>原子标签</Select.Option>
          <Select.Option value='derived'>衍生标签</Select.Option>
        </Select>
      </div> */}
      <Row className={styles.rowSpace} gutter={16}>
        <Col flex={`${scale*240}px`} style={{'overflow': 'hidden'}}>
          <MenuTree authItemKeys={authItemKeys} ref={menuTreeRef} title={'标签库'} showNumber type={`project_${current}_${id}`} extraParams={{projectId: id}} search={true} treeSelect={treeSelect} />
        </Col>
        <Col flex={1}>
          <div className={styles.searchFlex}>
            <Space size="large">
              <KeySearch keySearchClick={keySearchClick} />
            </Space>
            <Button type="primary" onClick={onLabel} style={permissionStyle('PROJECT_SAVE_LABEL')}>分配标签</Button>
          </div>
          <Table columns={columns} dataSource={list} pagination={pagination}onChange={handleTableChange}/>
        </Col>
      </Row>
      <LabelModal ref={labelRef} id={id} onSuccess={onSuccess} type='label'/>
      {(open || load) && (
        <TagEngineModal
          source="project"
          ref={tagEditRef}
          actionType={actionType}
          auditTag={auditTag}
          onSubmit={doneEdit}
          editRow={editRow}
          open={open}
          onCancel={() => setOpen(false)}
          afterClose={() => setLoad(false)}
        />
      )}
      <ImportModal onSuccess={onSuccess} ref={importRef}projectId={id}/>
    </>
  )
}

export default ProjectDetail
