/*
* 新版标签引擎，画布 gojs实现
* */

// eslint-disable-next-line no-unused-vars
import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react'
import * as go from 'gojs'
// eslint-disable-next-line no-unused-vars
import { ReactDiagram, ReactPalette } from 'gojs-react'
import scale from '@utils/auto-rem'
import styles from './index.module.less'
import Toolbar from '@views/tag/engineV2/components/toolbar'
import {message} from 'antd'
import {arrayPush, change, formValueSelector} from 'redux-form'
import {connect} from 'react-redux'
// import {useWindowSize} from '@hooks/useLayout'
let FlowDiagram = ({onChange, onSelectedChange, pushArray, changeArray, nodeDataArray, linkDataArray, onModelChange}, ref) => {
  const $ = go.GraphObject.make
  // const [,, scale] = useWindowSize()
  const diagramRef = useRef(null)
  const [canvasChanged, setCanvasChanged] = useState(false)
  const [myDiagramInstance, setMyDiagramInstance] = useState(null)
  const [paletteLinkDataArray] = useState([
    { key: 1, text: '开始', figure: 'TriangleUp', 'size': `${50*scale} ${40*scale}`, 'nodeSize': `${100*scale} ${80*scale}`, fill: 'rgba(196, 29, 127, 1)', type: 'START'},
    { key: 2, text: '标签\n控件', figure: 'RoundedRectangle', 'size': `${40*scale} ${40*scale}`, 'nodeSize': `${80*scale} ${80*scale}`, fill: 'rgba(0, 139, 199, 1)', type: 'RULE' },
    { key: 3, text: '取算法属性', figure: 'RoundedRectangle', 'size': `${72*scale} ${30*scale}`, 'nodeSize': `${144*scale} ${60*scale}`, fill: 'rgba(0, 166, 141, 1)', type: 'EXTRACT' },
    { key: 4, text: '数据控件', figure: 'Ellipse', 'size': `${40*scale} ${40*scale}`, 'nodeSize': `${80*scale} ${80*scale}`, fill: 'rgba(29, 57, 196, 1)', type: 'DATA' },
    /* todo Diamond */
    { key: 5, text: '算法控件', 'size': `${70*scale} ${30*scale}`, 'nodeSize': `${140*scale} ${60*scale}`, fill: 'rgba(212, 107, 8, 1)', type: 'AI', geometryString: 'F M0 0 L80 0 B-90 90 80 20 20 20 L100 100 20 100 B90 90 20 80 20 20z' },
    { key: 6, text: '常数控件', figure: 'RoundedRectangle', 'size': `${60*scale} ${28*scale}`, 'nodeSize': `${120*scale} ${56*scale}`, fill: 'rgba(212, 177, 6, 1)', type: 'CONSTANT' },
    { key: 7, text: '运算符控件', figure: 'Diamond', 'size': `${110*scale} ${34*scale}`, 'nodeSize': `${208*scale} ${68*scale}`, fill: 'rgba(56, 158, 13, 1)', type: 'OPERATION' },
    /* todo RoundedRectangle */
    { key: 8, text: ' 逻辑运算符', figure: 'RoundedRectangle', 'size': `${90*scale} ${30*scale}`, 'nodeSize': `${160*scale} ${60*scale}`, fill: 'rgba(8, 151, 156, 1)', type: 'LOGIC', geometryString: 'F M0 0 L100 0 Q150 50 100 100 L0 100 Q50 50 0 0z' },
    { key: 9, text: '输出控件', figure: 'Ellipse', 'size': `${76*scale} ${30*scale}`, 'nodeSize': `${152*scale} ${60*scale}`, fill: 'rgba(83, 29, 171, 1)', type: 'OUTPUT' },
    { key: 11, text: '知识图谱', figure: 'Ellipse', 'size': `${60*scale} ${30*scale}`, 'nodeSize': `${120*scale} ${60*scale}`, fill: 'rgba(170, 62, 25, 1)', type: 'KNOWLEDGE', geometryString: 'F M0 7.5 L30 0 60 7.5 60 22.5 30 30 0 22.5z' },
    { key: 10, text: '脚本控件', figure: 'Ellipse', 'size': `${76*scale} ${30*scale}`, 'nodeSize': `${152*scale} ${60*scale}`, fill: 'rgba(250, 140, 22, 1)', type: 'SCRIPT', geometryString: 'F M10 0 L66 0 76 30 0 30z' },
  ])
  // let mapNodeKeyIdx = new Map()
  /**
     * Update map of node keys to their index in the array.
     */
  // const refreshNodeIndex = (nodeArr) => {
  //   mapNodeKeyIdx.clear()
  //   nodeArr.forEach((n, idx) => {
  //     mapNodeKeyIdx.set(n.key, idx)
  //   })
  // }
  // refreshNodeIndex(nodeDataArray)

  const initDiagramData = (data) => {
    let initData = data || {
      class: 'GraphLinksModel',
      linkKeyProperty: 'key',
      linkDataArray: [],
      // 默认显示 Start、End
      nodeDataArray: [],
    }
    if (myDiagramInstance){
      myDiagramInstance.model = go.Model.fromJson(initData)
    }
    setCanvasChanged(false)
  }
  const initDiagram = () => {
    // set your license key here before creating the diagram: go.Diagram.licenseKey = "...";
    const myDiagram =
          $(go.Diagram,
            {
              maxSelectionCount: 1,
              grid: $(go.Panel, 'Grid',
                { background: '#FAFAFA' },
                // $(go.Shape, 'LineH', { stroke: 'lightgray', strokeWidth: 0.5 }),
                // $(go.Shape, 'LineH', { stroke: 'gray', strokeWidth: 0.5, interval: 10 }),
                // $(go.Shape, 'LineV', { stroke: 'lightgray', strokeWidth: 0.5 }),
                // $(go.Shape, 'LineV', { stroke: 'gray', strokeWidth: 0.5, interval: 10 })
              ),
              model: $(go.GraphLinksModel, {
                linkKeyProperty: 'key', // IMPORTANT! must be defined for merges and data sync when using GraphLinksModel
                // positive keys for nodes
                makeUniqueKeyFunction: (m, data) => {
                  let k = data.key || 1
                  while (m.findNodeDataForKey(k)) k++
                  data.key = k
                  return k
                },
                // negative keys for links
                makeUniqueLinkKeyFunction: (m, data) => {
                  let k = data.key || -1
                  while (m.findLinkDataForKey(k)) k--
                  data.key = k
                  return k
                }
              }),
              'draggingTool.dragsLink': false,
              'draggingTool.isGridSnapEnabled': false,
              'linkingTool.isUnconnectedLinkValid': false,
              'linkingTool.portGravity': 20,
              'relinkingTool.isUnconnectedLinkValid': true,
              'relinkingTool.portGravity': 20,
              'relinkingTool.fromHandleArchetype':
                      $(go.Shape, 'Diamond', { segmentIndex: 0, cursor: 'pointer', desiredSize: new go.Size(8, 8), fill: 'tomato', stroke: 'darkred' }),
              'relinkingTool.toHandleArchetype':
                      $(go.Shape, 'Diamond', { segmentIndex: -1, cursor: 'pointer', desiredSize: new go.Size(8, 8), fill: 'darkred', stroke: 'tomato' }),
              'linkReshapingTool.handleArchetype':
                      $(go.Shape, 'Diamond', { desiredSize: new go.Size(7, 7), fill: 'lightblue', stroke: 'deepskyblue' }),
              'rotatingTool.handleAngle': 270,
              'rotatingTool.handleDistance': 30,
              'rotatingTool.snapAngleMultiple': 15,
              'rotatingTool.snapAngleEpsilon': 15,
              'undoManager.isEnabled': true
            })
    // when the document is modified, add a "*" to the title and enable the "Save" button
    myDiagram.addDiagramListener('Modified', e => {
      let button = document.getElementById('SaveButton')
      if (button) button.disabled = !myDiagram.isModified
      let idx = document.title.indexOf('*')
      if (myDiagram.isModified) {
        if (idx < 0) document.title += '*'
      } else {
        if (idx >= 0) document.title = document.title.slice(0, idx)
      }
    })
    myDiagram.addDiagramListener('ChangedSelection', (event) => {
      event.diagram.selection.each( (part) => {
        if (part.data.type === 'START') return
        if (part instanceof go.Node) {
          onSelectedChange(part.data)
        }
      })
    })
    // 添加监听线生成事件
    myDiagram.addDiagramListener('LinkDrawn', function(e) {
      const linkNode = e.subject.data
      const {from: fromKey, to: toKey} = linkNode
      const fromNode = e.diagram.findNodeForKey(fromKey)?.data
      const toNode = e.diagram.findNodeForKey(toKey)?.data
      if (fromNode.type){
        e.diagram.model.setDataProperty(toNode, 'parentId', 11)
      }
    })
    // 约束条件：一个节点只能连接到另一个节点
    const canLink = (fromNode, fromPort, toNode, toPort) => {
      if (toNode && (toNode.linksConnected.count > 1 && toNode.data.type === 'EXTRACT')) {
        // 当目标节点已连接时，不允许链接
        message.warn('连线操作有误，请检查！')
        return false
      } else {
        return true
      }
    }

    myDiagram.toolManager.linkingTool.linkValidation = canLink
    // myDiagram.toolManager.relinkingTool.linkValidation = canLink

    // Define a function for creating a "port" that is normally transparent.
    // The "name" is used as the GraphObject.portId, the "spot" is used to control how links connect
    // and where the port is positioned on the node, and the boolean "output" and "input" arguments
    // control whether the user can draw links from or to the port.
    const makePort = (name, spot, output, input) => {
      // the port is basically just a small transparent circle
      return $(go.Shape, 'Circle',
        {
          fill: null, // not seen, by default; set to a translucent gray by showSmallPorts, defined below
          stroke: null,
          desiredSize: new go.Size(7, 7),
          alignment: spot, // align the port on the main Shape
          alignmentFocus: spot, // just inside the Shape
          portId: name, // declare this object to be a "port"
          fromSpot: spot, toSpot: spot, // declare where links may connect at this port
          fromLinkable: output, toLinkable: input, // declare whether the user may draw links to/from here
          cursor: 'pointer' // show a different cursor to indicate potential link point
        })
    }

    const showSmallPorts = (node, show) => {
      node.ports.each(port => {
        if (port.portId !== '') { // don't change the default port, which is the big shape
          port.fill = show ? 'rgba(0,0,0,.3)' : null
        }
      })
    }
    let nodeSelectionAdornmentTemplate =
          $(go.Adornment, 'Auto',
            $(go.Shape, { fill: null, stroke: 'deepskyblue', strokeWidth: 1.5, strokeDashArray: [4, 2] }),
            $(go.Placeholder)
          )

    // eslint-disable-next-line no-unused-vars
    let nodeResizeAdornmentTemplate =
          $(go.Adornment, 'Spot',
            { locationSpot: go.Spot.Right },
            $(go.Placeholder),
            $(go.Shape, { alignment: go.Spot.TopLeft, cursor: 'nw-resize', desiredSize: new go.Size(6, 6), fill: 'lightblue', stroke: 'deepskyblue' }),
            $(go.Shape, { alignment: go.Spot.Top, cursor: 'n-resize', desiredSize: new go.Size(6, 6), fill: 'lightblue', stroke: 'deepskyblue' }),
            $(go.Shape, { alignment: go.Spot.TopRight, cursor: 'ne-resize', desiredSize: new go.Size(6, 6), fill: 'lightblue', stroke: 'deepskyblue' }),

            $(go.Shape, { alignment: go.Spot.Left, cursor: 'w-resize', desiredSize: new go.Size(6, 6), fill: 'lightblue', stroke: 'deepskyblue' }),
            $(go.Shape, { alignment: go.Spot.Right, cursor: 'e-resize', desiredSize: new go.Size(6, 6), fill: 'lightblue', stroke: 'deepskyblue' }),

            $(go.Shape, { alignment: go.Spot.BottomLeft, cursor: 'se-resize', desiredSize: new go.Size(6, 6), fill: 'lightblue', stroke: 'deepskyblue' }),
            $(go.Shape, { alignment: go.Spot.Bottom, cursor: 's-resize', desiredSize: new go.Size(6, 6), fill: 'lightblue', stroke: 'deepskyblue' }),
            $(go.Shape, { alignment: go.Spot.BottomRight, cursor: 'sw-resize', desiredSize: new go.Size(6, 6), fill: 'lightblue', stroke: 'deepskyblue' })
          )

    // eslint-disable-next-line no-unused-vars
    let nodeRotateAdornmentTemplate =
          $(go.Adornment,
            { locationSpot: go.Spot.Center, locationObjectName: 'ELLIPSE' },
            $(go.Shape, 'Ellipse', { name: 'ELLIPSE', cursor: 'pointer', desiredSize: new go.Size(7, 7), fill: 'lightblue', stroke: 'deepskyblue' }),
            $(go.Shape, { geometryString: 'M3.5 7 L3.5 30', isGeometryPositioned: true, stroke: 'deepskyblue', strokeWidth: 1.5, strokeDashArray: [4, 2] })
          )
    // define a simple Node template
    myDiagram.nodeTemplate =
        $(go.Node, 'Auto',
          { locationSpot: go.Spot.Center, locationObjectName: 'SHAPE',},
          new go.Binding('location', 'loc', go.Point.parse).makeTwoWay(go.Point.stringify),
          { selectable: true, selectionAdornmentTemplate: nodeSelectionAdornmentTemplate },
          // { resizable: true, resizeObjectName: 'PANEL', resizeAdornmentTemplate: nodeResizeAdornmentTemplate },
          // { rotatable: true, rotateAdornmentTemplate: nodeRotateAdornmentTemplate },
          // new go.Binding('angle').makeTwoWay(),
          // the main object is a Panel that surrounds a TextBlock with a Shape
          $(go.Panel, 'Auto',
            { name: 'PANEL' },
            new go.Binding('desiredSize', 'size', go.Size.parse).makeTwoWay(go.Size.stringify),
            $(go.Shape, 'Rectangle', // default figure
              {
                portId: '', // the default port: if no spot on link data, use closest side
                fromLinkable: true, toLinkable: true, cursor: 'pointer',
                fill: 'white', // default color
                strokeWidth: 0,
                opacity: 0.1,
                geometryString: '',
                margin: 0
              },
              new go.Binding('figure'),
              new go.Binding('margin'),
              new go.Binding('geometryString'),
              new go.Binding('size', 'nodeSize'),
              new go.Binding('fill')),
            $(go.TextBlock,
              {
                font: `${10*scale}px sans-serif`,
                maxLines: 2,
                wrap: go.TextBlock.WrapFit,
                editable: true,
                stroke: '',
              },
              new go.Binding('text').makeTwoWay()),
            new go.Binding('stroke', 'fill'),
          ),
          // four small named ports, one on each side:
          makePort('T', go.Spot.Top, false, true),
          makePort('L', go.Spot.Left, true, true),
          makePort('R', go.Spot.Right, true, true),
          makePort('B', go.Spot.Bottom, true, false),
          { // handle mouse enter/leave events to show/hide the ports
            mouseEnter: (e, node) => showSmallPorts(node, true),
            mouseLeave: (e, node) => showSmallPorts(node, false)
          },
        )

    myDiagram.linkTemplate =
          $(go.Link, // the whole link panel
            { relinkableFrom: true, relinkableTo: true },
            new go.Binding('points').makeTwoWay(),
            $(go.Shape, // the link path shape
              { isPanelMain: true, strokeWidth: 2 }),
            $(go.Shape, // the arrowhead
              { toArrow: 'Standard', stroke: null }),
            $(go.TextBlock, // the label text
              {
                textAlign: 'center',
                font: '9pt helvetica, arial, sans-serif',
                margin: 4,
                editable: true, // enable in-place editing
                segmentOffset: new go.Point(0, -10),
                segmentOrientation: go.Link.OrientUpright,
                width: 80*scale,
              },
              // editing the text automatically updates the model data
              new go.Binding('text').makeTwoWay())
          )
    return myDiagram
  }

  // function initPalette() {
  //   const $ = go.GraphObject.make
  //   return $(go.Palette)
  // }
  const initPalette = () => {
    let nodeSelectionAdornmentTemplate =
          $(go.Adornment, 'Auto',
            $(go.Shape, { fill: null, stroke: 'deepskyblue', strokeWidth: 1.5, strokeDashArray: [4, 2] }),
            $(go.Placeholder)
          )

    const myPalette =
              $(go.Palette, // must name or refer to the DIV HTML element
                {
                  layout: $(go.GridLayout,
                    {alignment: go.GridLayout.Location, spacing: new go.Size(10*scale, 10*scale), wrappingColumn: 9, cellSize: new go.Size(10*scale, 10*scale)}),
                  maxSelectionCount: 1,
                  // nodeTemplateMap: initDiagram().nodeTemplateMap, // share the templates used by myDiagram
                  nodeTemplate:
                        $(go.Node, 'Vertical',
                          { locationObjectName: 'TB', locationSpot: go.Spot.Left },
                          { selectable: true, selectionAdornmentTemplate: nodeSelectionAdornmentTemplate },
                          $(go.Panel, 'Auto',
                            { margin: 10*scale},
                            new go.Binding('desiredSize', 'size', go.Size.parse).makeTwoWay(go.Size.stringify),
                            $(go.Shape, 'Rectangle', // default figure
                              {
                                portId: '', // the default port: if no spot on link data, use closest side
                                fromLinkable: true, toLinkable: true, cursor: 'pointer',
                                fill: 'white', // default color
                                strokeWidth: 0,
                                opacity: 0.1,
                                geometryString: '',
                                margin: 0
                              },
                              new go.Binding('figure'),
                              new go.Binding('margin'),
                              new go.Binding('geometryString'),
                              new go.Binding('size', 'nodeSize'),
                              new go.Binding('geometryString', 'geometryString'),
                              new go.Binding('fill')),
                            $(go.TextBlock,
                              {
                                font: `${10*scale}px sans-serif`,
                                maxLines: 2,
                                wrap: go.TextBlock.WrapFit,
                                editable: true,
                                stroke: '',
                                margin: 2,
                              },
                              new go.Binding('text').makeTwoWay()),
                            new go.Binding('stroke', 'fill'),
                          ),
                        ),
                  linkTemplate: // simplify the link template, just in this Palette
                          $(go.Link,
                            { // because the GridLayout.alignment is Location and the nodes have locationSpot == Spot.Center,
                              // to line up the Link in the same manner we have to pretend the Link has the same location spot
                              locationSpot: go.Spot.Center,
                              selectionAdornmentTemplate:
                                      $(go.Adornment, 'Link',
                                        { locationSpot: go.Spot.Center },
                                        $(go.Shape,
                                          { isPanelMain: true, fill: null, stroke: 'deepskyblue', strokeWidth: 0 }),
                                        $(go.Shape, // the arrowhead
                                          { toArrow: 'Standard', stroke: null })
                                      )
                            },
                            // {
                            //   routing: go.Link.AvoidsNodes,
                            //   curve: go.Link.JumpOver,
                            //   corner: 5,
                            //   toShortLength: 4
                            // },
                            new go.Binding('points'),
                            $(go.Shape, // the link path shape
                              { isPanelMain: true, strokeWidth: 1 }),
                            $(go.Shape, // the arrowhead
                              { toArrow: 'Standard', stroke: null })
                          )
                })
    return myPalette
  }

  const ifCanvasChanged = () => {
    return canvasChanged
  }
  function getModelJson () {
    // eslint-disable-next-line no-eval
    const modelJSON = eval('('+myDiagramInstance?.model?.toJson()+')')
    console.log('model:', modelJSON)
    onModelChange(modelJSON)
  }
  const handleModelChange = (obj) => {
    console.log('obj:', obj)
    const modelJSON = getModelJson()
    setCanvasChanged(true)
    onModelChange(modelJSON)
  }


  const insetPropertyToNode = (key, data) => {
    const curNode = myDiagramInstance.model.findNodeDataForKey(key)
    myDiagramInstance.model.setDataProperty(curNode, 'data', data)// 然后对这个对象的属性进行更改
  }
  const findNodesConnected = (key) => {
    let nodes = []
    let curNode = myDiagramInstance?.findNodeForKey(key)
    if (curNode) {
      let linkNodes = curNode?.findNodesConnected()
      console.log('linkNodes', linkNodes)
      while (linkNodes.next()){
        if (linkNodes.value.data.type === 'AI') {
          nodes.push(linkNodes.value.data)
        }
      }
    }
    return nodes
  }

  useImperativeHandle(ref, () => ({
    initDiagramData,
    ifCanvasChanged,
    getModelJson,
    insetPropertyToNode,
    findNodesConnected,
  }))

  /*
    * 获取系统时间
    * */
  useEffect(() => {
    const diagramInstance = diagramRef?.current?.getDiagram()
    setMyDiagramInstance(diagramInstance)
  })

  return (
    <div className={styles.canvasWrapper}>
      <div className={styles.canvas}>
        <Toolbar diagramInstance={myDiagramInstance}/>
        <ReactPalette
          initPalette={initPalette}
          divClassName='palette-gojs'
          style={{width: '100%', height: `${140*scale}px`}}
          nodeDataArray={paletteLinkDataArray}
        />
        <ReactDiagram
          ref={diagramRef}
          divClassName='diagram-gojs'
          initDiagram={initDiagram}
          nodeDataArray={nodeDataArray}
          linkDataArray={linkDataArray}
          onModelChange={handleModelChange}
        />
      </div>
    </div>
  )
}


const mapDispatchToProps = {
  // NOTE: This MUST be aliased or it will not work. Thanks Jack!
  pushArray: arrayPush,
  changeArray: change
}
const connectAndForwardRef = (
  mapStateToProps = null,
  mapDispatchToProps = null,
  mergeProps = null,
  options = {},
) => component => connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
  {
    ...options,
    forwardRef: true,
  },
)(forwardRef(component))


const selector = formValueSelector('tag') // <-- same as form name
const mapStateToProps = (state) => {
  // const tag = selector(state)
  const nodeDataArray = selector(state, 'nodeDataArray')
  const linkDataArray = selector(state, 'linkDataArray')
  return {
    nodeDataArray,
    linkDataArray,
  }
}

const ConnectedFlowDiagram = connectAndForwardRef(mapStateToProps, mapDispatchToProps)(FlowDiagram)

export default ConnectedFlowDiagram

