/*
* 通过Key值，递归遍历树形结构，获取对应的Name
* @curKey: 当前需要查询的key
* @items: 需要遍历的对象
* @key: 需要遍历对象的key
* @name: 遍历对象，需要返回的name值
* */

import moment from 'moment'
import {forOwn, isObject} from 'lodash'
import {isMoment} from 'moment/moment'

export const getNameByKey = (curKey, items, key, name) => {
  for (let i = 0; i < items.length; i++) {
    let item=items[i]
    if (item[key]===curKey){
      return item[name]
    } else {
      if (item.children && item.children.length>0){
        let res=getNameByKey(curKey, item.children, key, name)
        if (res){
          return res
        }
      }
    }
  }
}

export const getNameByKey1 = (curKey, items, key, name, extra) => {
  for (let i = 0; i < items.length; i++) {
    let item=items[i]
    if (item[extra][key]===curKey){
      return item[extra][name]
    } else {
      if (item.children && item.children.length>0){
        let res=getNameByKey1(curKey, item.children, key, name, extra)
        if (res){
          return res
        }
      }
    }
  }
}

/*
* 密码强度
* */
export function scorePassword (pass) {
  let score = 0
  if (!pass) {
    return score
  }
  // award every unique letter until 5 repetitions
  const letters = {}
  for (let i = 0; i < pass.length; i++) {
    letters[pass[i]] = (letters[pass[i]] || 0) + 1
    score += 5.0 / letters[pass[i]]
  }

  // bonus points for mixing it up
  const variations = {
    digits: /\d/.test(pass),
    lower: /[a-z]/.test(pass),
    upper: /[A-Z]/.test(pass),
    nonWords: /\W/.test(pass)
  }

  let variationCount = 0
  for (let check in variations) {
    variationCount += (variations[check] === true) ? 1 : 0
  }
  score += (variationCount - 1) * 10

  return parseInt(score)
}


// 树转数组
export const tree2Array = (tree, childrenName) => {
  let arr = []
  tree.forEach((item) => {
    arr.push(item)
    if (item[childrenName]) {
      arr = arr.concat(tree2Array(item[childrenName], childrenName))
    }
  })
  return arr
}

/* 过滤掉树结构中半选的选项
 * @treeData: 树结构
 * @checkedValues: 选中的值
 * @key: 树结构中value的key
 * */
export const filterHalfChecked = (treeData, checkedValues, key = 'value') => {
  const result = []
  const loop = (node, values) => {
    if (node.children && node.children.length) {
      node.children.forEach((item) => {
        item.checked = loop(item, values)
      })
      node.checked = node.children.every((item) => item.checked)
      if (node.checked) {
        result.push(node[key])
      }
      return node.checked
    }
    node.checked = values.includes(node[key])
    if (node.checked){
      result.push(node[key])
    }
    return node.checked
  }
  treeData.forEach((item) => {
    loop(item, checkedValues)
  })
  return result
}

export const getKeys = (treeData, key = 'key') => {
  const result = []
  const loop = (node) => {
    if (node.children && node.children.length) {
      node.children.forEach((item) => {
        loop(item)
      })
    }
    result.push(node[key])
  }
  treeData.forEach((item) => {
    loop(item)
  })
  return result
}

// 计算文件大小
export const formatFileSize = (size, fix=2) => {
  if (size < 1024) {
    return `${size}B`
  } else if (size < 1024 * 1024) {
    return `${(size / 1024).toFixed(fix)}KB`
  } else if (size < 1024 * 1024 * 1024) {
    return `${(size / 1024 / 1024).toFixed(fix)}MB`
  } else {
    return `${(size / 1024 / 1024 / 1024).toFixed(fix)}GB`
  }
}

// 时间戳转时间
export const formatDateFromTimestamp = (timestamp) => {
  if (!timestamp) return ''
  if (typeof timestamp === 'string') return timestamp
  return moment(timestamp).format('YYYY-MM-DD')
}

// 表格数据空白占位
export const withEmptyRender = (columns) => {
  return columns.map((item) => {
    if (item.render) {
      return item
    } else {
      return {
        ...item,
        render: (text, record) => {
          if (text === 0) {
            return text
          }
          if (text === 'null') {
            return '-'
          }
          return text || '-'
        },
      }
    }
  })
}


export const debounce = (fn, delay) => {
  let timer = null
  return function () {
    let context = this
    let args = arguments
    clearTimeout(timer)
    timer = setTimeout(() => {
      fn.apply(context, args)
    }, delay)
  }
}

// 判断字符串是xml还是html
export const isXML = (str) => {
  const reg1 = /xmlns:[\s\S]{0,10}="/
  const reg2 = /<\?xml[\s\S]{0,10}\?>/
  return reg1.test(str) || reg2.test(str)
}

// xml转html
export const xml2html = (xml) => {
  const excludeTags = ['metadata', 'revisions', 'parsererror']
  const str = replaceXMLTag(xml)
  const parser = new DOMParser()
  const xmlDoc = parser.parseFromString(str, 'text/xml')
  const html = document.createElement('html')
  const body = document.createElement('body')
  html.appendChild(body)

  function traverse(node, parentElement) {
    if (node.nodeType === Node.ELEMENT_NODE && !excludeTags.includes(node.nodeName)) {
      let element = document.createElement(
        ['c', 'd'].includes(node.nodeName) ? 'span' : node.nodeName
      )
      for (let i = 0; i < node.attributes.length; i++) {
        const attribute = node.attributes[i]
        if (attribute.nodeName === 'props') {
          if (node.nodeName === 'cell') {
            element.setAttribute(
              'style',
              attribute.nodeValue + ';display:inline-block;margin:0 5px'
            )
          } else if (node.nodeName === 'image') {
            element.setAttribute('style', 'display:none')
          } else {
            element.setAttribute('style', attribute.nodeValue)
          }
        }
        if (attribute.nodeName === 'base64') {
          element.setAttribute('style', 'display:none')
          break
        }
        element.setAttribute(attribute.nodeName, attribute.nodeValue)
      }
      parentElement.appendChild(element)

      for (let i = 0; i < node.childNodes.length; i++) {
        const childNode = node.childNodes[i]
        traverse(childNode, element)
      }
    } else if (node.nodeType === Node.TEXT_NODE) {
      const text = document.createTextNode(node.nodeValue)
      parentElement.appendChild(text)
    }
  }

  traverse(xmlDoc.firstChild, body)
  return html.outerHTML
}

// xml标签替换
export function replaceXMLTag(str) {
  // str = str.replace(
  //   /<\s*instanceData[^>]*>(.*)<\s*\/\s*instanceData\s*>/g,
  //   '$1'
  // )
  // str = str.replace(/<\s*body[^>]*>(.*)<\s*\/\s*body\s*>/g, '$1')
  // str = str.replace(/<[^>]*>/g, '')
  // str = str.replace(/&nbsp;/g, ' ')
  // str = str.replace(/&quot;/g, '"')
  // str = str.replace(/&rdquo;/g, '”')
  // str = str.replace(/&ldquo;/g, '“')
  // str = str.replace(/&gt;/g, '>')
  // str = str.replace(/&lt;/g, '<')
  // str = str.replace(/&amp;/g, '&')

  // str = str.replace(/<d name="\S+" mime-type="image\/png" base64="yes">[\s\S]*<\/d>/g, '')
  // str = str.replace(/<timeLastModify[\s\S]{0,100}<\/timeLastModify>/g, '')
  // str = str.replace(/<timeCreate[\s\S]{0,100}<\/timeCreate>/g, '')
  // str = str.replace(/<effectiveTime[\s\S]{0,100}<\/effectiveTime>/g, '')
  // str = str.replaceAll('<text>第页</text>', '')

  str = str.replace(/<metadata>[\s\S]*<\/metadata>/g, '')

  return str
}


// eslint-disable-next-line no-unused-vars
export const findVal = (object, key) => {
  let value
  Object.keys(object).some(function(k) {
    if (k === key) {
      value = object[k]
      return true
    }
    if (object[k] && typeof object[k] === 'object') {
      value = findVal(object[k], key)
      return value !== undefined
    }
  })
  return value
}


/*
* moment 时间格式转化
* */
export const traverse = obj => {
  forOwn(obj, (val, key) => {
    if (isMoment(val)){
      obj[key] = moment(val).format('YYYY-MM-DD HH:mm:ss')
    }
    if (isObject(val)) {
      traverse(val)
    } else {
      // do something with leaf node
    }
  })
}
export const traverse1 = obj => {
  forOwn(obj, (val, key) => {
    if (key==='standardOutputs' && !obj[key]){
      obj[key] = [{
        key: 'prime',
        title: 'Prime 标准字典'
      }, {
        key: 'omop',
        title: 'OMOP 标准字典'
      }]
    }
    if (isObject(val)) {
      traverse1(val)
    } else {
      // do something with leaf node
    }
  })
}
