import React, { useState, useEffect, useRef } from 'react'
import { Row, Col, Table, Space, message } from 'antd'
import styles from './index.module.less'
import KeySearch from '@components/form/search'
import MenuTree from '@components/tree/menuTree'
import SourceModal from './sourceModal'
import TypeModal from '@components/typeModal/index'
import fetch from '@utils/request'
import api from '@api'
import {useWindowSize} from '@hooks/useLayout'
import usePagination from '@hooks/usePagination'
import {permissionAuth, permissionStyle} from '@utils/permission'

const SourceCom = () => {
  const [treeId, setTreeId] = useState()
  const [loading, setLoading] = useState(false)
  const [keyWord, setKeyWord] = useState(null)
  const [list, setList] = useState([])
  const [,, scale] = useWindowSize()
  // eslint-disable-next-line no-unused-vars
  const [page, setPage, setTotal, pagination, total] = usePagination()
  const metaRef = useRef(null)
  const typeRef = useRef(null)
  const authItemKeys = [permissionAuth('SOURCE_LIST_DIR_EDIT'), permissionAuth('SOURCE_LIST_DIR_EDIT'), permissionAuth('SOURCE_LIST_DIR_EDIT'), permissionAuth('SOURCE_LIST_DIR_DELETE')]
  const [updateInfo, setUpdateInfo] = useState({})

  const fetUpdateInfo = async() => {
    const result = await fetch({
      method: 'GET',
      url: api.dictionaryApi.getDataSourceMaxVersion,
    })
    setUpdateInfo(result)
  }

  useEffect(() => {
    fetUpdateInfo()
  }, [])

  // 获取字典详情
  const getDictionary = async() => {
    // if (!treeId) return
    setLoading(true)
    const pager = {
      pageNumber: page.pageIndex,
      pageSize: page.pageSize,
    }
    const {content, totalCount} = await fetch({
      method: 'POST',
      url: api.dictionaryApi.listPageDataSource,
      data: {
        contentId: treeId,
        dataType: 'dataSource',
        tableColumnComment: keyWord,
        ...pager,
      },
      headers: {
        'Content-Type': 'application/json; charset=utf-8'
      }
    })
    if (content && content.length>0) {
      setList(content)
      setTotal(totalCount)
    } else {
      setList([])
      setTotal(0)
    }

    setLoading(false)
  }

  // 关键词搜索
  const keySearchClick = (e) => {
    setKeyWord(e)
  }

  const treeSelect = (e) => {
    setTreeId(e.node.code)
  }

  useEffect(() => {
    getDictionary()
  }, [treeId, keyWord, page])

  // // 新增、编辑数据元素
  // const setMeta = (e, record) => {
  //   if (!treeId && e === 1) {
  //     return message.warn('请先选择节点！')
  //   }
  //   metaRef.current.handleOpen(e, treeId)
  //   if (e === 2) {
  //     metaRef.current.handleValue(record)
  //   }
  // }

  // // 删除
  // const onDelete = (record) => {
  //   Modal.confirm({
  //     title: '数据来源删除提醒',
  //     content: '确认删除该数据来源吗？',
  //     onOk: async () => {
  //       await fetch({
  //         method: 'POST',
  //         url: api.dictionaryApi.deleteDataSource,
  //         data: {
  //           tableColumnName: record.tableColumnName,
  //           IsDeleted: 1
  //         },
  //         headers: {
  //           'Content-Type': 'application/json'
  //         }
  //       })
  //       getDictionary()
  //       message.success('删除成功！')
  //     }
  //   })
  // }

  // 分类
  const setType = (e, record) => {
    typeRef.current.handleOpen(e, record)
  }

  const fresh = async(record, code) => {
    await fetch({
      method: 'POST',
      url: api.dictionaryApi.updateDataSource,
      data: {
        dataType: record.dataType,
        columnLength: record.columnLength,
        enumAble: record.enumAble,
        isAllowed: record.isAllowed,
        IsDeleted: 0,
        tableColumnName: record.tableColumnName,
        tableColumnOldName: record.tableColumnName,
        contentId: code
      },
      headers: {
        'Content-Type': 'application/json'
      }
    })
    message.success('操作成功！')
    getDictionary()
  }

  const columns = [
    {
      title: '数据来源',
      dataIndex: 'tableColumnComment',
      key: 'tableColumnComment'
    },
    {
      title: '表名',
      dataIndex: 'tableName',
      key: 'tableName'
    },
    {
      title: '字段名',
      dataIndex: 'columnName',
      key: 'columnName'
    },
    {
      title: '数据格式',
      dataIndex: 'dataType',
      key: 'dataType'
    },
    {
      title: '长度',
      dataIndex: 'columnLength',
      key: 'columnLength'
    },
    {
      title: '允许值',
      dataIndex: 'isAllowed',
      key: 'isAllowed'
    },
    {
      title: '是否可枚举',
      dataIndex: 'enumAble',
      key: 'enumAble',
      render: (text) => {
        return <div>{text === '1' ? '是' : '否'}</div>
      }
    },
    {
      title: '维护时间',
      dataIndex: 'updatedTime',
      key: 'updatedTime',
      render: (text, record) => {
        return <div>{text || record.createdTime}</div>
      }
    },
    {
      title: '操作',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          {/* <a style={permissionStyle('SOURCE_LIST_EDIT')} onClick={() => setMeta(2, record)}>编辑</a>
          <a style={permissionStyle('SOURCE_LIST_DELETE')} onClick={() => onDelete(record)}>删除</a> */}
          <a style={permissionStyle('SOURCE_LIST_MOVE')} onClick={() => setType(1, record)}>分类</a>
          {/* <Tooltip mouseEnterDelay="2" title="编辑">
            <a onClick={() => onEdit(record)} style={permissionStyle('EDIT_USER')}><EditOutlined /></a>
          </Tooltip> */}
        </Space>
      ),
    }
  ]

  return (
    <>
      <Row gutter={16} wrap={false}>
        <Col flex={`${scale*240}px`} className={styles.leftArea}>
          <MenuTree authItemKeys={authItemKeys} title={'元数据'} type="dataSource" search treeSelect={treeSelect} />
        </Col>
        <Col flex={1} style={{flex: 1}}>
          {/* 搜索栏 */}
          <div className={styles.search}>
            <div>
              <KeySearch keySearchClick={keySearchClick} />
              <span style={{marginLeft: 16}}>
                <span>更新时间：{updateInfo.createdTime} </span>
                {
                  updateInfo.version && <span style={{marginLeft: 8}}>V{updateInfo.version}版本</span>
                }
              </span>
            </div>
            {/* <div>共计： {total} 条</div> */}
            {/* <Button type="primary" style={permissionStyle('SOURCE_LIST_ADD')} onClick={() => setMeta(1)}>新增</Button> */}
          </div>
          <Table
            rowKey="id"
            loading={loading}
            columns={columns}
            dataSource={list}
            pagination={pagination}
            scroll={{x: 'max-content'}}
          />
        </Col>
      </Row>
      <SourceModal ref={metaRef} onSuccess={getDictionary} />
      <TypeModal ref={typeRef} type="dataSource" onSuccess={fresh} />
    </>
  )
}

export default SourceCom

