import React, { useImperativeHandle, forwardRef, useState } from 'react'
import { Modal, Form, Input, message } from 'antd'

const Add = ({ item, onSuccess }, ref) => {
  const [visible, setVisible] = useState(false)
  const [form] = Form.useForm()
  const hide = () => {
    form.resetFields()
    setVisible(false)
  }
  const show = () => {
    setVisible(true)
  }
  useImperativeHandle(ref, () => {
    return {
      show,
      hide,
    }
  })

  const handleOk = async (values) => {
    message.success('添加成功')
    onSuccess && onSuccess(values)
    hide()
  }
  return (
    <Modal
      title="添加同义词"
      open={visible}
      onOk={() => form.submit()}
      onCancel={hide}
      centered
      destroyOnClose
    >
      <Form
        form={form}
        labelCol={{
          span: 6,
        }}
        onFinish={handleOk}
      >
        <Form.Item label="同义词来源" name="source">
          <Input placeholder="请输入同义词来源" />
        </Form.Item>
        <Form.Item
          label="同义词名称"
          name="name"
          rules={[
            {
              required: true,
              message: '请输入同义词名称',
            },
          ]}
        >
          <Input placeholder="请输入同义词名称" />
        </Form.Item>
        <Form.Item label="同义词编码" name="code">
          <Input placeholder="请输入同义词编码" />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default forwardRef(Add)
