import React, {useEffect, useState} from 'react'
import { Tree, Input } from 'antd'
const { Search } = Input
import styles from './index.module.less'

const TreeData = (props) => {
  // eslint-disable-next-line no-unused-vars
  const [selectedKeys, setSelectedKeys] = useState([])
  // 搜索
  const onSearch = (e) => {
    props.treeSearch(e)
  }

  // 节点选择
  const onSelect = (key, e) => {
    if (!key.length){
      return
    }
    props.treeSelect(e)
    setSelectedKeys(key)
  }

  useEffect(() => {
    setSelectedKeys(props.defaultSelectedKeys)
  }, [])

  return (
    <div className={styles.main}>
      <div className={styles.mainTitle}>
        <span> {props.title}</span>
      </div>
      {props.search && (
        <div className={styles.mainSearch}>
          <Search
            placeholder="搜索"
            size="middle"
            allowClear
            onSearch={onSearch}
            style={{
              width: '100%',
            }}
          />
        </div>
      )}
      <div className={styles.treeBox}>
        <Tree
          showLine={{ showLeafIcon: false }}
          showIcon={false}
          treeData={props.tree}
          // defaultSelectedKeys={['icd10']}
          fieldNames={{
            key: 'code',
            title: 'name',
          }}
          onSelect={onSelect}
          height={props.height}
          selectedKeys={selectedKeys}
        />
      </div>
    </div>
  )
}

export default TreeData
