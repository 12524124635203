/*
* 检验库首页
* */
import React, {useEffect, useState, useRef} from 'react'
import {Form, Row, Col, Input, Button, Spin, Table, Space, Tooltip, Divider} from 'antd'
import styles from './index.module.less'
import usePagination from '@hooks/usePagination'
import {useNavigate} from 'react-router-dom'
import { FileTextOutlined} from '@ant-design/icons'
import {useWindowSize} from '@hooks/useLayout'
import fetch from '@utils/request'
import api from '@api'
import {permissionStyle} from '@utils/permission'
import ImportProject from './components/importProject'
const Inspect = () => {
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const [,, scale] = useWindowSize()
  const [page, setPage, setTotal, pagination] = usePagination()
  const [loading, setLoading] = useState(false)
  const [filterParams, setFilterParams] = useState(false)
  const [list, setList] = useState([])
  const importProjectRef = useRef(null)

  const handleTableChange = (pagination, filters, sorter, extra) => {
    setPage({
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
    })
    // fetchData()
  }

  const getList = async(param ={}) => {
    const pager = {
      pageNumber: page.pageIndex,
      pageSize: page.pageSize,
    }
    setLoading(true)
    try {
      const res = await fetch({
        method: 'POST',
        url: api.knowledgeApi.examList,
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        data: {
          ...pager,
          ...param
        }
      })
      setLoading(false)
      setList(res.content)
      setTotal(res.totalCount)
    } catch (e) {
      setLoading(false)
    }

  }
  const onFinish = async (item) => {
    setPage({
      pageIndex: 1,
      pageSize: 10
    })
    const filters = form.getFieldsValue()
    console.log('filters:', filters)
    setFilterParams(filters)
    // await getList(filters)
  }
  // 跳转到详情页
  const goDetail = (rowData) => {
    navigate(`/knowledge/inspect/detail?id=${rowData.id}`)
  }

  const columns = [
    {
      title: '项目名称',
      dataIndex: 'projectName',
      key: 'projectName',
      width: 300,
    },
    {
      title: '英文名',
      dataIndex: 'englishName',
      key: 'englishName',
      width: 150,
    },
    {
      title: '别名',
      dataIndex: 'aliasName',
      key: 'aliasName',
      width: 150,
    },
    {
      title: '检验学科分类',
      dataIndex: 'examDepartGroup',
      key: 'examDepartGroup',
      width: 300,
    },
    {
      title: '功能指标分类',
      dataIndex: 'functionGroup',
      key: 'functionGroup',
      width: 300,
    }
  ]

  const actionColumn = [
    {
      title: '操作',
      key: 'action',
      width: 100,
      render: (_, record) => (
        <Space size="middle">
          <Tooltip mouseEnterDelay="2" title="详情" >
            <a onClick={() => goDetail(record)} style={permissionStyle('DRUG_STOCK_ADD')}><FileTextOutlined /></a>
          </Tooltip>
        </Space>
      ),
    },
  ]
  const reload = () => {
    // setData([])
    setPage({
      pageIndex: 1,
      pageSize: 10,
    })
    // setCheckedList([])
    // setSelectedRowKeys([])
  }
  // 导入项目
  const handleImport = () => {
    importProjectRef.current.show()
  }

  useEffect(() => {
    getList(filterParams)
  }, [page, filterParams])
  return (
    <>
      {/* 标题栏 */}
      <div className="head">
        <h3 className="head-title">检验库</h3>
      </div>
      <Form
        className={styles.searchBox}
        form={form}
        labelCol={{ style: { width: 92*scale } }}
        onFinish={onFinish}
      >
        <Row gutter={60}>
          <Col span={18}>
            <Row gutter={24}>
              <Col span={8}>
                <Form.Item
                  name="projectName"
                  label="项目名称"
                  rules={
                    [{
                      max: 30,
                    }]
                  }
                >
                  <Input placeholder="请输入关键词搜索" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="englishName"
                  label="英文名"
                  rules={
                    [{
                      max: 30,
                    }]
                  }
                >
                  <Input placeholder="请输入关键词搜索" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="aliasName"
                  label="别名"
                  rules={
                    [{
                      max: 30,
                    }]
                  }
                >
                  <Input placeholder="请输入关键词搜索" />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={6} className={styles.searchBoxRight}>
            <Row>
              <Button type="primary" htmlType="submit">
                            搜索
              </Button>
              <Button
                style={{
                  margin: '0 8px',
                }}
                onClick={() => {
                  form.resetFields()
                  getList()
                }}
              >
                            清空
              </Button>
              <Button
                type="primary"
                className="custom-btn"
                ghost
                onClick={handleImport}
                style={permissionStyle('EXAM_STOCK_IMPORT')}
              >
                导入
              </Button>
            </Row>
          </Col>
        </Row>
      </Form>
      <div className={styles.content}>
        <Row className={styles.titleWrapper}>
          <div className={styles.tableTitle}>
            <Divider type="vertical" className={styles.divider}/>
            检验库列表
          </div>
        </Row>
        <Spin spinning={loading}>
          <Table
            scroll={{ x: 1000 }}
            rowKey={record => record.id}
            columns={columns.concat(actionColumn)}
            dataSource={list}
            pagination={pagination}
            onChange={handleTableChange}
          />
        </Spin>
      </div>
      <ImportProject ref={importProjectRef} onSuccess={reload} />
    </>
  )
}

export default Inspect
