/*
* 知识图谱控件
* */

import {Form, Select, Button} from 'antd'
import React, {useEffect, useRef, useState} from 'react'
import {cloneDeep, get} from 'lodash'
import KnowledgeModal from '@views/tag/edit/components/label-from-dic/knowledgeModal'
import fetch from '@utils/request'
import api from '@api'
// import PrimeTag from '@components/base/tag'
import {useWindowSize} from '@hooks/useLayout'
import PrimeTag from '@components/base/tag'

const KnowledgeWidget = ({namePath, ...restProps}) => {
  console.count('ConstantWidget')
  const form = Form.useFormInstance()
  // eslint-disable-next-line no-unused-vars
  const [tableData, setTableData] = useState([])
  const [dicList, setDicList] = useState([])
  // eslint-disable-next-line no-unused-vars
  const [,, scale] = useWindowSize()
  const dicModalRef = useRef(null)
  // eslint-disable-next-line no-unused-vars
  const [relationOptions, setRelationOptions] = useState([])
  // eslint-disable-next-line no-unused-vars
  const editSynonym = (data) => {
    form.setFieldValue([...namePath, 'selectedDictList', 0, 'data'], data)
    setTimeout(() => {
      restProps.asyncData()
    }, 100)
  }
  const fetchDicList = async () => {
    const res = await fetch({
      method: 'GET',
      url: api.dictionaryApi.getRelationAllType,
    })
    setDicList(res)
  }
  /*
  * 获取同义词
  * */
  const fetchSynonym = async (params) => {
    const res = await fetch({
      method: 'GET',
      url: api.dictionaryApi.getRelationAll,
      params,
    })
    return res
  }
  const doneCallback = async (data) => {
    const convertData = cloneDeep(data)
    const tempConvertData = convertData.map(({entityConceptCode, entityConceptName}) => ({
      key: entityConceptCode,
      title: entityConceptName
    }))
    // const dicCode = form.getFieldValue([...namePath, 'selectedDictList', 0, 'code'])
    form.setFieldValue([...namePath, 'selectedDictList', 0, 'data'], tempConvertData)
    const conceptCodes = data?.map((item) => item.entityConceptId)
    // eslint-disable-next-line no-unused-vars
    const synonymData = await fetchSynonym({entityConceptIdList: conceptCodes.join(',')})
    setTableData(data)
    setRelationOptions(synonymData.map((item) => ({label: item, value: item})))
    setTimeout(() => {
      restProps.asyncData()
    }, 100)
  }
  useEffect(() => {
    fetchDicList()
  }, [restProps.itemKey])

  return (
    <>
      <Form.Item
        label="选择类型"
        name={[...namePath, 'selectedDictList', 0, 'code']}
      >
        <Select
          showSearch={true}
          optionFilterProp='name'
          placeholder="请选择类型"
          options={dicList}
          fieldNames={{label: 'name', value: 'code'}}
          onChange={() => {
            setTimeout(() => {
              form.setFieldValue([...namePath, 'selectedDictList', 0, 'data'], [])
            }, 200)
          }}
        />
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) => get(prevValues, [...namePath, 'selectedDictList', 0, 'code']) !== get(currentValues, [...namePath, 'selectedDictList', 0, 'code'])}
      >
        {({ getFieldValue }) => {
          const dictionaryCode = getFieldValue([...namePath, 'selectedDictList', 0, 'code'])
          // eslint-disable-next-line no-unused-vars
          const selectedDic = dicList.filter((item) => item.code === dictionaryCode)
          if (!dictionaryCode) return null
          return (
            <>
              <div style={{display: 'flex', justifyContent: 'flex-end', marginBottom: '16px'}}>
                <Button
                  ghost
                  type='primary'
                  size='middle'
                  onClick={() => {
                    dicModalRef.current.show()
                  }}
                >
                    选择实体
                </Button>
              </div>
              <KnowledgeModal ref={dicModalRef} doneCallback={doneCallback} dictionaryCode={dictionaryCode} dictionaryType='flat'/>
            </>
          )
        }}
      </Form.Item>
      <Form.Item
        label="选择实体"
        name={[...namePath, 'selectedDictList', 0, 'data']}
        valuePropName='sourceData'
        rules={[
          {
            required: true,
            message: '选择实体',
          },
        ]}
      >
        <PrimeTag onEdit={editSynonym} itemKey='title'/>
      </Form.Item>
      <Form.Item
        label="选择关系"
        name={[...namePath, 'relations']}
      >
        <Select
          mode="multiple"
          placeholder="请选择关系"
          options={relationOptions}
        />
      </Form.Item>
      <Form.Item
        label="数据类型"
      >
        <Select
          disabled
          defaultValue='text'
          placeholder="请选择数据类型"
          options={[{
            label: '文本',
            value: 'text'
          }]}
        />
      </Form.Item>
    </>
  )
}

export default KnowledgeWidget
