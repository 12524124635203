/*
 * 数据映射
 * */
import React, { useEffect, useState, useRef } from 'react'
import {
  Form,
  Row,
  Col,
  Input,
  Button,
  Table,
  Tabs,
  DatePicker,
  Select,
  Tooltip,
  message,
} from 'antd'
import styles from './index.module.less'
import usePagination from '@hooks/usePagination'
import fetch from '@utils/request'
import api from '@api'
import moment from 'moment'
import EditModal from '../components/editModal'
import Big from 'big.js'
import { useWindowSize } from '@hooks/useLayout'
import { permissionStyle, permissionAuth } from '@utils/permission'
import ImportProject from '../components/importProject'
import ExportModal from '../components/exportModal'
import { MAP_TYPE } from '../constants'

const { RangePicker } = DatePicker

const MappingData = () => {
  const [, , scale] = useWindowSize()
  const editModalRef = useRef(null)
  const [form] = Form.useForm()
  // eslint-disable-next-line no-unused-vars
  const [page, setPage, setTotal, pagination, total] = usePagination()
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [list, setList] = useState([])
  const [vocabularyList, setVocabularyList] = useState([])
  const [projectList, setProjectList] = useState([])
  const [params, setParams] = useState({})
  const [currentTab, setCurrentTab] = useState('1')
  const [modalTitle, setModalTitle] = useState('')
  const importProjectRef = useRef(null)
  const exportRef = useRef(null)
  const [filterInfo, setFilterInfo] = useState({})

  const fetchVocabularyList = async () => {
    const data = await fetch({
      url: api.mappingApi.getParams,
      method: 'POST',
    })
    const result = data.find((item) => item.type === 'vocabulary')
    setVocabularyList(result.valueNameVOS)
  }

  const fetchProjectList = async () => {
    const data = await fetch({
      url: api.mappingApi.getProjectList,
      method: 'GET',
    })
    setProjectList(data)
  }

  useEffect(() => {
    fetchVocabularyList()
    fetchProjectList()
  }, [])

  const handleTableChange = (pagination, filters, sorter, extra) => {
    setPage({
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
    })
    setFilterInfo(filters)
  }

  /*
   * Tabs change
   * */
  const onTabsChange = (value) => {
    setCurrentTab(value)
    setPage({
      pageIndex: 1,
      pageSize: 10,
    })
  }
  const getList = async () => {
    const pager = {
      pageNumber: page.pageIndex,
      pageSize: page.pageSize,
    }
    setLoading(true)
    try {
      const res = await fetch({
        method: 'POST',
        url: api.mappingApi.getList,
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
        data: {
          ...pager,
          ...params,
          valid: currentTab === '1',
          mapType: (filterInfo.mapType || []).join(','),
        },
      })
      setLoading(false)
      setList(res.content)
      setTotal(res.totalCount)
    } catch (e) {
      setLoading(false)
    }
  }
  const onFinish = async (values) => {
    if (values.createdTime && values.createdTime.length > 0) {
      values.createdTimeStart = moment(values.createdTime[0]).format(
        'YYYY-MM-DD'
      )
      values.createdTimeEnd = moment(values.createdTime[1]).format('YYYY-MM-DD')
    }
    delete values.createdTime
    setParams(values)
    console.log('values::', values)
    setPage({
      pageIndex: 1,
      pageSize: 10,
    })
  }

  const showEditModal = (record, title) => {
    if (!permissionAuth('MAPPING_STOCK_UPDATE')) return
    setModalTitle(`编辑${title}`)
    editModalRef.current.show(record)
  }
  // 导入
  const handleImport = () => {
    importProjectRef.current.show()
  }
  const renderColumn = (text, record, title, style) => {
    return (
      <div
        style={{
          minWidth: 200,
          minHeight: 40,
          display: 'flex',
          alignItems: 'center',
          ...style,
        }}
        onClick={() => showEditModal(record, title)}
      >
        {text}
      </div>
    )
  }
  const reload = () => {
    // setData([])
    setPage({
      pageIndex: 1,
      pageSize: 10,
    })
    fetchProjectList()
  }
  /*
   * 成功回調
   * */
  const success = () => {
    // getList()
  }
  const columns = [
    {
      title: '相似度',
      dataIndex: 'similarity',
      key: 'similarity',
      width: 120 * scale,
      render(value, record) {
        const result = new Big(value || 0)
        return (
          <div className={styles.match}>
            {result.times(100).toNumber()}%
            {record.status === 2 && (
              <Tooltip
                title="由未映射列表提交到已映射列表中被标记的数据。"
                placement="bottom"
              >
                <div className={styles.dot}>手动</div>
              </Tooltip>
            )}
          </div>
        )
      },
    },
    {
      title: '映射方式',
      dataIndex: 'mapType',
      key: 'mapType',
      render(value, record) {
        return MAP_TYPE[value] || '-'
      },
      filters: Object.keys(MAP_TYPE).map((key) => ({
        text: MAP_TYPE[key],
        value: key,
      })),
      filteredValue: filterInfo.mapType || null,
    },
    {
      title: '院内字典编码',
      dataIndex: 'code',
      key: 'code',
      width: 120 * scale,
    },
    {
      title: '院内字典名称',
      dataIndex: 'name',
      key: 'name',
      width: 120 * scale,
    },
    {
      title: '医院',
      dataIndex: 'projectName',
      key: 'projectName',
      width: 120 * scale,
    },
    {
      title: '标准字典编码',
      dataIndex: 'conceptId',
      key: 'conceptId',
      width: 150 * scale,
      render: (text, record) => renderColumn(text, record, '标准字典编码'),
    },
    {
      title: '标准字典名称',
      dataIndex: 'conceptName',
      key: 'conceptName',
      width: 220 * scale,
      render: (text, record) =>
        renderColumn(text, record, '标准字典名称', { color: '#00AFEC ' }),
    },
    {
      title: '父级编码',
      dataIndex: 'conceptParentCode',
      key: 'conceptParentCode',
      width: 200 * scale,
    },
    // {
    //   title: 'OMOP字典编码',
    //   dataIndex: 'omopConceptId',
    //   key: 'omopConceptId',
    //   width: 200 * scale,
    //   render: (text, record) => renderColumn(text, record, 'OMOP字典编码'),
    // },
    // {
    //   title: 'OMOP字典名称',
    //   dataIndex: 'omopConceptName',
    //   key: 'omopConceptName',
    //   width: 200 * scale,
    //   render: (text, record) => renderColumn(text, record, 'OMOP字典名称'),
    // },
    {
      title: '知识类型',
      dataIndex: 'vocabulary',
      key: 'vocabulary',
      width: 200 * scale,
      render(value) {
        const item = vocabularyList.find((item) => item.value === value)
        return item ? item.name : ''
      },
    },
    {
      title: '上传人',
      dataIndex: 'createdBy',
      key: 'createdBy',
      width: 120 * scale,
    },
    {
      title: '上传时间',
      dataIndex: 'createdTime',
      key: 'createdTime',
      width: 165 * scale,
    },
  ]

  const audit = async (record) => {
    await fetch({
      method: 'POST',
      url: api.mappingApi.audit,
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      data: {
        id: record.id,
      },
    })
    message.success('提交成功')
    getList()
  }
  // 任务任务

  const openExportModal = () => {
    exportRef.current.handleOpen()
  }

  const actionColumn = [
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      width: 100 * scale,
      fixed: 'right',
      render: (text, record) => {
        return (
          <div>
            <a onClick={() => audit(record)} style={permissionStyle('MAPPING_STOCK_AUDIT')}>提交</a>
          </div>
        )
      },
    },
  ]

  const TableComponent = () => (
    <Table
      scroll={{ x: 'max-content' }}
      rowKey={(record) => record.id}
      columns={columns.concat(currentTab === '1' ? [] : actionColumn)}
      dataSource={list}
      pagination={pagination}
      onChange={handleTableChange}
      loading={loading}
    />
  )

  const tabItems = [
    {
      label: '已映射数据',
      key: '1',
      children: <TableComponent />,
    },
    {
      label: '未映射数据',
      key: '2',
      children: <TableComponent />,
    },
  ]

  useEffect(() => {
    if (vocabularyList.length > 0) {
      getList()
    }
  }, [page, params, currentTab, vocabularyList, filterInfo])

  return (
    <>
      {/* 标题栏 */}
      <div className="head">
        <h3 className="head-title">术语映射</h3>
      </div>
      <Form
        className={styles.searchBox}
        form={form}
        onFinish={onFinish}
        labelCol={{ style: { width: 108 * scale } }}
      >
        <Row gutter={60}>
          <Col span={18}>
            <Row gutter={24}>
              <Col span={8}>
                <Form.Item
                  name="projectId"
                  label="医院"
                  rules={[
                    {
                      max: 30,
                    },
                  ]}
                >
                  {/* <Input placeholder="请输入关键词搜索" /> */}
                  <Select
                    placeholder="请选择"
                    options={projectList}
                    fieldNames={{
                      label: 'name',
                      value: 'code',
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="createdBy"
                  label="上传人"
                  rules={[
                    {
                      max: 30,
                    },
                  ]}
                >
                  <Input placeholder="请输入关键词搜索" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="vocabulary"
                  label="知识类型"
                  rules={[
                    {
                      max: 30,
                    },
                  ]}
                >
                  <Select
                    placeholder="请选择"
                    options={vocabularyList}
                    fieldNames={{
                      label: 'name',
                      value: 'value',
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={8}>
                <Form.Item name="createdTime" label="上传时间">
                  <RangePicker />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="conceptName"
                  label="Prime CDM"
                  rules={[
                    {
                      max: 30,
                    },
                  ]}
                >
                  <Input placeholder="请输入关键词搜索" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="omopConceptName"
                  label="OMOP CDM"
                  rules={[
                    {
                      max: 30,
                    },
                  ]}
                >
                  <Input placeholder="请输入关键词搜索" />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={6} className={styles.searchBoxRight}>
            <Row>
              <Col span={12}>
                <Button type="primary" htmlType="submit">
                  搜索
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  onClick={() => {
                    form.resetFields()
                    setPage({
                      pageIndex: 1,
                      pageSize: 10,
                    })
                    setParams({})
                  }}
                >
                  清空
                </Button>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Button
                  onClick={handleImport}
                  style={permissionStyle('EXAM_STOCK_IMPORT')}
                >
                  导入
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  onClick={() => {
                    openExportModal()
                  }}
                  style={permissionStyle('PROJECT_RULE_EXPORT')}
                >
                  导出
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
      <div className={styles.content}>
        <Tabs onChange={onTabsChange} activeKey={currentTab} items={tabItems} />
      </div>
      <EditModal ref={editModalRef} onSuccess={getList} title={modalTitle} />
      <ImportProject
        ref={importProjectRef}
        onSuccess={reload}
        vocabularyList={vocabularyList}
      />
      <ExportModal
        ref={exportRef}
        projectList={projectList}
        handleOk={() => {
          success()
        }}
      />
    </>
  )
}

export default MappingData
