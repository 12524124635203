import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react'
import { Modal, Form, Input, Select, TreeSelect, message } from 'antd'
const { Option } = Select
import fetch from '@utils/request'
import api from '@api'
import scale from '@utils/auto-rem'

const cTypes = ['布尔', '数值', '文本', '日期时间']

const MetaModal = (props, ref) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [type, setType] = useState('编辑')
  const [treeId, setTreeId] = useState(null)
  const [dwdTree, setDwdTree] = useState([])
  const [dictionary, setDictionary] = useState([])
  const [dataSource, setDataSource] = useState([])
  const [dwdValue, setDwdValue] = useState(null)
  const [recordId, setRecordId] = useState(null)
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()

  const handleOpen = (e, code) => {
    setIsModalVisible(true)
    setType(e)
    setTreeId(code)
  }

  const handleValue = (record) => {
    setRecordId(record.id)
    setTreeId(record.contentId)
    form.setFieldsValue({
      'id': record.id,
      'elementName': record.elementName,
      'vocabularyCode': record.vocabularyCode,
      'dataSourceIds': record.dataSourceIds ? record.dataSourceIds.split(',') : [],
      'dataType': record.dataType,
      'elementLength': record.elementLength || undefined,
      'isAllowed': record.isAllowed || undefined,
      'enumAble': record.enumAble
    })
  }

  const loop = (arr, flag) => {
    arr.forEach((item, i) => {
      item.value = `${item.tableName}${item.name ? `.${item.name}` : ''}`
      item.label = item.name ? item.name : item.tableName
      item.disabled = flag
      item.key = item.dwdCode
      if (item.children.length > 0) {
        item.children = loop(item.children)
      }
    })
    return arr
  }

  const getDwdTree = async() => {
    let res = await fetch({
      method: 'get',
      url: api.dictionaryApi.getDwdTableTree,
      params: {}
    })
    loop(res, true)
    setDwdTree(res)
  }

  const getDictionary = async() => {
    const res = await fetch({
      method: 'POST',
      url: api.dictionaryApi.listDictionary,
      data: {}
    })
    setDictionary(res)
  }

  const getDataSource = async() => {
    const res = await fetch({
      method: 'POST',
      url: api.dictionaryApi.listDataSource,
      headers: {
        'Content-Type': 'application/json'
      },
      data: {
        tableColumnComment: ''
      }
    })
    setDataSource(res)
  }

  useEffect(() => {
    getDwdTree()
    getDictionary()
  }, [])

  useEffect(() => {
    if (!isModalVisible) return
    getDataSource()
  }, [isModalVisible])

  const handleOk = async() => {
    await form.submit()
  }

  const handleCancel = () => {
    form.resetFields()
    setIsModalVisible(false)
  }

  const onFinish = async(values) => {
    try {
      setLoading(true)
      if (type === 1) {
        // 新增
        await fetch({
          method: 'POST',
          url: api.dictionaryApi.addDataElement,
          data: {
            ...values,
            dataSourceNames: values.dataSourceIds,
            contentId: treeId,
          },
          headers: {
            'Content-Type': 'application/json'
          }
        })
        props.onSuccess()
        message.success('保存成功！')
        handleCancel()
      } else {
        // 修改
        await fetch({
          method: 'POST',
          url: api.dictionaryApi.updateDataElement,
          data: {
            ...values,
            id: recordId,
            dataSourceNames: values.dataSourceIds,
            contentId: treeId,
            IsDeleted: 0
          },
          headers: {
            'Content-Type': 'application/json'
          }
        })
        props.onSuccess()
        message.success('修改成功！')
        handleCancel()
      }
    } finally {
      setLoading(false)
    }
  }

  useImperativeHandle(ref, () => ({
    handleOpen,
    handleValue
  }))

  // 支持自定义输入功能，没有选中节点时失去焦点输入框不清空
  const dwdSearch= (e) => {
    setDwdValue(e)
  }

  const dwdChange = (e) => {
    setDwdValue(null)
  }

  const dwdBlur= (e) => {
    form.setFieldsValue({
      'elementName': dwdValue || form.getFieldValue().elementName
    })
  }

  const dwdSelect = (value, node) => {
    form.setFieldsValue({
      'dataType': node.dataType,
      'elementLength': node.commentLength,
      'enumAble': node.enumAble,
      'isAllowed': node.isAllowed
    })
  }

  return (
    <Modal
      title={(type === 1 ? '新增' : '编辑') + '数据元素'}
      open={isModalVisible}
      width={600*scale}
      confirmLoading={loading}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <Form
        form= {form}
        labelCol={{
          span: 6,
        }}
        wrapperCol={{
          span: 16,
        }}
        onFinish={onFinish}
      >
        <Form.Item
          label="数据元素名称"
          name="elementName"
          rules={[
            {
              required: true,
              message: '请选择数据元素名称',
            },
          ]}
        >
          <TreeSelect
            showSearch
            placeholder="请选择"
            treeNodeFilterProp="name"
            onSearch={dwdSearch}
            onBlur={dwdBlur}
            onChange={dwdChange}
            onSelect={dwdSelect}
            treeData={dwdTree}
          />
        </Form.Item>
        <Form.Item
          label="匹配字典"
          allowClear
          name="vocabularyCode"
        >
          <Select
            showSearch
            optionFilterProp="label"
            placeholder="请选择"
          >
            {dictionary.map(item => (
              <Option key={item.code} value={item.code} label={item.name}>{item.name}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="匹配数据来源"
          name="dataSourceIds"
          rules={[
            {
              required: true,
              message: '请选择匹配数据来源',
            },
          ]}
        >
          <Select
            optionFilterProp="children"
            mode="multiple"
            // maxTagCount='responsive'
            placeholder="请选择"
          >
            {dataSource.map(item => (
              <Option key={item.tableColumnName}>{item.tableColumnComment}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="数据格式"
          name="dataType"
          rules={[
            {
              required: true,
              message: '请选择数据格式',
            },
          ]}
        >
          <Select
            placeholder="请选择"
          >
            {cTypes.map((item, i) => (
              <Option key={item}>{item}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="长度"
          name="elementLength"
        >
          <Input placeholder="请输入" />
        </Form.Item>
        <Form.Item
          label="允许值"
          name="isAllowed"
        >
          <Input placeholder="请输入" />
        </Form.Item>
        <Form.Item
          label="是否可枚举"
          name="enumAble"
        >
          <Select
            allowClear
            placeholder="请选择"
          >
            <Option key='1'>是</Option>
            <Option key='0'>否</Option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default forwardRef(MetaModal)

