import React, { useState, useEffect, useRef } from 'react'
import styles from './index.module.less'
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Table,
  Space,
  message,
  Modal,
} from 'antd'
import { useWindowSize } from '@hooks/useLayout'
import EditField from './components/editField'
import usePagination from '@hooks/usePagination'
import fetch from '@utils/request'
import api from '@api'
import { useSearchParams } from 'react-router-dom'
import { permissionStyle } from '@utils/permission'

// 0:文本、1:数值、2:单选、3:多选、4:日期-年、5:日期-日、6:时间
export const controlTypes = [
  {
    label: '文本',
    value: '0',
  },
  {
    label: '数值',
    value: '1',
  },
  {
    label: '单选',
    value: '2',
  },
  {
    label: '多选',
    value: '3',
  },
  {
    label: '日期-年',
    value: '4',
  },
  {
    label: '日期-日',
    value: '5',
  },
  {
    label: '时间',
    value: '6',
  },
]

const FieldList = ({ type = 'cdisc' }) => {
  const [, , scale] = useWindowSize()
  const [searchForm] = Form.useForm()
  const editRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [page, setPage, setTotal, pagination] = usePagination()
  const [sorter, setSorter] = useState({})
  const [searchParams] = useSearchParams()
  const formId = parseInt(searchParams.get('formId'))
  const queueId = searchParams.get('queueId')
  const formSet = queueId || 'default'
  const formRow = localStorage.getItem('formRow')
    ? JSON.parse(localStorage.getItem('formRow'))
    : {}
  const [keyword, setKeyword] = useState('')
  const handleTableChange = (pagination, filters, sorter) => {
    setPage({
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
    })
    setSorter(sorter)
  }
  const fetchData = async () => {
    setLoading(true)
    const pager = {
      pageNumber: page.pageIndex,
      pageSize: page.pageSize,
    }
    const sorterParams = {
      orderBy: sorter.order ? sorter.field : 'createTime',
      orderByWay: sorter.order === 'ascend' ? 0 : 1,
    }
    const res = await fetch({
      url: api[type === 'omop' ? 'omopControlApi' : 'cdiscApi'].listPageField,
      method: 'POST',
      data: {
        ...pager,
        ...sorterParams,
        formId,
        fieldKeyword: keyword,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    })
    setData(res.content)
    setTotal(res.totalCount)
    setLoading(false)
  }
  useEffect(() => {
    fetchData()
  }, [page, keyword])

  const handleStatus = async (record) => {
    const { optionList, ...rest } = record
    const options = JSON.parse(optionList)
    if (options && options.length && Array.isArray(options)) {
      rest.optionList = options
    }
    Modal.confirm({
      title: '提示',
      content: `确定${record.isEnable === 1 ? '禁用' : '启用'}该字段吗？`,
      onOk: async () => {
        await fetch({
          url: api.cdiscApi.saveField,
          method: 'POST',
          data: {
            ...rest,
            isEnable: record.isEnable === 1 ? 0 : 1,
          },
          headers: {
            'Content-Type': 'application/json',
          },
        })
        message.success(`${record.isEnable === 1 ? '禁用' : '启用'}成功`)
        fetchData()
      },
    })
  }

  const handleEdit = (record) => {
    if (type === 'omop') {
      editRef.current.show(record)
      return
    }
    const { optionList, ...rest } = record
    const options = JSON.parse(optionList)
    if (options && options.length && Array.isArray(options)) {
      rest.optionList = options
    }
    editRef.current.show(rest)
  }

  const handleCopy = async (record) => {
    const { id, ...rest } = record
    handleEdit({
      ...rest,
      fieldCode: `${record.fieldCode}_copy`,
    })
  }

  const handleDelete = async (record) => {
    Modal.confirm({
      title: '提示',
      content: '确定删除该字段吗？',
      onOk: async () => {
        await fetch({
          url:
            type === 'omop'
              ? api.omopControlApi.saveField
              : api.cdiscApi.deleteField,
          method: 'POST',
          data:
            type === 'omop'
              ? {
                ...record,
                isDeleted: 1,
              }
              : {
                id: record.id,
              },
          headers: {
            'Content-Type': 'application/json',
          },
        })
        message.success('删除成功')
        if (data.length === 1 && page.pageIndex > 1) {
          setPage({
            pageIndex: page.pageIndex - 1,
            pageSize: page.pageSize,
          })
        } else {
          fetchData()
        }
      },
    })
  }

  const cdiscColumns = [
    {
      title: '序号',
      dataIndex: 'index',
      key: 'index',
      width: 100 * scale,
      render: (text, record, index) =>
        `${index + 1 + (page.pageIndex - 1) * page.pageSize}`,
    },
    {
      title: '字段名称（中文）',
      dataIndex: 'fieldCnName',
      key: 'fieldCnName',
      width: 150 * scale,
      ellipsis: true,
      sorter: true,
      sortOrder: sorter.columnKey === 'fieldCnName' && sorter.order,
    },
    {
      title: '字段名称（英文）',
      dataIndex: 'fieldEnName',
      key: 'fieldEnName',
      width: 150 * scale,
      ellipsis: true,
    },
    {
      title: '字段编码',
      dataIndex: 'fieldCode',
      key: 'fieldCode',
      width: 150 * scale,
    },
    {
      title: '控件类型',
      dataIndex: 'controlType',
      key: 'controlType',
      render(val) {
        return controlTypes.find((item) => item.value === val)?.label
      },
    },
    {
      title: '选择值',
      dataIndex: 'optionList',
      key: 'optionList',
      render(val) {
        const options = val ? JSON.parse(val) : []
        if (options && options.length && Array.isArray(options)) {
          return options.map((item) => item.optionValue).join('，')
        }
      },
    },
    {
      title: '单位',
      dataIndex: 'unit',
      key: 'unit',
    },
    // {
    //   title: '关联标签',
    //   dataIndex: 'ruleName',
    //   key: 'ruleName',
    // },
    {
      title: '状态',
      dataIndex: 'isEnable',
      key: 'isEnable',
      render(val) {
        if (val === 1) {
          return <span className={styles.enable}>启用</span>
        }
        if (val === 0) {
          return <span className={styles.disable}>禁用</span>
        }
      },
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      width: 200 * scale,
      render: (val, record) => {
        return (
          <Space>
            <a
              onClick={(e) => {
                e.stopPropagation()
                handleEdit({
                  ...record,
                  queueId,
                })
              }}
              style={permissionStyle('CDISC_CONTROL_FIELD_UPDATE')}
            >
              编辑
            </a>
            <a
              onClick={(e) => {
                e.stopPropagation()
                handleCopy(record)
              }}
              style={permissionStyle('CDISC_CONTROL_FIELD_COPY')}
            >
              复制
            </a>
            <a
              onClick={(e) => {
                e.stopPropagation()
                handleStatus(record)
              }}
              style={permissionStyle('CDISC_CONTROL_FIELD_ONOROFF')}
            >
              {record.isEnable === 1 ? '禁用' : '启用'}
            </a>
            <a
              onClick={(e) => {
                e.stopPropagation()
                handleDelete(record)
              }}
              style={permissionStyle('CDISC_CONTROL_FIELD_DELETE')}
            >
              删除
            </a>
          </Space>
        )
      },
    },
  ]

  const omopColumns = [
    {
      title: '序号',
      dataIndex: 'index',
      key: 'index',
      width: 100 * scale,
      render: (text, record, index) =>
        `${index + 1 + (page.pageIndex - 1) * page.pageSize}`,
    },
    {
      title: '字段名称（中文）',
      dataIndex: 'fieldCnName',
      key: 'fieldCnName',
      width: 150 * scale,
      ellipsis: true,
      sorter: true,
      sortOrder: sorter.columnKey === 'fieldCnName' && sorter.order,
    },
    {
      title: '字段名称（英文）',
      dataIndex: 'fieldEnName',
      key: 'fieldEnName',
      width: 150 * scale,
      ellipsis: true,
    },
    {
      title: '变量类型',
      dataIndex: 'controlType',
      key: 'controlType',
      render(val) {
        return controlTypes.find((item) => item.value === val)?.label
      },
    },
    {
      title: '长度',
      dataIndex: 'fieldLength',
      key: 'fieldLength',
      width: 150 * scale,
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      width: 200 * scale,
      render: (val, record) => {
        return (
          <Space>
            <a
              onClick={(e) => {
                e.stopPropagation()
                handleEdit({
                  ...record,
                  queueId,
                })
              }}
              style={permissionStyle('OMOP_CONTROL_FIELD_UPDATE')}
            >
              编辑
            </a>
            <a
              onClick={(e) => {
                e.stopPropagation()
                handleDelete(record)
              }}
              style={permissionStyle('OMOP_CONTROL_FIELD_DELETE')}
            >
              删除
            </a>
          </Space>
        )
      },
    },
  ]

  const columns = type === 'omop' ? omopColumns : cdiscColumns

  return (
    <div>
      {/* 标题栏 */}
      <div className="head">
        <h3 className="head-title">变量字段列表</h3>
      </div>

      <div className={styles.fieldList}>
        <div className={styles.formDetail}>
          <span>表单名称（中文）：{formRow.formCnName}</span>
          <span>表单名称（英文）：{formRow.formEnName}</span>
          {type === 'cdisc' && <span>编码：{formRow.formCode}</span>}
        </div>
        <div className={styles.search}>
          <Form form={searchForm}>
            <Row gutter={[16]}>
              <Col>
                <Form.Item label="关键词" name="keyword">
                  <Input
                    placeholder="请输入关键词搜索"
                    style={{ width: 220 }}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item>
                  <Button
                    type="primary"
                    onClick={() => {
                      const keyword = searchForm.getFieldValue('keyword')
                      setKeyword(keyword)
                    }}
                  >
                    搜索
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Button
            type="primary"
            onClick={() =>
              editRef.current.show({
                formId,
                queueId,
              })
            }
            style={
              type === 'omop'
                ? permissionStyle('OMOP_CONTROL_FIELD_ADD')
                : permissionStyle('CDISC_CONTROL_FIELD_ADD')
            }
          >
            新增
          </Button>
        </div>

        <Table
          columns={columns}
          dataSource={data}
          onChange={handleTableChange}
          loading={loading}
          pagination={pagination}
          scroll={{ x: 'max-content' }}
        />
      </div>
      <EditField
        ref={editRef}
        onSuccess={fetchData}
        type={type}
        formSet={formSet}
      />
    </div>
  )
}

export default FieldList
