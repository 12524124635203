import {createSlice } from '@reduxjs/toolkit'

const initialState = {
  count: 0,
}

export const feedbackSlice = createSlice({
  name: 'feedback',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setCount: (state, action) => {
      state.count = action.payload
    },
  },
})

export const { setCount } = feedbackSlice.actions

export default feedbackSlice.reducer
