/* eslint-disable indent */
import React, { useState, useMemo, useEffect } from 'react'
import { Tabs, Table, Modal } from 'antd'
import styles from './index.module.less'
import usePagination from '@hooks/usePagination'
import fetch from '@utils/request'
import api from '@api'

export const FeedbackLabel = () => {
  const [exist, setExist] = useState(false)
  const queryWaitExists = async () => {
    const res = await fetch({
      url: api.feedbackApi.queryWaitExists,
      method: 'POST',
    })
    setExist(res)
  }
  useEffect(() => {
    queryWaitExists()
    const timer = setInterval(() => {
      queryWaitExists()
    }, 1000 * 60 * 5)
    return () => {
      clearInterval(timer)
    }
  }, [])
  return (
    <span className={styles.label}>
      用户反馈
      {exist && <span className={styles.exist}></span>}
    </span>
  )
}

const Feedback = () => {
  const [activeKey, setActiveKey] = useState('WAIT')
  const [page, setPage, setTotal, pagination] = usePagination()
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)

  const handleTableChange = (pagination, filters, sorter) => {
    setPage({
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
    })
  }

  const fetchData = async () => {
    setLoading(true)
    const { content, totalCount } = await fetch({
      url: api.feedbackApi.pageQuery,
      method: 'POST',
      data: {
        pageNumber: page.pageIndex,
        pageSize: page.pageSize,
        status: activeKey,
      },
    })
    setData(content)
    setTotal(totalCount)
    setLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [page])

  const handle = (record) => {
    Modal.confirm({
      title: '提示',
      content: '确认处理该反馈？',
      onOk: async () => {
        await fetch({
          url: api.feedbackApi.process,
          method: 'POST',
          data: {
            ids: record.id,
          },
        })
        if (data.length === 1 && page.pageIndex > 1) {
          setPage({
            pageIndex: page.pageIndex - 1,
            pageSize: page.pageSize,
          })
        } else {
          fetchData()
        }
      },
    })
  }

  const columns = useMemo(() => {
    const common = [
      {
        title: '反馈时间',
        dataIndex: 'feedbackTime',
        key: 'feedbackTime',
      },
      {
        title: '所属应用',
        dataIndex: 'appTypeName',
        key: 'appTypeName',
      },
      {
        title: '项目名称',
        dataIndex: 'projectName',
        key: 'projectName',
      },
      {
        title: '用户名称',
        dataIndex: 'createdBy',
        key: 'createdBy',
      },
      {
        title: '规则用途/场景描述',
        dataIndex: 'sceneDesc',
        key: 'sceneDesc',
        width: 500,
      },
      {
        title: '规则描述',
        dataIndex: 'ruleDesc',
        key: 'ruleDesc',
        width: 500,
      },
    ]
    const extra =
      activeKey === 'WAIT'
        ? [
            {
              title: '操作',
              dataIndex: 'action',
              key: 'action',
              render: (text, record) => (
                <a onClick={() => handle(record)}>处理</a>
              ),
              fixed: 'right',
            },
          ]
        : [
            {
              title: '处理时间',
              dataIndex: 'updatedTime',
              key: 'updatedTime',
            },
            {
              title: '处理人',
              dataIndex: 'updatedBy',
              key: 'updatedBy',
            },
          ]
    return [...common, ...extra]
  }, [activeKey])

  return (
    <div className={styles.wrapper}>
      <Tabs
        items={[
          {
            label: '待处理',
            children: null,
            key: 'WAIT',
          },
          {
            label: '已处理',
            children: null,
            key: 'PROCESSED',
          },
        ]}
        activeKey={activeKey}
        onChange={(key) => {
          setActiveKey(key)
          setPage({
            pageIndex: 1,
            pageSize: 10,
          })
        }}
      />
      <Table
        columns={columns}
        dataSource={data}
        onChange={handleTableChange}
        pagination={pagination}
        loading={loading}
        scroll={{ x: 'max-content' }}
        rowKey={(record) => record.id}
      ></Table>
    </div>
  )
}
export default Feedback
