import React, { useEffect, useRef, useState } from 'react'
import { Row, Col, Space, Modal, Table, Spin, message, Button } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import KeySearch from '@components/form/search'
import MenuTree from '@components/tree/menuTree'
import TypeModal from '@components/typeModal/index'
import styles from './index.module.less'
import classNames from 'classnames'
import usePagination from '@hooks/usePagination'
import fetch from '@utils/request'
import api from '@api'
import { useWindowSize } from '@hooks/useLayout'
import { useSearchParams, useNavigate } from 'react-router-dom'
import Edit from './components/edit'
import { permissionAuth, permissionStyle } from '@utils/permission'

const Control = () => {
  const navigate = useNavigate()
  const searchRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([])
  const [select, setSelect] = useState(null) // 选择的树节点
  const [page, setPage, setTotal, pagination] = usePagination()
  const [sorter, setSorter] = useState({})
  const [, , scale] = useWindowSize()
  const [searchParams] = useSearchParams()
  const queueId = searchParams.get('queueId')
  const formSet = queueId || 'default'
  const typeRef = useRef(null)
  const editRef = useRef(null)
  const authItemKeys =
    formSet === 'default'
      ? [
        permissionAuth('CDISC_CONTROL_DIR_ADD'),
        permissionAuth('CDISC_CONTROL_DIR_ADD'),
        permissionAuth('CDISC_CONTROL_DIR_UPDATE'),
        permissionAuth('CDISC_CONTROL_DIR_DELETE'),
      ]
      : [false, false, false, false]
  const menuTreeRef = useRef(null)

  const getList = async (node) => {
    setLoading(true)
    const pager = {
      pageNumber: page.pageIndex,
      pageSize: page.pageSize,
    }
    const sorterParams = {
      orderBy: sorter.order ? sorter.field : 'updateTime',
      orderByWay: sorter.order === 'ascend' ? 0 : 1,
    }
    const res = await fetch({
      method: 'POST',
      url: api.cdiscApi.listPageForm,
      data: {
        contentId: [select],
        formKeyWord: searchRef.current.getValue(),
        formSet,
        ...pager,
        ...sorterParams,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    })
    setList(res.content)
    setTotal(res.totalCount)
    setLoading(false)
  }

  // 树节点选择
  const treeSelect = async (e) => {
    setPage({
      pageIndex: 1,
      pageSize: 10,
    })
    if (!e.node.code) {
      setSelect(null)
      setList([])
      return
    }
    setSelect(e.node.code)
  }

  // 关键词搜索
  const keySearchClick = (e) => {
    if (!select) {
      return message.warn('请先选择节点！')
    }
    setPage({
      pageIndex: 1,
      pageSize: 10,
    })
  }

  // table删除
  const onDel = (record) => {
    Modal.confirm({
      title: record.contentNames
        ? `当前表单已被分类到${record.contentNames}，确定从这些分类中全部删除吗？`
        : '确认删除吗？',
      icon: <ExclamationCircleOutlined />,
      okText: '确认',
      cancelText: '取消',
      onOk: async () => {
        await fetch({
          method: 'POST',
          url: api.cdiscApi.saveForm,
          data: {
            ...record,
            isDeleted: 1,
          },
          headers: {
            'Content-Type': 'application/json',
          },
        })
        message.success('删除成功')
        getList()
      },
    })
  }

  // 分类
  const setType = (e, record) => {
    typeRef.current.handleOpen(e, {
      ...record,
      code: record.contentId,
    })
  }

  const fresh = async (record, code) => {
    const { code: newCode, ...rest } = record
    await fetch({
      method: 'POST',
      url: api.cdiscApi.saveForm,
      data: {
        ...rest,
        contentId: code,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    })
    message.success('操作成功！')
    getList()
    menuTreeRef.current.refreshTree()
  }

  const handleTableChange = (pagination, filters, sorter, extra) => {
    setPage({
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
    })
    setSorter(sorter)
    // fetchData()
  }

  useEffect(() => {
    if (select) {
      getList()
    }
  }, [page, select, sorter])

  let columns = [
    {
      title: '序号',
      dataIndex: 'index',
      key: 'index',
      width: 100 * scale,
      render: (text, record, index) =>
        `${index + 1 + (page.pageIndex - 1) * page.pageSize}`,
    },
    {
      title: '表单名称（中文）',
      dataIndex: 'formCnName',
      key: 'formCnName',
      width: 150 * scale,
      ellipsis: true,
      sorter: true,
      sortOrder: sorter.columnKey === 'formCnName' && sorter.order,
    },
    {
      title: '表单名称（英文）',
      dataIndex: 'formEnName',
      key: 'formEnName',
      width: 150 * scale,
      ellipsis: true,
    },
    {
      title: '表单编码',
      dataIndex: 'formCode',
      key: 'formCode',
      width: 150 * scale,
    },
    {
      title: '变量个数',
      dataIndex: 'fieldNum',
      key: 'fieldNum',
      width: 150 * scale,
    },
    {
      title: '更新人',
      dataIndex: 'updateBy',
      key: 'updateBy',
      width: 100 * scale,
      sorter: true,
      sortOrder: sorter.columnKey === 'updateBy' && sorter.order,
    },
    {
      title: '更新时间',
      dataIndex: 'updateTime',
      key: 'updateTime',
      width: 150 * scale,
      sorter: true,
      sortOrder: sorter.columnKey === 'updateTime' && sorter.order,
    },
    {
      title: '操作',
      key: 'action',
      fixed: 'right',
      width: 150 * scale,
      render: (_, record) => {
        return (
          <Space size="middle">
            <a
              onClick={(e) => {
                e.stopPropagation()
                editRef.current.show({
                  ...record,
                  queueId,
                })
              }}
              style={permissionStyle('CDISC_CONTROL_FORM_UPDATE')}
            >
              编辑
            </a>
            <a
              onClick={(e) => {
                e.stopPropagation()
                onDel(record)
              }}
              style={permissionStyle('CDISC_CONTROL_FORM_DELETE')}
            >
              删除
            </a>
            {formSet === 'default' && (
              <a
                onClick={(e) => {
                  e.stopPropagation()
                  setType(e, record)
                }}
                style={permissionStyle('CDISC_CONTROL_FORM_MOVE')}
              >
                分类
              </a>
            )}
          </Space>
        )
      },
    },
  ]

  return (
    <>
      {/* 标题栏 */}
      {!queueId && (
        <div className="head">
          <h3 className="head-title">表单列表</h3>
        </div>
      )}

      {/* 标签库 */}
      <Row className={styles.rowSpace} gutter={16}>
        <Col xxl={{ span: 4 }} lg={{ span: 6 }}>
          <MenuTree
            title={'控件库'}
            type="cdisc"
            search
            treeSelect={treeSelect}
            authItemKeys={authItemKeys}
            draggable={formSet === 'default' ? true : false}
            ref={menuTreeRef}
          />
        </Col>
        <Col xxl={{ span: 20 }} lg={{ span: 18 }}>
          <div className={classNames([styles.search])}>
            <div className={styles.spaceRight}>
              <KeySearch ref={searchRef} keySearchClick={keySearchClick} />
            </div>
            <Space>
              <Button
                type="primary"
                onClick={() => {
                  if (!select) {
                    return message.warn('请先选择节点！')
                  }
                  editRef.current.show({
                    contentId: [select],
                    formSet,
                    queueId,
                  })
                }}
                style={permissionStyle('CDISC_CONTROL_FORM_ADD')}
              >
                新增
              </Button>
            </Space>
          </div>
          <Spin spinning={loading}>
            <Table
              rowKey="id"
              columns={columns}
              dataSource={list}
              pagination={pagination}
              scroll={{ x: scale * 1000 }}
              onChange={handleTableChange}
              onRow={(record) => {
                return {
                  onClick: () => {
                    localStorage.setItem('formRow', JSON.stringify(record))
                    const path = queueId
                      ? '/specialtyControl/field'
                      : '/control/cdisc/field'
                    navigate(
                      `${path}?formId=${record.id}${
                        queueId ? `&queueId=${queueId}` : ''
                      }`
                    )
                  },
                }
              }}
            />
          </Spin>
        </Col>
      </Row>
      <TypeModal ref={typeRef} type="cdisc" onSuccess={fresh} multiple={true} />
      <Edit ref={editRef} onSuccess={getList} />
    </>
  )
}

export default Control
