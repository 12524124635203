import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react'
import { Modal, Form, Input, message } from 'antd'
import fetch from '@utils/request'
import api from '@api'
import { wordRegex} from '@utils/regex'
const { TextArea } = Input

const EditModal = (props, ref) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [add, setAdd] = useState(true)
  const [rowId, setRowId] = useState(null)
  const [form] = Form.useForm()

  const handleOpen = (e) => {
    setIsModalVisible(true)
    if (e) {
      setAdd(false)
      console.log('e:', e)
      form.setFieldsValue({
        'name': e.name
      })
      setRowId(e.id)
    } else {
      setAdd(true)
    }
  }

  const handleValue = (e) => {
    form.setFieldsValue(e)
  }

  const handleOk = async() => {
    await form.submit()
  }

  const handleCancel = () => {
    setIsModalVisible(false)
    form.resetFields()
  }

  const onFinish = async(values) => {
    if (add) {
      await fetch({
        method: 'POST',
        url: api.desensitizationApi.add,
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        data: {
          ...values,
        }
      })
      message.success('保存成功!')
    } else {
      await fetch({
        method: 'POST',
        url: api.desensitizationApi.update,
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        data: {
          id: rowId,
          ...values,
        }
      })
      message.success('编辑成功!')
    }
    props.handleOk()
    handleCancel()
  }

  useImperativeHandle(ref, () => ({
    handleOpen,
    handleValue
  }))

  useEffect(() => {
    //
  }, [])

  return (
    <Modal
      title={(add ? '新增脱敏字段' : '编辑脱敏字段')}
      visible={isModalVisible}
      width="720px"
      okText="确定"
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <Form
        form= {form}
        labelCol={{
          span: 6,
        }}
        wrapperCol={{
          span: 16,
        }}
        onFinish={onFinish}
      >
        <Form.Item
          label="脱敏字段名"
          name="name"
          rules={[
            {
              required: true,
              message: '请输入脱敏字段名',
            },
            {
              type: 'string',
              max: 50,
            },
            {
              pattern: wordRegex,
              message: '脱敏字段名只支持中文、英文',
            },
          ]}
        >
          {/* <Input placeholder="请输入脱敏字段名" /> */}
          <TextArea showCount maxLength={50} />
        </Form.Item>
        
      </Form>
    </Modal>
  )
}

export default forwardRef(EditModal)
