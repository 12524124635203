import React, { useState, useEffect, useRef } from 'react'
import { Row, Col, Table, Space, Button, Modal, message } from 'antd'
import styles from './index.module.less'
import KeySearch from '@components/form/search'
import MenuTree from '@components/tree/menuTree'
import MetaModal from './metaModal'
import TypeModal from '@components/typeModal/index'
import fetch from '@utils/request'
import api from '@api'
import {useWindowSize} from '@hooks/useLayout'
import usePagination from '@hooks/usePagination'
import {permissionAuth, permissionStyle} from '@utils/permission'
const ElementCom = () => {
  const [treeId, setTreeId] = useState()
  const [loading, setLoading] = useState(false)
  const [keyWord, setKeyWord] = useState(null)
  const [list, setList] = useState([])
  const [,, scale] = useWindowSize()
  // eslint-disable-next-line no-unused-vars
  const [page, setPage, setTotal, pagination, total] = usePagination()
  const metaRef = useRef(null)
  const typeRef = useRef(null)
  const authItemKeys = [permissionAuth('META_LIST_DIR_ADD'), permissionAuth('META_LIST_DIR_ADD'), permissionAuth('META_LIST_DIR_EDIT'), permissionAuth('META_LIST_DIR_DELETE')]

  // 获取字典详情
  const getDictionary = async() => {
    // if (!treeId) return
    setLoading(true)
    const pager = {
      pageNumber: page.pageIndex,
      pageSize: page.pageSize,
    }
    const {content, totalCount} = await fetch({
      method: 'POST',
      url: api.dictionaryApi.listPageDataElement,
      data: {
        contentId: treeId,
        elementName: keyWord,
        type: 'dataElement',
        ...pager,
      },
      headers: {
        'Content-Type': 'application/json; charset=utf-8'
      }
    })
    if (content && content.length>0) {
      setList(content)
      setTotal(totalCount)
    } else {
      setList([])
      setTotal(0)
    }

    setLoading(false)
  }

  // 关键词搜索
  const keySearchClick = (e) => {
    setKeyWord(e)
  }

  const treeSelect = (e) => {
    setTreeId(e.node.code)
  }

  useEffect(() => {
    getDictionary()
  }, [treeId, keyWord, page])

  // 新增、编辑数据元素
  const setMeta = (e, record) => {
    if (!treeId && e === 1) {
      return message.warn('请先选择节点！')
    }
    metaRef.current.handleOpen(e, treeId)
    if (e === 2) {
      metaRef.current.handleValue(record)
    }
  }

  // 删除
  const onDelete = (record) => {
    Modal.confirm({
      title: '数据元素删除提醒',
      content: '确认删除该数据元素吗？',
      onOk: async () => {
        await fetch({
          method: 'POST',
          url: api.dictionaryApi.deleteDataElement,
          data: {
            id: record.id,
            IsDeleted: 1
          },
          headers: {
            'Content-Type': 'application/json'
          }
        })
        getDictionary()
        message.success('删除成功！')
      }
    })
  }

  // 分类
  const setType = (e, record) => {
    typeRef.current.handleOpen(e, record)
  }

  const fresh = async(record, code) => {
    await fetch({
      method: 'POST',
      url: api.dictionaryApi.updateDataElement,
      data: {
        id: record.id,
        contentId: code
      },
      headers: {
        'Content-Type': 'application/json'
      }
    })
    message.success('操作成功！')
    getDictionary()
  }

  const columns = [
    {
      title: '数据元素',
      dataIndex: 'elementName',
      key: 'elementName'
    },
    {
      title: '匹配字典',
      dataIndex: 'vocabularyName',
      key: 'vocabularyName'
    },
    {
      title: '匹配数据来源',
      dataIndex: 'dataSourceNames',
      key: 'dataSourceNames',
      ellipsis: true,
      width: '160px'
    },
    {
      title: '格式',
      dataIndex: 'dataType',
      key: 'dataType'
    },
    {
      title: '长度',
      dataIndex: 'elementLength',
      key: 'elementLength'
    },
    {
      title: '允许值',
      dataIndex: 'isAllowed',
      key: 'isAllowed'
    },
    {
      title: '是否可枚举',
      dataIndex: 'enumAble',
      key: 'enumAble',
      render: (text) => {
        return <div>{text === '1' ? '是' : '否'}</div>
      }
    },
    {
      title: '维护时间',
      dataIndex: 'updatedTime',
      key: 'updatedTime',
      render: (text, record) => {
        return <div>{text || record.createdTime}</div>
      }
    },
    {
      title: '操作',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <a style={permissionStyle('META_LIST_EDIT')} onClick={() => setMeta(2, record)}>编辑</a>
          <a style={permissionStyle('META_LIST_DELETE')} onClick={() => onDelete(record)}>删除</a>
          <a style={permissionStyle('META_LIST_MOVE')} onClick={() => setType(1, record)}>分类</a>
          {/* <Tooltip mouseEnterDelay="2" title="编辑">
            <a onClick={() => onEdit(record)} style={permissionStyle('EDIT_USER')}><EditOutlined /></a>
          </Tooltip> */}
        </Space>
      ),
    }
  ]

  return (
    <>
      <Row gutter={16}>
        <Col flex={`${scale*240}px`} className={styles.leftArea}>
          <MenuTree authItemKeys={authItemKeys} title={'元数据'} type="dataElement" search treeSelect={treeSelect} />
        </Col>
        <Col flex={1} style={{flex: 1}}>
          {/* 搜索栏 */}
          <div className={styles.search}>
            <KeySearch keySearchClick={keySearchClick} />
            {/* <div>共计： {total} 条</div> */}
            <Button type="primary" style={permissionStyle('META_LIST_ADD')} onClick={() => setMeta(1)}>新增</Button>
          </div>
          <Table
            rowKey="id"
            loading={loading}
            columns={columns}
            dataSource={list}
            pagination={pagination}
          />
        </Col>
      </Row>
      <MetaModal ref={metaRef} onSuccess={getDictionary} />
      <TypeModal ref={typeRef} type="dataElement" onSuccess={fresh} />
    </>
  )
}

export default ElementCom

