/*
* 药品说明书，PDF格式
* */

import React, { useState } from 'react'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/TextLayer.css'
import { pdfjs } from 'react-pdf'
pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.js'
import {Spin} from 'antd'

import styles from '../index.module.less'
import {useWindowSize} from '@hooks/useLayout'

// const options = {
//   cMapUrl: 'cmaps/',
//   cMapPacked: true,
//   standardFontDataUrl: 'standard_fonts/',
// }

const PDF = (props) => {
  const [,, scale] = useWindowSize()
  const [numPages, setNumPages] = useState(null)

  const onDocumentLoadSuccess = ({ numPages: nextNumPages }) => {
    setNumPages(nextNumPages)
  }

  // eslint-disable-next-line no-unused-vars
  const renderLoading = () => {
    return (
      <div className={styles.loading}>
        <Spin tip="文档加载中..."/>
      </div>
    )
  }

  const renderError = () => {
    return (
      <div className={styles.loading}>
        <span>PDF资源不存在</span>
      </div>
    )
  }

  return (
    <div className="Example">
      <div className="Example__container">
        <div className="Example__container__document">
          <Document file='http://minio-kbms.primelifescience.com.cn/kbms/drug/stock/download/201703281__1.pdf' onLoadSuccess={onDocumentLoadSuccess} loading={renderLoading} error={renderError}>
            {Array.from(new Array(numPages), (el, index) => (
              <Page
                loading={''}
                width={1192*scale} key={`page_${index + 1}`} pageNumber={index + 1} />
            ))}
          </Document>
        </div>
      </div>
    </div>
  )
}

export default PDF
