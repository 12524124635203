// 患者管理

const patientApi = {
  patientList: '/kbms/patient/list', // 患者列表
  patientDetail: '/kbms/patient/details', // 患者详情
  pushPatient: '/kbms/patient/push', // 推送患者
  patientStatistics: '/kbms/patient/statistics', // 患者统计
  patientVenn: '/kbms/patient/contrast', // 患者标签韦恩图
  patientLabels: '/kbms/patient/label', // 患者标签
  patientLabelStatistics: '/kbms/patient/labelstatistics', // 患者标签数据统计
  patientSearch: '/kbms/patient/search', // 标签搜索患者
  searchCount: '/kbms/patient/searchCount', // 标签搜索患者
  exportPatient: '/kbms/patient/export', // 导出患者
  patientVisits: '/kbms/patient/visits', // 患者就诊记录
  patientRecords: '/kbms/patient/records', // 患者档案
  getHitValuePageNumber: '/kbms/patient/getHitValuePageNumber', // 获取命中值分页
  exportSearchedPatient: '/kbms/patient/searchExport', // 导出搜索患者
  customExport: '/kbms/patient/customExport', // 自定义导出
  customDeptList: '/kbms/patient/customDeptList', // 自定义筛选科室下拉列表
  customStatistics: '/kbms/patient/customStatistics', // 自定义报表
  targetPatient: '/kbms/patient/target', // 指定参数搜索患者
  funnelPlot: '/kbms/patient/funnelPlot', // 漏斗图
  // 智能推荐
  recommendSearch: '/kbms/recommend/search', // 智能推荐列表
  recommendExport: '/kbms/recommend/searchExport', // 智能推荐导出
  setMatchScore: '/kbms/config/setMatchScore', // 设置匹配度
  recommendTime: '/kbms/recommend/lastTime', // 智能推荐最后一次推荐时间
  originSearch: '/kbms/patient/originSearch', // 患者搜索
}

export default patientApi
