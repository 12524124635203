import React, { useState, useEffect, useRef } from 'react'
import {
  Button,
  Form,
  Input,
  Row,
  Col,
  Table,
  message,
  Space,
  Modal,
} from 'antd'
import { useWindowSize } from '@hooks/useLayout'
import styles from './index.module.less'
import KeySelect from '../components/keySelect'
import EditSynonym from '../components/editSynonym'
import api from '@api'
import fetch from '@utils/request'
import usePagination from '@hooks/usePagination'
import debounce from 'lodash/debounce'
import { exportExcel } from '@utils/export'
import Highlighter from 'react-highlight-words'
import moment from 'moment'
import { permissionStyle } from '@utils/permission'
import classNames from 'classnames'

const Synonym = () => {
  const editRef = useRef(null)
  const [, , scale] = useWindowSize()
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [page, setPage, setTotal, pagination] = usePagination()
  const [filterInfo, setFilterInfo] = useState({})
  const [keyword, setKeyword] = useState('')

  const [vocabularyList, setVocabularyList] = useState([])
  const [vocabulary, setVocabulary] = useState(['all'])

  const renderHighLight = (text, record, searchOutText) => {
    if (searchOutText) {
      return (
        <Highlighter
          highlightClassName="YourHighlightClass"
          searchWords={Array.from(new String(searchOutText))}
          autoEscape={true}
          textToHighlight={text}
        />
      )
    } else {
      return <div>{text}</div>
    }
  }

  useEffect(() => {
    const fetchOptions = async () => {
      const data = await fetch({
        url: api.mappingApi.getParams,
        method: 'POST',
      })
      const result = data.find((item) => item.type === 'vocabulary')
      setVocabularyList([
        { label: '全部', value: 'all' },
        ...result.valueNameVOS.map((item) => {
          return {
            label: item.name,
            value: item.value,
          }
        }),
      ])
    }
    fetchOptions()
  }, [])

  const handleChange = (value) => {
    setVocabulary(value)
    setPage({
      pageIndex: 1,
      pageSize: 10,
    })
  }

  const handleTableChange = (pagination, filters) => {
    setPage({
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
    })
    setFilterInfo(filters)
  }

  const fetchData = async () => {
    setLoading(true)
    let valid = null
    if (filterInfo.valid) {
      valid = filterInfo.valid.length > 1 ? null : filterInfo.valid[0] ? 1 : 2
    }
    const { content, totalCount } = await fetch({
      url: api.mappingApi.getThesaurusList,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        pageNumber: page.pageIndex,
        pageSize: page.pageSize,
        keyword,
        vocabularyList: vocabulary.filter((n) => n !== 'all'),
        valid,
        verifyStatusList: filterInfo.verifyStatus,
      },
    })
    setLoading(false)
    setTotal(totalCount)
    setData(content)
  }

  useEffect(() => {
    if (!vocabularyList.length) return
    fetchData()
  }, [page, keyword, vocabulary, vocabularyList, filterInfo])

  const edit = (record) => {
    editRef.current.show(record)
  }

  const columns = [
    {
      title: '序号',
      dataIndex: 'index',
      key: 'index',
      render(text, record, index) {
        return page.pageSize * (page.pageIndex - 1) + index + 1
      },
    },
    {
      title: '标准名',
      dataIndex: 'conceptName',
      key: 'conceptName',
      render: (text, record) => renderHighLight(text, record, keyword),
    },
    {
      title: '同义词',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => renderHighLight(text, record, keyword),
    },
    {
      title: '类型',
      dataIndex: 'vocabulary',
      key: 'vocabulary',
      render(text, record) {
        return vocabularyList.find((n) => n.value === record.vocabulary)?.label
      },
    },
    {
      title: '生效状态',
      dataIndex: 'valid',
      key: 'valid',
      filters: [
        {
          text: '生效',
          value: true,
        },
        {
          text: '失效',
          value: false,
        },
      ],
      filteredValue: filterInfo.valid || null,
      render(value) {
        return (
          <div className={styles.status}>
            <div
              className={classNames(
                styles.dot,
                value === 1 ? styles.success : styles.fail
              )}
            ></div>
            <span>{value === 1 ? '生效' : '失效'}</span>
          </div>
        )
      },
    },
    {
      title: '状态',
      dataIndex: 'verifyStatus',
      key: 'verifyStatus',
      render(value, record) {
        const status = {
          0: { text: '待审核', class: 'wait' },
          1: { text: '审核通过', class: 'success' },
          2: { text: '审核拒绝', class: 'fail' },
        }
        return (
          <span
            className={classNames(
              styles.audit,
              styles[status[value].class],
              !record.valid && styles.invalid
            )}
          >
            {status[value].text}
          </span>
        )
      },
      filters: [
        {
          text: '待审核',
          value: 0,
        },
        {
          text: '审核通过',
          value: 1,
        },
        {
          text: '审核拒绝',
          value: 2,
        },
      ],
      filteredValue: filterInfo.verifyStatus || null,
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      render(text, record) {
        return <a onClick={() => edit(record)}>编辑</a>
      },
      fixed: 'right',
    },
  ]

  const handleExport = async () => {
    try {
      const res = await fetch({
        url: api.mappingApi.exportThesaurus,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        responseType: 'blob',
        data: {
          vocabularyList: vocabulary.filter((n) => n !== 'all'),
        },
      })
      if (res.code === 0) {
        message.error(res.message)
      } else {
        const name =
          vocabulary.filter((n) => n !== 'all').length > 1 ||
          vocabulary[0] === 'all'
            ? '同义词'
            : vocabularyList.find((n) => n.value === vocabulary[0])?.label
        exportExcel(`${name}${moment(new Date()).format('YYYY-MM-DD')}`, res)
        message.success('导出成功!')
      }
    } catch (error) {}
  }

  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [selectedRows, setSelectedRows] = useState([])

  const verify = async (params, callback) => {
    await fetch({
      url: api.mappingApi.verifyThesaurus,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: params,
    })
    message.success('审核成功')
    typeof callback === 'function' && callback()
  }

  const batchAudit = async () => {
    if (!selectedRows.length) {
      message.warning('请先选择数据')
      return
    }
    // if (selectedRows.some((n) => n.verifyStatus !== 0)) {
    //   message.warning('请先选择待审核数据')
    //   return
    // }
    Modal.confirm({
      title: '提示',
      content: `是否对${selectedRows.length}条同义词进行批量审核通过？`,
      onOk: () => {
        verify(
          {
            idClassList: selectedRows.map((n) => {
              return {
                conceptId: n.conceptId,
                vocabulary: n.vocabulary,
              }
            }),
            verifyStatus: 1,
          },
          () => {
            fetchData()
            setSelectedRowKeys([])
            setSelectedRows([])
          }
        )
      },
    })
  }
  return (
    <>
      <div className="head">
        <h3 className="head-title">同义词库</h3>
      </div>
      <main className={styles.main}>
        <Form
          className={styles.searchBox}
          form={form}
          labelCol={{ style: { width: 108 * scale } }}
          labelAlign="left"
        >
          <Row gutter={60}>
            <Col span={18}>
              <Row>
                <Col span={12}>
                  <Form.Item
                    name="keyword"
                    label="搜索条件"
                    rules={[
                      {
                        max: 30,
                      },
                    ]}
                  >
                    <Input
                      placeholder="请输入..."
                      onChange={debounce((e) => {
                        setKeyword(e.target.value)
                        setPage({
                          pageIndex: 1,
                          pageSize: 10,
                        })
                      }, 500)}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={6} className={styles.searchBoxRight}>
              <Space>
                <Button
                  type="primary"
                  onClick={batchAudit}
                  disabled={
                    !selectedRows.length ||
                    selectedRows.some((item) => item.verifyStatus !== 0)
                  }
                  style={permissionStyle('THESAURUS_AUDIT')}
                >
                  批量审核通过
                </Button>
                <Button
                  onClick={handleExport}
                  style={permissionStyle('THESAURUS_EXPORT')}
                >
                  导出
                </Button>
              </Space>
            </Col>
          </Row>
          <Row>
            <KeySelect
              options={vocabularyList}
              onChange={handleChange}
              value={vocabulary}
            />
          </Row>
        </Form>
        <Table
          columns={columns}
          dataSource={data}
          pagination={pagination}
          onChange={handleTableChange}
          loading={loading}
          scroll={{ x: 'max-content' }}
          rowSelection={{
            onChange: (selectedRowKeys, selectedRows) => {
              setSelectedRowKeys(selectedRowKeys)
              setSelectedRows(selectedRows)
            },
            selectedRowKeys,
          }}
          rowKey={(record) => `${record.conceptId}-${record.vocabulary}`}
        />
      </main>
      <EditSynonym ref={editRef} onHide={fetchData} onVerify={verify} />
    </>
  )
}

export default Synonym
