import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react'
import {Space, Form, Drawer} from 'antd'
import {CloseOutlined} from '@ant-design/icons'
import {useWindowSize} from '@hooks/useLayout'
import PatientRecord from '@views/project/crf-enter/patientRecord'

const PatientRecordModal = (props, ref) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [ruleIdAndVersionList, setRuleIdAndVersionList] = useState([])
  const [form] = Form.useForm()
  const [,, scale] = useWindowSize()

  const handleOpen = (params) => {
    setRuleIdAndVersionList(params)
    setIsModalVisible(true)
  }

  const handleValue = (e) => {
    form.setFieldsValue(e)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  useEffect(() => {
    //
  }, [])

  useImperativeHandle(ref, () => ({
    handleOpen,
    handleCancel,
    handleValue
  }))
  if (!isModalVisible) return null
  return (
    <Drawer
      title="患者档案"
      placement='right'
      width={1152*scale}
      onClose={handleCancel}
      open={isModalVisible}
      closable={false}
      extra={
        <Space>
          <CloseOutlined onClick={handleCancel}/>
        </Space>
      }
    >
      <PatientRecord {...props} ruleIdAndVersionList={ruleIdAndVersionList}>
      </PatientRecord>
    </Drawer>
  )
}

export default forwardRef(PatientRecordModal)
