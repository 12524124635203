import React, { useEffect } from 'react'
import { Outlet, Navigate, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
// 图标
import { ReactComponent as TuominIcon } from '@assets/images/menu/icon_tuomin_svg.svg'
import { ReactComponent as ZhishiIcon } from '@assets/images/menu/icon_zhishi_svg.svg'
import { ReactComponent as YingsheIcon } from '@assets/images/menu/icon_yingshe_svg.svg'
import { ReactComponent as MarkIcon } from '@assets/images/menu/icon_mark.svg'
import { ReactComponent as ConfigIcon } from '@assets/images/menu/icon_config.svg'
import { ReactComponent as ControlIcon } from '@assets/images/menu/icon_control.svg'
import Icon, {
  AppstoreOutlined,
  TagOutlined,
  FileSearchOutlined,
  PartitionOutlined,
  TeamOutlined,
} from '@ant-design/icons'
// 布局
import MainLayout from '@layout/main'
// 登录
import Login from '@views/login'
// 项目管理
import Project from '@views/project'
import ProjectDetail from '@views/project/detail'
import ProjectExport from '@views/project/export'
// 标签管理
import Tag from '@views/tag'
import ProjectTag from '@views/project-tag'
// 字典管理
import Dictionary from '@views/dictionary'
import Metadata from '@views/dictionary/metadata'
// import Shine from '@views/dictionary/shine'
// import ShineDetail from '@views/dictionary/shineDetail'
// 权限管理
import Role from '@views/permission'
// 账号管理
import Account from '@views/account'
// 脱敏库
import Lib from '@views/desensitization/lib'
// 药品库
import Drug from '@views/knowledge/drug'
//  检验库
import Inspect from '@views/knowledge/inspect'
// 检查库
import Check from '@views/knowledge/check'
// 数据映射
import MappingData from '@views/mapping/data'
// OMOP映射
import MappingOmop from '@views/mapping/data/omop'
// 同义词库
import Synonym from '@views/mapping/synonym'
// 404
import Exception404 from '@views/built-in/exception/404'
// 药品说明书
import DrugDetail from '@views/knowledge/drug/detail'
// 检验库详情
import InspectDetail from '@views/knowledge/inspect/detail'
// 检查库详情
import CheckDetail from '@views/knowledge/check/detail'

// 标注管理
import MarkDataView from '@views/mark-mgt/dataview'
import CrfEnterIndex from '@views/project/crf-enter'
import LinkTagIndex from '@views/project/crf-enter/link-tag'
import CrfDataIndex from '@views/project/crf-enter/crf-data'

// 应用标签配置
// AE
import AE from '@views/tagConfig/AE'
// CM
import CM from '@views/tagConfig/CM'
// 关联标签
import RuleLinkTag from '@views/tagConfig/link-tag'
// 用户反馈
import Feedback, { FeedbackLabel } from '@views/feedback'

// 标准控件库
import Control from '@views/control'
import FieldList from '@views/control/fieldList'
import OmopControl from '@views/control/omop'

const RequireAuth = ({ children }) => {
  const navigate = useNavigate()
  const token = useSelector((state) => state.app.token)

  useEffect(() => {
    if (!token) {
      navigate('/login')
    }
  }, [token, navigate])

  return children
}

// eslint-disable-next-line no-unused-vars
const IndexRedirect = () => {
  const token = useSelector((state) => state.app.token)
  const privateHomeRoute = useSelector((state) => state.app.privateHomeRoute)

  /* eslint-disable */
  return (
    <>
      {token ? (
        <Navigate to={privateHomeRoute} />
      ) : (
        <Navigate to="/login" replace />
      )}
    </>
  )
  /* eslint-disable */
}

const privateRoutes = () => [
  {
    path: '/',
    label: '主页',
    element: <MainLayout />,
    children: [
      {
        path: '/',
        element: <IndexRedirect />,
        auth: false,
        hidden: true,
      },
      {
        path: '/project',
        element: <Outlet />,
        label: '项目管理',
        icon: <AppstoreOutlined />,
        key: 'PROJECT_MANAGEMENT',
        auth: true,
        meta: {
          title: '项目管理',
          code: 'PROJECT_MANAGEMENT',
        },
        hidden: false,
        children: [
          {
            path: '/project/index',
            element: (
              <RequireAuth>
                <Project />
              </RequireAuth>
            ),
            label: '项目列表',
            key: 'PROJECT_LIST',
            auth: true,
            meta: {
              title: '项目列表',
              code: 'PROJECT_LIST',
            },
            hidden: false,
          },
          {
            path: '/project/index/detail',
            element: (
              <RequireAuth>
                <ProjectDetail />
              </RequireAuth>
            ),
            label: '项目详情',
            key: 'PROJECT_DETAIL',
            auth: false,
            meta: {
              title: '项目详情',
              code: 'PROJECT_DETAIL',
            },
            hidden: true,
          },
          {
            path: '/project/index/crf',
            element: <Outlet />,
            auth: false,
            hidden: true,
            key: 'PROJECT_CRF',
            label: '项目详情',
            children: [
              {
                path: '/project/index/crf/',
                element: <Outlet />,
                auth: false,
                hidden: true,
                key: 'PROJECT_CRF2',
                label: '项目详情',
                meta: {
                  title: '项目详情',
                  code: 'PROJECT_CRF2',
                },
                children: [
                  {
                    path: '/project/index/crf/',
                    element: <CrfEnterIndex />,
                    auth: false,
                    hidden: true,
                    key: 'PROJECT_CRF3',
                    label: '项目详情',
                    meta: {
                      title: '项目详情',
                      code: 'PROJECT_CRF3',
                    },
                  },
                  {
                    path: '/project/index/crf/link',
                    element: <LinkTagIndex />,
                    auth: false,
                    hidden: true,
                    key: 'PROJECT_CRF_LINK',
                    label: '关联标签',
                    meta: {
                      title: '关联标签',
                      code: 'PROJECT_CRF_LINK',
                    },
                  },
                  {
                    path: '/project/index/crf/data',
                    element: <CrfDataIndex />,
                    auth: false,
                    hidden: true,
                    key: 'PROJECT_CRF_DATA',
                    label: '数据验证',
                    meta: {
                      title: '数据验证',
                      code: 'PROJECT_CRF_DATA',
                    },
                  },
                ],
              },
            ],
          },

          {
            path: '/project/export',
            element: (
              <RequireAuth>
                <ProjectExport />
              </RequireAuth>
            ),
            label: '导出任务',
            key: 'PROJECT_TASK_LIST',
            auth: true,
            meta: {
              title: '导出任务',
              code: 'PROJECT_TASK_LIST',
            },
            hidden: true,
          },
        ],
      },

      {
        path: '/knowledge',
        element: <Outlet />,
        label: '知识库',
        icon: <Icon component={ZhishiIcon} />,
        key: 'DRUG_MANAGE',
        auth: true,
        meta: {
          title: '知识库',
          code: 'DRUG_MANAGE',
        },
        hidden: false,
        children: [
          {
            index: true,
            path: '/knowledge/drug',
            element: (
              <RequireAuth>
                <Drug />
              </RequireAuth>
            ),
            label: '药品库',
            key: 'DRUG_STOCK',
            auth: true,
            meta: {
              title: '药品库',
              code: 'DRUG_STOCK',
            },
            hidden: false,
          },
          {
            path: '/knowledge/drug/detail',
            element: (
              <RequireAuth>
                <DrugDetail />
              </RequireAuth>
            ),
            label: '药品说明书',
            key: 'detail',
            auth: false,
            meta: {
              title: '药品说明书',
              code: 'detail',
            },
            hidden: true,
          },
          {
            index: true,
            path: '/knowledge/inspect',
            element: (
              <RequireAuth>
                <Inspect />
              </RequireAuth>
            ),
            label: '检验库',
            key: 'EXAM_STOCK',
            auth: true,
            meta: {
              title: '检验库',
              code: 'EXAM_STOCK',
            },
            hidden: false,
          },
          {
            path: '/knowledge/inspect/detail',
            element: (
              <RequireAuth>
                <InspectDetail />
              </RequireAuth>
            ),
            label: '检验库详情',
            key: 'detail2',
            auth: false,
            meta: {
              title: '检验库详情',
              code: 'detail2',
            },
            hidden: true,
          },

          {
            index: true,
            path: '/knowledge/check',
            element: (
              <RequireAuth>
                <Check />
              </RequireAuth>
            ),
            label: '检查库',
            key: 'CHECKOUT_STOCK',
            auth: true,
            meta: {
              title: '检查库',
              code: 'CHECKOUT_STOCK',
            },
            hidden: false,
          },
          {
            path: '/knowledge/check/detail',
            element: (
              <RequireAuth>
                <CheckDetail />
              </RequireAuth>
            ),
            label: '检查库详情',
            key: 'detail3',
            auth: false,
            meta: {
              title: '检查库详情',
              code: 'detail3',
            },
            hidden: true,
          },
        ],
      },
      {
        path: '/mapping',
        element: <Outlet />,
        label: '术语映射管理',
        icon: <Icon component={YingsheIcon} />,
        key: 'MAPPING_MANAGE',
        auth: true,
        meta: {
          title: '术语映射管理',
          code: 'MAPPING_MANAGE',
        },
        children: [
          {
            index: true,
            path: '/mapping/data',
            element: (
              <RequireAuth>
                <MappingData />
              </RequireAuth>
            ),
            label: '术语映射',
            key: 'MAPPING_LIST',
            auth: true,
            meta: {
              title: '术语映射',
              code: 'MAPPING_LIST',
            },
            hidden: false,
          },
          {
            index: true,
            path: '/mapping/omop',
            element: (
              <RequireAuth>
                <MappingOmop />
              </RequireAuth>
            ),
            label: 'OMOP字典映射',
            key: 'OMOP_LIST',
            auth: true,
            meta: {
              title: 'OMOP字典映射',
              code: 'OMOP_LIST',
            },
            hidden: false,
          },
          {
            index: true,
            path: '/mapping/synonym',
            element: (
              <RequireAuth>
                <Synonym />
              </RequireAuth>
            ),
            label: '同义词库',
            key: 'THESAURUS_LIST',
            auth: true,
            meta: {
              title: '同义词库',
              code: 'THESAURUS_LIST',
            },
            hidden: false,
          },
        ],
      },
      {
        path: '/tag',
        element: <Outlet />,
        label: '标签管理',
        icon: <TagOutlined />,
        key: 'LABEL_MANAGER',
        auth: true,
        meta: {
          title: '标签管理',
          code: 'LABEL_MANAGER',
        },
        hidden: false,
        children: [
          {
            path: '/tag/index',
            element: (
              <RequireAuth>
                <Tag />
              </RequireAuth>
            ),
            label: '标签库',
            key: 'LABEL_STORE',
            auth: true,
            meta: {
              title: '标签库',
              code: 'LABEL_STORE',
            },
            hidden: false,
          },
          {
            path: '/tag/projectTag',
            element: (
              <RequireAuth>
                <ProjectTag />
              </RequireAuth>
            ),
            label: '项目库',
            key: 'CRF_PROJECT_LABEL_STORE',
            auth: true,
            meta: {
              title: '项目库',
              code: 'CRF_PROJECT_LABEL_STORE',
            },
            hidden: false,
          },
        ],
      },

      {
        path: '/desensitizationLib',
        element: <Outlet />,
        label: '脱敏库管理',
        icon: <Icon component={TuominIcon} />,
        key: 'DESENSITIZATION_MANAGE',
        auth: true,
        meta: {
          title: '脱敏库管理',
          code: 'DESENSITIZATION_MANAGE',
        },
        hidden: false,
        children: [
          {
            index: true,
            path: '/desensitizationLib/index',
            element: (
              <RequireAuth>
                <Lib />
              </RequireAuth>
            ),
            label: '脱敏库',
            key: 'DESENSITIZATION_STOCK',
            auth: true,
            meta: {
              title: '脱敏库',
              code: 'DESENSITIZATION_STOCK',
            },
            hidden: false,
          },
        ],
      },

      {
        path: '/dictionary',
        element: <Outlet />,
        label: '字典管理',
        icon: <FileSearchOutlined />,
        key: 'DICTIONARY_MANAGER',
        auth: true,
        meta: {
          title: '字典管理',
          code: 'DICTIONARY_MANAGER',
        },
        hidden: false,
        children: [
          {
            path: '/dictionary/index',
            element: (
              <RequireAuth>
                <Dictionary />
              </RequireAuth>
            ),
            label: '数据字典',
            key: 'DICTIONARY_LIST',
            auth: true,
            meta: {
              title: '数据字典',
              code: 'DICTIONARY_LIST',
            },
            hidden: false,
          },
          {
            path: '/dictionary/metadata',
            element: (
              <RequireAuth>
                <Metadata />
              </RequireAuth>
            ),
            label: '元数据管理',
            key: 'META_MANAGEMENT',
            auth: true,
            meta: {
              title: '元数据管理',
              code: 'META_MANAGEMENT',
            },
            hidden: false,
          },
          // {
          //   path: '/dictionary/shine',
          //   element: <RequireAuth><Shine /></RequireAuth>,
          //   label: '字典映射',
          //   key: 'shine',
          //   meta: {
          //     title: '字典映射',
          //     code: 'shine'
          //   },
          //   hidden: false
          // },
          // {
          //   path: '/dictionary/shineDetail',
          //   element: <RequireAuth><ShineDetail /></RequireAuth>,
          //   label: '字典详情',
          //   key: 'shineDetail',
          //   meta: {
          //     title: '字典详情',
          //     code: 'shineDetail'
          //   },
          //   hidden: true
          // }
        ],
      },

      {
        path: '/mark',
        element: <Outlet />,
        label: '标注管理',
        icon: <Icon component={MarkIcon} style={{ color: 'red' }} />,
        key: 'RULE_STATISTICS_MANAGER',
        auth: true,
        meta: {
          title: '标注管理',
          code: 'RULE_STATISTICS_MANAGER',
        },
        hidden: false,
        children: [
          {
            path: '/mark/dataview',
            element: (
              <RequireAuth>
                <MarkDataView />
              </RequireAuth>
            ),
            label: '标注管理看板',
            key: 'RULE_STATISTICS_LIST',
            auth: true,
            meta: {
              title: '管理看板',
              code: 'RULE_STATISTICS_LIST',
            },
            hidden: false,
          },
        ],
      },

      {
        path: '/account',
        element: <Outlet />,
        label: '账号管理',
        icon: <TeamOutlined />,
        key: 'USER_MANAGE',
        auth: true,
        meta: {
          title: '账号管理',
          code: 'USER_MANAGE',
        },
        hidden: false,
        children: [
          {
            path: '/account/list',
            element: (
              <RequireAuth>
                <Account />
              </RequireAuth>
            ),
            label: '账号设置',
            key: 'USER_SETTING',
            auth: true,
            meta: {
              title: '账号设置',
              code: 'USER_SET',
            },
            hidden: false,
          },
        ],
      },

      {
        path: '/permission',
        element: <Outlet />,
        label: '权限管理',
        icon: <PartitionOutlined />,
        key: 'AUTH_MANAGER',
        auth: true,
        meta: {
          title: '权限管理',
          code: 'AUTH_MANAGER',
        },
        hidden: false,
        children: [
          {
            path: '/permission/role',
            element: (
              <RequireAuth>
                <Role />
              </RequireAuth>
            ),
            label: '角色权限',
            key: 'ROLE_MANAGER',
            auth: true,
            meta: {
              title: '角色权限',
              code: 'ROLE_MANAGER',
            },
            hidden: false,
          },
        ],
      },
      {
        path: '/tagConfig',
        element: <Outlet />,
        icon: <Icon component={ConfigIcon} />,
        label: '应用标签配置',
        key: 'TAG_CONFIG',
        auth: true,
        meta: {
          title: '应用标签配置',
          code: 'TAG_CONFIG',
        },
        hidden: false,
        children: [
          {
            path: '/tagConfig/ae',
            element: (
              <RequireAuth>
                <AE />
              </RequireAuth>
            ),
            label: 'AE规则配置',
            key: 'AE_CONFIG',
            auth: true,
            meta: {
              title: 'AE规则配置',
              code: 'AE_CONFIG',
            },
            hidden: false,
          },
          {
            path: '/tagConfig/cm',
            element: (
              <RequireAuth>
                <CM />
              </RequireAuth>
            ),
            label: 'CM规则配置',
            key: 'CM_CONFIG',
            auth: true,
            meta: {
              title: 'CM规则配置',
              code: 'CM_CONFIG',
            },
            hidden: false,
          },
          {
            path: '/tagConfig/linkTag',
            element: (
              <RequireAuth>
                <RuleLinkTag />
              </RequireAuth>
            ),
            label: '关联标签',
            key: 'LABEL_CONFIG',
            auth: false,
            meta: {
              title: '关联标签',
              code: 'LABEL_CONFIG',
            },
            hidden: true,
          },
          {
            path: '/tagConfig/feedback',
            element: (
              <RequireAuth>
                <Feedback />
              </RequireAuth>
            ),
            label: <FeedbackLabel />,
            key: 'FEEDBACK',
            auth: true,
            meta: {
              title: '用户反馈',
              code: 'FEEDBACK',
            },
          },
        ],
      },
      {
        path: '/control',
        label: '标准控件库',
        auth: true,
        key: 'CONTROL_MANAGER',
        icon: <Icon component={ControlIcon} />,
        meta: {
          title: '标准控件库',
          code: 'CONTROL_MANAGER',
        },
        element: <Outlet />,
        children: [
          {
            path: '/control/cdisc',
            hidden: false,
            auth: true,
            key: 'CDISC_CONTROL_MANAGER',
            label: 'CDISC标准',
            meta: {
              title: '表单列表',
            },
            element: <Outlet />,
            children: [
              {
                path: '/control/cdisc',
                hidden: true,
                auth: true,
                key: 'CDISC_CONTROL_MANAGER',
                label: 'CDISC标准',
                meta: {
                  title: '表单列表',
                },
                element: (
                  <RequireAuth>
                    <Control />
                  </RequireAuth>
                ),
              },
              {
                path: '/control/cdisc/field',
                hidden: true,
                label: '变量字段列表',
                meta: {
                  title: '变量字段列表',
                },
                element: (
                  <RequireAuth>
                    <FieldList />
                  </RequireAuth>
                ),
              },
            ],
          },
          {
            path: '/control/omop',
            hidden: false,
            auth: true,
            key: 'OMOP_CONTROL_MANAGER',
            label: 'OMOP标准',
            meta: {
              title: '表单列表',
            },
            element: <Outlet />,
            children: [
              {
                path: '/control/omop',
                hidden: true,
                auth: true,
                key: 'OMOP_CONTROL_MANAGER',
                label: 'OMOP标准',
                meta: {
                  title: '表单列表',
                },
                element: (
                  <RequireAuth>
                    <OmopControl />
                  </RequireAuth>
                ),
              },
              {
                path: '/control/omop/field',
                hidden: true,
                label: '变量字段列表',
                meta: {
                  title: '变量字段列表',
                },
                element: (
                  <RequireAuth>
                    <FieldList type="omop" />
                  </RequireAuth>
                ),
              },
            ],
          },
        ],
      },
    ],
  },
]

const publicRoutes = [{ path: '/login', element: <Login /> }]
const commonRoutes = [{ path: '*', element: <Exception404 /> }]

const microRoutes = [
  {
    path: '/istag',
    element: (
      <RequireAuth>
        <Tag />
      </RequireAuth>
    ),
  },
  {
    path: '/specialtyControl',
    element: <Outlet />,
    children: [
      {
        path: '/specialtyControl',
        element: <Control />,
      },
      {
        path: '/specialtyControl/field',
        element: <FieldList />,
      },
    ],
  },
]

const routesConfig = {
  publicRedirectRoute: '/profile', // redirect to `/profile` when authorized is trying to access public routes
  privateRedirectRoute: '/login', // redirect to `/login` when unauthorized user access a private route
  defaultFallback: '',
  privateRoutes,
  publicRoutes,
  commonRoutes,
  microRoutes,
  // appRoutes: [...privateRoutes, ...publicRoutes, ...commonRoutes]
}

export default routesConfig
