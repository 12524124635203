import React, { useMemo, useState, useContext, useEffect } from 'react'
import { Table, DatePicker, Space, Pagination, Spin, Empty } from 'antd'
import { ReactComponent as ExpandDown } from '@assets/images/icons/expand_down.svg'
import { ReactComponent as ExpandRight } from '@assets/images/icons/expand_right.svg'
import { ReactComponent as TipIcon } from '@assets/images/icons/tip.svg'
import DebounceSearch from '@components/debounceSearch'
import TagSelect from '@components/tagSelect'
import { formatDateFromTimestamp, withEmptyRender } from '@utils/common'
import fetch from '@utils/request'
import api from '@api'
import PatientContext from '../context'
import styles from './index.module.less'

const { RangePicker } = DatePicker

const options = [
  {
    label: '非培养类',
    value: '非培养类',
  },
  {
    label: '微生物培养类',
    value: '微生物培养类',
  },
]

// const sharedOnCell = () => {
//   return {
//     colSpan: 0,
//   }
// }

const SUB_PAGE_SIZE = 10
export const SubTable = ({ columns, hitIndex, dataSource = [] }) => {
  const [current, setCurrent] = useState(1)
  useEffect(() => {
    if (dataSource && dataSource.length && hitIndex >= 0) {
      const targetPage = Math.floor((hitIndex + 1) / SUB_PAGE_SIZE) + 1
      setCurrent(targetPage)
    }
  }, [hitIndex, dataSource])
  return (
    <Table
      showHeader={false}
      columns={columns}
      dataSource={dataSource}
      size="small"
      pagination={{
        current,
        pageSize: SUB_PAGE_SIZE,
        showSizeChanger: false,
        showQuickJumper: false,
      }}
      scroll={{ x: 'max-content' }}
      onChange={(page) => {
        setCurrent(page.current)
      }}
    />
  )
}

const Examine = ({ targetPage, render, itemTypeName, hitValue }) => {
  const { activeVisit, projectId, patientId } = useContext(PatientContext)
  const [itemType, setItemType] = useState(itemTypeName)
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState({
    pageIndex: targetPage || 1,
    pageSize: 10,
  })
  const [total, setTotal] = useState(0)
  const [text, setText] = useState('')
  const [time, setTime] = useState([])
  const [expandedRowKeys, setExpandedRowKeys] = useState([])
  // const [hitRecords, setHitRecords] = useState([])

  const reload = () => {
    setPage({
      pageIndex: 1,
      pageSize: 10,
    })
  }

  const columns = useMemo(() => {
    const base = [
      // {
      //   title: '申请单号',
      //   dataIndex: 'lab_request_no',
      //   key: 'lab_request_no',
      //   render,
      //   width: 100,
      // },
      // {
      //   title: '检验主题',
      //   dataIndex: 'origin_lab_item_name',
      //   key: 'origin_lab_item_name',
      //   width: 100,
      //   render,
      // },
      {
        title: '标准检验项目名称',
        dataIndex: 'standard_test_detail_item_name',
        key: 'standard_test_detail_item_name',
        width: 150,
        render,
      },
      {
        title: '标准检验项目编码',
        dataIndex: 'standard_test_detail_item_code',
        key: 'standard_test_detail_item_code',
        width: 150,
        render,
      },
      {
        title: '源检验项目名称',
        dataIndex: 'test_detail_item_name',
        key: 'test_detail_item_name',
        width: 150,
        render,
      },
      {
        title: '源检验项目编码',
        dataIndex: 'test_detail_item_code',
        key: 'test_detail_item_code',
        width: 150,
        render,
      },
      // {
      //   title: '检验时间',
      //   dataIndex: 'lab_exec_time',
      //   key: 'lab_exec_time',
      //   width: 100,
      //   render: (value, record) => render(formatDateFromTimestamp(value) || '-', record),
      // },
      // {
      //   title: '报告时间',
      //   dataIndex: 'lab_report_time',
      //   key: 'lab_report_time',
      //   width: 100,
      //   render: (value, record) => render(formatDateFromTimestamp(value) || '-', record),
      // },
      // {
      //   title: '检验值',
      //   dataIndex: 'value',
      //   key: 'value',
      //   render,
      //   width: 100,
      // },
      {
        title: '结果',
        dataIndex: 'value',
        key: 'value',
        width: 150,
        render,
      },
      {
        title: '单位',
        dataIndex: 'value_unit',
        key: 'value_unit',
        render,
        width: 150,
      },
    ]
    /* eslint-disable */
    const extra =
      itemType === '非培养类'
        ? [
            // {
            //   title: '定向结果',
            //   dataIndex: 'lab_result_value',
            //   key: 'lab_result_value',
            //   width: '10%',
            //   render,
            // },
            {
              title: '参考范围',
              dataIndex: 'reference_value',
              key: 'reference_value',
              render,
            },
          ]
        : [
            {
              title: '参考范围',
              dataIndex: 'reference_value',
              key: 'reference_value',
              width: 150,
              render,
            },
            {
              title: '培养结果',
              dataIndex: 'bacteria_cultivate_result',
              key: 'bacteria_cultivate_result',
              width: 150,
              render,
            },
            {
              title: '菌计数',
              dataIndex: 'bacterial_colony_count',
              key: 'bacterial_colony_count',
              width: 150,
              render,
            },
            {
              title: '微生物名称',
              dataIndex: 'microbe_name',
              key: 'microbe_name',
              render,
            },
          ]
    /* eslint-enable */
    return withEmptyRender([...base, ...extra])
  }, [itemType])

  // const wrapColumns = useMemo(() => {
  //   return columns.map((column, index) => {
  //     return {
  //       title: column.title,
  //       width: column.width,
  //       render(text, record) {
  //         return (
  //           <div className={styles.tableSection}>
  //             <div className={styles.title}>
  //               {record.standard_lab_item_name || record.origin_lab_item_name}
  //             </div>
  //             <div className={styles.item}>标本种类：{record.sample_type_name || '-'}</div>
  //             <div className={styles.item}>
  //               <TipIcon />
  //               <span>
  //                 检验时间：
  //                 {record.lab_exec_time
  //                   ? formatDateFromTimestamp(record.lab_exec_time, 'YYYY-MM-DD hh:mm')
  //                   : '-'}
  //               </span>
  //               <span>
  //                 报告时间：
  //                 {record.lab_report_time
  //                   ? formatDateFromTimestamp(record.lab_report_time, 'YYYY-MM-DD hh:mm')
  //                   : '-'}
  //               </span>
  //             </div>
  //           </div>
  //         )
  //       },
  //       onCell:
  //         index > 0
  //           ? sharedOnCell
  //           : () => {
  //               return {
  //                 colSpan: columns.length,
  //               }
  //             },
  //     }
  //   })
  // }, [columns])

  // const expandedRowRender = useCallback(
  //   (record) => {
  //     const target = hitRecords.find((n) => n.record_id === record.record_id)
  //     return (
  //       <SubTable
  //         record={record}
  //         columns={columns}
  //         hitIndex={target && target.hitIndex}
  //         dataSource={
  //           record.labResults &&
  //           record.labResults.map((n) => {
  //             const { labResults, ...rest } = record
  //             return {
  //               ...n,
  //               ...rest,
  //             }
  //           })
  //         }
  //       />
  //     )
  //   },
  //   [columns, expandedRowKeys]
  // )

  const handleSearch = (e) => {
    setText(e.target.value)
    reload()
  }

  // const handleTableChange = (pagination) => {
  //   setPage({
  //     pageIndex: pagination.current,
  //     pageSize: pagination.pageSize,
  //   })
  // }
  const fetchRecord = async () => {
    const pager = {
      pageNumber: page.pageIndex,
      pageSize: page.pageSize,
    }
    setLoading(true)
    const { indexNo, type } = activeVisit
    const { content, totalCount } = await fetch({
      url: api.patientApi.patientRecords,
      method: 'GET',
      params: {
        patientId,
        indexNo,
        type: 2,
        category: type,
        itemTypeName: itemType,
        proId: projectId,
        ...pager,
        text,
        startTime: time && time[0] ? time[0].format('YYYY-MM-DD') : '',
        endTime: time && time[1] ? time[1].format('YYYY-MM-DD') : '',
      },
    })
    setData(content || [])
    setLoading(false)
    setTotal(totalCount)
  }

  useEffect(() => {
    if (patientId) {
      fetchRecord()
    }
  }, [page, activeVisit])

  // 定位命中的记录
  const locateRecord = () => {
    const fields = columns.map((n) => n.dataIndex)
    const targetRecord = []
    if (data && data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        const { labResults } = data[i]
        if (labResults && labResults.length > 0) {
          for (let j = 0; j < labResults.length; j++) {
            const target = labResults[j]
            for (let k = 0; k < fields.length; k++) {
              if (target[fields[k]] && target[fields[k]].includes(hitValue)) {
                targetRecord.push({
                  record_id: data[i].record_id,
                  hitIndex: j,
                })
              }
            }
          }
        }
      }
    }
    if (targetRecord.length > 0) {
      setExpandedRowKeys(targetRecord.map((n) => n.record_id))
      // setHitRecords(targetRecord)
    } else {
      setExpandedRowKeys([])
      // setHitRecords([])
    }
  }

  useEffect(() => {
    if (data && data.length > 0) {
      if (hitValue) {
        locateRecord()
      } else {
        const first = data[0]
        setExpandedRowKeys([first.record_id])
      }
    }
  }, [JSON.stringify(data), hitValue, JSON.stringify(columns)])

  return (
    <>
      <TagSelect
        options={options}
        wrapperStyle={{ marginBottom: 16 }}
        onChange={(val) => {
          setItemType(val)
          reload()
        }}
        value={itemType}
      />
      <div style={{ marginBottom: 16 }}>
        <Space>
          <DebounceSearch
            placeholder="请输入检验主题/检验项目"
            style={{ width: 300 }}
            allowClear
            onSearch={handleSearch}
          />
          <RangePicker
            style={{ width: 300 }}
            value={time}
            onChange={(val) => {
              setTime(val)
              reload()
            }}
          />
        </Space>
      </div>
      <Spin spinning={loading}>
        <div className={styles.tableContainer}>
          <div className={styles.header}>
            {columns &&
              columns.map((column) => {
                return (
                  <div
                    className={styles.headerItem}
                    key={column.key}
                    style={{
                      width: `${100 / columns.length}%`,
                    }}
                  >
                    {column.title}
                  </div>
                )
              })}
          </div>
          {data.map((record) => {
            const expanded = expandedRowKeys.includes(record.record_id)
            return (
              <div key={record.record_id} className={styles.recordCard}>
                <div className={styles.cardHeader}>
                  {
                    // eslint-disable-next-line multiline-ternary
                    expanded ? (
                      <ExpandDown
                        onClick={(e) => {
                          setExpandedRowKeys(expandedRowKeys.filter((n) => n !== record.record_id))
                        }}
                      />
                    ) : (
                      <ExpandRight
                        onClick={(e) => {
                          setExpandedRowKeys([...expandedRowKeys, record.record_id])
                        }}
                      />
                    )
                  }
                  <div className={styles.title}>
                    {record.standard_lab_item_name || record.origin_lab_item_name}
                  </div>
                  <div className={styles.item}>标本种类：{record.sample_type_name || '-'}</div>
                  <div className={styles.item}>
                    <TipIcon />
                    <span>
                      检验时间：
                      {record.lab_exec_time
                        ? formatDateFromTimestamp(record.lab_exec_time, 'YYYY-MM-DD hh:mm')
                        : '-'}
                    </span>
                    <span>
                      报告时间：
                      {record.lab_report_time
                        ? formatDateFromTimestamp(record.lab_report_time, 'YYYY-MM-DD hh:mm')
                        : '-'}
                    </span>
                  </div>
                </div>
                <div className={styles.cardMain}>
                  {expanded && (
                    <>
                      {
                        // eslint-disable-next-line multiline-ternary
                        record.labResults && record.labResults.length > 0 ? (
                          record.labResults.map((labResult, index) => {
                            return (
                              <div className={styles.labItem} key={index}>
                                {columns &&
                                  columns.map((column) => {
                                    return (
                                      <div
                                        className={styles.labItemCell}
                                        key={column.key}
                                        style={{
                                          width: `${100 / columns.length}%`,
                                        }}
                                      >
                                        {labResult[column.dataIndex]
                                          ? render(labResult[column.dataIndex])
                                          : '-'}
                                      </div>
                                    )
                                  })}
                              </div>
                            )
                          })
                        ) : (
                          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="暂无数据" />
                        )
                      }
                    </>
                  )}
                </div>
              </div>
            )
          })}
          {total > 10 && (
            <div
              style={{
                textAlign: 'right',
              }}
            >
              <Pagination
                current={page.pageIndex}
                pageSize={page.pageSize}
                onChange={(current, pageSize) => {
                  setPage({
                    pageSize,
                    pageIndex: current,
                  })
                }}
                total={total}
                showTotal={(total) => `共 ${total} 条`}
              />
            </div>
          )}
        </div>
      </Spin>
      {/* {showTable && (
        <Table
          columns={wrapColumns}
          dataSource={data}
          rowKey={(record) => record.record_id}
          size="small"
          pagination={pagination}
          loading={loading}
          onChange={handleTableChange}
          scroll={{ x: 'max-content' }}
          expandable={{
            expandedRowKeys,
            expandedRowRender,
            expandIcon: ({ expanded, onExpand, record }) =>
              // eslint-disable-next-line multiline-ternary
              expanded ? (
                <ExpandDown
                  onClick={(e) => {
                    setExpandedRowKeys(expandedRowKeys.filter((n) => n !== record.record_id))
                    onExpand(record, e)
                  }}
                />
              ) : (
                <ExpandRight
                  onClick={(e) => {
                    setExpandedRowKeys([...expandedRowKeys, record.record_id])
                    onExpand(record, e)
                  }}
                />
              ),
          }}
        />
      )} */}
    </>
  )
}

export default Examine
