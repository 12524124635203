import React, {useState, forwardRef, useImperativeHandle, useEffect} from 'react'
import { Modal, Form, Input, Select } from 'antd'
const { Option } = Select
const { TextArea } = Input
import fetch from '@utils/request'
import api from '@api'
import {useWindowSize} from '@hooks/useLayout'

const ProjectModal = (props, ref) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [item, setItem] = useState('')
  const [title, setTitle] = useState('编辑')
  const [form] = Form.useForm()
  const [,, scale] = useWindowSize()
  // eslint-disable-next-line no-unused-vars
  const [hospitalTypes, setHospitalTypes] = useState([])
  const [projectTypes, setProjectTypes] = useState([])

  const handleOpen = (e) => {
    setIsModalVisible(true)
    if (e) {
      setTitle('新增')
    } else {
      setTitle('编辑')
    }
  }

  const handleValue = (e) => {
    setItem(e)
    form.setFieldsValue(e)
  }

  const handleOk = async() => {
    await form.submit()
  }

  const handleCancel = () => {
    form.resetFields()
    setIsModalVisible(false)
  }

  const onFinish = async (values) => {
    await fetch({
      method: 'POST',
      url: api.projectApi.savePro,
      data: {
        id: item?.id,
        createdBy: item?.createdBy,
        createdTime: item?.createdTime,
        projectName: values.projectName,
        projectCode: values.projectCode,
        type: values.type,
        remark: values.remark,
        hospitalName: values.hospitalName,
      },
      headers: {
        'Content-Type': 'application/json'
      }
    })
    handleCancel()
    props.onSuccess()
  }


  const fetchCommonDic = async() => {
    const res = await fetch({
      method: 'POST',
      url: api.commonApi.paramList,
    })
    console.log('commonDic', res)
    const PROJECT_TYPES = res.filter((item) => {
      return item.type === 'project_type'
    })
    const HOSPITAL_TYPES = res.filter((item) => {
      return item.type === 'project_hospital_name'
    })
    setProjectTypes(PROJECT_TYPES[0].valueNameVOS)
    setHospitalTypes(HOSPITAL_TYPES[0].valueNameVOS)
  }

  useEffect(() => {
    fetchCommonDic()
  }, [])

  useImperativeHandle(ref, () => ({
    handleOpen,
    handleValue
  }))

  return (
    <Modal
      title={title + '项目'}
      width={500*scale}
      open={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <Form
        form= {form}
        labelCol={{
          span: 6,
        }}
        wrapperCol={{
          span: 16,
        }}
        onFinish={onFinish}
      >
        <Form.Item
          label="项目名称"
          name="projectName"
          rules={[
            {
              required: true,
              message: '请输入项目名称',
            },
          ]}
        >
          <Input placeholder='请输入项目名称' />
        </Form.Item>

        <Form.Item
          label="项目编号"
          name="projectCode"
          rules={[
            {
              required: true,
              message: '请输入项目编号',
            },
          ]}
        >
          <Input disabled={title === '编辑'} placeholder='请输入项目编号' />
        </Form.Item>

        <Form.Item
          label="项目类型"
          name="type"
          rules={[
            {
              required: true,
              message: '请选择项目类型',
            },
          ]}
        >
          <Select
            disabled={title === '编辑'}
            placeholder="请选择项目类型"
          >
            {projectTypes.map(item => (
              <Option key={item.value}>{item.name}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) => prevValues.type !== currentValues.type}
        >
          {({ getFieldValue }) =>
            getFieldValue('type') === 'cti'
              ? (
                <Form.Item
                  label="医院名称"
                  name="hospitalName"
                  rules={[
                    {
                      required: true,
                      message: '请选择医院！',
                    },
                  ]}
                >
                  <Select
                    placeholder="请选择医院"
                  >
                    {hospitalTypes.map(item => (
                      <Option key={item.value}>{item.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
              )
              : null
          }
        </Form.Item>

        <Form.Item
          label="项目简介"
          name="remark"
        >
          <TextArea rows={4} placeholder="请输入项目简介" />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default forwardRef(ProjectModal)
