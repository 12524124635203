import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Space, Table, Modal, Tooltip, Progress } from 'antd'
import { EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import KeySearch from '@components/form/search'
import ProjectModal from './components/projectModal'
import styles from './index.module.less'
import { PROJECT_TYPE } from '@constants/common'
import { enumValueByKey } from '@utils/filter'
import fetch from '@utils/request'
import api from '@api'
import usePagination from '@hooks/usePagination'
// eslint-disable-next-line no-unused-vars
import { permissionStyle, permissionAuth } from '@utils/permission'
import {useWindowSize} from '@hooks/useLayout'
import {cloneDeep} from 'lodash'

const Project = () => {
  const [list, setList] = useState([])
  const projectRef = useRef(null)
  const searchRef = useRef(null)
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [searchFlag, setSearchFlag] = useState(false)
  const [page, setPage, setTotal, pagination] = usePagination()
  const [,, scale] = useWindowSize()
  const [orderBy, setOrderBy] = useState(null)
  const getList = async() => {
    setLoading(true)
    const pager = {
      pageNumber: page.pageIndex,
      pageSize: page.pageSize,
    }
    const res = await fetch({
      method: 'POST',
      url: api.projectApi.getProList,
      data: {
        keyword: searchFlag?searchRef.current.getValue(): '',
        ...pager,
        orderBy
      }
    })
    setList(res.content)
    setTotal(res.totalCount)
    setLoading(false)
  }

  const deleteProject = async(record) => {
    const res = await fetch({
      method: 'POST',
      url: api.projectApi.deleteProject,
      data: {
        projectId: record.id,
      }
    })
    return res
  }

  // 新增项目
  const headClick = () => {
    projectRef.current.handleOpen(true)
    projectRef.current.handleValue({})
  }

  // 关键词搜索
  const keySearchClick = (e) => {
    setSearchFlag(true)
    setPage({
      pageIndex: 1,
      pageSize: 10,
    })
  }

  // 跳转详情
  const detailClick = (record) => {
    const {type} = record
    if (type === 'CTI') {
      // 患者招募
      navigate(
        '/project/index/detail',
        {
          state: {
            id: record.id,
            projectName: record.projectName
          }
        }
      )

    } else if (type === 'CRF') {
      // 智能录入
      navigate(
        `/project/index/crf?id=${record.id}&projectName=${record.projectName}`,
        {
          state: {
            id: record.id,
            projectName: record.projectName
          }
        }
      )

    } else {
      return
    }
  }

  // 项目删除
  const onDel = (event, record) => {
    event.stopPropagation()
    Modal.confirm({
      title: '确定删除该项目？',
      icon: <ExclamationCircleOutlined />,
      okText: '确认',
      cancelText: '取消',
      async onOk() {
        await deleteProject(record)
        getList()
      }
    })
  }

  // 项目编辑
  const onEdit = (record) => {
    const cloneRecord = cloneDeep(record)
    cloneRecord.type = cloneRecord.type.toLowerCase()
    projectRef.current.handleOpen()
    projectRef.current.handleValue(cloneRecord)
  }

  const columns = [
    {
      title: '项目名称',
      dataIndex: 'projectName',
      key: 'project_name',
      width: 200*scale,
      sorter: true,
    },
    {
      title: '项目编号',
      dataIndex: 'projectCode',
      key: 'project_code',
      width: 200*scale,
      sorter: true,
    },
    {
      title: '项目类型',
      dataIndex: 'type',
      key: 'type',
      width: 150*scale,
      sorter: true,
      render: (text, record) => (<div>{enumValueByKey(text, PROJECT_TYPE)}</div>)
    },
    // TODO
    {
      title: '医院名称',
      dataIndex: 'hospitalName',
      key: 'hospital_name',
      width: 200*scale,
      sorter: true,
    },
    {
      title: '项目简介',
      dataIndex: 'remark',
      key: 'remark',
      width: 200*scale,
    },
    {
      title: '关联标签进度',
      dataIndex: 'progress',
      key: 'a.need_num::numeric/case when a.all_num = 0 then 1 else a.all_num end',
      width: 200*scale,
      sorter: true,
      render: (text, record) => {
        if (record.type === 'CRF'){
          return (
            <div className={styles.progressItem}>
              <Progress percent={record.needNum/record.allNum * 100} showInfo={false} />
              <span className={styles.progressItemInfo}>{`${record.needNum}/${record.allNum}`}</span>
            </div>
          )
        } else {
          return <div className={styles.progressItem}>——</div>
        }

      }
    },
    {
      title: '操作',
      key: 'action',
      width: 120*scale,
      render: (_, record) => (
        <Space size="middle">
          <Tooltip mouseEnterDelay="2" title="编辑">
            <a onClick={(e) => {
              e.stopPropagation()
              onEdit(record)
            }} style={permissionStyle('PROJECT_SAVE')}><EditOutlined /></a>
          </Tooltip>
          <Tooltip mouseEnterDelay="2" title="删除">
            <a onClick={(event) => onDel(event, record)} style={permissionStyle('DELETE_PROJECT')}><DeleteOutlined /></a>
          </Tooltip>
        </Space>
      ),
    },
  ]

  const handleTableChange = (pagination, filters, sorter, extra) => {
    if (sorter instanceof Array) {
      let sorterArr = sorter.map(({columnKey, order}) => {
        return `${columnKey} ${order.slice(0, -3)}`
      })
      setOrderBy(sorterArr.join())
    } else {
      let sorterStr
      if (sorter.order){
        sorterStr = `${sorter.columnKey} ${sorter?.order?.slice(0, -3)}`
      } else {
        sorterStr = ''
      }
      setOrderBy(sorterStr)
    }
    setPage({
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
    })
    // fetchData()
  }

  // 新增项目成功
  const onSuccess = () => {
    setPage({
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
    })
  }


  useEffect(() => {
    getList()
  }, [page, orderBy, searchFlag])

  return (
    <>
      {/* 标题栏 */}
      <div className="head">
        <h3 className="head-title">项目总览</h3>
        {/* 搜索栏 */}
        <div>
          <KeySearch ref={searchRef} keySearchClick={keySearchClick} />
          <Button type="primary" onClick={headClick} style={{marginLeft: '24px', ...permissionStyle('PROJECT_SAVE')}}>新增项目</Button>
        </div>
      </div>
      {/* 表格 */}
      <div className={styles.tableSpace}>
        <Table
          rowKey="id"
          rowClassName={styles.tableRow}
          onRow={(record) => ({
            onClick: (event) => {
              console.log('onClick', event)
              detailClick(record)
            }, // 点击行
          })}
          loading={loading}
          columns={columns}
          dataSource={list}
          pagination={pagination}
          onChange={handleTableChange}
        />
      </div>
      <ProjectModal ref={projectRef} onSuccess={onSuccess} />
    </>
  )
}

export default Project
