import React, { useState, useEffect, useRef } from 'react'
import { Button, Space, Tooltip, Modal, Table, message } from 'antd'
import { EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import styles from './index.module.less'
import EditRole from './components/roleModal'
import usePagination from '@hooks/usePagination'
import fetch from '@utils/request'
import api from '@api'
import { permissionStyle } from '@utils/permission'

const Role = () => {
  const roleRef = useRef(null)
  const [list, setList] = useState([])
  const [page, setPage, setTotal, pagination] = usePagination()

  const getList = async() => {
    const pager = {
      pageNumber: page.pageIndex,
      pageSize: page.pageSize,
    }
    const {content, totalCount} = await fetch({
      method: 'GET',
      url: api.permissionApi.roleList,
      params: {
        ...pager
      }
    })
    if (content && content.length>0) {
      setList(content.map(item => {
        return {
          ...item,
          key: item.id,
          status: String(item.status)
        }
      }))
      setTotal(totalCount)
    }
  }


  // 项目删除
  const onDel = (record) => {
    Modal.confirm({
      title: '确定删除该角色？',
      icon: <ExclamationCircleOutlined />,
      okText: '确认',
      cancelText: '取消',
      onOk: async() => {
        await fetch({
          method: 'DELETE',
          url: api.permissionApi.delete,
          data: {
            id: record.id
          }
        })
        message.success('删除成功')
        getList()
      }
    })
  }

  // 项目编辑
  const onEdit = (record) => {
    roleRef.current.show(record)
  }


  const handleTableChange = (pagination, filters, sorter, extra) => {
    setPage({
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
    })
    // fetchData()
  }

  useEffect(() => {
    getList()
  }, [page])

  const columns = [
    {
      title: '角色名称',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '角色说明',
      dataIndex: 'describe',
      key: 'describe',
    },
    {
      title: '操作',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Tooltip mouseEnterDelay="2" title="编辑">
            <a onClick={() => onEdit(record)} style={permissionStyle('EDIT_USER_ROLE')}><EditOutlined /></a>
          </Tooltip>
          <Tooltip mouseEnterDelay="2" title="删除">
            <a onClick={() => onDel(record)} style={permissionStyle('DELETE_USER_ROLE')}><DeleteOutlined /></a>
          </Tooltip>
        </Space>
      ),
    },
  ]

  return (
    <>
      {/* 标题栏 */}
      <div className="head">
        <h3 className="head-title">角色权限</h3>
        <Button type="primary" onClick={() => {
          onEdit(null)
        }} style={permissionStyle('ADD_USER_ROLE')}>新增角色</Button>
      </div>
      {/* 表格 */}
      <div className={styles.tableSpace}>
        <Table
          columns={columns}
          dataSource={list}
          pagination={pagination}
          onChange={handleTableChange}
        />
      </div>
      {/* <RoleModal ref={roleRef} handleOk={handleOk} /> */}
      <EditRole ref={roleRef} onSuccess={getList} />
    </>
  )
}

export default Role
