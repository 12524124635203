
import React, {useState, forwardRef, useImperativeHandle} from 'react'
import styles from './index.module.less'
import {Checkbox, Col, Row, Button} from 'antd'
import {useWindowSize} from '@hooks/useLayout'
import {useDispatch, useSelector} from 'react-redux'
import {setFilterColumnKeys} from '@store/app'
const columnAll = ['normalName', 'tradeName', 'englishName', 'category1', 'category2', 'specs', 'pack', 'dosage', 'baseDrugDirectory', 'medicalInsuranceCategory', 'manufacturer', 'approvalNo', 'drugStandardCode']
const FilterColumn = (props, ref) => {
  const dispatch = useDispatch()
  const filterColumnKeys = useSelector((state)=> state.app.filterColumnKeys)
  // eslint-disable-next-line no-unused-vars
  const [,, scale] = useWindowSize()
  const {columns} = props
  const [indeterminate, setIndeterminate] = useState(false)
  const [checkAll, setCheckAll] = useState(true)
  const [visible, setVisible] = useState(false)
  const [checkedKeys, setCheckedKeys] = useState(filterColumnKeys && filterColumnKeys.length?filterColumnKeys:columnAll)

  const show = () => {
    setVisible(true)
  }
  const hide = () => {
    setVisible(false)
  }
  const cancel = () => {
    setCheckedKeys(filterColumnKeys)
    hide()
  }
  const status = () => {
    return visible
  }
  const onChange = (checkedValue) => {
    setCheckedKeys(checkedValue)
    setIndeterminate(!!checkedValue.length && checkedValue.length < columns.length)
    setCheckAll(checkedValue.length === columns.length)
  }
  const onCheckAllChange = (e) => {
    setCheckedKeys(e.target.checked ? columns.map((item) => item.key) : [])
    setIndeterminate(false)
    setCheckAll(e.target.checked)
  }
  const getSelectedColumns = () => {
    return columns.filter((column) => {
      return checkedKeys.indexOf(column.key) !== -1
    })
  }
  // eslint-disable-next-line no-unused-vars
  const doneChecked = ()=> {
    const checkedColumns = getSelectedColumns()
    const checkedColumnKeys = checkedColumns.map((item)=> item.key)
    console.log('checkedColumnKeys', checkedColumnKeys)
    dispatch(setFilterColumnKeys(checkedColumnKeys))
    props.onChecked(checkedColumns)
    hide()
  }
  useImperativeHandle(ref, () => ({
    show,
    hide,
    status
  }))
  if (!visible) return null
  return (
    <div className={styles.filterWrapper}>
      <Col className={[styles.row, styles.self]} span={24}>
        <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
          全部
        </Checkbox>
      </Col>
      <Checkbox.Group
        style={{
          width: '100%',
          height: `${254*scale}px`,
          overflow: 'auto'
        }}
        value={checkedKeys}
        onChange={onChange}
      >
        <Row>

          {columns.map(({title, key}) => {
            return (
              <Col className={styles.row} span={24} key={key}>
                <Checkbox value={key}>{title}</Checkbox>
              </Col>
            )
          })}
        </Row>
      </Checkbox.Group>
      <div className={styles.btnGroup}>
        <Button style={{marginRight: '16px'}} type="link" onClick={cancel}>取消</Button>
        <Button type="link" onClick={doneChecked}>确定</Button>
      </div>
    </div>
  )
}

export default forwardRef(FilterColumn)
