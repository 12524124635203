/*
* 数据控件
* */

// eslint-disable-next-line no-unused-vars
import React, {useEffect, useMemo, useState} from 'react'
import api from '@api'
import fetch from '@utils/request'
import {change, Field, formValueSelector} from 'redux-form'
import {ASelect} from '@components/redux-form-antd'
import {connect} from 'react-redux'
import {debounce} from '@utils/common'
// import {isEqual} from 'lodash'
let DataWidget = ({namePath, metaId, changeArray}) => {
  const [metaData, setMetaData] = useState(null)
  const [metaSourceData, setMetaSourceData] = useState(null)
  const fetchMetaList = async () => {
    const data = await fetch({
      method: 'POST',
      url: api.tagApi.listDataElement,
      data: {
        keyword: ''
      },
      headers: {
        'Content-Type': 'application/json'
      },
    })
    setMetaData(data)
  }

  const fetchMetaSource = async () => {
    const data = await fetch({
      method: 'POST',
      url: api.tagApi.listDataSource,
      data: {
        keyword: ''
      },
      headers: {
        'Content-Type': 'application/json'
      },
    })
    setMetaSourceData(data)
  }
  /*
  * 表单联动 元数据字段选择变化时，初始化数据来源字段默认值
  * */
  // useMemo(() => {
  //   if (metaId) {
  //     const curMetaItem = metaData?.filter((item) => item.id === metaId)
  //     curMetaItem && changeArray(
  //       'tag',
  //       `${namePath}.sourceTableFields`,
  //       curMetaItem[0]?.dataSourceNames
  //     )
  //   } else {
  //     changeArray(
  //       'tag',
  //       `${namePath}.sourceTableFields`,
  //       []
  //     )
  //   }
  // }
  // , [metaId])

  useEffect(() => {
    fetchMetaList()
    fetchMetaSource()
  }, [])
  console.count('render')
  return (
    <>
      <Field
        label="选择数据元素"
        name={`${namePath}.metaId`}
        component={ASelect}
        fieldNames={{
          label: 'elementName',
          value: 'id',
        }}
        showSearch={true}
        optionFilterProp='elementName'
        options={metaData}
        placeholder="选择数据元素"
        autoClearSearchValue={false}
        onFocus={(e) => e.preventDefault()}
        onBlur={(e) => e.preventDefault()}
        changeEvent={(value) => {
          console.log('value::', value)
          const curMetaItem = metaData?.filter((item) => item.id === value)
          debounce( changeArray(
            'tag',
            `${namePath}.sourceTableFields`,
            curMetaItem[0]?.dataSourceNames
          ), 1000)
        }}
      />
      <Field
        label="选择数据来源"
        name={`${namePath}.sourceTableFields`}
        component={ASelect}
        value={null}
        mode='multiple'
        optionFilterProp='tableColumnComment'
        fieldNames={{
          label: 'tableColumnComment',
          value: 'tableColumnName',
        }}
        options={metaSourceData}
        placeholder="选择数据来源"
        autoClearSearchValue={false}
        onFocus={(e) => e.preventDefault()}
        onBlur={(e) => e.preventDefault()}
      />
    </>
  )
}


const selector = formValueSelector('tag') // <-- same as form name
const mapStateToProps = (state, ownProps) => {
  const {namePath} = ownProps
  const metaId = selector(state, `${namePath}.metaId`)
  return {
    metaId,
  }
}

const mapDispatchToProps = {
  // NOTE: This MUST be aliased or it will not work. Thanks Jack!
  changeArray: change
}
DataWidget = connect(mapStateToProps, mapDispatchToProps)(DataWidget)

export default DataWidget
