/*
* 取算法属性控件
* */

import React, {useEffect, useMemo, useState} from 'react'
import fetch from '@utils/request'
import api from '@api'
import {change, Field, formValueSelector} from 'redux-form'
import {ASelect} from '@components/redux-form-antd'
import {connect, useSelector} from 'react-redux'
import {debounce, findVal} from '@utils/common'

let AlgAttrsWidget = ({namePath, linkDataArray, changeArray, ...restProps}) => {
  const [aiModelProperties, setAiModelProperties] = useState([])
  const [preNode, setPreNode] = useState([])
  const tagAlgNode = useSelector((state) => state.app.tagAlgNode)
  const fetchAiProperties = async () => {
    const preNode = restProps.findPreNode(restProps.itemKey)
    let params = {}
    if (preNode && (preNode[0]?.type === 'AI')) {
      setPreNode(preNode)
      const modelCode = findVal(preNode[0], 'modelCode')
      params.modelCode = modelCode
    }

    const res = await fetch({
      method: 'GET',
      url: api.tagApi.aiModelProperties,
      params
    })
    setAiModelProperties(res)
  }

  useMemo(() => {
    console.log('2323')
    console.log('tagAlgNode', tagAlgNode)
    console.log('preNode', preNode)
    if (tagAlgNode && tagAlgNode.key === preNode[0]?.key) {
      // form.setFieldValue([...namePath, 'key'], '')
      debounce( changeArray(
        'tag',
        `${namePath}.key`,
        ''
      ), 0)
    }
  }, [JSON.stringify(tagAlgNode)])

  useEffect(() => {
    fetchAiProperties()
  }, [tagAlgNode, linkDataArray])
  return (
    <>
      <Field
        label="取算法输出"
        name={`${namePath}.key`}
        component={ASelect}
        value={null}
        placeholder="请选择算法属性"
        fieldNames={{label: 'name', value: 'code'}}
        showSearch={true}
        optionFilterProp='name'
        options={aiModelProperties}
        onFocus={(e) => e.preventDefault()}
        onBlur={(e) => e.preventDefault()}
      />
    </>
  )
}
const selector = formValueSelector('tag') // <-- same as form name
const mapStateToProps = (state) => {
  const linkDataArray = selector(state, 'linkDataArray')
  return {
    linkDataArray,
  }
}

const mapDispatchToProps = {
  // NOTE: This MUST be aliased or it will not work. Thanks Jack!
  changeArray: change
}

AlgAttrsWidget = connect(mapStateToProps, mapDispatchToProps)(AlgAttrsWidget)
export default AlgAttrsWidget
