import React, {
  useImperativeHandle,
  forwardRef,
  useState,
  useEffect,
} from 'react'
import { Form, Modal, Select, Input, Button, Space, message } from 'antd'
import fetch from '@utils/request'
import api from '@api'
import { DeleteOutlined } from '@ant-design/icons'
import DebounceSelect from '@components/DebounceSelect'

const fetchDrugs = (value) => {
  return fetch({
    url: api.aeApi.getExcludeConceptList,
    method: 'GET',
    params: {
      keyWord: value?.trim(),
    },
  })
}

const EditDrug = ({ onSuccess }, ref) => {
  const [form] = Form.useForm()
  const [visible, setVisible] = useState(false)
  const [record, setRecord] = useState({})
  const [projects, setProjects] = useState([]) // 项目列表
  const fetchProjects = async () => {
    const res = await fetch({
      url: api.aeApi.getExcludeProjectList,
      method: 'GET',
    })
    setProjects(
      res.map((item) => ({
        label: `${item.projectCode} ${item.projectName}`,
        value: item.id,
      }))
    )
  }

  useEffect(() => {
    fetchProjects()
  }, [])

  const hide = () => {
    form.resetFields()
    setVisible(false)
    setRecord({})
  }
  const show = (item) => {
    setVisible(true)
    if (!item) {
      form.setFieldValue('conceptIdArray', [
        {
          conceptId: undefined,
        },
      ])
      return
    }
    const { conceptDTOList, id } = item
    form.setFieldsValue({
      id,
      conceptIdArray: conceptDTOList.map((item) => {
        return {
          conceptId: item.name,
        }
      }),
    })
    setRecord(item)
  }
  useImperativeHandle(ref, () => ({
    show,
  }))

  const handleOk = async (values) => {
    const conceptIdArray = values.conceptIdArray.map((n) => {
      const { conceptId } = n
      if (typeof conceptId === 'number') {
        return conceptId
      } else {
        const target = record.conceptDTOList.find(
          (item) => item.name === conceptId
        )
        return target.id
      }
    })
    await fetch({
      url: api.aeApi.updateExcludeConcept,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        conceptIdArray,
        id: values.id,
      },
    })
    hide()
    message.success(!record.id ? '添加排除药品成功' : '编辑排除药品成功')
    typeof onSuccess === 'function' && onSuccess()
  }

  return (
    <Modal
      open={visible}
      onCancel={hide}
      title="排除药品"
      width={640}
      onOk={() => form.submit()}
      destroyOnClose
    >
      <Form
        form={form}
        labelCol={{
          style: {
            width: 120,
          },
        }}
        onFinish={handleOk}
        labelWrap={true}
      >
        <Form.Item
          label="项目编号/名称"
          name="id"
          rules={[
            {
              required: true,
              message: '请选择项目',
            },
          ]}
        >
          {
            /* eslint-disable */
            record.projectName ? (
              record.projectName
            ) : (
              <Select
                placeholder="请选择"
                options={projects}
                allowClear
                optionFilterProp="label"
                style={{ width: 400 }}
                showSearch={true}
                filterOption={(input, option) =>
                  option.label.indexOf(input.trim()) >= 0
                }
              />
            )
            /* eslint-disable */
          }
        </Form.Item>

        <Form.List name="conceptIdArray">
          {(fields, { add, remove }) => (
            <>
              <Form.Item label="排除药品" required>
                <Button type="primary" ghost onClick={() => add()}>
                  添加
                </Button>
              </Form.Item>
              {fields.map(({ key, name, ...restField }) => (
                <Space
                  key={key}
                  style={{
                    display: 'flex',
                  }}
                  align="baseline"
                >
                  <Form.Item
                    label={name + 1}
                    style={{ marginBottom: 0 }}
                    colon={false}
                  >
                    <Form.Item
                      {...restField}
                      name={[name, 'conceptId']}
                      rules={[
                        {
                          required: true,
                          message: '请输入通用名/商品名/英文名',
                        },
                      ]}
                    >
                      <DebounceSelect
                        placeholder="请输入通用名/商品名/英文名"
                        style={{ width: 370 }}
                        fetchOptions={fetchDrugs}
                        showArrow={true}
                        mode="single"
                        fieldNames={{
                          label: 'name',
                          value: 'id',
                        }}
                      ></DebounceSelect>
                    </Form.Item>
                  </Form.Item>
                  {name !== 0 && (
                    <DeleteOutlined
                      onClick={() => {
                        remove(name)
                      }}
                    />
                  )}
                </Space>
              ))}
            </>
          )}
        </Form.List>
        <Form.Item name="id" hidden>
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default forwardRef(EditDrug)
