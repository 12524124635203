import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import fetch from '@utils/request'
import api from '@api'

const initialState = {
  token: null,
  status: '',
  menuList: {},
  collapsed: false, // 折叠菜单flag
  userInfo: {},
  filterColumnKeys: [],
  privateHomeRoute: '/', // 默认首页路由
  sysConst: '', // 系统常量
  tagAlgNode: {} // 算法标签选项变化
}

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const loginAsync = createAsyncThunk(
  'app/login',
  async (data, thunkAPI) => {
    try {
      const response = await fetch(
        {
          method: 'POST',
          url: api.appApi.login,
          data
        }
      )
      // The value we return becomes the `fulfilled` action payload
      return response
    } catch (err){
      return thunkAPI.rejectWithValue(err)
      //
    }

  }
)
// TODO 登出逻辑因接口报错，暂时没调试
export const logoutAsync = createAsyncThunk(
  'app/logout',
  async (param) => {
    // eslint-disable-next-line no-debugger
    const data = await fetch(
      api.appApi.logout,
      param
    )
    // The value we return becomes the `fulfilled` action payload
    return data
  }
)

export const appIndex = createSlice({
  name: 'app',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    login: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.token = 1
    },
    logout: (state) => {
      state.token = 'logout'
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    toggleCollapsed: (state, action) => {
      // eslint-disable-next-line no-debugger
      state.collapsed = action.payload
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    setFilterColumnKeys: (state, action) => {
      // eslint-disable-next-line no-debugger
      state.filterColumnKeys = action.payload
    },
    setPrivateHomeRoute: (state, action) => {
      state.privateHomeRoute = action.payload
    },
    setSysConst: (state, action) => {
      state.sysConst = action.payload
    },
    setTagAlgNode: (state, action) => {
      state.tagAlgNode = action.payload
    }

  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(loginAsync.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(loginAsync.fulfilled, (state, action) => {
        const { token, privileges, ...userInfo } = action.payload
        state.status = 'idle'
        state.token = token
        state.privileges = privileges
        state.userInfo = userInfo
      })
      .addCase(logoutAsync.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(logoutAsync.fulfilled, (state, action) => {
        state.status = 'idle'
        state.token = ''
        state.privileges = []
        state.userInfo = {}
        state.userInfo = {}
        state.tag = {}
      })
  },
})

export const { login, logout, toggleCollapsed, setFilterColumnKeys, setPrivateHomeRoute, setSysConst, setTagAlgNode } = appIndex.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const accessToken = (state) => state.app.token

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd = (amount) => (dispatch, getState) => {
//     const currentValue = selectCount(getState());
//     if (currentValue % 2 === 1) {
//         dispatch(incrementByAmount(amount));
//     }
// };

export default appIndex.reducer
