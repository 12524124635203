
import React, {forwardRef} from 'react'
import { ReactComponent as IconDrag } from '@assets/images/tag/icon_drag.svg'
import {useWindowSize} from '@hooks/useLayout'
import styles from './index.module.less'
import Icon from '@ant-design/icons'
const DragItem = ({title, children, color, ...restProps}, ref ) => {
  const [,, scale] = useWindowSize()
  const getThemeStyle = (color) => {
    return {
      color,
      background: color?.replace(/1\)/, '0.1)')
    }
  }
  return (
    <div name={title} className={styles.itemWrapper} {...restProps} ref={ref}>
      <div className={styles.itemTitle} style={{...getThemeStyle(color)}}>
        <Icon style={{ color: `${color}`, marginRight: 9*scale, width: `${scale*6}px`, height: `${scale*12}px`, alignSelf: 'center'}} component={IconDrag} />
        <div style={{color: `${color}`}}>{title}</div>
      </div>
      <div className={styles.formWrapper}>
        {children}
      </div>
    </div>
  )
}

export default forwardRef(DragItem)
