import React, { useState, useImperativeHandle, forwardRef, useRef } from 'react'
// eslint-disable-next-line no-unused-vars
import { message, Form, Button } from 'antd'
import PropTypes from 'prop-types'
import { ReactComponent as AddIcon } from '@assets/images/icons/import_add.svg'
import CustomModal from '@components/customModal'
import FileUpload from '@components/fileUpload'
// import fetch from '@utils/request'
// import api from '@api'
import styles from './index.module.less'
import {useWindowSize} from '@hooks/useLayout'
import fetch from '@utils/request'
import api from '@api'
import {exportExcel} from '@utils/export'

const ImportProject = ({ onSuccess }, ref) => {
  const uploadRef = useRef(null)
  const [visible, setVisible] = useState(false)
  const [error, setError] = useState(false)
  const [file, setFile] = useState(null)
  const [uploading, setUploading] = useState(false)
  const [, , scale] = useWindowSize()

  const hide = () => {
    uploadRef.current.clear()
    setFile(null)
    setUploading(false)
    setVisible(false)
    setError(false)
  }
  const show = () => {
    setVisible(true)
  }
  useImperativeHandle(ref, () => ({
    show,
    hide,
  }))

  const handleSubmit = async () => {
    if (!file) {
      setError(true)
      return
    }
    typeof onSuccess === 'function' && onSuccess(file)
    // setUploading(true)
    // const formData = new FormData()
    // formData.append('file', file)
    // try {
    //   const importTotal = await fetch({
    //     url: api.knowledgeApi.checkoutImport,
    //     data: formData,
    //     method: 'POST',
    //     headers: {
    //       'Content-Type': 'multipart/form-data',
    //     },
    //   })
    //   setFile(null)
    //   setVisible(false)
    //   hide()
    //   message.success(`导入成功，${importTotal}`)
    //   typeof onSuccess === 'function' && onSuccess()
    // } catch (error) {
    //   setUploading(false)
    // }
  }

  /*
  * 下载模板
  * */
  const downLoadExecel = async () => {
    //
    try {
      const res = await fetch({
        method: 'GET',
        url: api.tagApi.labelDownload,
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        responseType: 'blob'
      })
      if (res.code === 0) {
        message.error(res.message)
      } else {
        exportExcel('原子标签导入模板', res)
        message.success('下载成功!')
      }

    } catch (err) {

    }
  }

  return (
    <CustomModal
      open={visible}
      onCancel={hide}
      title="文件导入"
      onOk={handleSubmit}
      okButtonProps={{ loading: uploading }}
      width={640*scale}
    >
      <Form layout="vertical">
        <Form.Item noStyle>
          <div className={styles.title}>当前支持诊断名称、手术操作名称、通用名、商品名数据元进行原子标签导入</div>
        </Form.Item>
        <Form.Item required>
          <FileUpload
            ref={uploadRef}
            uploadProps={{
              accept: '.xlsx',
              children: (
                <>
                  <div className={styles.btn}>
                    <AddIcon style={{ color: '#031f47', marginRight: 5, width: `${scale*20}px`, height: `${scale*20}px` }} />
                    点击导入模板文件
                  </div>
                  <div className={styles.text}>仅支持  xlsx 文件，单次最多上传 1 个</div>
                </>
              ),
            }}
            maxCount={1}
            onChange={(files) => {
              setFile(files[0])
              setError(false)
            }}
          />
          <div className={styles.downloadWrapper}><Button type='link' onClick={downLoadExecel}>模板下载</Button></div>
          {error && <div className={styles.error}>请选择文件</div>}
        </Form.Item>
      </Form>
    </CustomModal>
  )
}

export default forwardRef(ImportProject)

ImportProject.propTypes = {
  onSuccess: PropTypes.func,
}
