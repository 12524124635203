/*
* 标注管理
* */
import React, {useState, useEffect} from 'react'
import {Button, Col, DatePicker, Form, Input, message, Row, Spin, Table} from 'antd'
import {permissionStyle} from '@utils/permission'
import {useWindowSize} from '@hooks/useLayout'
import styles from './index.module.less'
import usePagination from '@hooks/usePagination'
import fetch from '@utils/request'
import api from '@api'
import moment from 'moment'
import {exportExcel} from '@utils/export'

const { RangePicker } = DatePicker

const Dataview = () => {
  const [form] = Form.useForm()
  const [,, scale] = useWindowSize()
  // eslint-disable-next-line no-unused-vars
  const [page, setPage, setTotal, pagination] = usePagination()
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [list, setList] = useState([])
  const [columns, setColumns] = useState([])
  const [orderBy, setOrderBy] = useState(null)
  const [filter, setFilter] = useState({})

  const getList = async(param ={}) => {
    const pager = {
      pageNumber: page.pageIndex,
      pageSize: page.pageSize,
      orderBy,
      ...filter,
    }
    setLoading(true)
    try {
      const res = await fetch({
        method: 'POST',
        url: api.markApi.list,
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        data: {
          ...pager,
          ...param
        }
      })
      setLoading(false)
      setList(res.dataList)
      setTotal(res.totalCount)
      const columns = res.columnsTypeVOS.map((column, index) => {
        return {
          ...column,
          width: 150*scale,
          sorter: {
            multiple: index +1
          }
        }
      })
      setColumns(columns)
    } catch (e) {
      setLoading(false)
    }

  }

  /*
  * 导出
  * */
  const handleExport = async () => {
    //
    const filters = form.getFieldsValue()
    let params = {}
    console.log('filters:', filters)
    if (filters?.searchName?.match(/^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/)) {
      params.phone = filters.searchName
    } else {
      params.name = filters.searchName
    }

    if (filters.markTime){
      params.start = moment(filters.markTime[0]).format('YYYY-MM-DD')
      params.end = moment(filters.markTime[0]).format('YYYY-MM-DD')
    }
    try {
      const res = await fetch({
        method: 'POST',
        url: api.markApi.export,
        data: params,
        headers: {
          'Content-Type': 'application/json'
        },
        responseType: 'blob'
      })
      if (res.code === 0) {
        message.error(res.message)
      } else {
        exportExcel('标注管理', res)
        message.success('导出成功!')
      }

    } catch (err) {

    }
  }

  const onFinish = async (item) => {
    setPage({
      pageIndex: 1,
      pageSize: 10
    })
    const filters = form.getFieldsValue()
    console.log('filters:', filters)
    if (filters?.searchName?.match(/^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/)) {
      setFilter({
        phone: filters.searchName
      })
    } else {
      setFilter({
        name: filters.searchName
      })
    }
    if (filters.markTime){
      setFilter((prevState) => {
        return {
          ...prevState,
          start: moment(filters.markTime[0]).format('YYYY-MM-DD'),
          end: moment(filters.markTime[1]).format('YYYY-MM-DD'),
        }
      })
    }
  }
  // handleTableChange
  const handleTableChange = (pagination, filters, sorter, extra) => {
    console.log('handleTableChange', sorter)
    setPage({
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
    })
    // fetchData()
    if (sorter instanceof Array) {
      let sorterArr = sorter.map(({field, order}) => {
        return `${field} ${order.slice(0, -3)}`
      })
      setOrderBy(sorterArr.join())
    } else {
      let sorterStr
      if (sorter.order){
        sorterStr = `${sorter.field} ${sorter?.order?.slice(0, -3)}`
      } else {
        sorterStr = ''
      }
      setOrderBy(sorterStr)
    }
    console.log('sorter', sorter)
    console.log('sorter: type', sorter instanceof Array)
  }
  useEffect(() => {
    getList()
  }, [page, filter])
  return (
    <>
      {/* 标题栏 */}
      <div className="head">
        <h3 className="head-title">标注管理看板</h3>
      </div>

      <Form
        className={styles.searchBox}
        form={form}
        labelCol={{ style: { width: 92*scale } }}
        onFinish={onFinish}
      >
        <Row gutter={60}>
          <Col span={18}>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  name="searchName"
                  label="搜索"
                  rules={
                    [{
                      max: 30,
                    }]
                  }
                >
                  <Input placeholder="请输入姓名" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="markTime"
                  label="标注时间"
                >
                  <RangePicker />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={6} className={styles.searchBoxRight}>
            <Row>
              <Button type="primary" htmlType="submit">
                            搜索
              </Button>
              <Button
                style={{
                  margin: '0 8px',
                }}
                onClick={() => {
                  form.resetFields()
                  setFilter({})
                }}
              >
                            清空
              </Button>
              <Button
                type="primary"
                className="custom-btn"
                ghost
                onClick={handleExport}
                style={permissionStyle('RULE_EXPORT')}
              >
                            导出
              </Button>
            </Row>
          </Col>
        </Row>
      </Form>

      <div className={styles.content}>
        <Spin spinning={loading}>
          <Table
            rowKey={record => record.id}
            columns={columns}
            dataSource={list}
            pagination={pagination}
            onChange={handleTableChange}
            scroll={{x: scale*2000}}
          />
        </Spin>
      </div>
    </>
  )
}

export default Dataview
