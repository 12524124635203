import React, { forwardRef, useImperativeHandle, useState } from 'react'
import CustomModal from '../customModal'
import styles from './index.module.less'

const ConfirmModal = (props, ref) => {
  const [visible, setVisible] = useState(false)
  const [values, setValues] = useState({})

  const hide = () => {
    setVisible(false)
  }

  useImperativeHandle(ref, () => {
    return {
      show: (record) => {
        setValues(record)
        setVisible(true)
      },
      hide,
    }
  })
  return (
    <CustomModal
      open={visible}
      width={460}
      onCancel={hide}
      zIndex={1200}
      {...values}
      className={styles.confirmModal}
    >
      {values.content}
    </CustomModal>
  )
}

export default forwardRef(ConfirmModal)
