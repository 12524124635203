
import React, {useRef, useState} from 'react'
import styles from './index.module.less'
import { ReactComponent as CloseIcon } from '@assets/images/icons/icon_close.svg'
import { ReactComponent as AddIcon } from '@assets/images/icons/icon_add.svg'
import SubjectModal from '@views/project/crf-enter/components/subjectModal'


const RadioTag = ({seletedData, onClick}) => {
  // eslint-disable-next-line no-unused-vars
  const [viewData, setViewData] = useState(seletedData)
  const [seletedTag, setSeletedTag] = useState(null)
  const subjectRef = useRef(null)
  const selectedSubjects = (data) => {
    console.log('selectedSubjects', data)
    setViewData(data)
  }
  const removeTag = (curItem) => {
    const newData = viewData.filter((item) => item !== curItem)
    setViewData(newData)
  }

  const addSubjects = () => {
    subjectRef.current.handleOpen(viewData)
  }
  const getItemStyle = (selectedFlag) => ({
    borderColor: selectedFlag ? '#262626': '#D9D9D9'
  })
  const TagItem = ({item}) => {
    return (
      <div className={styles.radioTag} onClick={() =>{
        setSeletedTag(item)
        onClick(item)
      }}>
        <div className={styles.itemWrapper}>
          <div className={styles.tagWrapper} style={getItemStyle(item === seletedTag)}>
            <div>受试者</div>
            <div>{item}</div>
          </div>
        </div>
        {viewData.length > 1
          ? <CloseIcon
            onClick={() => {
              removeTag(item)
            }}
            className={styles.icon}
          />
          : null}

      </div>
    )
  }

  return (
    <div className={styles.radioTagWrapper}>
      <div className={styles.radioTagArea}>
        {
          viewData.map((tag, index) => {
            return <TagItem key={index} item={tag} />
          })
        }
      </div>
      {
        viewData.length < 20 && (
          <div className={styles.addIcon} onClick={addSubjects}>
            <AddIcon className={styles.btn}/>
          </div>
        )
      }
      <SubjectModal ref={subjectRef} done={selectedSubjects} defaultValue={viewData}/>
    </div>
  )
}

export default RadioTag
