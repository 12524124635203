/*
* 药品库首页
* */
import React, {useEffect, useRef, useState} from 'react'
import {Form, Row, Col, Input, Button, Spin, Table, Space, Tooltip, Divider, Modal, message} from 'antd'
import styles from './index.module.less'
import usePagination from '@hooks/usePagination'
import {useNavigate} from 'react-router-dom'
import {CaretDownOutlined, ExclamationCircleOutlined, DeleteOutlined, FileTextOutlined, FilePdfOutlined} from '@ant-design/icons'
import {useWindowSize} from '@hooks/useLayout'
import FilterColumn from '@views/knowledge/drug/components/FilterColumn'
import fetch from '@utils/request'
import api from '@api'
import {permissionStyle} from '@utils/permission'
import {useSelector} from 'react-redux'
const Drug = () => {
  const filterColumnKeys = useSelector((state)=> state.app.filterColumnKeys)
  const navigate = useNavigate()
  // eslint-disable-next-line no-unused-vars
  const filterColumnsRef = useRef(null)
  const filterColumnRef = useRef(null)
  const [filterColumns, setFilterColumns] = useState([])
  const [form] = Form.useForm()
  // eslint-disable-next-line no-unused-vars
  const [,, scale] = useWindowSize()
  // eslint-disable-next-line no-unused-vars
  const [page, setPage, setTotal, pagination, total] = usePagination()
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false)
  const [filterParams, setFilterParams] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [list, setList] = useState([
    {
      filed1: '精蛋白生物合成人胰岛素注射液(预混30R)'
    }
  ])

  const handleTableChange = (pagination, filters, sorter, extra) => {
    setPage({
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
    })
    // fetchData()
  }

  const getList = async(param ={}) => {
    const pager = {
      pageNumber: page.pageIndex,
      pageSize: page.pageSize,
    }
    setLoading(true)
    try {
      const res = await fetch({
        method: 'POST',
        url: api.knowledgeApi.list,
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        data: {
          ...pager,
          ...param
        }
      })
      setLoading(false)
      setList(res.content)
      setTotal(res.totalCount)
    } catch (e) {
      setLoading(false)
    }

  }
  const onFinish = async (item) => {
    setPage({
      pageIndex: 1,
      pageSize: 10
    })
    const filters = form.getFieldsValue()
    console.log('filters:', filters)
    setFilterParams(filters)
    // await getList(filters)
  }
  // 跳转到详情页
  const goDetail = (e, rowData, type) => {
    e.stopPropagation()
    if (type === 'pdf') {
      window.open(rowData.downloadSource)
    } else {
      navigate(`/knowledge/drug/detail?id=${rowData.id}&type=${type}&source=${rowData.downloadSource}`)
    }
  }
  const onDel = async (rowData) => {

    Modal.confirm({
      title: '确定删除吗？',
      icon: <ExclamationCircleOutlined />,
      okText: '确认',
      cancelText: '取消',
      onOk: async() => {
        await fetch({
          method: 'POST',
          url: api.knowledgeApi.delete,
          data: {
            id: rowData.id
          }
        })
        message.success('刪除成功!')
        const totalPage = Math.ceil((total - 1) / page.pageSize) // 总页数
        let curPage = page.pageIndex > totalPage ? totalPage : page.pageIndex
        // curPage = page.pageIndex > 1 ? page.pageIndex : 1
        setPage({
          pageIndex: curPage,
          pageSize: page.pageSize,
        })
        getList()
      }
    })
  }
  const showModal = () => {
    let status = filterColumnRef.current.status()
    if (status) {
      filterColumnRef.current.hide()
    } else {
      filterColumnRef.current.show()
    }

  }
  // eslint-disable-next-line no-unused-vars
  const getSelectedColumns = (columns) => {
    setFilterColumns(columns)
  }

  const columns = [
    {
      title: '通用名',
      dataIndex: 'normalName',
      key: 'normalName',
      width: 300,
    },
    {
      title: '商品名',
      dataIndex: 'tradeName',
      key: 'tradeName',
      width: 300,
    },
    {
      title: '英文名',
      dataIndex: 'englishName',
      key: 'englishName',
      width: 300,
    },
    {
      title: '药品分类1',
      dataIndex: 'category1',
      key: 'category1',
      width: 300,
    },
    {
      title: '药品分类2',
      dataIndex: 'category2',
      key: 'category2',
      width: 300,
    },
    {
      title: '规格',
      dataIndex: 'specs',
      key: 'specs',
      width: 300,
    },
    {
      title: '包装',
      dataIndex: 'pack',
      key: 'pack',
      width: 300,
    },
    {
      title: '剂型',
      dataIndex: 'dosage',
      key: 'dosage',
      width: 300,
    },
    {
      title: '基药目录',
      dataIndex: 'baseDrugDirectory',
      key: 'baseDrugDirectory',
      width: 300,
    },
    {
      title: '医保分类',
      dataIndex: 'medicalInsuranceCategory',
      key: 'medicalInsuranceCategory',
      width: 300,
    },
    {
      title: '生产厂家',
      dataIndex: 'manufacturer',
      key: 'manufacturer',
      width: 300,
    },
    {
      title: '批准文号',
      dataIndex: 'approvalNo',
      key: 'approvalNo',
      width: 300,
    },
    {
      title: '药品本位码',
      dataIndex: 'drugStandardCode',
      key: 'drugStandardCode',
      width: 300,
    },
  ]

  const actionColumn = [
    {
      title: '操作',
      key: 'action',
      fixed: 'right',
      width: 150,
      render: (_, record) => (
        <Space size="middle">
          <Tooltip title={record.instructionType === 1?'说明书文本格式':'资源不存在！'} >
            <Button type='link' disabled={record.instructionType !== 1} onClick={(e) => goDetail(e, record, 'text')} style={{padding: 0, ...permissionStyle('DRUG_STOCK_ADD')}}><FileTextOutlined /></Button>
          </Tooltip>
          <Tooltip title={record.downloadSource?'说明书PDF/IMAGE格式': '资源不存在！'} >
            <Button type='link' disabled={!record.downloadSource} onClick={(e) => goDetail(e, record, 'pdf')} style={{padding: 0, ...permissionStyle('DRUG_STOCK_ADD')}}><FilePdfOutlined /></Button>
          </Tooltip>
          <Tooltip title="删除" >
            <Button type='link' onClick={() => onDel(record)} style={{padding: 0, ...permissionStyle('DRUG_STOCK_DELETE')}}><DeleteOutlined/></Button>
          </Tooltip>
        </Space>
      ),
    },
  ]

  useEffect(() => {
    const cacheColumns = columns.filter((column) => {
      if (filterColumnKeys && filterColumnKeys.length) {
        return filterColumnKeys?.indexOf(column.key) !== -1
      } else {
        return true
      }
    })
    setFilterColumns(cacheColumns)
  }, [])

  useEffect(() => {
    getList(filterParams)
  }, [page, filterParams])
  return (
    <>
      {/* 标题栏 */}
      <div className="head">
        <h3 className="head-title">药品库</h3>
      </div>
      <Form
        className={styles.searchBox}
        form={form}
        labelCol={{ style: { width: 92*scale } }}
        onFinish={onFinish}
      >
        <Row gutter={60}>
          <Col span={18}>
            <Row gutter={24}>
              <Col span={8}>
                <Form.Item
                  name="normalName"
                  label="通用名"
                  rules={
                    [{
                      max: 30,
                    }]
                  }
                >
                  <Input placeholder="请输入关键词搜索" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="tradeName"
                  label="商品名"
                  rules={
                    [{
                      max: 30,
                    }]
                  }
                >
                  <Input placeholder="请输入关键词搜索" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="manufacturer"
                  label="生产厂家"
                  rules={
                    [{
                      max: 30,
                    }]
                  }
                >
                  <Input placeholder="请输入关键词搜索" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={8}>
                <Form.Item
                  name="englishName"
                  label="英文名"
                  rules={
                    [{
                      max: 30,
                    }]
                  }
                >
                  <Input placeholder="请输入关键词搜索" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="approvalNo"
                  label="批准文号"
                  rules={
                    [{
                      max: 25,
                    }]
                  }
                >
                  <Input placeholder="请输入关键词搜索" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="drugStandardCode"
                  label="药品本位码"
                  rules={
                    [{
                      max: 25,
                    }]
                  }
                >
                  <Input placeholder="请输入关键词搜索" />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={6} className={styles.searchBoxRight}>
            <Row>
              <Button type="primary" htmlType="submit">
                            搜索
              </Button>
              <Button
                style={{
                  margin: '0 8px',
                }}
                onClick={() => {
                  form.resetFields()
                  getList()
                }}
              >
                            清空
              </Button>
            </Row>
          </Col>
        </Row>
      </Form>
      <div className={styles.content}>
        <Row className={styles.titleWrapper}>
          <div className={styles.tableTitle}>
            <Divider type="vertical" className={styles.divider}/>
            药品库列表
          </div>
          <div style={{position: 'relative'}}>
            <div className={styles.btnGroup}>
              <Button onClick={showModal} type="text" style={{width: '100%'}}>
                {`自定义（${filterColumnKeys?.length || columns.length}）`}
                <CaretDownOutlined />
              </Button>

            </div>
            <FilterColumn ref={filterColumnRef} columns={columns} onChecked={getSelectedColumns}/>
          </div>
        </Row>
        <Spin spinning={loading}>
          <Table
            scroll={{ x: 1000 }}
            rowKey={record => record.id}
            columns={filterColumns.concat(actionColumn)}
            dataSource={list}
            pagination={pagination}
            onChange={handleTableChange}
          />
        </Spin>
      </div>

    </>
  )
}

export default Drug
