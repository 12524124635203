import TagWidget from './tag'
import AlgAttrsWidget from './algAttrs'
import DataWidget from './data'
import AlgWidget from './alg'
import ConstantWidget from './constant'
import OperatorWidget from './operator'
import LogicWidget from './logic'
import ExportWidget from './export'
import ScriptWidget from './script'
import KnowledgeWidget from './knowledge'
import React, {useEffect} from 'react'

const ItemWidget = ({namePathPrefix, type, ...restProps}) => {
  console.log('ItemWidget: ', restProps)
  useEffect(() => {
    console.count('itemW')
  }, [])
  let widget
  switch (type) {
    case 'RULE': // 标签控件
      widget = <TagWidget namePath={`${namePathPrefix}.ruleExpression`} {...restProps} />
      break
    case 'EXTRACT':
      widget = <AlgAttrsWidget namePath={`${namePathPrefix}.extractExpression`} {...restProps}/>
      break
    case 'DATA':
      widget = <DataWidget namePath={`${namePathPrefix}.dataExpression`} {...restProps}/>
      break
    case 'AI':
      widget = <AlgWidget namePath={`${namePathPrefix}.aiExpression`} {...restProps}/>
      break
    case 'CONSTANT':
      widget = <ConstantWidget namePath={`${namePathPrefix}.constantExpression`} {...restProps}/>
      break
    case 'OPERATION':
      widget = <OperatorWidget namePath={`${namePathPrefix}.operationExpression`} {...restProps}/>
      break
    case 'LOGIC':
      widget = <LogicWidget namePath={`${namePathPrefix}.logicExpression`} {...restProps}/>
      break
    case 'OUTPUT':
      widget = <ExportWidget namePath={`${namePathPrefix}.outputExpression`} {...restProps}/>
      break
    case 'SCRIPT':
      widget = <ScriptWidget namePath={`${namePathPrefix}.scriptExpression`} {...restProps}/>
      break
    case 'KNOWLEDGE':
      widget = <KnowledgeWidget namePath={`${namePathPrefix}.knowledgeExpression`} {...restProps}/>
      break
    default:
      widget = null
      break
  }
  return widget
}

export default ItemWidget
