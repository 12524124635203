/*
* 账号相关api
* */
const accountApi = {
  EmailCode: '/account/forgetPasswordSendEmail', // 忘记密码发送邮件
  updatePassword: '/account/updatePassword', // 修改密码
  roles: '/user/roles', // 所有角色
  userList: '/user/list', // 用户列表
  add: '/user/add', // 创建用户
  edit: '/user/edit', // 创建用户
  repwd: '/user/repwd', // 重置密码
  status: '/user/status', // 启用-禁用
}

export default accountApi
