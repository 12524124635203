import {createSlice } from '@reduxjs/toolkit'

const initialState = {
  stagedCrfState: {}, // crf项目详情页缓存状态
}

export const project = createSlice({
  name: 'mapping',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setStagedCrfState: (state, action) => {
      state.stagedCrfState = action.payload
    }
  },
})

export const { setStagedCrfState } = project.actions

export default project.reducer
