/*
* 知识库相关api
* */
const knowledgeApi = {
  list: '/kbms/drug/list', // 药品库列表
  delete: '/kbms/drug/delete', // 删除
  detail: '/kbms/drug/instruction', // 详情--药品说明书
  examList: '/kbms/exam/list', // 检验库列表
  examImport: '/kbms/exam/import', // 检验库导入
  examDetail: '/kbms/exam/detail', // 检验库详情
  checkoutList: '/kbms/checkout/list', // 检查库列表
  checkoutImport: '/kbms/checkout/import', // 检查库导入
  checkoutDetail: '/kbms/checkout/detail', // 检查库详情
}

export default knowledgeApi
