import React, { useState, useRef, useEffect } from 'react'
import styles from './index.module.css'
import bgLoing from '@assets/images/login/bg_login.png'
import imgLogo from '@assets/images/login/logo.png'
import LoginForm from '@views/login/components/login-form'
import ForgetForm from '@views/login/components/forget-form'
import api from '@api'
import fetch from '@utils/request'
import { PASSWORD_EXPIRE } from '@constants/common'
import { useSearchParams, useNavigate } from 'react-router-dom'
import ChangePwd from '@layout/main/header/components/settings/components/changePwd'
import moment from 'moment'
import { notification } from 'antd'
import { WarningOutlined } from '@ant-design/icons'

const Login = () => {
  const [forgetPWFlag, setForgetPWFlag] = useState(false)
  const toggleLoginType = (flag) => {
    setForgetPWFlag(flag)
  }
  const welcomeText = () => {
    const hour = moment().hour()
    /* eslint-disable  */
    const text =
      hour < 9
        ? '早上好'
        : hour <= 11
        ? '上午好'
        : hour <= 13
        ? '中午好'
        : hour < 20
        ? '下午好'
        : '晚上好'
    /* eslint-disable  */
    return text
  }
  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: '欢迎',
      description: `${welcomeText()} ，欢迎回来！`,
    })
  }
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const changePwdRef = useRef(null)
  const judgePwd = async (path) => {
    const res = await fetch({
      url: api.appApi.lastDay,
      method: 'GET',
    })
    if (res >= PASSWORD_EXPIRE) {
      changePwdRef.current.show()
      setSearchParams({ error: 'expired' }, { replace: true })
      return
    }
    openNotificationWithIcon('success')
    setTimeout(() => {
      navigate(path)
    }, 600)
  }

  useEffect(() => {
    const error = searchParams.get('error')
    if (error === 'expired') {
      changePwdRef.current.show()
    }
  }, [])

  const onSuccess = () => {
    setSearchParams({}, { replace: true })
  }
  return (
    <div className={styles.loginWrapper}>
      <div className={styles.center}>
        <div className={styles.left}>
          <img src={bgLoing} className={styles.bgLeft} />
        </div>
        <div className={styles.right}>
          <div className={styles.rightHeader}>
            <img src={imgLogo} className={styles.imgLogo} />
            <div className={styles.headerTitle}>KBMS</div>
            <div className={styles.headerSubTitle}>知识管理系统</div>
          </div>
          {
            /* eslint-disable */
            forgetPWFlag ? (
              <ForgetForm toggleLoginType={toggleLoginType} />
            ) : (
              <LoginForm
                toggleLoginType={toggleLoginType}
                onSuccess={judgePwd}
              />
            )
            /* eslint-disable */
          }
        </div>
      </div>
      <div className={styles.footer}></div>
      <ChangePwd
        ref={changePwdRef}
        onSuccess={onSuccess}
        modalProps={{
          title: (
            <div>
              <WarningOutlined style={{ color: '#f5222d', marginRight: 10 }} />
              <span style={{ fontSize: 14 }}>
                您已超过90天未修改密码，为了账号安全请修改密码，否则无法进行其他操作！
              </span>
            </div>
          ),
        }}
      />
    </div>
  )
}

export default Login
