
import React, {useEffect, useRef, useState} from 'react'
import {Button, message, Modal, Space, Table, Tooltip} from 'antd'
import {permissionStyle} from '@utils/permission'
import styles from '@views/project/index.module.less'
import fetch from '@utils/request'
import api from '@api'
import usePagination from '@hooks/usePagination'
import {useNavigate, useSearchParams} from 'react-router-dom'
import LinkTagModal from '@views/project/crf-enter/components/linkTagModal'
import {useWindowSize} from '@hooks/useLayout'
import {CRF_STATUS} from '@constants/common'
import {enumValueByKey} from '@utils/filter'
import {ExclamationCircleOutlined} from '@ant-design/icons'
import {useLocation} from 'react-router'
import SubjectModal from '@views/project/crf-enter/components/subjectModal'
import EditRule from './components/editRule'
import { useDispatch, useSelector } from 'react-redux'
import {setStagedCrfState} from '@store/project'

const LinkButton = (props)=>{
  const {style, ...rest} = props
  return (
    <Button type="link" style={{padding: 0, ...style}} {...rest}>{props.children}</Button>
  )
}

export const loop = (node) => {
  const dfs = (node, parent, level)=>{
    if (node.length){
      node.forEach((item) => {
        item.level = level
        if (level===2 && parent.formId){
          item.formId = parent.formId
          item.canDelete = node.length > 1
        }
        if (!item.formName && parent) {
          item.formName = parent.formName
        }
        if (!item?.children?.length){
          item.children = null
        } else {
          dfs(item.children, item, level+1)
        }
      })
    }
  }
  dfs(node, null, 1)
}

const statusOptions = CRF_STATUS.map(o=>({
  text: o.desc,
  value: o.value
}))

const CrfEnterIndex = () => {
  const dispatch = useDispatch()
  const {stagedCrfState={}} = useSelector(state => state.project)
  const {
    page: stagedPage={},
    expandedRowKeys: stagedExpandedRowKeys=[],
    id: stagedId,
    projectName: stagedProjectName,
    orderBy: stagedOrderBy,
    filterInfo: stagedFilterInfo={},
  } = stagedCrfState
  const stage = useRef(false)
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [page, setPage, setTotal, pagination] = usePagination({
    pageIndex: stagedPage.pageIndex || 1,
    pageSize: stagedPage.pageSize || 10,
  })
  const [filterInfo, setFilterInfo] = useState(stagedFilterInfo)
  // eslint-disable-next-line no-unused-vars
  // const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [selectedRows, setSelectedRows] = useState([])
  const [fieldIdList, setFieldIdList] = useState([])
  const navigate = useNavigate()
  const linkTagRef = useRef(null)
  const subjectRef = useRef(null)
  const editRuleRef = useRef(null)
  const { state} = useLocation()
  const [params] = useSearchParams()
  const id = params.get('id') || stagedId
  const projectName = params.get('projectName') || stagedProjectName
  const [, , scale] = useWindowSize()
  const [orderBy, setOrderBy] = useState(stagedOrderBy)
  const [expandedRowKeys, setExpandedRowKeys] = useState(stagedExpandedRowKeys)
  const [addRuleLoading, setAddRuleLoading] = useState({
    key: '',
    flag: false
  })

  const pageState = {
    id,
    projectName,
    orderBy,
    expandedRowKeys,
    page,
    filterInfo
  }

  useEffect(()=>{
    return ()=>{
      if (!stage.current){
        dispatch(setStagedCrfState({}))
      }
    }
  }, [])

  const getList = async() => {
    setLoading(true)
    const pager = {
      pageNumber: page.pageIndex,
      pageSize: page.pageSize,
    }
    const {content, totalCount} = await fetch({
      method: 'POST',
      url: api.projectApi.crfList,
      data: {
        // projectId: id,
        projectId: id || state.id,
        formId: '',
        ...pager,
        orderBy,
        statusList: filterInfo.status
      },
      headers: {
        'Content-Type': 'application/json'
      }
    })
    loop(content)
    setList(content)
    setTotal(totalCount)
    setLoading(false)
  }
  const packageParams = () => {
    return selectedRows.filter((row) => {
      return row.level === 3
    }).map((item) => item.fieldRuleId)
  }

  const deleteRule = (record)=>{
    Modal.confirm({
      title: `确认删除${record.ruleClassName}？`,
      icon: <ExclamationCircleOutlined />,
      okText: '确定',
      cancelText: '取消',
      onOk: async () => {
        try {
          await fetch({
            method: 'POST',
            url: api.projectApi.deleteRuleClass,
            data: {
              formId: record.formId,
              ruleClassName: record.ruleClassName
            },
            headers: {
              'Content-Type': 'application/json'
            }
          })
          message.success('删除成功！')
          getList()
        } catch (e) {
          console.log(e)
        }
      }
    })
  }
  /*
  * 关联标签
  * */
  const linkTag = (record, disabled) => {
    stage.current = true
    dispatch(setStagedCrfState(pageState))
    const {fieldId, fieldName, formName, status, outputType } = record
    navigate(
      `/project/index/crf/link?projectId=${id || state.id}&fieldId=${fieldId}&fieldName=${encodeURIComponent(fieldName)}
      &formName=${encodeURIComponent(formName)}&status=${status}&outputType=${outputType || ''}&ruleClassName=${record.ruleClassName || ''}
      &fieldRuleId=${record.fieldRuleId || ''}`,
      {
        state: {
          id: id || state.id,
        }
      }
    )
  }
  /*
* 删除
* */
  const onDelete = (record) => {
    //
    let params = []
    console.log('dataOnline', record)
    if (record){
      params = [record.fieldRuleId]
    } else {
      params = packageParams()
    }
    const modal = Modal.confirm({
      title: '您确认将当前字段删除吗？',
      icon: <ExclamationCircleOutlined />,
      okText: '确定',
      cancelText: '取消',
      onOk: async () => {
        try {
          await fetch({
            method: 'POST',
            url: api.projectApi.crfDelete,
            data: {
              fieldRuleIdList: params,
            },
            headers: {
              'Content-Type': 'application/json'
            }
          })
          message.success('删除成功！')
          getList()
        } catch (e) {
          modal.destroy
        }
      }
    })
  }
  /*
  * 数据验证
  * */
  const dataValidate = async () => {
    // Todo
    subjectRef.current.handleOpen()
  }
  const dataOnline = (record) => {
    let params = []
    let title = ''
    console.log('dataOnline', record)
    if (record){
      params = [record.fieldRuleId]
      title = `您确认将当前${enumValueByKey(record.status, CRF_STATUS)}的${params.length}条智能录入标签进行发布吗？`
    } else {
      params = packageParams()
      const selectedDatas = selectedRows.filter((row) => {
        return !row.formId
      })
      // 所有勾选项都是待发布（value:3）状态
      const onlineFlag = selectedDatas.every((row) => {
        return row.status === 3
      })
      // 没有待发布（value:3）状态
      const noPublishFlag = selectedDatas.every((row) => {
        return row.status > 3
      })
      console.log('selectedDatas', selectedDatas)
      console.log('noPublishFlag', noPublishFlag)
      // 筛选出待验证（value:2）状态
      const validateLength = selectedDatas.filter((row) => {
        return row.status === 2
      })
      // 筛选出待发布（value:3）状态
      // eslint-disable-next-line no-unused-vars
      const onlineLength = selectedDatas.filter((row) => {
        return row.status === 3
      })
      if (noPublishFlag) {
        return message.warn('您当前选中的智能录入标签里，没有待发布状态！')
      }
      if (onlineFlag) {
        title = `您确认将当前待发布的${params.length}条智能录入标签进行批量发布吗？`
      } else {
        title = `您当前选中${params.length}条智能录入标签，  ${validateLength.length? `存在${validateLength.length}条未进行数据验证,`: ''}确定进行批量发布吗？`
      }
    }
    const modal = Modal.confirm({
      title,
      icon: <ExclamationCircleOutlined />,
      okText: '确定',
      cancelText: '取消',
      onOk: async () => {
        try {
          await fetch({
            method: 'POST',
            url: api.projectApi.crfOnline,
            data: {
              projectId: id,
              fieldRuleIdList: params,
            },
            headers: {
              'Content-Type': 'application/json'
            }
          })
          message.success('发布成功！')
          getList()
        } catch (e) {
          modal.destroy
        }
      }
    })
  }

  const handleTableChange = (pagination, filters, sorter, extra) => {
    console.log('sorter', sorter)
    if (sorter instanceof Array) {
      let sorterArr = sorter.map(({columnKey, order}) => {
        return `${columnKey} ${order.slice(0, -3)}`
      })
      setOrderBy(sorterArr.join())
    } else {
      let sorterStr
      if (sorter.order){
        sorterStr = `${sorter.columnKey} ${sorter?.order?.slice(0, -3)}`
      } else {
        sorterStr = ''
      }
      setOrderBy(sorterStr)
    }
    setPage({
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
    })
    if (filters){
      setFilterInfo(filters)
    }
    // fetchData()
  }
  const onSuccess = () => {
    //
  }
  const selectedSubjects = async (data) => {
    console.log('selectedSubjects', data)
    console.log('fieldIdList', fieldIdList)
    const params = packageParams()
    try {
      await fetch({
        method: 'POST',
        url: api.projectApi.crfValid,
        data: {
          projectId: id,
          fieldRuleIdList: params,
        },
        headers: {
          'Content-Type': 'application/json'
        }
      })
    } catch (err) {
      console.log(err)
    }
    setFieldIdList(params)
    stage.current = true
    dispatch(setStagedCrfState(pageState))
    navigate(
      `/project/index/crf/data?id=${id || state.id}&projectName=${projectName}&fieldRuleIdList=${JSON.stringify(params)}`,
      {
        state: {
          subjects: data,
          id: id || state.id,
          projectName
        }
      }
    )
    subjectRef.current.handleCancel()
    // /project/index/crf/data
  }

  const addRule = async (record)=>{
    setAddRuleLoading(true)
    await fetch({
      url: api.projectApi.addRuleClass,
      method: 'POST',
      data: {
        formId: record.formId,
      },
      headers: {
        'Content-Type': 'application/json'
      }
    })
    message.success('添加标签规则成功！')
    setAddRuleLoading(false)
    getList()
  }

  const onEdit = (values)=>{
    const {oldKey, newKey} = values
    if (oldKey!==newKey && expandedRowKeys.includes(oldKey)){
      const index = expandedRowKeys.findIndex((item)=>item===oldKey)
      expandedRowKeys.splice(index, 1, newKey)
      setExpandedRowKeys([...expandedRowKeys])
    }
    getList()
  }

  const columns = [
    {
      title: '关联表单和字段',
      dataIndex: 'name',
      key: 'form_name',
      sorter: true,
      width: 200*scale,
      render(text, record){
        let children = text
        if (record.level === 2){
          children= (
            <div className={styles.rule}>
              <span className={styles.name}>{record.ruleClassName}</span>
              <span className={styles.desc}>{record.ruleClassDesc}</span>
            </div>
          )
        }
        if (record.level === 3){
          children = record.fieldName
        }
        return {
          children,
          props: {
            colSpan: record.level === 2? 4: 1,
          }
        }
      },
    },
    {
      title: '状态',
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      width: 200 * scale,
      filters: statusOptions,
      filteredValue: filterInfo.status,
      render: (text, record) => {
        //
        return {
          children: <div>{enumValueByKey(text, CRF_STATUS)}</div>,
          props: {
            colSpan: record.level === 2? 0: 1,
          }
        }
      },
    },
    {
      title: '更新时间',
      dataIndex: 'updatedTime',
      key: 'updated_time',
      sorter: true,
      width: 200*scale,
      render(text, record){
        return {
          children: text,
          props: {
            colSpan: record.level === 2? 0: 1,
          }
        }
      }
    },
    {
      title: '关联标签',
      dataIndex: 'ruleName',
      key: 'rule_name',
      sorter: true,
      width: 200*scale,
      render(text, record){
        return {
          children: text,
          props: {
            colSpan: record.level === 2? 0: 1,
          }
        }
      }
    },
    {
      title: '操作',
      key: 'action',
      width: 150*scale,
      render: (_, record) => {
        if (record.level === 1){
          return <LinkButton
            onClick={()=>addRule(record)}
            loading={addRuleLoading.key===record.key && addRuleLoading.flag}
            style={permissionStyle('PROJECT_CRF_RULECLASS_ADD')}
          >新建标签规则</LinkButton>
        }
        if (record.level === 2){
          return (
            <Space>
              <LinkButton
                onClick={() => {
                  editRuleRef.current.show({
                    ...record,
                    oldRuleClassName: record.ruleClassName,
                  })

                }}
                style={permissionStyle('PROJECT_CRF_RULECLASS_UPDATE')}
              >编辑</LinkButton>
              <LinkButton
                onClick={()=>deleteRule(record)}
                disabled={!record.canDelete}
                style={permissionStyle('PROJECT_CRF_RULECLASS_DELETE')}
              >删除</LinkButton>
            </Space>
          )
        }
        if (!record.formId && record.status !== -1){
          return (
            <Space size="middle">
              <Tooltip mouseEnterDelay="2" title="关联标签">
                <LinkButton onClick={() => {
                  linkTag(record)
                }} style={permissionStyle('PROJECT_CRF_BIND')}> 关联标签</LinkButton>
              </Tooltip>
              {
                (record.status === 2 || record.status ===3) &&
                ( <Tooltip mouseEnterDelay="2" title="发布">
                  <LinkButton onClick={() => {
                    dataOnline(record)
                  }} style={permissionStyle('PROJECT_CRF_ONLINE')}> 发布</LinkButton>
                </Tooltip>)
              }
            </Space>
          )
        } else if (!record.formId && record.status === -1) {
          return (
            <Space size="middle">
              <Tooltip mouseEnterDelay="2" title="查看">
                <LinkButton onClick={() => {
                  linkTag(record)
                }} style={permissionStyle('PROJECT_CRF_QUERY')}> 查看</LinkButton>
              </Tooltip>
              <Tooltip mouseEnterDelay="2" title="删除">
                <LinkButton onClick={() => {
                  onDelete(record)
                }} style={permissionStyle('PROJECT_CRF_DELETE')}> 删除</LinkButton>
              </Tooltip>
            </Space>
          )
        }
      },
    },
  ]

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows)
    },
    // onSelect: (record, selected, selectedRows) => {
    //   console.log(record, selected, selectedRows)
    // },
    // onSelectAll: (selected, selectedRows, changeRows) => {
    //   console.log(selected, selectedRows, changeRows)
    // },
  }
  useEffect(() => {
    getList()
  }, [page, orderBy, filterInfo])

  const handleExpand = (expanded, record) => {
    if (expanded) {
      // 获取当前展开的父节点的 key
      const parentKey = record.key

      // 获取所有子节点的 key
      const childKeys = record.children.map(child => child.key)

      // 合并父节点和子节点的 key，设置为展开的行
      const newExpandedRowKeys = [...expandedRowKeys, parentKey, ...childKeys]
      console.log('newExpandedRowKeys', newExpandedRowKeys)
      setExpandedRowKeys(newExpandedRowKeys)
    } else {
      // 收起时，将当前父节点和子节点的 key 从展开的行中移除
      const newExpandedRowKeys = expandedRowKeys.filter(key => key !== record.key)
      setExpandedRowKeys(newExpandedRowKeys)
    }
  }

  return (
    <>
      {/* 标题栏 */}
      <div className="head">
        <h3 className="head-title">{projectName}</h3>
        <Space wrap>
          <Button
            disabled={!selectedRows.length || selectedRows.filter(n => n.level === 3).some((item)=>item.status === 1)}
            onClick={() => dataValidate()}
            style={permissionStyle('PROJECT_CRF_VALID')}
          >
            数据验证
          </Button>
          <Button
            disabled={!selectedRows.length || selectedRows.filter(n => n.level === 3).some((item)=>item.status !== 2 && item.status !== 3)}
            onClick={() => dataOnline()}
            style={permissionStyle('PROJECT_CRF_ONLINE')}>
            批量发布
          </Button>
        </Space>
      </div>

      {/* 表格 */}
      <div className={styles.tableSpace}>
        <Table
          loading={loading}
          columns={columns}
          dataSource={list}
          rowSelection={{
            ...rowSelection,
            checkStrictly: false
          }}
          pagination={pagination}
          onChange={handleTableChange}
          expandable={{
            defaultExpandAllRows: true,
            // onExpandedRowsChange: (expandedRows) => {
            //   console.log('expandedRows', expandedRows)
            //   setExpandedRowKeys(expandedRows)
            // },
            expandedRowKeys: expandedRowKeys,
            onExpand: handleExpand
          }}
          rowKey={record=>record.key}
        />
      </div>
      <LinkTagModal ref={linkTagRef} id={1} onSuccess={onSuccess} />
      <SubjectModal ref={subjectRef} done={selectedSubjects}/>
      <EditRule ref={editRuleRef} onSuccess={onEdit}/>
    </>
  )
}

export default CrfEnterIndex
