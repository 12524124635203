import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { useSelector } from 'react-redux'
import { Form, Input, message, Modal } from 'antd'
import { passwordRegex } from '@utils/regex'
import fetch from '@utils/request'
import api from '@api'

const ChangePwd = (props, ref) => {
  const userInfo = useSelector((state) => state.app.userInfo)
  const [visible, setVisible] = useState(false)
  const [form] = Form.useForm()

  const show = () => {
    setVisible(true)
  }

  const hide = () => {
    form.resetFields()
    setVisible(false)
  }

  useImperativeHandle(ref, () => ({
    show,
  }))

  const handleSubmit = async (values) => {
    delete values.confirmPwd
    await fetch({
      url: api.appApi.updatePassword,
      data: {
        ...values,
        id: userInfo.id,
      },
      method: 'PUT',
    })
    hide()
    message.success('密码修改成功')
    props.success()
  }

  return (
    <Modal
      title="修改密码"
      visible={visible}
      onCancel={hide}
      onOk={() => form.submit()}
      width={640}
      {...props.modalProps}
    >
      <Form form={form} layout="vertical" size="large" onFinish={handleSubmit}>
        <Form.Item
          label="原始密码"
          name="oldPwd"
          rules={[{ required: true, message: '密码不能为空' }]}
        >
          <Input.Password
            placeholder="请输入密码"
            type="password"
            autoComplete="off"
          />
        </Form.Item>
        <Form.Item
          label="新密码"
          name="newPwd"
          rules={[
            { required: true, message: '密码不能为空' },
            {
              pattern: passwordRegex,
              message:
                '密码必须包含大写字母、小写字母、数字和特殊字符，长度8-16位',
            },
          ]}
        >
          <Input.Password
            placeholder="请输入密码"
            type="password"
            autoComplete="off"
          />
        </Form.Item>
        <Form.Item
          label="确认密码"
          name="confirmPwd"
          rules={[
            { required: true, message: '密码不能为空' },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue('newPwd') === value) {
                  return Promise.resolve()
                }
                return Promise.reject('两次密码输入不一致')
              },
            }),
          ]}
        >
          <Input.Password
            placeholder="请输入密码"
            type="password"
            autoComplete="off"
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default forwardRef(ChangePwd)
