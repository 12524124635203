
import React, {useEffect, useRef, useState} from 'react'
import {Space, Table, Tooltip} from 'antd'
import {permissionStyle} from '@utils/permission'
import styles from '@views/project/index.module.less'
import fetch from '@utils/request'
import api from '@api'
import {useNavigate, useSearchParams} from 'react-router-dom'
import LinkTagModal from '@views/project/crf-enter/components/linkTagModal'
import {useWindowSize} from '@hooks/useLayout'
import {useLocation} from 'react-router'
import SubjectModal from '@views/project/crf-enter/components/subjectModal'
import PatientRecordModal from '@views/project/crf-enter/components/patientRecordModal'
import RadioTag from '@components/radio-tag'
import {loop} from '../index'

const CrfDataIndex = () => {
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const navigate = useNavigate()
  const linkTagRef = useRef(null)
  const subjectRef = useRef(null)
  const patientRecordRef = useRef(null)
  const { state} = useLocation()
  const [subjects, setSubjects] = useState(state.subjects || [])
  const [selectedSubject, setSelectedSubject] = useState(null)
  const [params] = useSearchParams()
  const id = params.get('id')
  // eslint-disable-next-line no-unused-vars
  const fieldRuleIdList = params.get('fieldRuleIdList')
  const projectName = params.get('projectName')
  const [, , scale] = useWindowSize()
  const [orderBy, setOrderBy] = useState(null)

  const getList = async() => {
    setLoading(true)
    const pager = {
      pageNumber: 1,
      pageSize: 10000,
    }
    const content = await fetch({
      method: 'POST',
      url: api.projectApi.crfListForTargetFieldId,
      data: {
        projectId: id || state.id,
        formId: '',
        patientNoList: [selectedSubject],
        fieldRuleIdList: JSON.parse(fieldRuleIdList),
        ...pager,
        orderBy
      },
      headers: {
        'Content-Type': 'application/json'
      }
    })
    loop(content)
    setList(content)
    setLoading(false)
  }
  /*
    * 关联标签
    * */
  const viewRecord = ({rule={}}) => {
    const ruleIdAndVersionList = [`${rule.id}#${rule.version}`]
    patientRecordRef.current.handleOpen(ruleIdAndVersionList)
  }

  // eslint-disable-next-line no-unused-vars
  const dataValidate = () => {
    subjectRef.current.handleOpen()
  }

  const onSuccess = () => {
    //
  }
  const setSourceSubjects = (data) => {
    setSubjects(data)
    setSelectedSubject(data[0])
  }
  // const renderLinkTags = (text) => {
  //   return text?.map((item) =>
  //     item.name
  //   ).join()
  // }
  const columns = [
    {
      title: '关联表单和字段',
      dataIndex: 'name',
      key: 'form_name',
      width: 200*scale,
      sorter: true,
      render(text, record){
        let children = text
        if (record.level === 2){
          children = (
            <div className={styles.rule}>
              <span className={styles.name}>{record.ruleClassName}</span>
              <span className={styles.desc}>{record.ruleClassDesc}</span>
            </div>
          )
        }
        if (record.level === 3){
          children = record.fieldName
        }
        return {
          children,
          props: {
            colSpan: record.level === 2? 4: 1,
          }
        }
      },
    },
    {
      title: '关联标签',
      dataIndex: 'ruleName',
      key: 'ruleName',
      width: 200*scale,
      render: (text, record) => {
        return {
          children: text,
          props: {
            colSpan: record.level === 2? 0: 1,
          }
        }
      },
    },
    {
      title: '验证状态',
      dataIndex: 'verifyStatus',
      key: 'verify_status',
      sorter: true,
      width: 200*scale,
      render: (text, record) => {
        return {
          children: <div>{record.level===3 ? (text === 1 ? '正确': '错误'): ''}</div>,
          props: {
            colSpan: record.level === 2? 0: 1,
          }
        }
      }
    },
    {
      title: '验证数据',
      dataIndex: 'verifyResult',
      key: 'verifyResult',
      width: 200*scale,
      render(text, record){
        return {
          children: text,
          props: {
            colSpan: record.level === 2? 0: 1,
          }
        }
      }
    },
    {
      title: '操作',
      key: 'action',
      width: 150*scale,
      render: (_, record) => {
        if (record.level===3){
          return (
            <Space size="middle">
              <Tooltip mouseEnterDelay="2" title="查看档案">
                <a onClick={() => {
                  viewRecord(record)
                }} style={permissionStyle('PROJECT_CRF_BIND')}> 查看档案</a>
              </Tooltip>
            </Space>
          )
        }
      },
    },
  ]
  const onTagClickEvent = (data) => {
    setSelectedSubject(data)
  }

  const handleTableChange = (pagination, filters, sorter, extra) => {
    console.log('sorter', sorter)
    if (sorter instanceof Array) {
      let sorterArr = sorter.map(({columnKey, order}) => {
        return `${columnKey} ${order.slice(0, -3)}`
      })
      setOrderBy(sorterArr.join())
    } else {
      let sorterStr
      if (sorter.order){
        sorterStr = `${sorter.columnKey} ${sorter?.order?.slice(0, -3)}`
      } else {
        sorterStr = ''
      }
      setOrderBy(sorterStr)
    }
    // fetchData()
  }
  useEffect(() => {
    selectedSubject && getList()
  }, [selectedSubject, orderBy])
  return (
    <>
      {/* 标题栏 */}
      <div className="head">
        <h3 className="head-title">{projectName}</h3>
      </div>
      <div className={styles.radioTagWrapper}>
        <RadioTag seletedData={subjects} onClick={onTagClickEvent}/>
      </div>
      {/* 表格 */}
      <div className={styles.tableSpace}>
        <Table
          loading={loading}
          columns={columns}
          dataSource={list}
          pagination={false}
          expandable={{
            defaultExpandAllRows: true
          }}
          onChange={handleTableChange}
        />
      </div>
      <LinkTagModal ref={linkTagRef} id={1} onSuccess={onSuccess} />
      <SubjectModal ref={subjectRef} done={(data) => {
        setSourceSubjects(data)
      }} defaultValue={subjects}/>
      <PatientRecordModal ref={patientRecordRef} patientId={selectedSubject}/>
    </>
  )
}

export default CrfDataIndex
