/*
* 标签控件
* */

import {Form, Select, Input} from 'antd'
import React from 'react'
import styles from '@views/project/components/index.module.less'
const TagWidget = ({namePath, showLabelModal}) => {
  return (
    <>
      <Form.Item label="选择标签" name={[...namePath, 'ruleName']}>
        <Select
          className={styles.select}
          mode="multiple"
          showSearch={false}
          placeholder="选择入组原子标签"
          dropdownStyle={{'display': 'none'}}
          onClick={() => showLabelModal(namePath)}
          fieldNames={{label: 'name', value: 'id'}}
        ></Select>
      </Form.Item>
      <Form.Item label="选择标签" name={[...namePath, 'ruleId']} style={{display: 'none'}}>
        <Input />
      </Form.Item>
    </>
  )
}

export default TagWidget
