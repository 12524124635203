import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react'
import { Modal, Form, Input, Select, Switch, message } from 'antd'
const { Option } = Select
import fetch from '@utils/request'
import api from '@api'

const UserModal = (props, ref) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [id, setId] = useState()
  const [add, setAdd] = useState(true)
  const [isSuper, setIsSuper] = useState(false)
  const [roles, setRoles] = useState([])
  const [form] = Form.useForm()

  const getRoles = async() => {
    const res = await fetch({
      method: 'GET',
      url: api.accountApi.roles,
      data: {}
    })
    setRoles(res)
  }

  const handleOpen = (e) => {
    setIsModalVisible(true)
    if (e) {
      setAdd(true)
    } else {
      setAdd(false)
    }
  }

  const handleValue = (e) => {
    setId(e.id)
    setIsSuper(e.isSuper === true)
    form.setFieldsValue(e)
  }

  const handleOk = async() => {
    await form.submit()
  }

  const handleCancel = () => {
    form.resetFields()
    setIsModalVisible(false)
  }

  const onFinish = async(values) => {
    if (add) {
      await fetch({
        method: 'POST',
        url: api.accountApi.add,
        data: {
          ...values,
          status: values.status ? 1 : 0
        }
      })
      message.success('保存成功')
    } else {
      await fetch({
        method: 'PUT',
        url: api.accountApi.edit,
        data: {
          id: id,
          ...values,
          status: values.status ? 1 : 0
        }
      })
      message.success('保存成功')
    }
    props.handleOk()
    handleCancel()
  }

  useImperativeHandle(ref, () => ({
    handleOpen,
    handleValue
  }))

  useEffect(() => {
    getRoles()
  }, [])

  return (
    <Modal
      title={(add ? '新增' : '编辑') + '账号'}
      visible={isModalVisible}
      width="600px"
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <Form
        form= {form}
        labelCol={{
          span: 6,
        }}
        wrapperCol={{
          span: 16,
        }}
        initialValues={{
          status: true
        }}
        onFinish={onFinish}
      >
        <Form.Item
          label="用户名"
          name="username"
          rules={[
            {
              required: true,
              message: '请输入用户名',
            },
          ]}
        >
          <Input placeholder="请输入用户名" disabled={!add}/>
        </Form.Item>

        <Form.Item
          label="姓名"
          name="name"
          rules={[
            {
              required: true,
              message: '请输入姓名',
            },
          ]}
        >
          <Input placeholder="请输入姓名" />
        </Form.Item>

        <Form.Item
          label="手机号"
          name="phone"
          rules={[
            {
              required: true,
              message: '请输入手机号',
            },
            {
              pattern: /^1[3|4|5|7|8][0-9]\d{8}$/,
              message: '请输入正确的手机号',
            }
          ]}
        >
          <Input placeholder="请输入手机号" />
        </Form.Item>

        <Form.Item
          label="角色"
          name="roleId"
          rules={[
            {
              required: true,
              message: '请选择角色',
            },
          ]}
        >
          <Select
            placeholder="请选择角色"
            disabled={isSuper}
          >
            {roles.map(item => (
              <Option key={item.id}>{item.name}</Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="状态"
          name="status"
          valuePropName="checked"
        >
          <Switch checkedChildren="启用" unCheckedChildren="禁用" defaultChecked disabled={isSuper}/>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default forwardRef(UserModal)
