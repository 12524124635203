export const genderMap = {
  1: '男',
  2: '女',
}

export const patientSourceMap = {
  1: '门诊',
  2: '住院',
  3: '体检',
  4: '急诊',
}

export const patientTreatmentMap = {
  1: '门诊',
  2: '住院',
}

// 诊断类型
export const patientDiagTypeMap = {
  1: '门诊',
  2: '住院',
  3: '体检',
  4: '急诊',
}
