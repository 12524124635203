/*
* 输出控件
* */

// eslint-disable-next-line no-unused-vars
import React, {useEffect, useMemo, useState} from 'react'

import {ACheckbox, AInput, ASelect} from '@components/redux-form-antd'
import {change, Field, FieldArray, formValueSelector} from 'redux-form'
import fetch from '@utils/request'
import api from '@api'
import {connect, useSelector} from 'react-redux'
import {Col, Row} from 'antd'
import {debounce} from '@utils/common'

const dicTypes = [{
  key: 'prime',
  title: 'Prime 标准字典'
}, {
  key: 'omop',
  title: 'OMOP 标准字典'
}]

// const dicTitle = {
//   prime: 'Prime 标准字典',
//   omop: 'OMOP 标准字典'
// }
const renderOutputsStandard = ({ fields, meta: { error }, num, dicList, dicList1, changeArray }) => {
  console.count('222')
  console.log('num', num)
  console.log('dicList', dicList)
  return (
    <>
      <p>输出标准</p>
      {fields.map((standard, index) => {
        const fieldItem = fields.get(index)
        console.log('fieldItem', fieldItem)
        return (
          <Row key={index} gutter={16}>
            <Col span={9}>
              <Field
                name={`${standard}.type`}
                component={ACheckbox}
                type="checkbox"
                normalize={value => value? dicTypes[index].key : null}
              >
                {dicTypes[index].title}
              </Field>
            </Col>
            {fieldItem.type && (
              <>
                <Col span={7}>
                  <Field
                    name={`${standard}.vocabulary`}
                    component={ASelect}
                    placeholder="请选择类型"
                    fieldNames={{label: 'name', value: 'code'}}
                    options={fieldItem.type === 'prime'?dicList:dicList1}
                    onFocus={(e) => e.preventDefault()}
                    onBlur={(e) => e.preventDefault()}
                    changeEvent={() => {
                      debounce( changeArray(
                        'tag',
                        `${standard}.sources[0]`,
                        []
                      ), 1000)
                    }}
                  />
                </Col>
                <Col span={8}>
                  <Field
                    name={`${standard}.sources[0]`}
                    component={ASelect}
                    placeholder="请选择版本"
                    options = {(fieldItem.type === 'prime'?dicList:dicList1)?.filter(item => item.code === fieldItem?.vocabulary)[0]?.sources.map(item => ({label: item, value: item}))}
                    onFocus={(e) => e.preventDefault()}
                    onBlur={(e) => e.preventDefault()}
                  />
                </Col>
              </>
            )}
          </Row>
        )
      })}
      {error && <li className="error">{error}</li>}
    </>
  )
}

let ExportWidget = ({namePath, outputType, changeArray}) => {
  console.log('outputType', outputType)
  // eslint-disable-next-line no-unused-vars
  const [dicList, setDicList] = useState([])
  // eslint-disable-next-line no-unused-vars
  const [dicList1, setDicList1] = useState([])
  const sysConst = useSelector((state) => state.app.sysConst)
  const exportOptions = sysConst.filter(item => item.type === 'engine_output_type')[0]?.valueNameVOS
  /*
  * 获取OMOP
  * */
  const fetchOMOPDicList = async () => {
    const res = await fetch({
      method: 'POST',
      url: api.dictionaryApi.treeList,
      data: {standardType: 'omop'}
    })
    // v1.8 版本，OMOP字典类型只支持icd10(诊断)
    setDicList1(res)
  }
  /*
  * 获取字典
  * */
  const fetchDicList = async () => {
    const res = await fetch({
      method: 'POST',
      url: api.dictionaryApi.treeList,
      data: {}
    })
    setDicList(res)
  }
  // useMemo(() => {
  //   debugger
  //   outputType === 'str' && changeArray(
  //     'tag',
  //     `${namePath}.standardOutputs`,
  //     dicTypes
  //   )
  // }
  // , [outputType])

  useEffect(() => {
    fetchDicList()
    fetchOMOPDicList()
  }, [])

  return (
    <>
      <Field
        label="选择输出类型"
        name={`${namePath}.outputType`}
        component={ASelect}
        placeholder="选择输出类型"
        fieldNames={{label: 'name'}}
        options={exportOptions}
        onFocus={(e) => e.preventDefault()}
        onBlur={(e) => e.preventDefault()}
        changeEvent={(value) => {
          value === 'str'&& changeArray(
            'tag',
            `${namePath}.standardOutputs`,
            dicTypes
          )
        }}
      />

      { outputType === 'custom' && (
        <Field
          label="输出内容"
          name={`${namePath}.defaultValue`}
          component={AInput}
          placeholder="请输入输出内容"
        />
      )}
      { outputType === 'str' && (
        <FieldArray name={`${namePath}.standardOutputs`} dicList={dicList} dicList1={dicList1} changeArray={changeArray} component={renderOutputsStandard} />
      )}
    </>
  )
}

const selector = formValueSelector('tag') // <-- same as form name
const mapStateToProps = (state, ownProps) => {
  const {namePath} = ownProps
  const outputType = selector(state, `${namePath}.outputType`)
  return {
    outputType,
  }
}
const mapDispatchToProps = {
  // NOTE: This MUST be aliased or it will not work. Thanks Jack!
  changeArray: change
}

ExportWidget = connect(mapStateToProps, mapDispatchToProps)(ExportWidget)
export default ExportWidget
