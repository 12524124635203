import { useState } from 'react'

const usePagination = (paginationOptions = {}) => {
  const {pageIndex, pageSize, ...rest} = paginationOptions
  const [page, setPage] = useState({
    pageIndex: pageIndex || 1,
    pageSize: pageSize || 10,
  })
  const [total, setTotal] = useState(0)
  const pagination = {
    total,
    current: page.pageIndex,
    pageSize: page.pageSize,
    showQuickJumper: true,
    pageSizeOptions: [10, 20, 50],
    showSizeChanger: true,
    showTotal: (total) => `共 ${total}条`,
    onChange: (page, pageSize) => {
      setPage({
        pageIndex: page,
        pageSize: pageSize,
      })
    },
    onShowSizeChange: (current, size) => {
      setPage({
        pageIndex: 1,
        pageSize: size,
      })
    },
    ...rest,
  }
  return [page, setPage, setTotal, pagination, total]
}

export default usePagination
