export const sorterTip = {
  ascend: {
    default: '点击升序',
    gender: '点击"女"上',
    provinceName: '首字拼音"a-z"',
    phone: '点击"正确手机号"上',
  },
  descend: {
    default: '点击降序',
    gender: '点击"男"上',
    provinceName: '首字拼音"z-a"',
    phone: '点击"存疑手机号"上',
  },
  cancel: {
    default: '取消排序',
  },
}

export const sorterNext = {
  ascend: 'descend',
  descend: 'cancel',
  cancel: 'ascend',
}

export const moduleMap = {
  dashBoardPatientList: '数据看板',
  filterStore: '筛选库',
  patientRetrieval: '患者检索',
  patientRecord: '患者档案',
  patientSearch: '患者搜索',
  smartRecommend: '智能推荐',
}

export const moduleTypeMap = {
  labelSearch: 1,
  smartRecommend: 2,
  filterStore: 3,
  patientSearch: 4,
  dashboard: 5,
}

export const moduleTypeName = {
  [moduleTypeMap.labelSearch]: '标签患者',
  [moduleTypeMap.smartRecommend]: '智能推荐',
  [moduleTypeMap.filterStore]: '筛选库',
  [moduleTypeMap.patientSearch]: '患者检索',
  [moduleTypeMap.dashboard]: '数据看板',
}

export const labelTypeEnum = {
  include: 1,
  exclude: 2,
  group: 3,
}

export const labelTypeMap = {
  [labelTypeEnum.include]: '纳入标签',
  [labelTypeEnum.exclude]: '排除标签',
  [labelTypeEnum.group]: '组合标签',
}

export const generateLabelParams = (labels, targetLabel) => {
  let result = labels
  if (targetLabel) {
    const targetIndex = labels.findIndex((item) => item.id === targetLabel)
    if (targetIndex > -1) {
      result = labels.slice(0, targetIndex + 1)
    }
  }
  const includeLabelArr = []
  const excludeLabelArr = []
  const labelGroupIArr = []
  result.forEach((item) => {
    if (item.type === labelTypeEnum.include) {
      includeLabelArr.push(item.id)
    } else if (item.type === labelTypeEnum.exclude) {
      excludeLabelArr.push(item.id)
    } else if (item.type === labelTypeEnum.group) {
      labelGroupIArr.push(item.id)
    }
  })
  return {
    includeLabelId: includeLabelArr.join(),
    excludeLabelId: excludeLabelArr.join(),
    labelGroupId: labelGroupIArr.join(),
  }
}
