import React from 'react'
import { Modal } from 'antd'
import { ReactComponent as CloseIcon } from '@assets/images/icons/modal_close.svg'
import './animation.css'

const CustomModal = (props) => {
  return (
    <Modal
      forceRender
      centered
      closeIcon={<CloseIcon />}
      transitionName="custom-slide-up"
      {...props}
    ></Modal>
  )
}

export default CustomModal
