import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle } from 'react'
import { Modal, Form, message, Space, Button, Select } from 'antd'
import {MinusCircleOutlined, FormOutlined} from '@ant-design/icons'
import LabelSelectModal from '../../components/newLabelSelectModal.js'
import fetch from '@utils/request'
import api from '@api'
import {useWindowSize} from '@hooks/useLayout'
import styles from '@views/project/components/index.module.less'

const LinkTagModal = (props, ref) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [intoAtomArr, setIntoAtomArr] = useState([])
  const [intoDeriveArr, setIntoDeriveArr] = useState([])
  const [outAtomArr, setOutAtomArr] = useState([])
  const [outDeriveArr, setOutDeriveArr] = useState([])
  // eslint-disable-next-line no-unused-vars
  const [selectedData, setSelectedData] = useState([1, 2])
  const [form] = Form.useForm()
  const labelSelectRef = useRef(null)
  const [,, scale] = useWindowSize()

  const getList = async() => {
    const res = await fetch({
      method: 'POST',
      url: api.projectApi.getLabelMap,
      data: {
        projectId: props.id
      }
    })
    console.log(res)
  }

  const handleOpen = (e) => {
    setIsModalVisible(true)
  }

  const handleValue = (e) => {
    form.setFieldsValue(e)
  }

  const handleOk = async() => {
    await form.submit()
  }

  const handleCancel = () => {
    form.resetFields()
    setIntoAtomArr([])
    setIntoDeriveArr([])
    setOutAtomArr([])
    setOutDeriveArr([])
    setIsModalVisible(false)
  }

  // 保存
  const onFinish = async(values) => {
    console.log('fds', values)
    const {intoAtom, outAtom} = values
    if ((!intoAtom || !intoAtom?.length) && (!outAtom || !outAtom?.length)) {
      return message.error('入组标签和排除标签不能都为空！')
    }
    const obj = {
      'included': {
        'meta': intoAtomArr.map(node => {
          return {
            id: node.id,
            version: node.version
          }
        }),
        'derived': intoDeriveArr.map(node => {
          return {
            id: node.id,
            version: node.version
          }
        }),
      },
      'excluded': {
        'meta': outAtomArr.map(node => {
          return {
            id: node.id,
            version: node.version
          }
        }),
        'derived': outDeriveArr.map(node => {
          return {
            id: node.id,
            version: node.version
          }
        }),
      }
    }
    await fetch({
      method: 'POST',
      url: api.projectApi.saveLabel,
      data: {
        projectId: props.id,
        projectLabelMapString: JSON.stringify(obj)
      }
    })
    message.success('分配成功')
    props.onSuccess()
    handleCancel()
  }

  // 打开选择弹窗
  // eslint-disable-next-line no-unused-vars
  const onselect = (e) => {
    labelSelectRef.current.handleOpen(e)
    switch (e) {
      case 1:
        labelSelectRef.current.handleValue(intoAtomArr)
        break
      case 2:
        labelSelectRef.current.handleValue(intoDeriveArr)
        break
      case 3:
        labelSelectRef.current.handleValue(outAtomArr)
        break
      case 4:
        labelSelectRef.current.handleValue(outDeriveArr)
        break
    }
  }

  // 确认选择，接受选中项
  const onSure = (e, i) => {
    // eslint-disable-next-line no-unused-vars
    const arr = e.map(node => {
      return {
        ...node,
        tag: node.name,
        type: 'boolean'
      }
    })
    console.log('eee', e)
    form.setFieldsValue({
      linkTags: arr
    })
    setSelectedData(e)
  }

  useEffect(() => {
    getList()
  }, [])

  useImperativeHandle(ref, () => ({
    handleOpen,
    handleValue
  }))
  if (!isModalVisible) return null
  return (
    <>
      <Modal
        width={640*scale}
        title="关联标签选择" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <Form
          form= {form}
          labelCol={{
            span: 4,
          }}
          wrapperCol={{
            span: 20,
          }}
          onFinish={onFinish}
        >
          <Form.Item
            label="关联表单"
          >
            <Form.Item
              name="intoAtom"
              style={{
                display: 'inline-block',
                width: 'calc(50% - 8px)',
              }}
            >
              <div>随防</div>
            </Form.Item>
            <Form.Item
              label="关联表单"
              name="intoAtom"
              style={{
                display: 'inline-block',
                width: 'calc(50% - 8px)',
                margin: '0 8px',
              }}
            >
              <div>随防</div>
            </Form.Item>
          </Form.Item>

          <Form.Item
            label="关联标签"
          >
            <Form.Item>
              <Button onClick={() => {
                onselect(1)
              }}>选择标签</Button>
            </Form.Item>
            <Form.List
              name="linkTags"
              initialValue={selectedData}
            >
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Space
                      key={key}
                      style={{
                        display: 'flex',
                        marginBottom: 8,
                      }}
                      align="baseline"
                    >
                      <Form.Item
                        {...restField}
                        name={[name, 'tag']}
                        rules={[
                          {
                            required: true,
                            message: '请选择标签',
                          },
                        ]}
                      >
                        <Select
                          style={{ minWidth: 160*scale }}
                          className={styles.select}
                          mode="multiple"
                          showSearch={false}
                          placeholder="请选择标签"
                          dropdownStyle={{'display': 'none'}}
                        ></Select>
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, 'type']}
                        rules={[
                          {
                            required: true,
                            message: '请选择输出方式',
                          },
                        ]}
                      >
                        <Select
                          defaultValue="boolean"
                          style={{ minWidth: 120*scale }}
                          options={[{ value: 'boolean', label: '输出1和0，以及值' }, { value: 'text', label: '输出文本' }]}
                        />
                      </Form.Item>
                      <MinusCircleOutlined onClick={() => remove(name)} />
                      <FormOutlined onClick={() => remove(name)} />
                    </Space>
                  ))}
                </>
              )}
            </Form.List>
          </Form.Item>
        </Form>
      </Modal>
      <LabelSelectModal ref={labelSelectRef} onSure={onSure} />
    </>
  )
}

export default forwardRef(LinkTagModal)
