import { Select, Spin } from 'antd'
import debounce from 'lodash/debounce'
import React, { useMemo, useRef, useState } from 'react'
const DebounceSelect = ({ fetchOptions, debounceTimeout = 800, ...props }) => {
  const [fetching, setFetching] = useState(false)
  const [options, setOptions] = useState([])
  const fetchRef = useRef(0)
  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1
      const fetchId = fetchRef.current
      setOptions([])
      setFetching(true)
      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return
        }
        setOptions(newOptions)
        setFetching(false)
      })
    }
    return debounce(loadOptions, debounceTimeout)
  }, [fetchOptions, debounceTimeout])
  return (
    <Select
      mode="SECRET_COMBOBOX_MODE_DO_NOT_USE"
      showSearch
      showArrow={false}
      filterOption={false}
      onSearch={debounceFetcher}
      onBlur={(data, d, df) => {
        console.log('onBlur::', data)
      }}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      {...props}
      options={options}
    />
  )
}

export default DebounceSelect
