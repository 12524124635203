/*
* 权限管理
* */
const permissionApi = {
  roleList: '/role/list', // 角色列表
  privileges: '/role/privileges', // 所有权限
  add: '/role/add', // 创建角色
  delete: '/role/delete', // 删除角色
  edit: '/role/edit' // 修改角色
}

export default permissionApi
