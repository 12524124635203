import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from 'react'
import { Modal, Col, Row, Spin, Table, message, Button } from 'antd'
import styles from './index.module.less'
import TreeData from './treeData'
import IcdTree from '@components/tree/icdTree'
import { useWindowSize } from '@hooks/useLayout'
import fetch from '@utils/request'
import api from '@api'
import usePagination from '@hooks/usePagination'

import DebounceSelect from '@components/DebounceSelect'
import Highlighter from 'react-highlight-words'
import { setItemCurTreeNode } from '@store/mapping'
import { useDispatch, useSelector } from 'react-redux'

const apiMap = {
  normal: 'dictionaryApi',
  omop: 'omopApi',
}

const extraParams = {
  normal: {},
  omop: { flat: true },
}

const EditModal = ({ onSuccess, type = 'normal' }, ref) => {
  const dispatch = useDispatch()
  const [dictionary, setDictionary] = useState({})
  const [tree, setTree] = useState([])
  const [icdArr, setIcdarr] = useState([])
  const [loading, setLoading] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const [, , scale] = useWindowSize()
  const [selectedKey, setSelectedKey] = useState('')
  const [visible, setVisible] = useState(false)
  const [record, setRecord] = useState(null)
  const [curTreeNode, setCurTreeNode] = useState(null)
  // 搜索框 value 实时
  const [searchText, setSearchText] = useState(null)
  const [triggerSearch, setTriggerSearch] = useState(false)
  // 搜索框 value 搜索结束
  const [searchOutText, setSearchOutText] = useState(null)
  const [page, setPage, setTotal, pagination] = usePagination()
  const cacheCurTreeNode = useSelector((state) => state.mapping.curTreeNode)
  const [treeDateSelectedKey, setTreeDateSelectedKey] = useState([])

  // eslint-disable-next-line no-unused-vars
  let clipboard = null

  const show = (record) => {
    setVisible(true)
    setRecord(record)
  }
  const hide = () => {
    setVisible(false)
  }

  useImperativeHandle(ref, () => ({
    show,
    hide,
  }))

  // 获取字典列表
  const getList = async () => {
    const res = await fetch({
      method: 'POST',
      url: api.dictionaryApi.treeList,
      data: {},
    })
    if (res && res.length > 0) {
      setTree(res)
    }
  }

  // 获取字典详情
  const getDictionary = async (e) => {
    if (!e || !e.key) return
    const pager = {
      pageNumber: page.pageIndex,
      pageSize: page.pageSize,
    }
    const { content, totalCount } = await fetch({
      method: 'POST',
      url: api[apiMap[type]].listDirectDescendant,
      data: {
        ...pager,
        parentCode: '',
        dictionaryCode: e?.key,
        ...extraParams[type],
      },
    })
    setIcdarr(content)
    setTotal(totalCount)
    setSelectedKey('')
    setDictionary(e)
    setExpanded(false)
    setLoading(false)
  }

  const treeSelect = (e) => {
    setPage({
      pageIndex: 1,
      pageSize: 10,
    })
    setLoading(true)
    // getDictionary(e.node)
    setCurTreeNode(e.node)
    setTriggerSearch(false)
    setSearchOutText(null)
    setSelectedKey(e.node.key)
  }

  const fetchSearchItemPage = async (e) => {
    if (!dictionary.key) {
      message.error('请先选择字典')
      return
    }
    setLoading(true)
    let pagerParam = {
      pageNumber: page.pageIndex,
      pageSize: page.pageSize,
    }
    const { content, totalCount } = await fetch({
      method: 'POST',
      url: api[apiMap[type]].searchItemPage,
      data: {
        searchText: e,
        dictionaryCode: dictionary.key,
        ...pagerParam,
        ...extraParams[type],
      },
    })
    setTotal(totalCount)
    setIcdarr(content)
    setExpanded(true)
    setLoading(false)
  }
  const handleTableChange = (pagination, filters, sorter, extra) => {
    setPage({
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
    })
  }
  const renderHighLight = (text, record, searchOutText) => {
    if (searchOutText) {
      return (
        <Highlighter
          highlightClassName="YourHighlightClass"
          searchWords={Array.from(new String(searchOutText))}
          autoEscape={true}
          textToHighlight={text}
        />
      )
    } else {
      return <div>{text}</div>
    }
  }

  const columns = [
    {
      title: 'code',
      dataIndex: 'key',
      key: 'key',
      render: (text, record) => renderHighLight(text, record, searchOutText),
    },
    {
      title: '值',
      dataIndex: 'title',
      key: 'title',
      width: 600,
      render: (text, record) => renderHighLight(text, record, searchOutText),
    },
  ]

  async function fetchSearchList(searchText) {
    const res = await fetch({
      method: 'POST',
      url: api[apiMap[type]].searchItemRecommend,
      data: {
        searchText: searchText,
        dictionaryCode: dictionary.key,
      },
    })
    return res.map((item) => ({
      label: item,
      value: item,
    }))
  }

  const submit = async () => {
    if (!selectedKey) {
      return message.error('请先选择映射类目！')
    }
    /* eslint-disable */
    const formData =
      type === 'omop'
        ? {
            id: record?.id,
            dictionaryCode: dictionary.key,
            omopConceptCode: selectedKey,
            standardConceptCode: record?.standardConceptCode,
          }
        : {
            id: record?.id,
            dictionaryCode: dictionary.key,
            conceptId: selectedKey,
          }
    /* eslint-disable */
    const url =
      type === 'normal' ? api.mappingApi.editMapping : api.omopApi.editMapping
    await fetch({
      method: 'POST',
      url,
      data: formData,
      headers: {
        'Content-Type': 'application/json',
      },
    })
    dispatch(setItemCurTreeNode(curTreeNode))
    setSelectedKey('')
    message.success('字典名称已更新')
    hide()
    typeof onSuccess === 'function' && onSuccess()
  }

  useEffect(() => {
    getList()
  }, [])

  useEffect(() => {
    let vocabulary = record?.currentVocabulary || record?.vocabulary
    const curTreeNode = tree?.filter((node) => {
      return node.code === vocabulary
    })
    if (curTreeNode.length) {
      setCurTreeNode({ ...curTreeNode[0], key: curTreeNode[0]?.code })
      setTreeDateSelectedKey([vocabulary])
    } else {
      setCurTreeNode(cacheCurTreeNode)
      setTreeDateSelectedKey([cacheCurTreeNode?.key])
      setExpanded(false)
    }

    setPage({
      pageIndex: 1,
      pageSize: 10,
    })
    setSearchText(null)
    setSearchOutText(null)
    setTriggerSearch(false)
    setLoading(true)
  }, [record])

  useEffect(() => {
    if (triggerSearch) {
      fetchSearchItemPage(searchText)
    } else {
      curTreeNode && getDictionary(curTreeNode)
    }
  }, [curTreeNode, page, triggerSearch, searchOutText])

  return (
    <Modal
      title={'编辑标准字典名称'}
      open={visible}
      onCancel={hide}
      width={1200 * scale}
      onOk={submit}
      centered
      destroyOnClose
    >
      <Row className={styles.tableSpace} gutter={24}>
        <Col flex={`${scale * 240}px`} className={styles.over}>
          <TreeData
            title={'全部字典'}
            treeSelect={treeSelect}
            tree={tree}
            defaultSelectedKeys={treeDateSelectedKey}
          />
        </Col>
        <Col flex={1}>
          <Row>
            <div className={styles.searchArea}>
              <div className={styles.icdTitle}>{dictionary?.name}</div>
              <div>
                <DebounceSelect
                  value={searchText}
                  placeholder="输入关键词搜索..."
                  fetchOptions={fetchSearchList}
                  onChange={(newValue) => {
                    console.log('onChange::', newValue)
                    setSearchText(newValue)
                  }}
                  onSelect={(newValue) => {
                    console.log('onSelect::', newValue)
                    setSearchOutText(newValue)
                    setTriggerSearch(true)
                  }}
                  style={{
                    width: `${210 * scale}px`,
                    marginRight: `${12 * scale}px`,
                  }}
                />
                <Button
                  type="primary"
                  onClick={() => {
                    setPage({
                      pageIndex: 1,
                      pageSize: 10,
                    })
                    setSearchOutText(searchText)
                    setTriggerSearch(true)
                  }}
                >
                  搜索
                </Button>
              </div>
            </div>
          </Row>
          <Spin spinning={loading}>
            {dictionary?.structure === 'tree' && type !== 'omop' && (
              <div>
                {/* <div className={styles.icdTitle}>{dictionary.name}</div> */}
                <div className={styles.icdSpace}>
                  <IcdTree
                    tree={icdArr}
                    highlight={searchOutText}
                    dictionary={dictionary}
                    onExpanded={expanded}
                    height={450 * scale}
                    autoExpandParent={searchOutText}
                    bounds={{
                      selectedKeys: [selectedKey],
                      onSelect: (keys) => {
                        setSelectedKey(keys[0])
                        setTimeout(() => {
                          console.log('selectedKey::', selectedKey)
                        })
                      },
                    }}
                  />
                </div>
              </div>
            )}
            {(dictionary?.structure !== 'tree' || type === 'omop') && (
              <Table
                size="small"
                columns={columns}
                dataSource={icdArr}
                onRow={(record) => {
                  return {
                    onClick: (event) => {
                      setSelectedKey(record?.key)
                    },
                  }
                }}
                rowClassName={(record) => {
                  return record?.key === selectedKey ? 'clickRowStyle' : ''
                }}
                onChange={handleTableChange}
                pagination={pagination}
              />
            )}
          </Spin>
        </Col>
      </Row>
    </Modal>
  )
}

export default forwardRef(EditModal)
