/*
* cdisc相关api
* */
const cdiscApi = {
  saveForm: '/kbms/cdisc/saveForm', // 保存表单
  saveField: '/kbms/cdisc/saveField', // 保存字段
  listPageForm: '/kbms/cdisc/listPageForm', // 分页查询表单
  listPageField: '/kbms/cdisc/listPageField', // 分页查询字段
  deleteField: '/kbms/cdisc/deleteField', // 删除字段
}

export default cdiscApi
