import { useLayoutEffect, useState } from 'react'

export function useWindowSize() {
  const [size, setSize] = useState([0, 0, 1])
  useLayoutEffect(() => {
    function updateSize() {
      let scale = document.documentElement.clientWidth / 1440
      scale = scale < 1 ? 1 : scale
      setSize([document.documentElement.clientWidth, document.documentElement.innerHeight, scale])
    }
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])
  return size
}
