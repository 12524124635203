import React, { useEffect, useRef, useState} from 'react'
import {
  Row,
  Col,
  Space,
  Modal,
  Table,
  Spin,
  message,
  notification,
  Select,
  Button,
} from 'antd'
const { Option } = Select
// const { TabPane } = Tabs
import {
  ExclamationCircleOutlined,
} from '@ant-design/icons'
import KeySearch from '@components/form/search'
import MenuTree from '@components/tree/menuTree'
import TypeModal from '@components/typeModal/index'
import styles from './index.module.less'
import classNames from 'classnames'
import { TAG_STATUS } from '@constants/common'
import { enumValueByKey } from '@utils/filter'
import usePagination from '@hooks/usePagination'
import TagEngineModal from '@views/tag/engineV2/index'
import fetch from '@utils/request'
import api from '@api'
import { permissionAuth, permissionStyle } from '@utils/permission'
import { useWindowSize } from '@hooks/useLayout'
import { useSearchParams, useNavigate } from 'react-router-dom'
import ConfirmModal from '@components/confirmModal'
import ImportProject from '@views/tag/components/importProject'
import ImportStatus from '@views/tag/components/importStatus'
import { useSelector } from 'react-redux'
import { exportExcel } from '@utils/export'
import moment from 'moment'
import ImportTags from './components/importTags'

const ProjectTag = () => {
  const [open, setOpen] = useState(false)
  const [load, setLoad] = useState(false)
  const tagEditRef = useRef(null)
  const searchRef = useRef(null)
  const importProjectRef = useRef(null)
  const importStatusRef = useRef(null)
  const confirmRef = useRef(null)
  const [status, setStatus] = useState('7')
  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([])
  const [projectList, setProjectList] = useState([])
  const [defaultProject, setDefaultProject] = useState(null)
  // eslint-disable-next-line no-unused-vars
  const [curProjectType, setCurProjectType] = useState('')
  const [select, setSelect] = useState(null) // 选择的树节点
  const [actionType, setActionType] = useState('add') // 选择的树节点
  const [orderBy, setOrderBy] = useState(null)
  // eslint-disable-next-line no-unused-vars
  const [curAddTag, setCurAddTag] = useState([]) // 当前添加标签，所在的树节点
  const [editRow, setEditRow] = useState(null) // 编辑标签规则
  const [file, setFile] = useState(null) // 缓存文件对象
  const [page, setPage, setTotal, pagination] = usePagination()
  const [, , scale] = useWindowSize()
  const [, setSelectedNode] = useState(null)
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const editFromIs = searchParams.get('editFromIs')
  const userInfo = useSelector((state) => state.app.userInfo)
  const typeRef = useRef(null)
  const menuTreeRef = useRef(null)
  const authItemKeys = [permissionAuth('CRF_PROJECT_LABEL_STORE_DIR_ADD'), permissionAuth('CRF_PROJECT_LABEL_STORE_DIR_ADD'), permissionAuth('CRF_PROJECT_LABEL_STORE_DIR_EDIT'), permissionAuth('CRF_PROJECT_LABEL_STORE_DIR_DELETE')]


  // const getLabelCount = async() => {
  //   const res = await fetch({
  //     method: 'GET',
  //     url: api.tagApi.labelCount,
  //     data: {}
  //   })
  //   console.log(res)
  //   tagCount[0].value = res.laberCount
  //   tagCount[1].value = res.laberMetaCount
  //   tagCount[2].value = res.laberDerivationCount
  //   setTagCount(tagCount)
  // }


  const getList = async (node) => {
    setLoading(true)
    const pager = {
      pageNumber: page.pageIndex,
      pageSize: page.pageSize,
    }
    const res = await fetch({
      method: 'POST',
      url: api.tagApi.getTagsByCategory,
      data: {
        categoryId: select,
        categoryType: curProjectType,
        checkStatus: status,
        keyword: searchRef.current.getValue(),
        proId: searchParams.get('projectId'),
        ...pager,
        orderBy,
        projectId: parseInt(curProjectType?.split('project_')[1])
      },
      headers: {
        'Content-Type': 'application/json'
      }
    })
    setList(res.content)
    setTotal(res.totalCount)
    setLoading(false)
  }

  const geProjectList = async (node) => {
    const res = await fetch({
      method: 'GET',
      url: api.tagApi.crfProjectList,
    })
    setProjectList(res)
    setDefaultProject(res[0]?.id)
    setCurProjectType(`project_${res[0]?.id}`)
  }

  // 树节点选择
  const treeSelect = async (e) => {
    setPage({
      pageIndex: 1,
      pageSize: 10,
    })
    if (!e.node.code) {
      setSelect(null)
      setList([])
      return
    }
    setSelect(e.node.code)
    setSelectedNode(e.node)
    setCurAddTag(e.node)
  }

  // 关键词搜索
  const keySearchClick = (e) => {
    if (!select) {
      return message.warn('请先选择节点！')
    }
    setPage({
      pageIndex: 1,
      pageSize: 10,
    })
  }
  const onEdit = (record, type) => {
    setActionType(type || 'edit')
    setEditRow(record)
    setLoad(true)
  }


  const beforeDoneEdit = () => {
    return new Promise((resolve, reject) => {
      if (editRow && editRow.isGroupCite === 1 && editFromIs === '1') {
        confirmRef.current.show({
          title: '编辑标签',
          content: '编辑此标签会导致相关组合标签失效，是否继续？',
          onOk: async () => {
            confirmRef.current.hide()
            resolve(true)
          },
          onCancel: () => {
            confirmRef.current.hide()
            resolve(false)
          },
        })
      } else {
        confirmRef.current.hide()
        resolve(true)
      }
    })
  }

  /*
   * 标签新增or编辑
   * */
  const doneEdit = async (canvasData) => {
    const {nodeDataArray, linkDataArray, remark, tagName} = canvasData
    if (!nodeDataArray?.length || !nodeDataArray) {
      return message.error('画布节点数据为空，请检查！')
    } else {
      const startNodeFlag = nodeDataArray.some(({ type }) => {
        return type === 'START'
      })
      if (!startNodeFlag) {
        return message.error('画布必须要有一个"START"节点')
      }
    }
    if (!linkDataArray.length) {
      return message.error('画布连线数据为空，请检查！')
    } else {
      const LinkEmptyFlag = linkDataArray.some(({ from, to }) => {
        return (!from && from !== 0) || (!to && to !== 0)
      })
      if (LinkEmptyFlag) {
        return message.error('连线操作有误，节点存在未连线，请检查！')
      }
    }
    const beforeFlag = await beforeDoneEdit()
    // 判断组合标签
    if (!beforeFlag) {
      tagEditRef.current.hide()
      if (editFromIs === '1') {
        navigate(-1)
        return
      }
    }
    try {
      await fetch({
        method: 'POST',
        url: api.tagApi.labelSave,
        data: {
          categoryId:
              (actionType === 'add' || actionType === 'copy') ? curAddTag.code : editRow.categoryId,
          categoryName:
              (actionType === 'add' || actionType === 'copy') ? curAddTag.name : editRow.categoryName,
          id: (actionType === 'add' || actionType === 'copy') ? '' : editRow.id,
          categoryType: curProjectType,
          name: tagName,
          remark: remark,
          ruleJson: {nodeDataArray: canvasData.nodeDataArray, linkDataArray: canvasData.linkDataArray },
          startTime: canvasData.startTime,
          version: (actionType === 'add' || actionType === 'copy') ? null : editRow.version,
          ruleSet: 'project',
          proId: searchParams.get('projectId'),
          type: searchParams.get('type'),
        },
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
      })
      message.success(`${actionType === 'edit'? '修改成功！': '添加成功！'}`)
      // tagEditRef.current.resetError()
      setOpen(false)
      if (editFromIs === '1') {
        navigate(-1)
        return
      }
      getList()
    } catch (e) {
      console.log('catch::', e)
      // tagEditRef.current.setError()
    }
  }

  const auditTag = async (type) => {
    await fetch({
      method: 'POST',
      url: api.tagApi.auditTag,
      data: {
        version: editRow.version,
        id: editRow.id,
        code: type,
      },
    })
    notification.success({
      message: '提示',
      description: type === 2 ? '标签审核通过！' : '标签已审核拒绝！',
    })
    getList()
    setOpen(false)
  }

  // table删除
  const onDel = (record) => {
    Modal.confirm({
      title: '确定删除该标签？',
      icon: <ExclamationCircleOutlined />,
      okText: '确认',
      cancelText: '取消',
      onOk: async () => {
        await fetch({
          method: 'POST',
          url: api.tagApi.deleteLabel,
          data: {
            labelId: record.id,
            version: record.version,
            proId: searchParams.get('projectId'),
            type: searchParams.get('type'),
          },
        })
        message.success('删除成功')
        await getList()
        menuTreeRef.current.refreshTree()
        // getLabelCount()
      },
    })
  }

  // 分类
  const setType = (e, id) => {
    typeRef.current.handleOpen(e, id)
  }


  const fresh = async(record, code) => {
    await fetch({
      method: 'POST',
      url: api.tagApi.labelDistribute,
      data: {
        id: record.id,
        categoryId: code
      }
    })
    message.success('操作成功！')
    getList()
  }

  const handleTableChange = (pagination, filters, sorter, extra) => {
    setPage({
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
    })
    if (sorter instanceof Array) {
      let sorterArr = sorter.map(({ columnKey, order }) => {
        return `${columnKey} ${order.slice(0, -3)}`
      })
      setOrderBy(sorterArr.join())
    } else {
      let sorterStr
      if (sorter.order) {
        sorterStr = `${sorter.columnKey} ${sorter?.order?.slice(0, -3)}`
      } else {
        sorterStr = ''
      }
      setOrderBy(sorterStr)
    }
  }

  // 增加标签
  const addTag = () => {
    if (!select) {
      return message.warn('请先选择节点！')
    }
    // showCanvas(selectedNode, 'add')

    // showCanvas(selectedNode, 'add')
    // tagEditRef.current.initModelJSON()
    setActionType('add')
    setLoad(true)
    setEditRow(null)
  }

  // eslint-disable-next-line no-unused-vars
  const contentDom = () => {
    return <div style={{ color: 'rgba(0, 0, 0, 0.45)' }}>标签上传中...</div>
  }
  /*
   * 导入Execl
   * */
  const importExcel = async (file, type) => {
    if (!file) {
      return
    }
    // importProjectRef.current.hide()
    const modal = Modal.success({
      title: '校验成功！',
      content: contentDom(),
      cancelText: () => <></>,
      okText: '取消',
      okType: 'default',
      width: '30%',
      footer: null,
      onOk: () => {
        return
      },
    })
    const formData = new FormData()
    formData.append('file', file)
    if (type) {
      formData.append('overrideType', type)
    }
    try {
      const { existingRules, success } = await fetch({
        url: api.tagApi.labelImport,
        data: formData,
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      modal.destroy()
      console.log('existingRules:;', existingRules)
      console.log('res:;', success)
      if (existingRules?.length) {
        importProjectRef.current.hide()
        importStatusRef.current.show(existingRules)
      } else {
        importProjectRef.current.hide()
        importStatusRef.current.hide()
        message.success(`导入完成${success}条`)
      }
      setPage({
        pageIndex: 1,
        pageSize: 10,
      })
    } catch (error) {
      modal.destroy()
    }
  }

  /*
   * 导入模版后，刷新列表
   * */
  const reload = async (file) => {
    setFile(file)
    importExcel(file)
  }

  useEffect(() => {
    if (select) {
      getList()
    }
  }, [page, select])
  useEffect(() => {
    setOpen(!!load)
  }, [load])
  useEffect(() => {
    // iscreen 编辑标签
    if (editFromIs === '1') {
      const labelRow = JSON.parse(localStorage.getItem('labelRow'))
      if (labelRow) {
        onEdit(labelRow)
      }
    }
  }, [editFromIs])

  useEffect(() => {
    geProjectList()
    console.log('window.__POWERED_BY_QIANKUN__', window.__POWERED_BY_QIANKUN__)
  }, [])

  let columns = [
    {
      title: '标签名',
      dataIndex: 'name',
      key: 'name',
      width: 150 * scale,
      ellipsis: true,
      sorter: {
        multiple: 1,
      }
    },
    {
      title: '规则',
      dataIndex: 'remark',
      key: 'remark',
      width: 150 * scale,
      ellipsis: true
    },
    {
      title: '编辑人',
      dataIndex: 'updatedBy',
      key: 'updatedBy',
      width: 100 * scale,
    },
    {
      title: '编辑时间',
      dataIndex: 'updatedTime',
      key: 'updated_time', // update_time
      width: 150 * scale,
      sorter: {
        multiple: 2,
      },
    },
    {
      title: '操作',
      key: 'action',
      fixed: 'right',
      width: 200 * scale,
      render: (_, record) => {
        // 有审核权限才能编辑、删除所有编辑，否则（如有权限）只能编辑、删除自己创建的标签
        if (window.__POWERED_BY_QIANKUN__ || userInfo.username === record.createdBy || permissionAuth('CRF_PROJECT_LABEL_AUDIT')) {
          return (
            <Space size="middle">
              <a
                onClick={() => onEdit(record)}
                style={permissionStyle('CRF_PROJECT_LABEL_EDIT')}
              >
                编辑
              </a>
              <a
                onClick={() => onDel(record)}
                style={permissionStyle('CRF_PROJECT_LABEL_DELETE')}
              >
                删除
              </a>
              <a
                onClick={() => setType(2, record)}
                style={permissionStyle('CRF_PROJECT_LABEL_MOVE')}
              >
                分类
              </a>
              <a
                onClick={() => onEdit(record, 'copy')}
                style={permissionStyle('CRF_PROJECT_LABEL_COPY')}
              >
                复制
              </a>
            </Space>
          )
        } else {
          return null
        }
      },
    },
  ]
  if (!window.__POWERED_BY_QIANKUN__) {
    columns.splice(
      2,
      0,
      {
        title: '状态',
        dataIndex: 'checkStatus',
        key: 'checkStatus',
        width: 150 * scale,
        render: (text) => {
          const statusClass = classNames({
            [styles.statusGreen]: Number(text) === 2,
            [styles.statusRed]: Number(text) === 4,
          })
          return (
            <div className={statusClass}>
              {enumValueByKey(text, TAG_STATUS)}
            </div>
          )
        },
      },
      {
        title: '创建人',
        dataIndex: 'createdBy',
        key: 'createdBy',
        width: 100 * scale,
      },
      {
        title: '审核人',
        dataIndex: 'auditBy',
        key: 'auditBy',
        width: 100 * scale,
      },
      {
        title: '方式',
        dataIndex: 'source',
        key: 'source',
        width: 100 * scale,
        render: (text) => {
          return <div>{text === 1 ? '添加' : '导入'}</div>
        },
      }
    )
  }

  const exportTag = async () => {
    const project = projectList.find((item) => item.id === defaultProject)
    try {
      const res = await fetch({
        url: api.projectApi.ruleCrfProjectExport,
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: {
          projectId: project.id,
          projectName: project.projectName,
        },
        responseType: 'blob',
      })
      if (res.type === 'application/json') {
        const result = JSON.parse(await res.text())
        if (result.code === 0) {
          message.error(result.message)
        }
      } else {
        const today = moment().format('YYYY-MM-DD')
        exportExcel(`${project.projectName}${today}`, res)
        message.success('导出成功!')
      }
    } catch (error) {}
  }

  const importTagsRef = useRef(null)

  return (
    <>
      {/* 标题栏 */}
      <div className="head">
        <h3 className="head-title">项目库</h3>
        <Space>
          <Button
            onClick={()=>{
              importTagsRef.current.show()
            }}
            style={permissionStyle('CRF_PROJECT_LABEL_STORE_IMPORT')}
            disabled={!defaultProject}>导入标签</Button>
          <Button
            onClick={exportTag}
            style={permissionStyle('CRF_PROJECT_LABEL_STORE_EXPORT')}
            disabled={!defaultProject}>导出标签</Button>
        </Space>
      </div>
      {/* 标签库 */}
      <Row className={styles.rowSpace} gutter={16}>
        <Col xxl={{ span: 4 }} lg={{ span: 6 }}>
          <MenuTree authItemKeys={authItemKeys} ref={menuTreeRef} title={'标签库'} showNumber type={curProjectType} search={true} treeSelect={treeSelect} />
        </Col>
        <Col xxl={{ span: 20 }} lg={{ span: 18 }}>
          <div className={classNames([styles.search])}>
            <div className={styles.spaceRight}>
              {!window.__POWERED_BY_QIANKUN__ && (
                <>
                  <Space style={{ marginRight: 30 }}>
                    <div>项目：</div>
                    <Select
                      className={styles.searchWidth}
                      placeholder="请选择项目"
                      value={defaultProject}
                      onChange={(e) => {
                        setCurProjectType(`project_${e}`)
                        menuTreeRef.current.onSelectClear()
                        setDefaultProject(e)
                        setList([])

                      }}
                      options={projectList}
                      fieldNames={{value: 'id', label: 'projectName'}}
                    />
                  </Space>
                  <Space style={{ marginRight: 30 }}>
                    <div>状态：</div>
                    <Select
                      className={styles.searchWidth}
                      value={status}
                      placeholder="请选择状态"
                      onChange={(e) => setStatus(e)}
                    >
                      {TAG_STATUS.map((item) => (
                        <Option key={item.value}>{item.desc}</Option>
                      ))}
                    </Select>
                  </Space>
                </>
              )}
              <KeySearch ref={searchRef} keySearchClick={keySearchClick} />
            </div>
            <Space>
              <Button
                style={permissionStyle('CRF_PROJECT_LABEL_ADD')}
                onClick={addTag}
                type="primary"
              >
                新增
              </Button>
            </Space>
          </div>
          <Spin spinning={loading}>
            <Table
              rowKey="id"
              columns={columns}
              dataSource={list}
              pagination={pagination}
              scroll={{ x: scale * 1000 }}
              onChange={handleTableChange}
            />
          </Spin>
        </Col>
      </Row>

      {(open || load) && (
        <TagEngineModal
          source="default"
          ref={tagEditRef}
          actionType={actionType}
          auditTag={auditTag}
          onSubmit={doneEdit}
          editRow={editRow}
          open={open}
          onCancel={() => setOpen(false)}
          afterClose={() => setLoad(false)}
          onHide={() => {
            if (editFromIs === '1') {
              navigate(-1)
            }
          }}
        />
      )}

      <ConfirmModal ref={confirmRef} />
      <ImportProject ref={importProjectRef} onSuccess={reload} />
      <ImportStatus
        ref={importStatusRef}
        callback={(type) => {
          importExcel(file, type)
        }}
      />
      <TypeModal ref={typeRef} type={curProjectType} onSuccess={fresh} />
      <ImportTags ref={importTagsRef} onSuccess={() => {
        menuTreeRef.current.refreshTree()
      }} projectId={defaultProject}/>
    </>
  )
}

export default ProjectTag
