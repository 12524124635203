import React, { useState, useEffect, useRef } from 'react'
import { Button, Table, Space, Modal, message } from 'antd'
import styles from './index.module.less'
import classNames from 'classnames'
import Edit from './components/edit'
import Publish from './components/publish'
import { useNavigate } from 'react-router-dom'
import fetch from '@utils/request'
import api from '@api'
import usePagination from '@hooks/usePagination'
import { TYPE } from './constants'
import { useWindowSize } from '@hooks/useLayout'
import { permissionStyle } from '@utils/permission'

const type = TYPE.AE

const AE = () => {
  const [, , scale] = useWindowSize()
  const navigate = useNavigate()
  const editRef = useRef(null)
  const publishRef = useRef(null)

  const [data, setData] = useState([])
  const [page, setPage, setTotal, pagination] = usePagination()
  const [loading, setLoading] = useState(false)

  const handleTableChange = (pagination, filters, sorter) => {
    setPage({
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
    })
  }
  const fetchData = async () => {
    setLoading(true)
    const { content, totalCount } = await fetch({
      url: api.aeApi.pageQuery,
      method: 'POST',
      data: {
        pageNumber: page.pageIndex,
        pageSize: page.pageSize,
        type,
      },
    })
    setData(content)
    setTotal(totalCount)
    setLoading(false)
  }
  useEffect(() => {
    fetchData()
  }, [page])

  const edit = (record) => {
    editRef.current.show(record)
  }
  const publish = (record, cancel = false) => {
    publishRef.current.show(record, cancel)
  }
  const del = (record) => {
    Modal.confirm({
      title: '提示',
      content: '确认删除此规则？',
      onOk: async () => {
        await fetch({
          url: api.aeApi.remove,
          method: 'POST',
          data: {
            idList: record.id,
          },
        })
        message.success('删除成功')
        if (data.length === 1 && page.pageIndex > 1) {
          setPage({
            pageIndex: page.pageIndex - 1,
            pageSize: page.pageSize,
          })
        } else {
          fetchData()
        }
      },
    })
  }
  const columns = [
    {
      title: '规则编号',
      dataIndex: 'ruleCode',
      key: 'ruleCode',
    },
    {
      title: '规则类型',
      dataIndex: 'ruleTypeName',
      key: 'ruleTypeName',
    },
    {
      title: '规则名称',
      dataIndex: 'ruleName',
      key: 'ruleName',
    },
    {
      title: '规则描述/智能推荐理由',
      dataIndex: 'ruleDesc',
      key: 'ruleDesc',
    },
    {
      title: '关联的标签',
      dataIndex: 'tageVoList',
      key: 'tageVoList',
      render(text, record) {
        return text && text.map((item) => item.tagName).join('，')
      },
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      width: 350 * scale,
      fixed: 'right',
      render(text, record) {
        return (
          <Space>
            <a
              onClick={() => edit(record)}
              style={permissionStyle('AE_CONFIG_EDIT')}
            >
              编辑
            </a>
            <a
              onClick={() => publish(record, false)}
              style={permissionStyle('AE_CONFIG_ONLINE')}
            >
              发布项目
            </a>
            <a
              onClick={() => publish(record, true)}
              style={permissionStyle('AE_CONFIG_OFFLINE')}
            >
              取消发布
            </a>
            <a
              onClick={() => {
                navigate(`/tagConfig/linkTag?ruleId=${record.id}`)
              }}
              style={permissionStyle('AE_CONFIG_BIND')}
            >
              关联标签
            </a>
            <a
              onClick={() => del(record)}
              style={permissionStyle('AE_CONFIG_DELETE')}
            >
              删除
            </a>
          </Space>
        )
      },
    },
  ]
  return (
    <>
      <div className={classNames('head', styles.header)}>
        <Button
          type="primary"
          onClick={() => editRef.current.show()}
          style={permissionStyle('AE_CONFIG_ADD')}
        >
          新增
        </Button>
      </div>
      <div className={styles.tableSpace}>
        <Table
          columns={columns}
          dataSource={data}
          onChange={handleTableChange}
          pagination={pagination}
          rowKey={(record) => record.id}
          scroll={{ x: 1600 }}
          loading={loading}
        />
      </div>
      <Edit
        ref={editRef}
        projectType={type}
        onSuccess={() =>
          setPage({
            pageIndex: 1,
            pageSize: 10,
          })
        }
      />
      <Publish ref={publishRef} onSuccess={fetchData} />
    </>
  )
}

export default AE
