import axios from 'axios'
import { notification } from 'antd'
import { baseHttpURL } from '@config'
import qs from 'qs'
// import {useSelector} from "react-redux";
let authError = false
// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  baseURL: baseHttpURL,
  timeout: 600000, // 请求超时时间
})

// 异常拦截处理器
const errorHandler = (error) => {
  if (error.response) {
    if (error.response.status === 401) {
      if (!authError) {
        authError = true
        notification.error({
          message: '提示！',
          description: '账号长时间未操作，请重新登录',
        })
      }
      // 跳转至登录页面
      setTimeout(() => {
        authError = false
        const loginPath = !window.__POWERED_BY_QIANKUN__
          ? '/kbms/login'
          : '/login'
        window.location.href = loginPath
      }, 1500)
    }
    if (error.response.status === 504) {
      notification.error({
        message: '网络超时',
        description: '请稍后再试',
      })
    }
  }
  return Promise.reject(error)
}

// request interceptor
request.interceptors.request.use((config) => {
  if (!config.headers['Content-Type']) {
    config.headers['Content-Type'] = 'multipart/form-data'
    // config.data = qs.stringify(config.data)
  }

  if (config.headers['Content-Type'] === 'application/x-www-form-urlencoded') {
    config.data = qs.stringify(config.data)
  }

  // eslint-disable-next-line no-debugger
  // const token = useSelector((state)=> state.app.token)
  let localtorage = JSON.parse(localStorage.getItem('persist:root'))
  let { token } = JSON.parse(localtorage.app)
  // 如果 token 存在
  // 让每个请求携带自定义 token 请根据实际情况自行修改
  if (token) {
    config.headers['token'] = token
    config.headers['Authorization'] = 'bearer ' + token
  }
  return config
}, errorHandler)

// response interceptor
request.interceptors.response.use((response) => {
  const {
    status,
    data: { code, data, message },
  } = response
  // eslint-disable-next-line no-debugger
  return new Promise((resolve, reject) => {
    if (
      response.config.url === '/kbms/rule/export' ||
      response.config.url === '/kbms/mapping/export' ||
      response.config.url === '/kbms/rule/statistics/export' ||
      response.config.url === '/kbms/label/download' ||
      response.config.url === '/kbms/mapping/template' ||
      response.config.url === '/kbms/thesaurus/export' ||
      response.config.url === '/kbms/rule/crf/project/export'
    ) {
      // 导出接口 特殊处理
      return resolve(response.data)
    }

    if (status === 200) {
      if (code === 200) {
        return resolve(data)
      } else if (code === 401) {
        if (!authError) {
          authError = true
          notification.error({
            message: '提示！',
            description: '账号长时间未操作，请重新登录',
          })
        }
        setTimeout(() => {
          authError = false
          const loginPath = !window.__POWERED_BY_QIANKUN__
            ? '/kbms/login'
            : '/login'
          window.location.href = loginPath
        }, 1500)
        reject(response.data)
      } else {
        notification.error({
          message: '错误',
          description: message,
        })
        reject(response.data)
      }
    } else {
      notification.error({
        message: '错误',
        description: message,
      })
      reject(response)
    }
  })
}, errorHandler)

const fetch = (config) => {
  return new Promise((resolve, reject) => {
    request(config)
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export default fetch
