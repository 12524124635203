const baseSize = 100
let scale
// 设置 rem 函数
function setRem() {
  // 当前页面宽度相对于 1440 宽的缩放比例，可根据自己需要修改。
  scale = document.documentElement.clientWidth / 1440
  scale = scale < 1 ? 1 : scale

  // 如果是嵌入页面，则不需要响应式
  if (window.__POWERED_BY_QIANKUN__) {
    scale = 1
  }
  // 设置页面根节点字体大小
  document.documentElement.style.fontSize = baseSize * scale + 'px'
  if (scale === 1) {
    document.documentElement.style.minWidth = '1440px'
    document.documentElement.style.overflowX = 'scroll'
  } else {
    document.documentElement.style.minWidth = 'auto'
    document.documentElement.style.overflowX = 'none'
  }
}
// 初始化
setRem()
// 改变窗口大小时重新设置 rem
window.onresize = function () {
  setRem()
}

export default scale
