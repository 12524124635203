import React, {useState} from 'react'
// eslint-disable-next-line no-unused-vars
import {Transfer, Tree, theme, Card, Button} from 'antd'
import StockTagTree from './stockTagTree'
// eslint-disable-next-line no-unused-vars
const generateTree = (treeNodes = [], checkedKeys = []) =>
  treeNodes.map(({ children, ...props }) => ({
    ...props,
    disabled: checkedKeys.includes(props.code),
    children: generateTree(children, checkedKeys),
  }))
// eslint-disable-next-line no-unused-vars
const isChecked = (selectedKeys, eventKey) => selectedKeys.includes(eventKey)
const TreeTransfer = ({ onChange, targetKeys, keyword }) => {
  const transferDataSourceCache = []
  const [transferDataSource, setTransferDataSource] = useState(transferDataSourceCache)
  // const { token } = theme.useToken()
  function flatten(list = []) {
    list.forEach((item) => {
      transferDataSourceCache.push(item)
      flatten(item.children)
    })
    return list
  }
  console.log('targetKeys', targetKeys)
  const labelNode = (type) => {
    if (type === 'left') {
      return <div>原子标签</div>
    } else if (type === 'right') {
      return <div>已选中的标签</div>
    } else {
      return null
    }
  }
  const updateTransferData = (data) => {
    flatten(data)
    setTransferDataSource((prevState) => Array.from(new Set([...prevState, ...transferDataSourceCache])))
  }
  return (
    <Transfer
      targetKeys={targetKeys}
      onChange={(keys) => {
        onChange(keys, transferDataSource)
      }}
      rowKey={(record) => record.id}
      dataSource={transferDataSource}
      className="tree-transfer"
      render={(item) => item.name}
      showSelectAll={false}
      oneWay={true}
      selectAllLabels={[labelNode('left'), labelNode('right')]}
    >
      {({ direction, onItemSelect, selectedKeys }) => {
        if (direction === 'left') {
          // eslint-disable-next-line no-unused-vars
          const checkedKeys = [...selectedKeys, ...targetKeys]
          return (
            <div
              style={{
                padding: 10,
                maxHeight: '70vh',
                overflow: 'auto'
              }}
            >
              <StockTagTree
                keyword={keyword}
                targetKeys={targetKeys}
                checkStrictly
                checkable
                checkedKeys={checkedKeys}
                updateTransferData={updateTransferData}
                onCheck={(_, { node: { key } }) => {
                  console.log('node.key:', key)
                  onItemSelect(key, !isChecked(checkedKeys, key))
                }}
              />
            </div>
          )
        }
      }}
    </Transfer>
  )
}

export default TreeTransfer
