import React, { useState } from 'react'
import styles from './index.module.less'
import classNames from 'classnames'

const KeySelect = ({ options, onChange, value }) => {
  const [expand, setExpand] = useState(false)
  return (
    <div className={classNames(styles.select, expand && styles.expand)}>
      {options.map((item) => {
        return (
          <span
            key={item.value}
            onClick={() => {
              if (item.value === 'all') {
                onChange && onChange([item.value])
                return
              }
              const index = value.findIndex((n) => n === item.value)
              if (index > -1) {
                value.splice(index, 1)
              } else {
                value.push(item.value)
              }
              const newValue = value.filter((n) => n !== 'all')
              onChange && onChange(newValue)
            }}
            className={classNames(
              styles.item,
              value.find((n) => n === item.value) && styles.active
            )}
          >
            {item.label}
          </span>
        )
      })}
      <a onClick={() => setExpand(!expand)} className={styles.trigger}>
        {expand ? '收起' : '展开'}
      </a>
    </div>
  )
}

export default KeySelect
