/*
* 药品说明书，text格式
* */
import React from 'react'
import styles from './index.module.less'
const DESC = (props) => {
  const {
    aliasName, //	别名	string
    clinicalSignificance, //	临床意义	string
    contraindication, //	禁忌症	string
    departGroup, //	科室分类	string
    englishName, //	英文名称	string
    indication, //	适用症	string
    method, //	方法	string
    normalValue, //	正常值	string
    projectGroup, //	项目分类	string
    projectName, //	项目名称	string
    summary, //	概述	string
  } = props.sourceData
  return (
    <div>
      <div className={styles.titleWrapper}>
        检查库详情
      </div>
      <div className={styles.section}>
        <p>
          <div className={styles.bold}>【项目名称】</div>
          <div dangerouslySetInnerHTML={{ __html: projectName }} />
        </p>
        <p>
          <div className={styles.bold}>【英文名称】</div>
          <div dangerouslySetInnerHTML={{ __html: englishName }} />
        </p>
        <p>
          <div className={styles.bold}>【别名】</div>
          <div dangerouslySetInnerHTML={{ __html: aliasName }} />
        </p>
        <p>
          <div className={styles.bold}>【科室分类】</div>
          <div dangerouslySetInnerHTML={{ __html: departGroup }} />
        </p>
        <p>
          <div className={styles.bold}>【项目分类】</div>
          <div dangerouslySetInnerHTML={{ __html: projectGroup }} />
        </p>

        <p>
          <div className={styles.bold}>【概述】</div>
          <div dangerouslySetInnerHTML={{ __html: summary }} />
        </p>
        <p>
          <div className={styles.bold}>【适应症】</div>
          <div dangerouslySetInnerHTML={{ __html: indication }} />
        </p>
        <p>
          <div className={styles.bold}>【禁忌症】</div>
          <div dangerouslySetInnerHTML={{ __html: contraindication }} />
        </p>
        <p>
          <div className={styles.bold}>【方法】</div>
          <div dangerouslySetInnerHTML={{ __html: method }} />
        </p>
        <p>
          <div className={styles.bold}>【正常值】</div>
          <div dangerouslySetInnerHTML={{ __html: normalValue }} />
        </p>
        <p>
          <div className={styles.bold}>【临床意义】</div>
          <div dangerouslySetInnerHTML={{ __html: clinicalSignificance }} />
        </p>
      </div>
    </div>
  )
}

export default DESC
