import React, {
  forwardRef,
  useRef,
  useImperativeHandle,
  useState,
  useCallback, useEffect, lazy,
} from 'react'
import FlowDiagram from '@views/tag/engine/components/index'
import {Button, Form, Input, Modal, message, Row, Col} from 'antd'
import { useWindowSize } from '@hooks/useLayout'
import { permissionStyle } from '@utils/permission'
import DragList from '@views/tag/engine/components/dragList'
const LabelSelectModal = lazy(() => import('@views/project/components/labelSelectModaV1'))

import fetch from '@utils/request'
import api from '@api'
import {cloneDeep} from 'lodash'
import SubjectModal from '@views/project/crf-enter/components/subjectModal'
import PatientRecordModal from '@views/project/crf-enter/components/patientRecordModal'
import RadioTag from '@components/radio-tag'
import {findVal, traverse1} from '@utils/common'

const TagEngineModal = ({open, onCancel, afterClose, ...restProps}, ref) => {
  const flowDiagramRef = useRef(null)
  const [visible, setVisible] = useState(false)
  const [, , scale] = useWindowSize()
  const [form] = Form.useForm()
  const [errClass, setErrClass] = useState('')
  const labelSelectRef = useRef(null)
  const subjectRef = useRef(null)
  const patientRecordRef = useRef(null)
  const [namePath, setNamePath] = useState([])
  const [linkDataArray, setLinkDataArray] = useState([])
  const [nodeDataArray, setNodeDataArray] = useState([])
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [fields, setFields] = useState([{
    name: ['tagName'],
    value: '',
  }, {
    name: ['remark'],
    value: '',
  }, {
    name: ['nodeDataArray'],
    value: [],
  }])
  const [selectedNode, setSelectedNode] = useState(null)
  const [subjects, setSubjects] = useState([])
  const [selectedSubject, setSelectedSubject] = useState([])
  const [sysTime, setSysTime] = useState('')
  // antd modal 头、尾高度
  const antdModalHeaderHeight = 55
  const antdModalFooterHeight = 53
  // eslint-disable-next-line no-unused-vars
  const scaleWidth = (antdModalHeaderHeight + antdModalFooterHeight) * scale
  // eslint-disable-next-line no-unused-vars
  const setError = () => {
    setErrClass('ant-input ant-input-status-error')
  }
  const resetError = () => {
    setErrClass('')
  }
  const show = () => {
    setVisible(true)
  }
  const hide = () => {
    form.resetFields()
    flowDiagramRef.current?.initDiagramData()
    setVisible(false)
  }

  /*
     * 画布配置完成，透传数据
     * */
  // eslint-disable-next-line no-unused-vars
  const doneCanvas = async () => {
    const values = form.getFieldValue()
    const {nodeDataArray, remark, tagName} = values
    let { linkDataArray } = flowDiagramRef.current.getModelJson()
    if (!nodeDataArray?.length || !nodeDataArray) {
      return message.error('画布节点数据为空，请检查！')
    } else {
      const startNodeFlag = nodeDataArray.some(({ type }) => {
        return type === 'START'
      })
      if (!startNodeFlag) {
        return message.error('画布必须要有一个"START"节点')
      }
    }
    if (!linkDataArray.length) {
      return message.error('画布连线数据为空，请检查！')
    } else {
      const LinkEmptyFlag = linkDataArray.some(({ from, to }) => {
        return (!from && from !== 0) || (!to && to !== 0)
      })
      if (LinkEmptyFlag) {
        return message.error('连线操作有误，节点存在未连线，请检查！')
      }
    }
    restProps.doneEdit({linkDataArray, nodeDataArray, remark, tagName, startTime: new Date().getTime()})
    // 在外层调用hide方法，关闭弹窗
    // hide()
  }

  // eslint-disable-next-line no-unused-vars
  const onFinish = () => {
    doneCanvas()
  }
  // eslint-disable-next-line no-unused-vars
  const handleCancel = () => {
    const flag = flowDiagramRef.current.ifCanvasChanged()
    if (flag) {
      Modal.confirm({
        title: '提示！',
        content: '您所配置的标签规则有变动，关闭弹窗数据将不会被保存！',
        okText: '确认关闭',
        cancelText: '继续编辑',
        width: '30%',
        onCancel: () => {
          return
        },
        onOk: () => {
          hide()
          typeof restProps?.onHide === 'function' && restProps.onHide()
        },
      })
    } else {
      hide()
      typeof restProps?.onHide === 'function' && restProps.onHide()
    }
  }
  const auditTag = (type) => {
    restProps.auditTag(type)
  }
  const updateModal = useCallback((params) => {
    form.setFieldsValue(params)
  })

  const asyncFormDataToCanvas = useCallback(()=>{
    const formData = form.getFieldValue('nodeDataArray')
    const canvasData = nodeDataArray
    const cloneFormData = cloneDeep(formData)
    canvasData?.forEach((node, index) => {
      flowDiagramRef.current.insetPropertyToNode(node.key, cloneFormData[index]?.data || null)
    })
    setTimeout(() => {
      // eslint-disable-next-line no-unused-vars
      let { nodeDataArray, linkDataArray } = flowDiagramRef.current.getModelJson()
      setNodeDataArray(nodeDataArray)
    }, 200)
  }, [nodeDataArray])

  const findPreNode = useCallback((curNodeKey)=>{
    const preNode = flowDiagramRef.current.findNodesConnected(curNodeKey)
    return preNode
  }, [linkDataArray])


  const onSure = (targetNodes) => {
    form.setFieldValue(
      [...namePath, 'ruleName'], targetNodes.map(item => item.name)[0]
    )
    form.setFieldValue(
      [...namePath, 'ruleVersion'], targetNodes.map(item => item.version)[0]
    )
    form.setFieldValue(
      [...namePath, 'ruleId'], targetNodes.map(item => item.id)[0]
    )
    setTimeout(() => {
      asyncFormDataToCanvas()
    }, 100)
  }

  const showLabelModal = (namePath) => {
    setNamePath(namePath)
    labelSelectRef.current.handleOpen()
  }

  const onModelChange = ({linkDataArray, nodeDataArray}) => {
    setLinkDataArray(linkDataArray)
    setNodeDataArray(nodeDataArray)
  }

  /*
* 获取系统时间
* */
  const fetchSysTime = async () => {
    const res = await fetch({
      method: 'POST',
      url: api.tagApi.statisticsTime,
    })
    setSysTime(res)
  }
  const getSysTime = () => {
    return sysTime
  }
  const initModelJSON = () => {
    setLinkDataArray([])
    setNodeDataArray([])
    updateModal({
      'tagName': '',
      'remark': ''
    })
  }
  /*
* 获取标签详情
* */
  const fetchTagRuleDetail = async () => {
    setLoading(true)
    const {id, version} = restProps.editRow
    const res = await fetch({
      method: 'POST',
      url: api.tagApi.getLabelDetail,
      data: {
        id,
        version
      }
    })
    setLoading(false)
    traverse1(res)
    console.log('res::', res)
    updateModal({
      'tagName': res.name,
      'remark': res.remark
    })

    // eslint-disable-next-line no-unused-vars
    let {linkDataArray, nodeDataArray} = res.ruleJson
    setTimeout(() => {
      flowDiagramRef.current?.initDiagramData({
        class: 'GraphLinksModel',
        linkKeyProperty: 'key',
        linkDataArray,
        nodeDataArray
      })
    }, 0)

    setTimeout(() => {
      setLinkDataArray(linkDataArray)
      setNodeDataArray(nodeDataArray)
    }, 100)
  }
  const selectedSubjects = (data) => {
    const {id, version} = restProps.editRow
    const ruleIdAndVersionList = [`${id}#${version}`]
    patientRecordRef.current.handleOpen(ruleIdAndVersionList)
    setSelectedSubject(data[0])
    setSubjects(data)
  }

  const dataValidate = async () => {
    subjectRef.current.handleOpen()
  }

  const onTagClickEvent = (data) => {
    setSelectedSubject(data)
  }

  useImperativeHandle(ref, () => ({
    show,
    auditTag,
    hide,
    setError,
    resetError,
    getSysTime,
    initModelJSON,
  }))
  /*
  * 获取标签规则详情，初始化画布
  * */
  useEffect(() => {
    if (restProps?.editRow){
      fetchTagRuleDetail()
    } else {
      flowDiagramRef.current?.initDiagramData()
    }
    fetchSysTime()
  }, [restProps?.editRow])
  let footerBtns = [
    <Button
      key="submit"
      type="primary"
      onClick={() => {
        form.submit()
      }}
    >
      保存
    </Button>,
  ]

  if (
    restProps.source === 'default' &&
      restProps.actionType === 'edit' &&
      restProps?.editRow?.checkStatus === 1
  ) {
    footerBtns = [
      <Button
        key="pass"
        onClick={() => {
          auditTag(2)
        }}
        style={permissionStyle('LABEL_UPDATE_STATUS')}
      >
        审核通过
      </Button>,
      <Button
        key="fail"
        danger
        onClick={() => {
          auditTag(4)
        }}
        style={permissionStyle('LABEL_UPDATE_STATUS')}
      >
        审核拒绝
      </Button>,
      <Button
        key="validate"
        ghost
        type="primary"
        onClick={() => {
          dataValidate()
        }}
        style={permissionStyle('SAVE_LABEL')}
      >
        数据验证
      </Button>,
      <Button
        key="submit"
        type="primary"
        onClick={() => {
          form.submit()
        }}
        style={permissionStyle('SAVE_LABEL')}
      >
        保存
      </Button>,
    ]
  }
  return (
    <Modal
      width="100%"
      open={open}
      onCancel={onCancel}
      onOk={onCancel}
      afterClose={afterClose}
      title={restProps.editRow ? '编辑标签' : '新增标签'}
      modalRender={(node) => (
        <Form
          form={form}
          layout="horizontal"
          fields={fields}
          onFinish={onFinish}
          onValuesChange={(changedFields) => {
            if (!findVal(changedFields, 'stringValue') && !findVal(changedFields, 'tagName') && !findVal(changedFields, 'remark')){
              console.count('findval')
              setTimeout(() => {
                asyncFormDataToCanvas()
              }, 200)
            }
          }}
        >
          {node}
        </Form>
      )}
      okButtonProps={{ htmlType: 'submit' }}

      style={{
        maxWidth: '100vw',
        top: 0,
        paddingBottom: 0,
      }}
      bodyStyle={{
        // height: `calc(100vh - ${scaleWidth}px`,
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
        padding: 0,
        paddingTop: '16px'
      }}
      footer={footerBtns}
    >
      <Form.Item
        style={{marginBottom: '16px', paddingLeft: '24px'}}
      >
        <Row gutter={0}>
          <Col span={6}>
            <Form.Item
              label="标签名称"
              labelAlign="left"
              name="tagName"
              style={{marginBottom: 0, marginRight: '24px'}}
              rules={[
                {
                  required: true,
                  message: '请输入标签名称!',
                },
              ]}
            >
              <Input className={errClass}/>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="标签描述"
              labelAlign="left"
              name="remark"
              style={{marginBottom: 0}}
              rules={[
                {
                  required: true,
                  message: '请输入描述内容!',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>
      <Form.Item noStyle>
        <Row>
          <Col flex={1}>
            <FlowDiagram
              ref={flowDiagramRef}
              editRow={restProps.editRow}
              actionType={restProps.actionType}
              updateModal={updateModal}
              visible={visible}
              onSelectedChange={(data) => {
                setSelectedNode(data)
              }}
              onChange={onModelChange}
            />
          </Col>
          <Col flex={`${scale*460}px`}>
            <DragList source={nodeDataArray} linkDataArray={linkDataArray} showLabelModal={showLabelModal} selectedNode={selectedNode} asyncData={asyncFormDataToCanvas} findPreNode={findPreNode}/>
          </Col>
        </Row>
      </Form.Item>
      <LabelSelectModal ref={labelSelectRef} onSure={onSure} />
      <SubjectModal ref={subjectRef} done={selectedSubjects}/>
      <PatientRecordModal ref={patientRecordRef} patientId={selectedSubject}>
        <RadioTag seletedData={subjects} onClick={onTagClickEvent}/>
      </PatientRecordModal>
    </Modal>
  )
}

export default forwardRef(TagEngineModal)
