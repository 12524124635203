import React, { useContext, useEffect, useState, useMemo, useRef } from 'react'
import { Spin, Table } from 'antd'
import TagSelect from '@components/tagSelect'
import { xml2html, isXML, withEmptyRender, formatDateFromTimestamp } from '@utils/common'
import fetch from '@utils/request'
import api from '@api'
import PatientContext from '../context'
import { secTabInfo } from '../index'
import styles from './index.module.less'

// 遍历节点，如果是文本节点且包含hitValue，则高亮hitValue
const highlight = (node, hitValue) => {
  if (node.nodeType === 3) {
    const text = node.nodeValue
    if (text.indexOf(hitValue) !== -1) {
      // text中hitValue用span标签包裹
      const newText = text.replaceAll(hitValue, `<span class="record-highlight">${hitValue}</span>`)
      const newNode = document.createElement('span')
      newNode.innerHTML = newText
      node.parentNode.replaceChild(newNode, node)
    }
  } else if (node.nodeType === 1 && node.childNodes && node.childNodes.length > 0) {
    for (let i = 0; i < node.childNodes.length; i++) {
      highlight(node.childNodes[i], hitValue)
    }
  }
}

const ProgressNote = (props) => {
  const { render, hitValue, hitTable } = props
  const { baseInfo, activeVisit, projectId, patientId } = useContext(PatientContext)
  const [records, setRecords] = useState([])
  const [selected, setSelected] = useState(0)
  const [loading, setLoading] = useState(false)
  const hlColumns = withEmptyRender([
    {
      title: '测量项目编码',
      dataIndex: 'measure_item_code',
      render,
    },
    {
      title: '测量项目名称',
      dataIndex: 'measure_item_name',
      render,
    },
    {
      title: '测量项目值',
      dataIndex: 'measure_item_value',
      render,
    },
    {
      title: '测量项目单位',
      dataIndex: 'measure_item_unit',
      render,
    },
    {
      title: '测量结果记录时间',
      dataIndex: 'record_date',
      render: (value, record) => render(formatDateFromTimestamp(value) || '-', record),
    },
  ])
  // const jzColumns = withEmptyRender([
  //   {
  //     title: '现病史',
  //     dataIndex: 'currDiseaseHistory',
  //     render,
  //   },
  //   {
  //     title: '既往史',
  //     dataIndex: 'pastDiseaseHistory',
  //     render,
  //   },
  //   {
  //     title: '个人史',
  //     dataIndex: 'personalHistory',
  //     render,
  //   },
  //   {
  //     title: '家族史',
  //     dataIndex: 'familyHistory',
  //     render,
  //   },
  //   {
  //     title: '呼吸',
  //     dataIndex: 'breathingRate',
  //     render,
  //   },
  //   {
  //     title: '脉搏',
  //     dataIndex: 'pulseRate',
  //     render,
  //   },
  //   {
  //     title: '血压高值',
  //     dataIndex: 'diastolicPressure',
  //     render,
  //   },
  //   {
  //     title: '血压低值',
  //     dataIndex: 'systolicPressure',
  //     render,
  //   },
  //   {
  //     title: '疼痛评分',
  //     dataIndex: 'painMeasurementScale',
  //     render,
  //   },
  //   {
  //     title: '体重',
  //     dataIndex: 'weight',
  //     render,
  //   },
  //   {
  //     title: '身高',
  //     dataIndex: 'height',
  //     render,
  //   },
  // ])

  const options = useMemo(() => {
    return records.map((item, index) => {
      return {
        label: item.title,
        value: index,
      }
    })
  }, [records])

  const selectedRecord = useMemo(() => {
    const record = records[selected]
    if (record && record.htmlValue) {
      let htmlText = ''
      if (isXML(record.htmlValue)) {
        htmlText = xml2html(record.htmlValue)
      } else {
        htmlText = record.htmlValue
      }
      if (!hitValue) return htmlText
      // 在html的标签内容区域匹配hitValue
      const reg = new RegExp(`(?<=>)[^<>]*${hitValue}[^<>]*(?=<)`, 'g')
      htmlText = htmlText.replace(reg, `<span class="record-highlight">${hitValue}</span>`)
      return htmlText
    } else if (record && !record.htmlValue) {
      if (record.title === '护理记录表') {
        return record.dwdNursingRecordList
      } else {
        return ''
      }
    } else {
      return ''
    }
  }, [records, selected, hitValue])

  const htmlContainerRef = useRef(null)

  useEffect(() => {
    // 使用JavaScript来高亮hitValue
    if (htmlContainerRef.current && hitValue) {
      highlight(htmlContainerRef.current, hitValue)
    }
  }, [selectedRecord, hitValue])

  const handleSelect = (value) => {
    setSelected(value)
  }

  const fetchRecord = async () => {
    setLoading(true)
    const { indexNo } = activeVisit
    const { content } = await fetch({
      url: api.patientApi.patientRecords,
      method: 'GET',
      params: {
        indexNo,
        patientId,
        type: 1,
        pageNumber: 1,
        pageSize: 10000,
        proId: projectId,
      },
    })
    setRecords(content)
    setLoading(false)
  }

  useEffect(() => {
    if (patientId) {
      fetchRecord()
    }
  }, [baseInfo, activeVisit])

  useEffect(() => {
    let _secIndex = 0
    for (let i = 0; i < records.length; i++) {
      const val = records[i]
      if (secTabInfo[hitTable] === '护理记录表' || secTabInfo[hitTable] === '门急诊病历') {
        _secIndex = i
        break
      } else if (val.title === secTabInfo[hitTable]) {
        if (val.htmlValue.indexOf(hitValue) !== -1) {
          _secIndex = i
          break
        }
      }
    }
    setSelected(_secIndex)
  }, [records, hitTable, hitValue])

  return (
    <>
      <TagSelect
        options={options}
        wrapperStyle={{ marginBottom: 16, maxWidth: '100%', overflowX: 'auto' }}
        onChange={handleSelect}
        value={selected}
      />
      <Spin spinning={loading}>
        {/* {records[selected] && records[selected].title === '门急诊病历' && (
          <div id="tabPosition">
            <Table
              columns={jzColumns}
              dataSource={selectedRecord}
              rowKey={(record) => record.id}
              size="small"
              pagination={false}
            />
          </div>
        )} */}
        {records[selected] && records[selected].title === '护理记录表' && (
          <div id="tabPosition">
            <Table
              columns={hlColumns}
              dataSource={selectedRecord}
              rowKey={(record) => record.id}
              size="small"
              pagination={false}
            />
          </div>
        )}
        {records[selected] && records[selected].title !== '护理记录表' && (
          <div
            id="tabPosition"
            className={styles.content}
            dangerouslySetInnerHTML={{ __html: selectedRecord }}
            ref={htmlContainerRef}
          />
        )}
      </Spin>
    </>
  )
}

export default ProgressNote
