import React, {useEffect, useRef, useState} from 'react'
import {Button, message, Space, Table, Tabs, Tooltip, Input, Modal, Spin, Row, Col} from 'antd'
import styles from './index.module.less'
import usePagination from '@hooks/usePagination'
import fetch from '@utils/request'
import api from '@api'
import {permissionStyle} from '@utils/permission'
import {DeleteOutlined, EditOutlined, ExclamationCircleOutlined} from '@ant-design/icons'
import EditModal from './components/editModal'
import {useWindowSize} from '../../../hooks/useLayout'
const { Search } = Input
const Lib = () => {
  const editRef = useRef(null)
  const [curTabKey, setCurTabKey] = useState(1)
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [searchValue, setSearchValue] = useState(null)
  const [searchOutValue, setSearchOutValue] = useState(null)
  const [page, setPage, setTotal, pagination, total] = usePagination()
  const handleTableChange = (pagination, filters, sorter, extra) => {
    setPage({
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
    })
    // fetchData()
  }
  const [,, scale] = useWindowSize()
  const getList = async() => {
    const pager = {
      pageNumber: page.pageIndex,
      pageSize: page.pageSize,
    }
    setLoading(true)
    const {content, totalCount} = await fetch({
      method: 'POST',
      url: api.desensitizationApi.list,
      headers: {
        'Content-Type': 'application/json; charset=utf-8'
      },
      data: {
        ...pager,
        fieldType: curTabKey,
        name: searchValue
      }
    })
    setLoading(false)
    setList(content)
    setTotal(totalCount)
  }
  const onEdit = (rowData) => {
    editRef.current.handleOpen(rowData)
    //
  }
  const onDel = async (rowData) => {

    Modal.confirm({
      title: '确定删除该字段？',
      icon: <ExclamationCircleOutlined />,
      okText: '确认',
      cancelText: '取消',
      onOk: async() => {
        await fetch({
          method: 'POST',
          url: api.desensitizationApi.delete,
          data: {
            id: rowData.id
          }
        })
        message.success('刪除成功!')
        const totalPage = Math.ceil((total - 1) / page.pageSize) // 总页数
        let curPage = page.pageIndex > totalPage ? totalPage : page.pageIndex
        setPage({
          pageIndex: curPage > 1? curPage: 1,
          pageSize: page.pageSize,
        })
        getList()
      }
    })
  }
  const columns = [
    {
      title: ' 序号 ',
      width: 80*scale,
      // render: (text, record, index) => `${index + 1}`,  //每一页都从1开始
      render: (text, record, index) =>
        `${(pagination.current - 1) * (pagination.pageSize) + (index + 1)}`
      // 当前页数减1乘以每一页页数再加当前页序号+1
    },
    {
      title: '脱敏类型名',
      dataIndex: 'name',
      key: 'name',
      render: (text) => {
        return (
          <div title={text} className={styles.ellipse}>{text}</div>
        )
      }
    },
  ]
  const columns1 = [
    {
      title: ' 序号 ',
      // render: (text, record, index) => `${index + 1}`,  //每一页都从1开始
      render: (text, record, index) =>
        `${(pagination.current - 1) * (pagination.pageSize) + (index + 1)}`
      // 当前页数减1乘以每一页页数再加当前页序号+1
      ,
      width: 80*scale
    },
    {
      title: '脱敏类型名',
      dataIndex: 'name',
      width: 312*scale,
      key: 'name',
      render: (text) => {
        return (
          <div title={text} className={styles.ellipse}>{text}</div>
        )
      }
    },
    {
      title: '操作人',
      dataIndex: 'updatedBy',
      width: 312*scale,
      key: 'updatedBy',
    },
    {
      title: '操作日期',
      dataIndex: 'updatedTime',
      key: 'updatedTime',
      width: 312*scale,
    },
    {
      title: '操作',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Tooltip mouseEnterDelay="2" title="编辑">
            <a onClick={() => onEdit(record)} style={permissionStyle('DESENSITIZATION_STOCK_UPDATE')}><EditOutlined /></a>
          </Tooltip>
          <Tooltip mouseEnterDelay="2" title="删除">
            <a onClick={() => onDel(record)} style={permissionStyle('DESENSITIZATION_STOCK_DELETE')}><DeleteOutlined /></a>
          </Tooltip>
        </Space>
      ),
    },
  ]
  const Standard = () => {
    return (
      <Spin spinning={loading}>
        <Table
          rowKey={record => record.id}
          columns={columns}
          dataSource={list}
          pagination={pagination}
          onChange={handleTableChange}
        />
      </Spin>
    )
  }
  const UnStandard = () => {
    return (
      <Spin spinning={loading}>
        <Table
          rowKey={record => record.id}
          columns={columns1}
          dataSource={list}
          pagination={pagination}
          onChange={handleTableChange}
        />
      </Spin>
    )
  }

  const items = [
    { label: '标准字段', key: '1', children: <Standard/> }, // 务必填写 key
    { label: '非标准字段', key: '2', children: <UnStandard/> },
  ]
  /*
  * 成功回調
  * */
  const success = () => {
    getList()
  }
  const onSearch = (value) => {
    console.log('value::', value)
    setPage({
      pageIndex: 1,
      pageSize: 10
    })
    setSearchOutValue(value.trim())
  }
  useEffect(() => {
    getList()
  }, [curTabKey, page, searchOutValue])
  return (
    <>
      {/* 标题栏 */}
      <div className={styles.headerPage}>
        <div className={styles.headerPageTitle}>脱敏库</div>
        <Row className={styles.searchWrapper}>
          <Col className={styles.searchTitle}>查询字段：</Col>
          <Col className={styles.actionBtns}>
            <Search placeholder="请输入关键词搜索" onSearch={onSearch} enterButton="搜索" allowClear style={permissionStyle('DESENSITIZATION_STOCK')} value={searchValue} onChange={(e) => {
              setSearchValue(e.target.value.trim())
            }}/>
          </Col>
        </Row>
      </div>
      <div className={styles.content}>
        <Tabs items={items}
          onChange={(activeKey) => {
            setCurTabKey(activeKey)
            setSearchValue('')
            setPage({
              pageIndex: 1,
              pageSize: 10
            })
          }}/>
        {curTabKey === '2' && <Button className={styles.newBtn} type="primary" onClick={() => {
          onEdit(null)
        }} style={permissionStyle('DESENSITIZATION_STOCK_ADD')}>新增脱敏字段</Button>}
      </div>
      <EditModal
        ref={editRef}
        handleOk={() => {
          success()
        }}/>
    </>
  )
}

export default Lib
