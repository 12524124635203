/*
 * 系统应用相关api(登录、登出、密码)
 * */
const appApi = {
  login: '/login', // 登录
  logout: '/logout', // 登出
  updatePassword: '/user/editpwd', // 修改密码
  lastDay: '/lastDay', // 查询最后登录时间
  configList: '/kbms/config/param/list', // 系统常量
}

export default appApi
