import React, {
  useEffect,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react'
import { Form, Space, Button, Select, message, Modal } from 'antd'
import { MinusCircleOutlined } from '@ant-design/icons'
import LabelSelectModal from '@views/project/components/labelSelectModaV1.js'
import fetch from '@utils/request'
import api from '@api'
import { useWindowSize } from '@hooks/useLayout'
import styles from './index.module.less'
import { useNavigate, useSearchParams } from 'react-router-dom'
// import uuid from '@utils/tool'
// import { uniq } from 'lodash'
// import TagEdit from '@views/tag/edit'

const LinkTagIndex = (props, ref) => {
  const [selectedData, setSelectedData] = useState([])
  // const [outputTyps, setOutputTyps] = useState([])
  const [form] = Form.useForm()
  const labelSelectRef = useRef(null)
  const [, , scale] = useWindowSize()
  const [searchParams] = useSearchParams()
  const ruleId = searchParams.get('ruleId')
  const navigate = useNavigate()
  // const [draftId, setdraftId] = useState(null)
  // const tagEditRef = useRef(null)
  // const [editRow, setEditRow] = useState(null) // 编辑标签规则
  // const fetchCommonDic = async () => {
  //   const res = await fetch({
  //     method: 'POST',
  //     url: api.commonApi.paramList,
  //   })
  //   console.log('commonDic', res)
  //   const OUTPUT_TYPES = res.filter((item) => {
  //     return item.type === 'rule_out_put_type'
  //   })
  //   setOutputTyps(OUTPUT_TYPES[0].valueNameVOS)
  // }

  /*
   * 获取已绑定的标签
   * */
  const fetchRuleList = async () => {
    const res = await fetch({
      method: 'POST',
      url: api.aeApi.getPushedTagList,
      data: {
        id: ruleId,
      },
    })
    res.map((item) => {
      item.outputType += ''
    })
    form.setFieldsValue({
      ruleIdVersions: res,
    })
    setSelectedData(res)
    console.log('fetchRuleList', res)
  }

  const handleValue = (e) => {
    form.setFieldsValue(e)
  }

  // 保存
  const onFinish = async (values) => {
    console.log('fds', values)
    values?.ruleIdVersions?.map((item) => {
      if ((item.id + '').match(/_/)) {
        item.id = item.id.split('_')[0]
      }
    })
    await fetch({
      method: 'POST',
      url: api.projectApi.crfBind,
      data: {
        id: ruleId,
        ...values,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    })
    message.success('关联成功！')
    navigate(-1)
  }

  /*
   * 标签和项目预绑定
   * */
  const draftBind = async (data, callback) => {
    const res = await fetch({
      method: 'POST',
      url: api.aeApi.pushTag,
      data: JSON.stringify(
        data.map((item) => {
          return {
            esRuleId: ruleId,
            kbmsRuleId: item.id,
            kbmsRuleVersion: item.version,
          }
        })
      ),
      headers: {
        'Content-Type': 'application/json',
      },
    })
    message.success('关联成功！')
    callback(res)
  }

  // 打开选择弹窗
  // eslint-disable-next-line no-unused-vars
  const onselect = (e) => {
    labelSelectRef.current.handleOpen(e)
  }

  // 确认选择，接受选中项
  const onSure = (targetNodes) => {
    // targetNodes.forEach((item) => {
    //   item.id = item.id.split('_')[0]
    // })

    // draftBind(targetNodes, (draftData) => {
    //   const uniqData = uniq([...selectedData, ...draftData])
    //   setSelectedData((prevState) => uniqData)
    //   form.setFieldsValue({
    //     ruleIdVersions: uniqData,
    //   })
    // })
    draftBind(targetNodes, () => {
      fetchRuleList()
    })
  }
  // const showCanvas = (data, type) => {
  //   tagEditRef.current.show(data)
  // }
  // const onEdit = (record) => {
  //   console.log('onEdit::', record)
  //   setEditRow(record)
  //   showCanvas(record, 'edit')
  // }
  /*
   * 标签新增or编辑
   * */
  // const doneEdit = async (canvasData) => {
  //   // eslint-disable-next-line no-unused-vars
  //   const response = await fetch({
  //     method: 'POST',
  //     url: api.tagApi.labelSave,
  //     data: {
  //       categoryId: editRow.categoryId,
  //       categoryName: editRow.categoryName,
  //       id: editRow.id,
  //       categoryType: '',
  //       // categoryType: type,
  //       name: canvasData.tagName,
  //       remark: canvasData.remark,
  //       ruleJson: canvasData.modeJSON,
  //       version: editRow.version,
  //       ruleSet: 'project',
  //     },
  //     headers: {
  //       'Content-Type': 'application/json; charset=utf-8',
  //     },
  //   })
  //   tagEditRef.current.hide()
  //   // 刷新列表
  //   // treeNode && getList()
  // }

  useEffect(() => {
    // fetchCommonDic()
    fetchRuleList()
    // const uuidValue = uuid()
    // setdraftId(uuidValue)
    // console.log('useEffectuuidValue', uuidValue)
  }, [])

  useImperativeHandle(ref, () => ({
    handleValue,
  }))

  const handleRemove = async (index) => {
    Modal.confirm({
      title: '提示',
      content: '确认取消关联该标签吗？',
      onOk: async () => {
        const ruleIdVersions = form.getFieldValue('ruleIdVersions')
        const targetRule = ruleIdVersions[index]
        await fetch({
          method: 'POST',
          url: api.aeApi.cancelPushTag,
          data: JSON.stringify([
            {
              esRuleId: ruleId,
              kbmsRuleId: targetRule.id,
              kbmsRuleVersion: targetRule.version,
            },
          ]),
          headers: {
            'Content-Type': 'application/json',
          },
        })
        message.success('取消关联成功！')
        fetchRuleList()
      },
    })
  }

  return (
    <>
      {/* 标题栏 */}
      <div className="head">
        <h3 className="head-title">关联标签选择</h3>
      </div>
      <div className={styles.pageWrapper}>
        {/* <Descriptions title="">
          <Descriptions.Item label="关联表单">随防</Descriptions.Item>
          <Descriptions.Item label="关联字段">是否使用抗凝剂</Descriptions.Item>
        </Descriptions> */}
        <Form
          form={form}
          labelCol={{
            span: 4,
          }}
          wrapperCol={{
            span: 20,
          }}
          onFinish={onFinish}
        >
          <Form.Item>
            <Button
              type="primary"
              ghost
              onClick={() => {
                if (selectedData.length > 0) {
                  message.warning('请先取消关联标签')
                  return
                }
                onselect(1)
              }}
            >
              选择标签
            </Button>
          </Form.Item>
          <Form.List name="ruleIdVersions" initialValue={selectedData}>
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    style={{
                      display: 'flex',
                      marginBottom: 8,
                    }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      name={[name, 'name']}
                      rules={[
                        {
                          required: true,
                          message: '请选择标签',
                        },
                      ]}
                    >
                      <Select
                        style={{ minWidth: 200 * scale }}
                        className={styles.select}
                        showSearch={false}
                        placeholder="请选择标签"
                        dropdownStyle={{ display: 'none' }}
                        suffixIcon={null}
                      ></Select>
                    </Form.Item>
                    {/* <Form.Item
                      {...restField}
                      name={[name, 'outputType']}
                      rules={[
                        {
                          required: true,
                          message: '请选择输出方式',
                        },
                      ]}
                    >
                      <Select
                        placeholder="请选择输出方式"
                        style={{ minWidth: 120 * scale }}
                        options={outputTyps}
                        fieldNames={{
                          label: 'name',
                          value: 'value',
                        }}
                      />
                    </Form.Item> */}
                    <MinusCircleOutlined
                      onClick={() => {
                        handleRemove(name)
                      }}
                    />
                    {/* <FormOutlined onClick={() => onEdit(selectedData[name])} /> */}
                  </Space>
                ))}
              </>
            )}
          </Form.List>
          <Form.Item>
            <Space>
              {/* <Button type="primary" htmlType="submit">
                保存
              </Button> */}
              <Button
                onClick={() => {
                  navigate(-1)
                }}
              >
                返回
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </div>
      <LabelSelectModal ref={labelSelectRef} onSure={onSure} />
      {/* <TagEdit
        ref={tagEditRef}
        doneEdit={doneEdit}
        editRow={editRow}
        source="project"
      /> */}
    </>
  )
}

export default forwardRef(LinkTagIndex)
