import { useRoutes } from 'react-router-dom'
import { permissionAuth } from '@utils/permission'
import routesConfig from './routes'

const filterRoutes = (routes) => {
  if (!routes) return []
  return routes.filter((route) => {
    if (route.children) {
      route.children = filterRoutes(route.children)
    }
    return (permissionAuth(route.key) && route.auth) || !route.auth
  })
}

const MainRoutes = () => {
  const { privateRoutes, publicRoutes, commonRoutes, microRoutes } = routesConfig
  const authPrivateRoutes = filterRoutes(privateRoutes())
  const element = useRoutes([...authPrivateRoutes, ...publicRoutes, ...commonRoutes, ...microRoutes])
  return element
}

export default MainRoutes
