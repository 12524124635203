import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
  useRef,
  useMemo,
} from 'react'
import {
  Modal,
  Form,
  Row,
  Col,
  Input,
  Button,
  Table,
  message,
  Space,
} from 'antd'
import styles from './index.module.less'
import Add from './add'
import api from '@api'
import fetch from '@utils/request'
import debounce from 'lodash/debounce'
import { permissionStyle } from '@utils/permission'
import classNames from 'classnames'

const EditSynonym = ({ onHide, onVerify }, ref) => {
  const [visible, setVisible] = useState(false)
  const [item, setItem] = useState({})
  const [data, setData] = useState([])
  const addRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const [keyword, setKeyword] = useState('')

  const hide = () => {
    typeof onHide === 'function' && onHide()
    setVisible(false)
  }
  const show = (record) => {
    setItem(record)
    setVisible(true)
  }

  useImperativeHandle(ref, () => {
    return {
      show,
      hide,
    }
  })

  const search = (e) => {
    setKeyword(e.target.value)
  }

  const fetchData = async () => {
    setLoading(true)
    const { content } = await fetch({
      url: api.mappingApi.getThesaurusDetail,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        conceptId: item.conceptId,
        vocabulary: item.vocabulary,
        pageNumber: 1,
        pageSize: 10000,
        keyword,
      },
    })
    setLoading(false)
    setData(content)
  }

  useEffect(() => {
    if (!visible) return
    fetchData()
  }, [visible, item, keyword])

  const del = (record) => {
    Modal.confirm({
      title: '同义词删除提醒',
      content: '确认删除该同义词吗？删除后将同时解除其映射关系',
      onOk: async () => {
        await fetch({
          url: api.mappingApi.deleteThesaurus,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          data: {
            idList: [record.id],
          },
        })
        message.success('删除成功')
        fetchData()
      },
    })
  }

  const verify = async (pass) => {
    onVerify(
      {
        idClassList: [
          {
            conceptId: item.conceptId,
            vocabulary: item.vocabulary,
          },
        ],
        verifyStatus: pass ? 1 : 2,
      },
      () => {
        hide()
      }
    )
  }

  const columns = [
    {
      title: '来源',
      dataIndex: 'source',
      key: 'source',
    },
    {
      title: '名称',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '编码',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: '状态',
      dataIndex: 'verifyStatus',
      key: 'verifyStatus',
      render(value, record) {
        const status = {
          0: { text: '待审核', class: 'wait' },
          1: { text: '审核通过', class: 'success' },
          2: { text: '审核拒绝', class: 'fail' },
        }
        return (
          <span
            className={classNames(styles.audit, styles[status[value].class])}
          >
            {record.isDeleted === 1 ? status[0].text : status[value].text}
          </span>
        )
      },
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      render(text, record) {
        return (
          <Button
            onClick={() => del(record)}
            style={permissionStyle('THESAURUS_DELETE')}
            disabled={record.isDeleted === 1}
            type="link"
          >
            删除
          </Button>
        )
      },
    },
  ]

  const showVerify = useMemo(() => {
    return data.some((item) => item.verifyStatus === 0 || item.isDeleted === 1)
  }, [data])

  return (
    <>
      <Modal
        title="编辑"
        open={visible}
        width={1140}
        centered
        destroyOnClose
        footer={
          <Space>
            <Button onClick={hide}>取消</Button>
            {showVerify && (
              <>
                <Button
                  style={{
                    borderColor: '#00B42A',
                    color: '#00B42A',
                    ...permissionStyle('THESAURUS_AUDIT'),
                  }}
                  onClick={() => verify(true)}
                >
                  审核通过
                </Button>
                <Button
                  danger
                  onClick={() => verify(false)}
                  style={permissionStyle('THESAURUS_AUDIT')}
                >
                  审核拒绝
                </Button>
              </>
            )}
          </Space>
        }
        onCancel={hide}
      >
        <div className={styles.top}>
          <div className={styles.name}>
            <span>标准名称：{item.conceptName}</span>
            {item.valid === 2 && <div className={styles.invalidText}>失效</div>}
          </div>

          <span>编码：{item.conceptId}</span>
        </div>
        <Form className={styles.form}>
          <Row gutter={24}>
            <Col span={22}>
              <Form.Item label="同义词" name="synonym">
                <Input
                  placeholder="请输入编码或名称"
                  onChange={debounce(search, 500)}
                />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Button
                type="primary"
                ghost
                onClick={() => addRef.current.show()}
                style={permissionStyle('THESAURUS_INSERT')}
              >
                新建
              </Button>
            </Col>
          </Row>
        </Form>
        <Table
          size="small"
          columns={columns}
          dataSource={data}
          loading={loading}
          scroll={{ y: 400 }}
          rowKey={(record) => record.id}
          rowClassName={(record) => {
            if (record.isDeleted === 1) {
              return styles.isDelete
            }
            if (!record.valid) {
              return styles.invalid
            }
          }}
          pagination={{ pageSize: 6 }}
        />
      </Modal>
      <Add ref={addRef} item={item} onSuccess={fetchData} />
    </>
  )
}

export default forwardRef(EditSynonym)
