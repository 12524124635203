import React from 'react'
import cls from 'classnames'
import PropTypes from 'prop-types'
import styles from './index.module.less'

const TagSelect = ({
  options = [],
  onChange,
  wrapperStyle,
  value,
  activeStyle = {},
  renderItem,
  content,
}) => {
  const handleClick = (idx) => {
    onChange && onChange(options[idx].value)
  }

  return (
    <div className={styles.tagSelect} style={wrapperStyle}>
      {options.map((item, index) => {
        const originalItem = (
          <div
            className={cls(styles.option, value === item.value && styles.active)}
            style={value === item.value ? activeStyle : {}}
            onClick={() => handleClick(index)}
            key={index}
          >
            {item.label}
            {item.iconShow && content}
          </div>
        )
        if (renderItem) {
          return renderItem(item, index, originalItem)
        }
        return originalItem
      })}
    </div>
  )
}

export default TagSelect

TagSelect.propTypes = {
  options: PropTypes.array,
  onChange: PropTypes.func,
  wrapperStyle: PropTypes.object,
  activeStyle: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  renderItem: PropTypes.func,
}
