import React, { useImperativeHandle, useState, forwardRef, useRef } from 'react'
import {
  Form,
  Drawer,
  Input,
  Select,
  Button,
  Radio,
  message,
  InputNumber,
} from 'antd'
import styles from './index.module.less'
import fetch from '@utils/request'
import api from '@api'
import { controlTypes } from '../fieldList'
import LabelSelectModal from '@views/project/components/labelSelectModaV1.js'
import { useWindowSize } from '@hooks/useLayout'

const fieldStyle = {
  width: 'calc(100% - 16px)',
}

const optionStyle = {
  width: 'calc(100% - 16px)',
}

const EditField = ({ onSuccess, type = 'cdisc', formSet = 'default' }, ref) => {
  const [, , scale] = useWindowSize()
  const controlTypeOptions =
    type === 'omop'
      ? controlTypes.filter((item) => ['0', '1', '5', '6'].includes(item.value))
      : controlTypes
  const labelSelectRef = useRef(null)
  const addRef = useRef(null)
  const [visible, setVisible] = useState(false)
  const [record, setRecord] = useState({})
  const [controlType, setControlType] = useState('0')
  const [form] = Form.useForm()
  const hide = () => {
    form.resetFields()
    setRecord({})
    setVisible(false)
  }
  const show = (record) => {
    if (record) {
      setRecord(record)
      setControlType(record.controlType || '0')
      form.setFieldsValue(record)
    }
    setVisible(true)
  }
  useImperativeHandle(ref, () => ({
    show,
  }))

  const validateNames = (_, value) => {
    const errorText = '变量字段（中文）、变量字段（英文）两者至少填写一项'
    const { fieldCnName, fieldEnName } = form.getFieldsValue()
    if (!fieldCnName && !fieldEnName) {
      return Promise.reject(errorText)
    }
    if (fieldCnName) {
      form.setFields([{ name: 'formEnName', errors: [] }])
    }
    if (fieldEnName) {
      form.setFields([{ name: 'formCnName', errors: [] }])
    }
    return Promise.resolve()
  }

  const validateOptionNames = (index) => {
    const errorText = '选项（中文）、选项（英文）两者至少填写一项'
    const { optionList } = form.getFieldsValue()
    const { optionCnName, optionEnName } = optionList[index] || {}
    if (!optionCnName && !optionEnName) {
      return Promise.reject(errorText)
    }
    if (optionCnName) {
      form.setFields([
        { name: ['optionList', index, 'optionEnName'], errors: [] },
      ])
    }
    if (optionEnName) {
      form.setFields([
        { name: ['optionList', index, 'optionCnName'], errors: [] },
      ])
    }
    return Promise.resolve()
  }

  const onSubmit = async (values) => {
    await fetch({
      url: api[type === 'omop' ? 'omopControlApi' : 'cdiscApi'].saveField,
      method: 'POST',
      data: {
        ...record,
        ...values,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    })
    message.success(record.id ? '编辑成功' : '新增成功')
    hide()
    typeof onSuccess === 'function' && onSuccess()
  }

  const onSelectLabel = (values) => {
    if (values && values.length > 1) {
      message.error('只能选择一个标签')
      return
    }
    form.setFieldsValue({
      ruleId: values[0].id,
      ruleVersion: values[0].version,
      ruleName: values[0].name,
    })
  }
  return (
    <Drawer
      open={visible}
      onClose={hide}
      title={record.id ? '编辑变量' : '新增变量'}
      width={580 * scale}
      footer={
        <div style={{ textAlign: 'right' }}>
          <Button onClick={hide}>取消</Button>
          <Button
            type="primary"
            style={{ marginLeft: 8 }}
            onClick={() => form.submit()}
          >
            确定
          </Button>
        </div>
      }
    >
      <Form
        form={form}
        labelCol={{
          style: {
            width: 144,
          },
        }}
        onFinish={onSubmit}
      >
        <Form.Item required label="变量字段"></Form.Item>
        <div className={styles.section}>
          <Form.Item
            label="（中文）"
            name="fieldCnName"
            rules={[{ validator: validateNames }]}
          >
            <Input placeholder="请输入变量字段" style={fieldStyle} />
          </Form.Item>
          <Form.Item
            label="（英文）"
            name="fieldEnName"
            rules={[{ validator: validateNames }]}
          >
            <Input placeholder="请输入变量字段" style={fieldStyle} />
          </Form.Item>
        </div>
        {type === 'cdisc' && (
          <>
            <Form.Item
              label="编码"
              rules={[
                {
                  required: true,
                  message: '请输入编码',
                },
              ]}
              name="fieldCode"
            >
              <Input placeholder="请输入编码" style={fieldStyle} />
            </Form.Item>
            <Form.Item label="单位" name="unit">
              <Input placeholder="请输入单位" style={fieldStyle} />
            </Form.Item>
          </>
        )}
        <Form.Item
          label="控件类型"
          rules={[
            {
              required: true,
              message: '请选择控件类型',
            },
          ]}
          name="controlType"
        >
          <Select
            placeholder="请选择控件类型"
            style={fieldStyle}
            options={controlTypeOptions}
            onChange={(value) => {
              setControlType(value)
              if (value === '2' || value === '3') {
                form.setFieldValue('optionList', [
                  {
                    optionCnName: '',
                  },
                ])
              }
            }}
          />
        </Form.Item>
        {(controlType === '2' || controlType === '3') && (
          <>
            <Form.List name="optionList">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <div className={styles.option} key={name}>
                      <div className={styles.left}>
                        <Form.Item
                          {...restField}
                          name={[name, 'optionCnName']}
                          label="选项（中文）"
                          rules={[
                            {
                              validator: (rule, value) => {
                                return validateOptionNames(name)
                              },
                            },
                          ]}
                        >
                          <Input placeholder="请输入" style={optionStyle} />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, 'optionEnName']}
                          label="选项（英文）"
                          rules={[
                            {
                              validator: (rule, value) => {
                                return validateOptionNames(name)
                              },
                            },
                          ]}
                        >
                          <Input placeholder="请输入" style={optionStyle} />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, 'optionValue']}
                          label="选项（值）"
                          rules={[
                            {
                              required: true,
                              message: '请输入选项（值）',
                            },
                          ]}
                        >
                          <Input placeholder="请输入" style={optionStyle} />
                        </Form.Item>
                      </div>
                      <div className={styles.right}>
                        <a onClick={() => remove(name)}>删除</a>
                      </div>
                    </div>
                  ))}
                  <Form.Item hidden>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      ref={addRef}
                    >
                      Add field
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
            <div className={styles.add}>
              <Form.Item noStyle name="showMethod">
                <Radio.Group>
                  <Radio value={0}>平铺</Radio>
                  <Radio value={1}>下拉</Radio>
                </Radio.Group>
              </Form.Item>
              <a
                onClick={() => {
                  addRef.current.click()
                }}
              >
                新增
              </a>
            </div>
          </>
        )}
        {type === 'omop' && (
          <Form.Item label="长度" name="fieldLength" rules={[{
            validator: (rule, value) => {
              if (value && value > 1000000000) {
                return Promise.reject('长度不能大于1000000000')
              }
              // 不允许小数
              if (value && value.toString().indexOf('.') > -1) {
                return Promise.reject('长度不能为小数')
              }
              return Promise.resolve()
            }
          }]}>
            <InputNumber
              placeholder="请输入长度"
              style={fieldStyle}
              min={1}
              max={1000000000}
              step={1}
            />
          </Form.Item>
        )}
        {formSet !== 'default' && (
          <>
            <Form.Item label="关联标签" name="ruleName">
              <Input
                placeholder="请选择关联标签"
                onClick={() => {
                  labelSelectRef.current.handleOpen()
                }}
                style={fieldStyle}
                allowClear
                onChange={(e) => {
                  if (!e.target.value) {
                    form.setFieldsValue({
                      ruleId: '',
                      ruleVersion: '',
                    })
                  }
                }}
              />
            </Form.Item>
            <Form.Item hidden name="ruleId">
              <Input />
            </Form.Item>
            <Form.Item hidden name="ruleVersion">
              <Input />
            </Form.Item>
          </>
        )}
      </Form>
      <LabelSelectModal
        ref={labelSelectRef}
        onSure={onSelectLabel}
        noProject={true}
      />
    </Drawer>
  )
}

export default forwardRef(EditField)
