/*
* 药品库详情
* */
import React, {useEffect, useState} from 'react'
import { useSearchParams } from 'react-router-dom'
import PDF from './components/PDF'
import fetch from '@utils/request'
import api from '@api'
import DESC from '@views/knowledge/drug/detail/components/DESC'
const DrugDetail = () => {
  const [params] = useSearchParams()
  const [sourceData, setSourceData] = useState({detailDTO: null})
  const drugId = params.get('id')
  const type = params.get('type')
  const source = params.get('source')
  const getDetail = async(param ={}) => {
    const {detailDTO} = await fetch({
      method: 'GET',
      url: api.knowledgeApi.detail,
      params: {
        id: drugId
      }
    })

    setSourceData({detailDTO})

    console.log('detailDTO::', detailDTO)

  }

  useEffect(() => {
    setSourceData({
      detailDTO: null,
    })
    getDetail()
  }, [])

  if (type === 'pdf') {
    if (source.indexOf('.pdf') !== -1){
      return (
        <>
          <PDF sourceData={source}/>
        </>
      )
    } else {
      return (
        <>
          <img src={source}/>
        </>
      )
    }
  } else {
    return (
      <>
        {sourceData.detailDTO && <DESC sourceData={sourceData.detailDTO}/>}
      </>
    )
  }


}

export default DrugDetail
