import React from 'react'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import styles from '@views/tag/engine/components/index.module.less'
import { ReactComponent as EmptyIcon } from '@assets/images/icons/icon_empty.svg'
import FormArea from '@views/tag/engine/components/formArea'
import {useSelector} from 'react-redux'
// import moment from 'moment/moment'

// eslint-disable-next-line no-unused-vars
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const grid = 0
const DragList = ({source, selectedNode, ...restProps}) => {
  const {nodeDataArray} = useSelector((state) => state.tag.ruleJson)
  console.log('node', nodeDataArray)
  const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? 'lightblue' : '#fff',
    padding: grid,
    width: '100%'
  })
  // eslint-disable-next-line no-unused-vars
  const onDragEnd = (result) => {
    // console.log('result::', result)
    // if (!result.destination) {
    //   return
    // }
    // const newItems = reorder(
    //   items,
    //   result.source.index,
    //   result.destination.index
    // )
    // console.log('result:arfter::', newItems)
    // setItems(newItems)
  }

  if (!nodeDataArray?.length) {
    return (
      <div className={styles.canvasProps}>
        <div className={styles.canvasPropsTitle}>控件属性</div>
        <div className={styles.dragListEmpty}>
          <div><EmptyIcon/></div>
          <div style={{color: 'rgba(0, 0, 0, 0.25)'}}>暂无数据</div>
        </div>
      </div>
    )
  }


  return (
    <div className={styles.canvasProps}>
      <div className={styles.canvasPropsTitle}>控件属性</div>
      <div className={styles.dragList}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                <FormArea items={nodeDataArray} selectedNode={selectedNode} {...restProps}/>
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  )
}
export default DragList
