import {Tree} from 'antd'
import styles from './index.module.less'
import React, {forwardRef, useImperativeHandle, useState} from 'react'

const TagTree = ({treeData, treeSelect, showNumber}, ref) => {
  const [select, setSelect] = useState([])
  const onTreeSelect = (selectedKeys, e) => {
    // eslint-disable-next-line no-unused-vars
    const {node, selectedNodes} = e
    // Tree组件单选实现（选中不能取消）
    // if (!selectedNodes.length && node.code=== select[0]){
    //   return
    // }
    setSelect(selectedKeys)
    treeSelect(selectedKeys, node)
  }
  const onSelectClear = () => {
    setSelect([])
  }
  useImperativeHandle(ref, () => ({
    onSelectClear
  }))
  return (
    <Tree
      className={styles.tree}
      treeData={treeData}
      fieldNames={{
        key: 'code',
        title: 'name'
      }}
      selectedKeys={select}
      onSelect={onTreeSelect}
      titleRender={(data) => {
        const list = (
          <div className={styles.treeItem}>
            <div className={styles.itemWrapper}>
              {data.name}
              {showNumber && <span> ({data.number})</span>}
            </div>
          </div>
        )
        return <div>{list}</div>
      }}
    />
  )
}

export default forwardRef(TagTree)
