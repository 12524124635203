import React, { useEffect, useState, forwardRef, useImperativeHandle, useRef } from 'react'
import { Modal, Form, Input, Radio, Button, Table } from 'antd'
import usePagination from '@hooks/usePagination'
import Add from './add'
import classNames from 'classnames'
import styles from '@views/dictionary/index.module.less'
import api from '@api'
import fetch from '@utils/request'
import { permissionStyle } from '@utils/permission'

const types = [
  { value: 0, text: '待审核',},
  { value: 1, text: '审核通过',},
  { value: 2, text: '审核拒绝',}
]

const DicEditModal = (props, ref) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [form] = Form.useForm()
  const [title, setTitle] = useState('新增字典')
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [isDel, setIsDel] = useState([])
  const [initialValue, setInitialValue] = useState(null)
  const addRef = useRef(null)
  const [page, setPage, setTotal, pagination] = usePagination()

  const handleOpen = (initialValue) => {
    if (initialValue){
      setTitle('编辑字典')
      setInitialValue(initialValue)
      const {
        keyCopy, titleCopy, source, validCopy, remark, id
      } = initialValue
      form.setFieldsValue({
        conceptCode: keyCopy,
        conceptName: titleCopy,
        source,
        valid: validCopy,
        id,
        remark,
      })
    }
    setIsModalVisible(true)
  }

  const getSynonym = async () => {
    if (!initialValue.key) return
    setLoading(true)
    const res = await fetch({
      url: api.mappingApi.listDetailAll,
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
      },
      params: {
        id: initialValue.id
      },
    })
    setLoading(false)
    setData(res || [])
    setTotal(res?.length)
  }

  const fetchData = (values) => {
    if (!values) return
    let arr = JSON.parse(JSON.stringify(data))
    arr.push({
      ...values,
      verifyStatus: 0
    })
    setData(arr)
    setTotal(arr.length)
  }

  const handleTableChange = (pagination) => {
    setPage({
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
    })
  }

  const del = (index) => {
    let arr = JSON.parse(JSON.stringify(data))
    let del = JSON.parse(JSON.stringify(isDel))
    if (arr[index].id) {
      del.push(arr[index].id)
      arr[index].isDeleted = 1
    } else {
      arr.splice(index, 1)
    }
    setData(arr)
    setTotal(arr.length)
    setIsDel(del)
  }

  useEffect(() => {
    if (!isModalVisible) return
    getSynonym()
  }, [isModalVisible])

  useEffect(() => {
    if (!isModalVisible) return
    fetchData()
  }, [page])


  const handleOk = async() => {
    await form.submit()
  }

  const onFinish = () => {
    const fieldValue = form.getFieldsValue()
    let arr = []
    data.forEach(item => {
      if (!item.id) {
        arr.push(item)
      }
    })
    props.onSubmit({
      ...fieldValue,
      thesaurusDTOList: arr,
      thesaurusIdList: isDel
    })
  }

  const handleCancel = () => {
    form.resetFields()
    setTitle('新增字典')
    setData([])
    setTotal(null)
    setIsDel([])
    setInitialValue(null)
    setIsModalVisible(false)
  }

  const handlecheck = async(e) => {
    await fetch({
      url: api.dictionaryApi.verify,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        id: initialValue.id,
        verifyStatus: e
      },
    })
    props.onCheck()
  }

  useImperativeHandle(ref, () => ({
    handleOpen,
    handleCancel,
  }))

  const columns = [
    {
      title: '编码',
      dataIndex: 'code',
      key: 'code',
      render: (text, record) => {
        return (
          <div className={classNames({
            [styles.tableGary]: record.isDeleted === 1
          })}>{text}</div>
        )
      }
    },
    {
      title: '名称',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => {
        return (
          <div className={classNames({
            [styles.tableGary]: record.isDeleted === 1
          })}>{text}</div>
        )
      }
    },
    {
      title: '来源',
      dataIndex: 'source',
      key: 'source',
      render: (text, record) => {
        return (
          <div className={classNames({
            [styles.tableGary]: record.isDeleted === 1
          })}>{text}</div>
        )
      }
    },
    {
      title: '审核状态',
      dataIndex: 'verifyStatus',
      key: 'verifyStatus',
      render: (text, record) => {
        const status = record.isDeleted === 1 ? 0 : text
        const typeObj = types.filter((type) => {
          return type.value === status
        })
        return <div className={classNames({
          [styles.statusColor]: true,
          [styles.statusIn]: status === 0,
          [styles.statusSuccess]: status === 1,
          [styles.statusFail]: status === 2,
          [styles.statusGary]: record.isDeleted === 1
        })}>{typeObj[0].text}</div>
      }
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      render(text, record, index) {
        return (
          <a
            disabled={record.isDeleted === 1}
            onClick={() => del(index)}
          >
            删除
          </a>
        )
      },
    },
  ]

  return (
    <>
      <Modal
        forceRender
        width='580px'
        title={title}
        open={isModalVisible}
        footer={[
          <Button key="back" onClick={handleCancel}>
            取消
          </Button>,
          initialValue && initialValue.verifyStatus === 0 && <Button key="danger" danger style={permissionStyle('DICTIONARY_LIST_AUDIT')} onClick={() => handlecheck(2)}>审核拒绝</Button>,
          initialValue && initialValue.verifyStatus === 0 && <Button key="ghost" ghost type="primary" className={styles.auditBtn} style={permissionStyle('DICTIONARY_LIST_AUDIT')} onClick={() => handlecheck(1)}>审核通过</Button>,
          <Button key="submit" type="primary" onClick={handleOk}>
            确定
          </Button>
        ]}
        onCancel={handleCancel}>
        <Form
          form= {form}
          labelCol={{
            span: 4,
          }}
          wrapperCol={{
            span: 18,
          }}
          onFinish={onFinish}
        >
          <Form.Item
            style={{display: 'none'}}
            label="id"
            name="id"
          >
            <Input placeholder="请输入编码" />
          </Form.Item>
          <Form.Item
            label="编码"
            name="conceptCode"
          >
            <Input placeholder="请输入编码" />
          </Form.Item>
          <Form.Item
            label="名称"
            name="conceptName"
            rules={[
              {
                required: true,
                message: '请输入名称',
              },
            ]}
          >
            <Input placeholder="请输入名称" />
          </Form.Item>
          <Form.Item
            label="来源"
            name="source"
            rules={[
              {
                required: true,
                message: '请输入来源',
              },
            ]}
          >
            <Input placeholder="请输入来源" />
          </Form.Item>
          <Form.Item
            label="同义词"
            name="source"
          >
            <Button type="primary" ghost onClick={() => addRef.current.show()}>新增</Button>
          </Form.Item>
          {data.length >0 && <Table
            style={{margin: '20px'}}
            size="small"
            columns={columns}
            dataSource={data}
            onChange={handleTableChange}
            loading={loading}
            pagination={pagination}
            scroll={{ y: 400 }}
            rowKey={(record) => record.code}
          />}
          <Form.Item
            label="是否生效"
            name="valid"
            rules={[
              {
                required: true,
                message: '请选择生效方式！',
              },
            ]}
          >
            <Radio.Group>
              <Radio value={true}>生效</Radio>
              <Radio value={false}>失效</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label="备注"
            name="remark"
          >
            <Input.TextArea placeholder="请输入备注" />
          </Form.Item>
        </Form>
      </Modal>
      <Add ref={addRef} onSuccess={fetchData} />
    </>
  )
}

export default forwardRef(DicEditModal)
