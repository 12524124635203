// eslint-disable-next-line no-unused-vars
import React, {useState, forwardRef, useImperativeHandle, useRef, useEffect, useMemo} from 'react'
import {Col, message, Modal, Row, Select, Space, Tabs,} from 'antd'
import {useWindowSize} from '@hooks/useLayout'
import TagTree from '@views/project/components/tagTree'
import fetch from '@utils/request'
import api from '@api'
import styles from './index.module.less'
import TableTransfer from '@views/project/components/tableTransfer'
import Search from 'antd/es/input/Search'
import {uniqueId} from 'lodash/util'
import Highlighter from 'react-highlight-words'
import usePagination from '@hooks/usePagination'
const LabelSelectModalV1 = (props, ref) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [curTabKey, setCurTabKey] = useState('label')
  const [treeData, setTreeData] = useState([])
  const [curTreeNode, setCurTreeNode] = useState(null)
  const [transferData, setTransferData] = useState([])
  const [targetKeys, setTargetKeys] = useState([])
  const [targetNodes, setTargetNodes] = useState([])
  // eslint-disable-next-line no-unused-vars
  const [keyword, setKeyword] = useState(null)
  const [defaultProject, setDefaultProject] = useState([])
  // eslint-disable-next-line no-unused-vars
  const [curProjectType, setCurProjectType] = useState('')
  const [projectList, setProjectList] = useState([])
  const [,, scale] = useWindowSize()
  const tagTreeRef = useRef(null)
  // eslint-disable-next-line no-unused-vars
  const [page, setPage, setTotal, pagination] = usePagination()
  const tabItems = useMemo(() => {
    if (props.noProject) {
      return [
        {
          key: 'label',
          label: '原子标签',
        }
      ]
    }
    return [
      {
        key: 'label',
        label: '原子标签',
      },
      {
        key: curProjectType,
        label: '项目标签',
      }
    ]
  }, [curProjectType, props.noProject])

  const leftTableColumns = [
    {
      dataIndex: 'name',
      title: '',
      render: (text) => {
        if (keyword) {
          return <Highlighter
            highlightClassName="YourHighlightClass"
            searchWords={Array.from(new String(keyword))}
            autoEscape={true}
            textToHighlight={text}
          />
        } else {
          return <span>{text}</span>
        }
      }
    },
  ]
  const rightTableColumns = [
    {
      dataIndex: 'title',
      title: 'Name',
    },
  ]


  // 已选中的树节点
  let selectedNodes = []


  const geProjectList = async (node) => {
    const res = await fetch({
      method: 'GET',
      url: api.tagApi.crfProjectList,
    })
    setProjectList(res)
    setDefaultProject(res[0]?.id)
    setCurProjectType(`project_${res[0]?.id}`)
  }
  /*
  * 递归树，获取已选中的树节点
  * */
  function targetTreeNodes (treeNodes = [], selectedKeys) {
    selectedNodes = treeNodes.filter( ({ children, ...props }) => {
      return selectedKeys.includes(props.id)
    })
  }

  const onChange = (keys) => {
    setTargetKeys(keys)
    targetTreeNodes(transferData, keys)
    setTargetNodes(selectedNodes)
  }
  const handleCancel = () => {
    setIsModalVisible(false)
    setTargetKeys([])
    setTargetNodes([])
    setCurTreeNode(null)
    setTransferData([])
    setKeyword(null)
    setPage({
      pageIndex: 1,
      pageSize: 10,
    })
    setTotal(0)
  }
  const handleOk = () => {
    if (!targetNodes.length) {
      return message.warn('请先选择标签！')
    }
    if (targetNodes.length > 1) {
      return message.warn('仅支持单选！')
    }
    const dd = uniqueId()
    props.onSure(targetNodes, curTabKey)
    console.log('targetNodes', targetNodes)
    console.log('dd', dd)
    // setIsModalVisible(false)
    handleCancel()
  }

  const handleOpen = (e) => {
    setIsModalVisible(true)
  }
  const initPage = () => {
    setPage({
      pageIndex: 1,
      pageSize: 10,
    })
  }

  const tabChange = (key) => {
    setCurTabKey(key)
    setTransferData([])
    tagTreeRef.current.onSelectClear()
    setCurTreeNode([])
    initPage()
  }

  /*
  * 获取标签目录树
  * */
  const getCategory = async () => {
    if (curTabKey !== 'label' && !curProjectType) return null
    const res = await fetch({
      method: 'POST',
      url: api.tagApi.generalContentTree,
      data: {
        type: curTabKey === 'label'? 'label': curProjectType,
        status: 2
      },
    })
    setTreeData(res)
  }
  /*
  * 获取标签目录树下的标签列表
  * */
  // eslint-disable-next-line no-unused-vars
  const getTagsByCategoryId = async (node) => {
    console.log('curTabKey', curTabKey)
    const pager = {
      pageNumber: page.pageIndex,
      pageSize: page.pageSize,
    }
    let extraParams = {
      categoryId: node?.categoryId,
      categoryType: props.noProject?'label':curProjectType,
      checkStatus: '2',
      keyword,
    }
    if (curTabKey !== 'label') {
      extraParams.projectId = parseInt(curProjectType?.split('project_')[1])
    }
    const {content, totalCount} = await fetch({
      method: 'POST',
      url: api.tagApi.getTagsByCategory,
      data: {
        ...extraParams,
        ...pager,
      },
      headers: {
        'Content-Type': 'application/json'
      }
    })
    console.log('getTagsByCategoryId', content)
    setTransferData(content)
    setTotal(totalCount)
  }
  const onSearch = (e) => {
    //
    console.log('setKeyword', e)
    setKeyword(e)
  }
  const labelNode = (type) => {
    if (type === 'left') {
      return <Search
        placeholder="标签名称"
        size="middle"
        allowClear
        onSearch={onSearch}
        style={{
          width: '100%'
        }}
      />
    } else if (type === 'right') {
      return <div>已选中的标签（仅支持单选）</div>
    } else {
      return null
    }
  }

  /*
  * 标签树选中事件
  * */
  const tagTreeSelect = (selectedKeys, node) => {
    //
    setCurTreeNode(node)
    console.log('tagTreeSelect', selectedKeys)
    console.log('tagTreeSelect.node', node)
    initPage()
  }

  const handleTableChange = (pagination, filters, sorter, extra) => {
    setPage({
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
    })
  }
  useEffect(() => {
    getCategory()
  }, [curTabKey, curProjectType])
  useEffect(() =>{
    geProjectList()
  }, [curTabKey])

  useEffect(() => {
    (curTreeNode || keyword) && getTagsByCategoryId(curTreeNode)
  }, [curTreeNode, keyword, page])


  useImperativeHandle(ref, () => ({
    handleOpen,
    initPage
  }))

  return (
    <Modal
      title="选择标签"
      width={1140*scale}
      visible={isModalVisible}
      onOk={handleOk}
      bodyStyle={{padding: `${16*scale}px ${24*scale}px`}}
      onCancel={handleCancel}
      zIndex={1001}
      destroyOnClose
    >
      <>
        <Tabs defaultActiveKey="1" items={tabItems} onChange={tabChange} />
        {(curTabKey !== 'label') && <Space style={{ marginRight: 30, marginBottom: 15}}>
          <div>项目：</div>
          <Select
            style={{width: `${160*scale}px` }}
            placeholder="请选择项目"
            showSearch={true}
            optionFilterProp='projectName'
            value={defaultProject}
            onChange={(e) => {
              setCurProjectType(`project_${e}`)
              setDefaultProject(e)
              setTransferData([])
              setCurTreeNode([])
            }}
            options={projectList}
            fieldNames={{value: 'id', label: 'projectName'}}
          />
        </Space>
        }

        <Row gutter={24}>
          <Col flex={`${scale*348}px`} span={8} className={styles.tagTree} style={{background: '#FAFAFA'}}>
            <TagTree ref={tagTreeRef} showNumber treeData={treeData} treeSelect={tagTreeSelect}/>
          </Col>
          <Col span={16} flex={1}>
            <TableTransfer
              dataSource={transferData}
              targetKeys={targetKeys}
              render={(item) => item.name}
              leftColumns={leftTableColumns}
              rightColumns={rightTableColumns}
              showSelectAll={false}
              onChange={onChange}
              selectAllLabels={[labelNode('left'), labelNode('right')]}
              pagination={pagination}
              handleTableChange={handleTableChange}
            />
          </Col>
        </Row>
      </>
    </Modal>
  )
}

export default forwardRef(LabelSelectModalV1)
