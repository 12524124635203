import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Result } from 'antd'
import {useSelector} from 'react-redux'

const Exception404 = () => {
  const navigate = useNavigate()
  const privateHomeRoute = useSelector((state) => state.app.privateHomeRoute)
  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={
        <Button type="primary" onClick={() => navigate(privateHomeRoute)}>
                    Back Home
        </Button>
      }
    />
  )
}

export default Exception404
