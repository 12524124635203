import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle, lazy, Suspense } from 'react'
import {Form, Space, Button, Descriptions, message, Input} from 'antd'
// import LabelSelectModal from '../../components/labelSelectModaV1.js'
const LabelSelectModal = lazy(() => import('../../components/labelSelectModaV1.js'))
import fetch from '@utils/request'
import api from '@api'
import {useWindowSize} from '@hooks/useLayout'
import styles from './index.module.less'
import {useNavigate, useSearchParams} from 'react-router-dom'
import uuid from '@utils/tool'
import {cloneDeep, uniq} from 'lodash'
import TagEdit from '@views/tag/engine'
import { ReactComponent as DeleteIcon } from '@assets/images/icons/icon_delete.svg'
// import { ReactComponent as EditIcon } from '@assets/images/icons/icon_edit.svg'

const LinkTagIndex = (props, ref) => {
  const [selectedData, setSelectedData] = useState([])
  const [form] = Form.useForm()
  const labelSelectRef = useRef(null)
  const [,, scale] = useWindowSize()
  const [params] = useSearchParams()
  const projectId = params.get('projectId')
  const fieldId = params.get('fieldId')
  const fieldName = params.get('fieldName')
  const fieldRuleId = params.get('fieldRuleId')
  const formName = params.get('formName')
  const ruleClassName = params.get('ruleClassName')
  const [outputType, setOutType] = useState('')
  const status = params.get('status')
  const navigate = useNavigate()
  const [draftId, setdraftId] = useState(null)
  const tagEditRef = useRef(null)
  const [editRow, setEditRow] = useState(null) // 编辑标签规则
  const [showLabelModal, setShowLabelModal] = useState(false) // 显示选择标签modal

  /*
  * 获取已绑定的标签
  * */
  const fetchRuleList = async() => {
    const res = await fetch({
      method: 'POST',
      url: api.projectApi.crfRuleList,
      headers: {
        'Content-Type': 'application/json'
      },
      data: {
        draftId: '',
        projectId,
        fieldRuleId,
        ruleClassName
      },
    })
    const result = res.filter(n=>n)
    result.map((item) => {
      item.outputType += ''
    })
    form.setFieldsValue({
      ruleIdVersions: result
    })
    setSelectedData(result)
  }

  /*
  * 获取绑定标签详情
  * */
  const fetchCrfField = async() => {
    const res = await fetch({
      method: 'GET',
      url: api.projectApi.crfField,
      params: {
        fieldId,
      },
    })
    setOutType(res)
  }

  const handleValue = (e) => {
    form.setFieldsValue(e)
  }

  // 保存
  const onFinish = async(values) => {
    const {ruleIdVersions} = values
    // if (!ruleIdVersions.length) {
    //   return message.warn('请先选择标签')
    // }
    if (ruleIdVersions.length > 1) {
      return message.warn('只能选择一个标签')
    }
    ruleIdVersions?.map((item) => {
      if ((item.id+ '').match(/_/)){
        item.id = item.id.split('_')[0]
      }
    })
    await fetch({
      method: 'POST',
      url: api.projectApi.crfBind,
      data: {
        projectId,
        fieldRuleId,
        ruleIdVersion: ruleIdVersions[0] || null,
      },
      headers: {
        'Content-Type': 'application/json'
      }
    })
    message.success('关联成功！')
    navigate(-1)
  }

  /*
* 标签和项目预绑定
* */
  const draftBind = async (data, callback) => {
    const res = await fetch({
      method: 'POST',
      url: api.projectApi.crfDraftBind,
      data: {
        draftId,
        projectId,
        fieldId,
        ruleIdVersions: data
      },
      headers: {
        'Content-Type': 'application/json'
      }
    })
    callback(res)
  }

  // 打开选择弹窗
  // eslint-disable-next-line no-unused-vars
  const onselect = (e) => {
    setShowLabelModal(true)
    setTimeout(() => {
      labelSelectRef.current.handleOpen(e)
    }, 200)
  }

  // 确认选择，接受选中项
  const onSure = (targetNodes, type) => {
    if (targetNodes.length > 1) {
      return message.error('仅支持单选！')
    }
    if (type === 'label') {
      draftBind(targetNodes, (draftData) => {
        let uniqData = uniq([...draftData])
        setSelectedData((prevState) => uniqData)
        form.setFieldsValue({
          ruleIdVersions: uniqData
        })
      })
    } else {
      const uniqData = uniq([...targetNodes])
      form.setFieldsValue({
        ruleIdVersions: uniqData
      })
    }
  }
  const showCanvas = (data, type) => {
    tagEditRef.current.show(data)
  }
  // eslint-disable-next-line no-unused-vars
  const onEdit = (record) => {
    setEditRow(record)
    showCanvas(record, 'edit')
  }
  /*
* 标签新增or编辑
* */
  const doneEdit = async (canvasData) => {
    // eslint-disable-next-line no-unused-vars
    const response = await fetch(
      {
        method: 'POST',
        url: api.tagApi.labelSave,
        data: {
          categoryId: editRow.categoryId,
          categoryName: editRow.categoryName,
          id: editRow.id,
          categoryType: '',
          // categoryType: type,
          name: canvasData.tagName,
          remark: canvasData.remark,
          ruleJson: canvasData.modeJSON,
          version: editRow.version,
          ruleSet: 'project'
        },
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        }
      }
    )
    tagEditRef.current.hide()
    // 刷新列表
    // treeNode && getList()
  }

  const removeRow = (func, index) => {
    func(index)
    const newData = cloneDeep(selectedData)
    newData.splice(index, 1)
    setSelectedData(newData)
  }

  useEffect(() => {
    fetchRuleList()
    fetchCrfField()
    const uuidValue = uuid()
    setdraftId(uuidValue)
  }, [])

  useImperativeHandle(ref, () => ({
    handleValue
  }))

  return (
    <>
      {/* 标题栏 */}
      <div className="head">
        <h3 className="head-title">关联标签选择</h3>
      </div>
      <div className={styles.pageWrapper}>
        <Descriptions title="">
          <Descriptions.Item label="关联表单">{formName}</Descriptions.Item>
          <Descriptions.Item label="关联字段">{fieldName}</Descriptions.Item>
          <Descriptions.Item label="输出方式">{outputType || ''}</Descriptions.Item>
        </Descriptions>
        <Form
          form= {form}
          labelCol={{
            span: 4,
          }}
          wrapperCol={{
            span: 20,
          }}
          onFinish={onFinish}
        >
          <Form.Item>
            <Button
              disabled={status === '-1'}
              type='primary'
              ghost
              onClick={() => {
                onselect(1)
              }}
            >选择标签</Button>
          </Form.Item>
          <Form.List
            name="ruleIdVersions"
            initialValue={selectedData}
          >
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    size={8*scale}
                    key={key}
                    {...restField}
                    style={{
                      display: 'flex',
                      marginBottom: 8,
                    }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      name={[name, 'name']}
                      rules={[
                        {
                          required: true,
                          message: '请选择标签',
                        },
                      ]}
                    >
                      <Input
                        readOnly
                        disabled={status === '-1'}
                        style={{ minWidth: 200*scale }}
                        className={styles.select}
                        showSearch={false}
                        placeholder="请选择标签"
                      ></Input>
                    </Form.Item>
                    <Form.Item>
                      <Space size={8*scale} align='center'>
                        <Button disabled={status === '-1'} type='text' ghost icon={<DeleteIcon style={{color: '#00AFEC', width: `${16*scale}px`, height: `${16*scale}px`}}/>} onClick={() => removeRow(remove, name)}/>
                        {/* <Button disabled={status === '-1'} type='text' ghost icon={<EditIcon style={{color: '#00AFEC', width: `${16*scale}px`, height: `${16*scale}px`}}/>} onClick={() => onEdit(selectedData[name])}/> */}
                      </Space>
                    </Form.Item>
                  </Space>
                ))}
              </>
            )}
          </Form.List>
          <Form.Item>
            <Space>
              <Button
                disabled={status === '-1'}
                type="primary"
                htmlType="submit"
              >
                保存
              </Button>
              <Button onClick={() => {
                navigate(-1)
              }}>取消</Button>
            </Space>
          </Form.Item>
        </Form>
      </div>
      {showLabelModal && (
        <Suspense>
          <LabelSelectModal ref={labelSelectRef} onSure={onSure} projectId={projectId} />
        </Suspense>
      )}

      <TagEdit ref={tagEditRef} doneEdit={doneEdit} editRow={editRow} source="project"/>
    </>
  )
}

export default forwardRef(LinkTagIndex)
