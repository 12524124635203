import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { Button, Form, Input, TreeSelect, Modal } from 'antd'
import PropTypes from 'prop-types'
import { tree2Array, filterHalfChecked } from '@utils/common'
import fetch from '@utils/request'
import api from '@api'

// 获取父级权限
const getParentId = (id, powersArr) => {
  const target = powersArr.find((item) => item.value === id)
  return target ? target.parentId : null
}

const EditRole = ({ onSuccess }, ref) => {
  const [visible, setVisible] = useState(false)
  const [powers, setPowers] = useState([])
  const [type, setType] = useState('add')
  const [form] = Form.useForm()

  const show = (data) => {
    setType(data ? 'edit' : 'add')
    const powerArray = tree2Array(powers, 'children')
    if (data) {
      data.privilegeIds = filterHalfChecked(powerArray, data.privileges)
      form.setFieldsValue(data)
      setType('edit')
    }
    setVisible(true)
  }
  const hide = () => {
    if (type === 'edit') {
      form.resetFields()
    }
    setVisible(false)
  }
  useImperativeHandle(ref, () => ({
    show,
  }))

  const fetchPowers = async () => {
    const mapKeys = (arr, parentId) => {
      if (!arr) return null
      return arr.map((item) => {
        return {
          title: item.name,
          value: item.id,
          key: item.id,
          parentId: parentId,
          children: mapKeys(item.childrenPrivileges, item.id),
        }
      })
    }
    const data = await fetch({
      url: api.permissionApi.privileges,
      method: 'GET',
    })
    const result = mapKeys(data, null)
    setPowers(result)
  }

  useEffect(() => {
    fetchPowers()
  }, [])

  const handleSubmit = async (values) => {
    const powerArray = tree2Array(powers, 'children')
    // 权限子项选择时，父级带上
    if (values.privilegeIds && values.privilegeIds.length) {
      for (let index = 0; index < values.privilegeIds.length; index++) {
        const id = values.privilegeIds[index]
        const parentId = getParentId(id, powerArray)
        if (parentId && !values.privilegeIds.includes(parentId)) {
          values.privilegeIds.push(parentId)
        }
      }
      values.privilegeIds = values.privilegeIds.join(',')
    }

    if (type === 'add') {
      await fetch({ url: api.permissionApi.add, data: values, method: 'POST' })
    } else {
      await fetch({ url: api.permissionApi.edit, data: values, method: 'PUT' })
    }
    form.resetFields()
    hide()
    typeof onSuccess === 'function' && onSuccess()
  }

  return (
    <Modal
      onCancel={hide}
      forceRender={true}
      visible={visible}
      width='40%'
      title={type === 'add' ? '新增角色' : '编辑角色'}
      footer={
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <div>
            <Button onClick={hide} className="custom-btn">
                  取消
            </Button>
            <Button type="primary" onClick={() => form.submit()} className="custom-btn">
                  确定
            </Button>
          </div>
        </div>
      }
    >
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Form.Item
          name="name"
          rules={[
            { required: true, message: '请输入角色名称' },
            { max: 10, message: '最多输入10个字符' },
          ]}
          label="角色名称"
        >
          <Input placeholder="请输入..." autoComplete="off" />
        </Form.Item>
        <Form.Item
          name="describe"
          label="角色描述"
          rules={[{ max: 20, message: '最多输入20个字符' }]}
        >
          <Input placeholder="请输入..." autoComplete="off" />
        </Form.Item>
        <Form.Item
          name="privilegeIds"
          label="功能权限"
          rules={[{ required: true, message: '请选择功能权限' }]}
        >
          <TreeSelect
            treeData={powers}
            multiple
            showSearch
            treeCheckable
            placeholder="请选择..."
            showCheckedStrategy="SHOW_ALL"
          />
        </Form.Item>
        {type === 'edit' && (
          <Form.Item name="id" hidden>
            <Input />
          </Form.Item>
        )}
      </Form>
    </Modal>
  )
}

export default forwardRef(EditRole)

EditRole.propTypes = {
  onSuccess: PropTypes.func,
}
