import React, { useState, useCallback } from 'react'
import { Input } from 'antd'
import PropTypes from 'prop-types'
import { ReactComponent as SearchIcon } from '@assets/images/icons/search.svg'
import { debounce } from '@utils/common'

const DebounceSearch = ({ onSearch, ...rest }) => {
  const [text, setText] = useState('')
  // 搜索防抖
  const debounceSearch = useCallback(
    debounce((e) => {
      typeof onSearch === 'function' && onSearch(e)
    }, 500),
    []
  )

  return (
    <Input
      suffix={<SearchIcon />}
      onChange={(e) => {
        setText(e.target.value)
        debounceSearch(e)
      }}
      value={text}
      {...rest}
    />
  )
}

export default DebounceSearch

DebounceSearch.propTypes = {
  onSearch: PropTypes.func,
  onChange: PropTypes.func,
}
