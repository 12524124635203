/*
* 逻辑运算符控件
* */

import React from 'react'
import {useSelector} from 'react-redux'
import {Field} from 'redux-form'
import {ASelect} from '@components/redux-form-antd'

const LogicWidget = ({namePath}) => {
  const sysConst = useSelector((state) => state.app.sysConst)
  const logicOptions = sysConst.filter(item => item.type === 'engine_logic_type')[0].valueNameVOS
  return (
    <>
      <Field
        label="选择运算符"
        name={`${namePath}.operator`}
        component={ASelect}
        value={null}
        placeholder="请选择运算符"
        fieldNames={{label: 'name', value: 'value'}}
        options={logicOptions}
        onFocus={(e) => e.preventDefault()}
        onBlur={(e) => e.preventDefault()}
      />
    </>
  )
}

export default LogicWidget
