import React, { useEffect, useState, useRef } from 'react'
import { Button, Table, Space, Modal, Tooltip, message } from 'antd'
import { EditOutlined, DownloadOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import classNames from 'classnames'
import styles from './index.module.less'
import { EXPORT_STATUS } from '@constants/common'
import { enumValueByKey } from '@utils/filter'
import { useLocation } from 'react-router'
import ExportModal from './components/exportModal'
import usePagination from '@hooks/usePagination'
import fetch from '@utils/request'
import api from '@api'
import { permissionStyle } from '@utils/permission'
import { exportZipcom } from '@utils/export'

const ProjectExport = () => {
  const { state: {id} } = useLocation()
  const exportRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([])
  const [page, setPage, setTotal, pagination] = usePagination()

  const getList = async() => {
    setLoading(true)
    const pager = page
    const res = await fetch({
      method: 'POST',
      url: api.projectApi.projectTask,
      data: {
        proId: id,
        ...pager
      }
    })
    if (res && res.content.length>0) {
      setList(res.content.map(item => {
        return {
          key: item.id,
          ...item
        }
      }))
      setTotal(res.totalCount)
    }
    setLoading(false)
  }

  // 翻页
  const onTableChange = (e) => {
    setPage({
      pageNumber: e.current,
      pageSize: e.pageSize
    })
  }

  // 创建任务
  const headClick = () => {
    exportRef.current.handleOpen(true)
    exportRef.current.handleValue({})
  }

  // 任务删除
  const onDel = (record) => {
    Modal.confirm({
      title: '确定删除该任务？',
      icon: <ExclamationCircleOutlined />,
      okText: '确认',
      cancelText: '取消',
      onOk: async() => {
        await fetch({
          method: 'POST',
          url: api.projectApi.deleteProjectExportTask,
          data: {
            taskId: record.id
          }
        })
        message.success('删除成功')
        getList()
      }
    })
  }

  // 任务下载
  const onDoenload = async(record) => {
    const res = await fetch({
      method: 'POST',
      url: api.projectApi.downloadProjectExportTask,
      data: {
        taskId: record.id
      },
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      responseType: 'blob'
    })
    exportZipcom('任务', res)
  }

  // 任务编辑
  const onEdit = async(record) => {
    exportRef.current.handleOpen()
    exportRef.current.handleValue(record)
  }

  // 新增成功
  const onSuccess = () => {
    setPage({
      pageNumber: 1,
      pageSize: page.pageSize
    })
  }

  useEffect(() => {
    getList()
  }, [page])

  const columns = [
    {
      title: '用户名',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '创建人',
      dataIndex: 'createdBy',
      key: 'createdBy',
    },
    {
      title: '创建时间',
      dataIndex: 'createdTime',
      key: 'createdTime',
    },
    {
      title: '说明',
      dataIndex: 'remarks',
      key: 'remarks',
    },
    {
      title: '状态',
      dataIndex: 'status',
      key: 'status',
      render: (text) => {
        const statusClass = classNames({
          [styles.statusNormal]: true,
          [styles.statusGreen]: Number(text) === 0,
          [styles.statusYellow]: Number(text) === 1,
          [styles.statusRed]: Number(text) === 2
        })
        return (
          <div className={statusClass}>{enumValueByKey(text, EXPORT_STATUS)}</div>
        )
      }
    },
    {
      title: '操作',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Tooltip mouseEnterDelay="2" title="编辑">
            <a onClick={() => onEdit(record)} style={permissionStyle('PROJECT_TASK_SAVE')}><EditOutlined /></a>
          </Tooltip>
          <Tooltip mouseEnterDelay="2" title="下载">
            <a onClick={() => onDoenload(record)} style={permissionStyle('PROJECT_TASK_DOWNLOAD')}><DownloadOutlined /></a>
          </Tooltip>
          <Tooltip mouseEnterDelay="2" title="删除">
            <a onClick={() => onDel(record)} style={permissionStyle('PROJECT_TASK_DELETE')}><DeleteOutlined /></a>
          </Tooltip>
        </Space>
      ),
    },
  ]

  return (
    <>
      {/* 标题栏 */}
      <div className="head">
        <h3 className="head-title">导出任务</h3>
        <Button type="primary" onClick={headClick}>创建任务</Button>
      </div>
      {/* 表格 */}
      <div className={styles.tagTableSpace}>
        <Table
          rowKey="id"
          loading={loading}
          columns={columns}
          dataSource={list}
          pagination={pagination}
          onChange={onTableChange}
        />
      </div>
      <ExportModal ref={exportRef} proId={id} onSuccess={onSuccess} />
    </>
  )
}

export default ProjectExport
