import React, { useImperativeHandle, useState, forwardRef } from 'react'
import { Form, Modal, Input, message } from 'antd'
import fetch from '@utils/request'
import api from '@api'
import { useWindowSize } from '@hooks/useLayout'

const Edit = ({ onSuccess, type = 'cdisc' }, ref) => {
  const [, , scale] = useWindowSize()
  const [visible, setVisible] = useState(false)
  const [record, setRecord] = useState({})
  const [form] = Form.useForm()
  const hide = () => {
    form.resetFields()
    setRecord({})
    setVisible(false)
  }
  const show = (record) => {
    if (record) {
      setRecord(record)
      form.setFieldsValue(record)
    }

    setVisible(true)
  }
  useImperativeHandle(ref, () => ({
    show,
  }))

  const validateNames = (_, value) => {
    const errorText = '表单名称（中文）、表单名称（英文）两者至少填写一项'
    const { formCnName, formEnName } = form.getFieldsValue()
    if (!formCnName && !formEnName) {
      return Promise.reject(errorText)
    }
    if (formCnName) {
      form.setFields([{ name: 'formEnName', errors: [] }])
    }
    if (formEnName) {
      form.setFields([{ name: 'formCnName', errors: [] }])
    }
    return Promise.resolve()
  }

  const onSubmit = async (values) => {
    await fetch({
      method: 'POST',
      url: api[type === 'omop' ? 'omopControlApi' : 'cdiscApi'].saveForm,
      data:
        type === 'omop'
          ? {
            ...record,
            formCnName: values.formCnName || '',
            formEnName: values.formEnName || '',
          }
          : {
            ...record,
            formCnName: values.formCnName || '',
            formEnName: values.formEnName || '',
            formCode: values.formCode,
          },
      headers: {
        'Content-Type': 'application/json',
      },
    })
    hide()
    message.success(record.id ? '编辑成功' : '新增成功')
    typeof onSuccess === 'function' && onSuccess()
  }

  return (
    <Modal
      open={visible}
      onCancel={hide}
      title={record.id ? '编辑表单' : '新增表单'}
      width={600 * scale}
      onOk={() => form.submit()}
    >
      <Form form={form} labelCol={{ span: 6 }} onFinish={onSubmit}>
        <Form.Item
          label="表单名称（中文）"
          name="formCnName"
          rules={[{ validator: validateNames }]}
        >
          <Input placeholder="请输入表单名称（中文）" />
        </Form.Item>
        <Form.Item
          label="表单名称（英文）"
          name="formEnName"
          rules={[{ validator: validateNames }]}
        >
          <Input placeholder="请输入表单名称（英文）" />
        </Form.Item>
        {type === 'cdisc' && (
          <Form.Item
            label="表单编码"
            name="formCode"
            rules={[
              {
                required: true,
                message: '请输入表单编码',
              },
            ]}
          >
            <Input placeholder="请输入表单编码" />
          </Form.Item>
        )}
      </Form>
    </Modal>
  )
}

export default forwardRef(Edit)
