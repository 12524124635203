import React, { useEffect, useRef, useState } from 'react'
import { Row, Col, Space, Modal, Table, Spin, message, Button } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import KeySearch from '@components/form/search'
import styles from './index.module.less'
import classNames from 'classnames'
import usePagination from '@hooks/usePagination'
import fetch from '@utils/request'
import api from '@api'
import { useWindowSize } from '@hooks/useLayout'
import { useNavigate } from 'react-router-dom'
import Edit from './components/edit'
import { permissionStyle} from '@utils/permission'

const OmopControl = () => {
  const navigate = useNavigate()
  const searchRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([])
  const [page, setPage, setTotal, pagination] = usePagination()
  const [sorter, setSorter] = useState({})
  const [, , scale] = useWindowSize()
  const editRef = useRef(null)

  const getList = async (node) => {
    setLoading(true)
    const pager = {
      pageNumber: page.pageIndex,
      pageSize: page.pageSize,
    }
    const sorterParams = {
      orderBy: sorter.field || 'updateTime',
      orderByWay: sorter.order === 'ascend' ? 0 : 1,
    }
    const res = await fetch({
      method: 'POST',
      url: api.omopControlApi.listPageForm,
      data: {
        formKeyWord: searchRef.current.getValue(),
        ...pager,
        ...sorterParams,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    })
    setList(res.content)
    setTotal(res.totalCount)
    setLoading(false)
  }


  // 关键词搜索
  const keySearchClick = (e) => {
    setPage({
      pageIndex: 1,
      pageSize: 10,
    })
  }

  // table删除
  const onDel = (record) => {
    Modal.confirm({
      title: '确认删除吗？',
      icon: <ExclamationCircleOutlined />,
      okText: '确认',
      cancelText: '取消',
      onOk: async () => {
        await fetch({
          method: 'POST',
          url: api.omopControlApi.saveForm,
          data: {
            ...record,
            isDeleted: 1,
          },
          headers: {
            'Content-Type': 'application/json',
          },
        })
        message.success('删除成功')
        getList()
      },
    })
  }


  const handleTableChange = (pagination, filters, sorter, extra) => {
    setPage({
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
    })
    setSorter(sorter)
    // fetchData()
  }

  useEffect(() => {
    getList()
  }, [page, sorter])

  let columns = [
    {
      title: '序号',
      dataIndex: 'index',
      key: 'index',
      width: 100 * scale,
      render: (text, record, index) =>
        `${index + 1 + (page.pageIndex - 1) * page.pageSize}`,
    },
    {
      title: '表单名称（中文）',
      dataIndex: 'formCnName',
      key: 'formCnName',
      width: 150 * scale,
      ellipsis: true,
      sorter: true,
      sortOrder: sorter.columnKey === 'formCnName' && sorter.order,
    },
    {
      title: '表单名称（英文）',
      dataIndex: 'formEnName',
      key: 'formEnName',
      width: 150 * scale,
      ellipsis: true,
    },
    {
      title: '变量个数',
      dataIndex: 'fieldNum',
      key: 'fieldNum',
      width: 150 * scale,
    },
    {
      title: '更新人',
      dataIndex: 'updateBy',
      key: 'updateBy',
      width: 100 * scale,
      sorter: true,
      sortOrder: sorter.columnKey === 'updateBy' && sorter.order,
    },
    {
      title: '更新时间',
      dataIndex: 'updateTime',
      key: 'updateTime',
      width: 150 * scale,
      sorter: true,
      sortOrder: sorter.columnKey === 'updateTime' && sorter.order,
    },
    {
      title: '操作',
      key: 'action',
      fixed: 'right',
      width: 150 * scale,
      render: (_, record) => {
        return (
          <Space size="middle">
            <a
              onClick={(e) => {
                e.stopPropagation()
                editRef.current.show({
                  ...record,
                })
              }}
              style={permissionStyle('OMOP_CONTROL_FORM_UPDATE')}
            >
              编辑
            </a>
            <a
              onClick={(e) => {
                e.stopPropagation()
                onDel(record)
              }}
              style={permissionStyle('OMOP_CONTROL_FORM_DELETE')}
            >
              删除
            </a>
          </Space>
        )
      },
    },
  ]

  return (
    <>
      {/* 标题栏 */}
      <div className="head">
        <h3 className="head-title">表单列表</h3>
      </div>

      {/* 标签库 */}
      <Row className={styles.rowSpace} gutter={16}>
        
        <Col span={24}>
          <div className={classNames([styles.search])}>
            <div className={styles.spaceRight}>
              <KeySearch ref={searchRef} keySearchClick={keySearchClick} />
            </div>
            <Space>
              <Button
                type="primary"
                onClick={() => {
                  editRef.current.show()
                }}
                style={permissionStyle('OMOP_CONTROL_FORM_ADD')}
              >
                新增
              </Button>
            </Space>
          </div>
          <Spin spinning={loading}>
            <Table
              rowKey="id"
              columns={columns}
              dataSource={list}
              pagination={pagination}
              scroll={{ x: scale * 1000 }}
              onChange={handleTableChange}
              onRow={(record) => {
                return {
                  onClick: () => {
                    localStorage.setItem('formRow', JSON.stringify(record))
                    const path = '/control/omop/field'
                    navigate(
                      `${path}?formId=${record.id}`
                    )
                  },
                }
              }}
            />
          </Spin>
        </Col>
      </Row>
      <Edit ref={editRef} onSuccess={getList} type='omop'/>
    </>
  )
}

export default OmopControl
