/*
* 运算符控件
* */

import {Form, Select} from 'antd'
import React from 'react'
import {useSelector} from 'react-redux'
import {get} from 'lodash'

const OperatorWidget = ({namePath, exportType}) => {
  const sysConst = useSelector((state) => state.app.sysConst)
  const exportOptions = sysConst.filter(item => item.type === 'engine_operator_output')[0]?.valueNameVOS
  return (
    <>
      <Form.Item
        label="输出类型"
        name={[...namePath, 'leftType']}
      >
        <Select
          placeholder="选择输出类型"
          options={exportOptions}
          fieldNames={{label: 'name'}}
        />
      </Form.Item>

      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) => get(prevValues, [...namePath, 'leftType']) !== get(currentValues, [...namePath, 'leftType'])}
      >
        {({ getFieldValue, setFieldValue }) => {
          // setFieldValue([...namePath, 'operator'], '')
          const exportType = getFieldValue([...namePath, 'leftType'])
          const options = sysConst.filter(item => item.type === `engine_operator_${exportType}`)[0]?.valueNameVOS
          return (
            <Form.Item
              label="选择运算符"
              name={[...namePath, 'operator']}
            >
              <Select
                options={options}
                fieldNames={{label: 'name', value: 'value'}}
              />
            </Form.Item>
          )
        }
        }
      </Form.Item>
    </>
  )
}

export default OperatorWidget
