export const PROJECT_TYPE = [
  {
    value: 'CTI',
    desc: '患者招募',
  },
  {
    value: 'CRF',
    desc: '智能录入',
  },
]

export const HOSPITAL_TYPE = [
  {
    value: 'linpin',
    desc: '临平医院',
  },
  {
    value: 'zhejiang',
    desc: '浙江医院',
  },
]

export const EXPORT_STATUS = [
  {
    value: 0,
    desc: '生成中',
  },
  {
    value: 1,
    desc: '已完成',
  },
  {
    value: 2,
    desc: '生成失败',
  },
]

export const TAG_STATUS = [
  {
    value: 7,
    desc: '全部',
  },
  {
    value: 2,
    desc: '审核通过',
  },
  {
    value: 1,
    desc: '待审核',
  },
  {
    value: 4,
    desc: '审核拒绝',
  },
]

export const CRF_STATUS = [
  {
    value: 1,
    desc: '待关联',
  },
  {
    value: 2,
    desc: '待验证',
  },
  {
    value: 3,
    desc: '待发布',
  },
  {
    value: 4,
    desc: '已发布',
  },
  {
    value: -1,
    desc: '已失效',
  },
]

export const PASSWORD_EXPIRE = 90
