import { Layout, Menu } from 'antd'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import styles from './index.module.css'
const { Sider } = Layout
import {useWindowSize} from '@hooks/useLayout'
import logoImg from '@assets/images/app/logo.png'
import {useSelector} from 'react-redux'
import useMenu from '@hooks/useMenu'
/*
* 暂时不支持内联样式从px转成rem，临时方案： 用useWindowSize来返回scale解决antd内联样式不能响应式
* */
const PrimeSideMenu = () => {
  const [authMenu] = useMenu()
  const items = authMenu && authMenu[0]?.children
  const navigate = useNavigate()
  const collapsed = useSelector((state)=> state.app.collapsed)
  const [, , scale] = useWindowSize()
  const siderWidth = 200 *scale
  const collapsedWidth = 80 *scale
  const onClick = ({ item }) => {
    const {path} = item.props
    navigate(path)
  }

  return (
    <Sider className={[styles.siteBg, 'logo']} width={siderWidth} trigger={null} collapsible collapsed={collapsed} collapsedWidth={collapsedWidth}>
      <div className={styles.sideLogo}>
        <img className={styles.sideLogoImg} src={logoImg} alt=""/>
        { !collapsed && <div>KBMS</div>}
      </div>
      <Menu
        defaultSelectedKeys={['1']}
        mode="inline"
        items={items}
        onClick={onClick}
      />
    </Sider>
  )
}

export default PrimeSideMenu
