import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react'
import { Modal, Form, Input, Select, TreeSelect, message } from 'antd'
const { Option } = Select
import fetch from '@utils/request'
import api from '@api'
import scale from '@utils/auto-rem'

const cTypes = ['布尔', '数值', '文本', '日期时间']

const MetaModal = (props, ref) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [type, setType] = useState('编辑')
  const [treeId, setTreeId] = useState(null)
  const [oldName, setOldName] = useState(null)
  const [dwdTree, setDwdTree] = useState([])
  const [loading, setLoading] = useState(false)
  const [tableColumnName, setTableColumnName] = useState(null)
  const [form] = Form.useForm()

  const handleOpen = (e, code) => {
    setIsModalVisible(true)
    setType(e)
    setTreeId(code) // 新增时取到code
  }

  const handleValue = (record) => {
    setTableColumnName(record.tableColumnName)
    setTreeId(record.contentId) // 编辑时取得code
    setOldName(record.tableColumnName) // 编辑时存储主键
    form.setFieldsValue({
      'tableColumnComment': record.tableColumnComment,
      'dataType': record.dataType,
      'columnLength': record.columnLength || undefined,
      'isAllowed': record.isAllowed || undefined,
      'enumAble': record.enumAble
    })
  }

  const loop = (arr, flag) => {
    arr.forEach(item => {
      item.value = `${item.tableName}${item.name ? `.${item.name}` : ''}`
      item.label = item.name ? item.name : item.tableName
      item.disabled = flag
      item.key = item.dwdCode
      if (item.children.length > 0) {
        item.children = loop(item.children)
      }
    })
    return arr
  }

  const getDwdTree = async() => {
    const res = await fetch({
      method: 'get',
      url: api.dictionaryApi.getDwdTableTree,
      params: {}
    })
    loop(res, true)
    setDwdTree(res)
  }

  useEffect(() => {
    getDwdTree()
  }, [])

  const handleOk = async() => {
    await form.submit()
  }

  const handleCancel = () => {
    form.resetFields()
    setTableColumnName(null)
    setTreeId(null)
    setOldName(null)
    setIsModalVisible(false)
  }

  const onFinish = async(values) => {
    try {
      setLoading(true)
      if (type === 1) {
        // 新增
        await fetch({
          method: 'POST',
          url: api.dictionaryApi.addDataSource,
          data: {
            ...values,
            contentId: treeId,
            tableColumnName: tableColumnName
          },
          headers: {
            'Content-Type': 'application/json'
          }
        })
        props.onSuccess()
        message.success('保存成功！')
        handleCancel()
      } else {
        // 修改
        await fetch({
          method: 'POST',
          url: api.dictionaryApi.updateDataSource,
          data: {
            ...values,
            contentId: treeId,
            tableColumnName: tableColumnName,
            tableColumnOldName: oldName,
            IsDeleted: 0
          },
          headers: {
            'Content-Type': 'application/json'
          }
        })
        props.onSuccess()
        message.success('修改成功！')
        handleCancel()
      }
    } finally {
      setLoading(false)
    }
  }

  useImperativeHandle(ref, () => ({
    handleOpen,
    handleValue
  }))

  const dwdSelect = (value, node) => {
    console.log(node)
    setTableColumnName(`${node.tableCode}${node.code ? `.${node.code}` : ''}`)
    form.setFieldsValue({
      'dataType': node.dataType,
      'columnLength': node.commentLength,
      'enumAble': node.enumAble,
      'isAllowed': node.isAllowed
    })
  }

  return (
    <Modal
      title={(type === 1 ? '新增' : '编辑') + '数据来源'}
      open={isModalVisible}
      width={600*scale}
      confirmLoading={loading}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <Form
        form= {form}
        labelCol={{
          span: 6,
        }}
        wrapperCol={{
          span: 16,
        }}
        onFinish={onFinish}
      >
        <Form.Item
          label="数据来源名称"
          name="tableColumnComment"
          rules={[
            {
              required: true,
              message: '请选择数据来源名称',
            },
          ]}
        >
          <TreeSelect
            showSearch
            placeholder="请选择"
            treeNodeFilterProp="name"
            treeData={dwdTree}
            fi
            onSelect={dwdSelect}
            treeNodeLabelProp='value'
          />
        </Form.Item>
        <Form.Item
          label="数据格式"
          name="dataType"
          rules={[
            {
              required: true,
              message: '请选择数据格式',
            },
          ]}
        >
          <Select
            placeholder="请选择"
          >
            {cTypes.map((item, i) => (
              <Option key={item}>{item}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="长度"
          name="columnLength"
        >
          <Input placeholder="请输入" />
        </Form.Item>
        <Form.Item
          label="允许值"
          name="isAllowed"
        >
          <Input placeholder="请输入" />
        </Form.Item>
        <Form.Item
          label="是否可枚举"
          name="enumAble"
        >
          <Select
            allowClear
            placeholder="请选择"
          >
            <Option key='1'>是</Option>
            <Option key='0'>否</Option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default forwardRef(MetaModal)

