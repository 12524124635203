import React, {useEffect, useState} from 'react'
import { Tree } from 'antd'
import fetch from '@utils/request'
import api from '@api'
import {getKeys} from '@utils/common'
import Highlighter from 'react-highlight-words'
const generateTree = (treeNodes = [], checkedKeys = []) =>
  treeNodes.map(({ children, ...props }) => ({
    ...props,
    disabled: checkedKeys.includes(props.id),
    children: generateTree(children, checkedKeys),
  }))
const StockTagTree = (props) => {
  const [treeData, setTreeData] = useState([])
  const [expandedKeys, setExpandedKeys] = useState([])
  const updateTreeData = (list, key, children) =>
    list.map((node) => {
      if (node.id === key) {
        return { ...node, children }
      }

      if (node.children) {
        return {
          ...node,
          children: updateTreeData(node.children, key, children),
        }
      }

      return node
    })
  const getCategory = async (parentId, type, keyword) => {
    const res = await fetch({
      method: 'GET',
      url: api.tagApi.stockRule,
      params: {
        pid: parentId,
        keyword
      }
    })
    res.map((item) => {
      item.isLeaf = item.leaf
      item.checkable = item.leaf
    })
    if (type === 'async'){
      setTreeData((origin) => updateTreeData(origin, parentId, res))
    } else {
      if (keyword) {
        const keys = getKeys(res, 'id')
        setExpandedKeys(keys)
      } else {
        setExpandedKeys([])
      }
      setTreeData(res)
    }
    props.updateTransferData(res)

  }
  const onLoadData = async (node) => {
    if (props.keyword) return null
    getCategory(node.id, 'async')
  }
  const onExpand = (expandedKeysValue) => {
    setExpandedKeys(expandedKeysValue)
  }

  useEffect( () => {
    getCategory(null, null, props.keyword)
  }, [props.keyword])

  return <Tree
    {...props}
    expandedKeys={expandedKeys}
    onExpand={onExpand}
    loadData={onLoadData}
    treeData={generateTree(treeData, props.targetKeys)}
    titleRender={({name, count}) =>{
      if (props.keyword) {
        return <Highlighter
          highlightClassName="YourHighlightClass"
          searchWords={Array.from(new String(props?.keyword))}
          autoEscape={true}
          textToHighlight={name}
        />
      } else if (count){
        return (
          <div>{`${name} (${count})`}</div>
        )
      } else {
        return (
          <div>{`${name}`}</div>
        )
      }
    }}
    fieldNames={{
      key: 'id',
      title: 'name',
    }}
  />
}

export default StockTagTree
