import React from 'react'
import {Form, Input, Radio, Select, Checkbox, DatePicker, InputNumber, Table} from 'antd'
import moment from 'moment'

const FormItem = Form.Item
const RadioGroup = Radio.Group
const { TextArea } = Input
const { RangePicker } = DatePicker

// eslint-disable-next-line react/display-name
const makeField = (Component, type) => ({ input, meta, children, hasFeedback, label, labelCol, labelAlign, valuePropName, changeEvent, required, itemStyle, ...rest }) => {
  const hasError = meta.touched && meta.invalid
  let extraProps = {}
  valuePropName && (extraProps.dataSource = input.value)
  return (
    <FormItem
      labelAlign={labelAlign || 'right'}
      labelCol={labelCol || {
        span: 6,
      }}
      required={required}
      label={label}
      style={itemStyle}
      validateStatus={hasError ? 'error' : 'success'}
      hasFeedback={hasFeedback && hasError}
      help={hasError && meta.error}
    >
      <Component
        {...input}
        value={input.value || undefined}
        {...extraProps}
        {...rest}
        onChange={value => {
          input.onChange(value) // call the onChange function passed to you by `Field` this will update pristine, dirty values.
          changeEvent && changeEvent(value) // call your other side effect function
        }}
      >

        {children}
      </Component>
    </FormItem>
  )
}

// eslint-disable-next-line react/display-name,no-unused-vars
const makeField1 = Component => ({ input, meta, children, hasFeedback, label, labelCol, labelAlign, required, itemStyle, ...rest }) => {
  const hasError = meta.touched && meta.invalid
  return (
    <FormItem
      labelAlign={labelAlign || 'right'}
      labelCol={labelCol || {
        span: 6,
      }}
      required={required}
      label={label}
      style={itemStyle}
      validateStatus={hasError ? 'error' : 'success'}
      hasFeedback={hasFeedback && hasError}
      help={hasError && meta.error}
    >
      <Component {...input} value={input.value ? moment(input.value): undefined} {...rest} >
        {children}
      </Component>
    </FormItem>
  )
}


export const AInput = makeField(Input)
export const AInputNumber = makeField(InputNumber)
export const AInputTextArea = makeField(Input.TextArea)
export const ARadioGroup = makeField(RadioGroup)
export const ASelect = makeField(Select, 'select')
export const ACheckbox = makeField(Checkbox)
export const ATextarea = makeField(TextArea)
export const ARangePicker = makeField1(RangePicker)
export const ADatePicker = makeField1(DatePicker)
export const ATable = makeField(Table)

