import React, { useState, useRef, useEffect } from 'react'
import { Input, Button, Table, Space, Modal, message, Form } from 'antd'
import styles from './index.module.less'
import Edit from '../components/excludeDrugEdit'
import usePagination from '@hooks/usePagination'
import api from '@api'
import fetch from '@utils/request'
import { permissionStyle } from '@utils/permission'

const ExcludeDrug = () => {
  const [data, setData] = useState([])
  const [page, setPage, setTotal, pagination] = usePagination()
  const [keyWord, setKeyWord] = useState('')
  const [loading, setLoading] = useState(false)
  const editRef = useRef(null)
  const [searchForm] = Form.useForm()

  const handleTableChange = (pagination, filters, sorter) => {
    setPage({
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
    })
  }
  const fetchData = async () => {
    setLoading(true)
    const { content, totalCount } = await fetch({
      url: api.aeApi.pageExcludeProjectList,
      method: 'GET',
      params: {
        pageNumber: page.pageIndex,
        pageSize: page.pageSize,
        keyWord,
      },
    })
    setData(content)
    setTotal(totalCount)
    setLoading(false)
  }
  useEffect(() => {
    fetchData()
  }, [page, keyWord])

  const edit = (record) => {
    editRef.current.show(record)
  }

  const del = (record) => {
    Modal.confirm({
      title: '提示',
      content: '确认删除此项目排除药品？',
      onOk: async () => {
        await fetch({
          url: api.aeApi.deleteExcludeConcept,
          method: 'GET',
          params: {
            projectId: record.id,
          },
        })
        message.success('删除成功')
        if (data.length === 1 && page.pageIndex > 1) {
          setPage({
            pageIndex: page.pageIndex - 1,
            pageSize: page.pageSize,
          })
        } else {
          fetchData()
        }
      },
    })
  }

  const columns = [
    {
      title: '项目编号',
      dataIndex: 'projectCode',
      key: 'projectCode',
      width: 400,
    },
    {
      title: '项目名称',
      dataIndex: 'projectName',
      key: 'projectName',
      width: 400,
    },
    {
      title: '排除药品名称',
      dataIndex: 'conceptDTOList',
      key: 'conceptDTOList',
      width: 400,
      render(text, record) {
        if (text && text.length > 0) {
          return text.map((item) => item.name).join('、')
        }
        return '-'
      },
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      render(text, record) {
        return (
          <Space>
            <a
              onClick={() => edit(record)}
              style={permissionStyle('CM_CONFIG_EDIT')}
            >
              编辑
            </a>
            <a
              onClick={() => del(record)}
              style={permissionStyle('CM_CONFIG_DELETE')}
            >
              删除
            </a>
          </Space>
        )
      },
      fixed: 'right',
    },
  ]

  return (
    <>
      <div className={styles.drugHeader}>
        <Form
          form={searchForm}
          onFinish={(values) => {
            setKeyWord(values.keyWord?.trim())
            setPage({
              pageIndex: 1,
              pageSize: 10,
            })
          }}
          autoComplete="off"
        >
          <Form.Item name="keyWord" noStyle>
            <Input
              style={{ width: 300, marginRight: 20 }}
              placeholder="请输入项目编号/名称"
              allowClear
              maxLength={100}
            />
          </Form.Item>
          <Space>
            <Button
              type="primary"
              onClick={() => {
                searchForm.submit()
              }}
            >
              搜索
            </Button>
            <Button
              onClick={() => {
                setKeyWord('')
                setPage({
                  pageIndex: 1,
                  pageSize: 10,
                })
                searchForm.resetFields()
              }}
            >
              重置
            </Button>
          </Space>
        </Form>
        <Button
          type="primary"
          onClick={() => {
            editRef.current.show()
          }}
          style={permissionStyle('CM_CONFIG_ADD')}
        >
          新增
        </Button>
      </div>
      <Table
        columns={columns}
        dataSource={data}
        loading={loading}
        pagination={pagination}
        onChange={handleTableChange}
        scroll={{ x: 'max-content' }}
        rowKey={(record) => record.id}
      />
      <Edit ref={editRef} onSuccess={fetchData} />
    </>
  )
}

export default ExcludeDrug
