import React, {useRef} from 'react'
import {Avatar, Dropdown, Menu} from 'antd'
import {useWindowSize} from '@hooks/useLayout'
import { DownOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons'
import styles from './index.module.css'
import {logoutAsync} from '@store/app'
import {useDispatch, useSelector} from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ChangePwd from './components/changePwd'

const PrimeSettings = () => {
  const [, , scale] = useWindowSize()
  const dispatch = useDispatch()
  const avatarSize = 32*scale
  const navigate = useNavigate ()
  const changePwdRef = useRef(null)
  const {username} = useSelector((state) => state?.app?.userInfo)
  const logout = async () => {
    await dispatch(logoutAsync())
    navigate('/login')
  }
  const editPwd = () => {
    changePwdRef.current.show()
  }
  const dropdownMenus = (
    <Menu
      items={[
        {
          label: (
            <a onClick={editPwd}>
              <UserOutlined
                className={styles.actionIcon}/>
                    修改密码
            </a>
          ),
          key: '0',
        },
        {
          type: 'divider',
        },
        {
          label: (
            <a onClick={logout}>
              <LogoutOutlined className={styles.actionIcon}/>
                    退出登录
            </a>
          ),
          key: '1',
        },
      ]}
    />
  )

  return (
    <div>
      <Avatar size={avatarSize} src="https://gw.alipayobjects.com/zos/antfincdn/XAosXuNZyF/BiazfanxmamNRoxxVxka.png" />
      <Dropdown
        overlay={dropdownMenus}
        placement="bottomRight"
        arrow
        className={styles.dropdown}>
        <span onClick={(e) => e.preventDefault()}>
          <span>
            {username}
            <DownOutlined className={styles.dropdownIcon}/>
          </span>
        </span>
      </Dropdown>
      <ChangePwd ref={changePwdRef} success={logout}/>
    </div>
  )
}

export default PrimeSettings
