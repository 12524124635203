import React, { useState, useImperativeHandle, forwardRef, useRef } from 'react'
import { message, Form, Input, Select, Row, Col } from 'antd'
import PropTypes from 'prop-types'
import { ReactComponent as AddIcon } from '@assets/images/icons/import_add.svg'
import CustomModal from '@components/customModal'
import FileUpload from '@components/fileUpload'
import fetch from '@utils/request'
import api from '@api'
import styles from './index.module.less'
import { DownloadOutlined } from '@ant-design/icons'
import { exportExcel } from '@utils/export'
import {useWindowSize} from '@hooks/useLayout'


const ImportProject = ({ onSuccess, vocabularyList }, ref) => {
  const uploadRef = useRef(null)
  const [visible, setVisible] = useState(false)
  const [error, setError] = useState(false)
  const [file, setFile] = useState(null)
  const [uploading, setUploading] = useState(false)
  const [form] = Form.useForm()
  const [,, scale] = useWindowSize()

  const hide = () => {
    uploadRef.current.clear()
    setFile(null)
    setUploading(false)
    setVisible(false)
    setError(false)
  }
  const show = () => {
    setVisible(true)
  }
  useImperativeHandle(ref, () => ({
    show,
    hide,
  }))

  const handleSubmit = async () => {
    const validate = await form.validateFields()
    if (!validate) return
    if (!file) {
      setError(true)
      return
    }
    setUploading(true)
    const values = form.getFieldsValue()
    const formData = new FormData()
    formData.append('file', file)
    formData.append('vocabulary', values.vocabulary)
    formData.append('projectName', values.projectName)
    try {
      const importTotal = await fetch({
        url: api.mappingApi.import,
        data: formData,
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      setFile(null)
      setVisible(false)
      hide()
      message.success(`导入成功，${importTotal}`)
      typeof onSuccess === 'function' && onSuccess()
    } catch (error) {
      setUploading(false)
    }
  }

  const downloadTemplate = async () => {
    try {
      const res = await fetch({
        url: api.mappingApi.getTemplate,
        method: 'GET',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        responseType: 'blob',
      })
      if (res.code === 0) {
        message.error(res.message)
      } else {
        exportExcel('导入模板', res)
        message.success('下载成功!')
      }
    } catch (error) {}
  }

  return (
    <CustomModal
      open={visible}
      onCancel={hide}
      title="文件导入"
      onOk={handleSubmit}
      okButtonProps={{ loading: uploading }}
      width={640*scale}
    >
      <Form layout="vertical" form={form}>
        <Row gutter={20}>
          <Col span={12}>
            <Form.Item
              label="医院"
              name="projectName"
              rules={[
                {
                  required: true,
                  message: '请输入医院名称',
                },
              ]}
            >
              <Input placeholder="请输入医院名称" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="知识类型"
              name="vocabulary"
              rules={[
                {
                  required: true,
                  message: '请选择知识类型',
                },
              ]}
            >
              <Select
                placeholder="请选择知识类型"
                options={vocabularyList}
                fieldNames={{
                  label: 'name',
                  value: 'value',
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label="模板文件" required>
          <FileUpload
            ref={uploadRef}
            uploadProps={{
              accept: '.xlsx, .xls',
              children: (
                <>
                  <div className={styles.btn}>
                    <AddIcon style={{ color: '#031f47', marginRight: 5 }} />
                    点击导入模板文件
                  </div>
                  <div className={styles.text}>
                    仅支持 xlsx 文件，单次最多上传 1 个
                  </div>
                </>
              ),
            }}
            maxCount={1}
            onChange={(files) => {
              setFile(files[0])
              setError(false)
            }}
          />
          {error && <div className={styles.error}>请选择文件</div>}
        </Form.Item>
        <div
          style={{ color: '#1CC2FD', cursor: 'pointer' }}
          onClick={downloadTemplate}
        >
          模板下载
          <DownloadOutlined />
        </div>
      </Form>
    </CustomModal>
  )
}

export default forwardRef(ImportProject)

ImportProject.propTypes = {
  onSuccess: PropTypes.func,
}
