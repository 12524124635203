import {Draggable} from 'react-beautiful-dnd'
import DragItem from '@views/tag/engine/components/dragItem'
import ItemWidget from '@views/tag/engine/components/widget'
import React, {memo} from 'react'
import {isEqual} from 'lodash'
const getItemStyle = (isDragging, draggableStyle, highLight, color) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  // change background colour if dragging
  background: isDragging ? 'lightgreen' : '#fff',

  // styles we need to apply on draggables
  ...draggableStyle,
  border: highLight && `1px solid ${color}`
})
const FormArea = ({items, selectedNode, ...restProps}) => {
  return items?.map((item, index) => {
    console.count('items-rereder')
    if (item.type === 'START') {
      return null
    }
    return (
      <Draggable key={item.key.toString()} draggableId={item.key.toString()} index={item.sortIndex}>
        {(provided, snapshot) => (
          <DragItem
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={getItemStyle(
              snapshot.isDragging,
              provided.draggableProps.style,
              item.key === selectedNode?.key,
              item.fill
            )}
            color={item.fill}
            title={item.text}>
            <>
              {/* <ItemWidget key={index} type={item.type} index={item.sortIndex} itemKey={item.key} {...restProps}/> */}
              <ItemWidget item={item} index={index} {...restProps} items={items}/>
            </>
          </DragItem>
        )}
      </Draggable>
    )
  })

}

const shouldUpdate = (prevProps, nextProps) => {

  if (
    isEqual(prevProps.items, nextProps.items)
      && isEqual(prevProps.linkDataArray, nextProps.linkDataArray)
      && prevProps?.selectedNode?.key === nextProps?.selectedNode?.key
  ) {
    return true
  } else {
    return false
  }
}
export default memo(FormArea, shouldUpdate)
