/*
* 字典相关api
* */
const menuApi = {
  treeList: '/kbms/generalContent/tree', // 获取目录树
  delete: '/kbms/generalContent/delete', // 通用目录-删除
  update: '/kbms/generalContent/update', // 通用目录-修改
  add: '/kbms/generalContent/add', // 通用目录-新增
  move: '/kbms/generalContent/move', // 通用目录-移动
  moveBusinessData: '/kbms/generalContent/moveBusinessData', // 业务数据移动的时候调的接口
}

export default menuApi
