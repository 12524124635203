import React, { useState, useImperativeHandle, forwardRef, useRef } from 'react'
import { message, Form } from 'antd'
import PropTypes from 'prop-types'
import { ReactComponent as AddIcon } from '@assets/images/icons/import_add.svg'
import CustomModal from '@components/customModal'
import FileUpload from '@components/fileUpload'
import fetch from '@utils/request'
import api from '@api'
import styles from './index.module.less'

const ImportProject = ({ onSuccess }, ref) => {
  const uploadRef = useRef(null)
  const [visible, setVisible] = useState(false)
  const [error, setError] = useState(false)
  const [file, setFile] = useState(null)
  const [uploading, setUploading] = useState(false)

  const hide = () => {
    uploadRef.current.clear()
    setFile(null)
    setUploading(false)
    setVisible(false)
    setError(false)
  }
  const show = () => {
    setVisible(true)
  }
  useImperativeHandle(ref, () => ({
    show,
    hide,
  }))

  const handleSubmit = async () => {
    if (!file) {
      setError(true)
      return
    }
    setUploading(true)
    const formData = new FormData()
    formData.append('file', file)
    try {
      const importTotal = await fetch({
        url: api.knowledgeApi.examImport,
        data: formData,
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      setFile(null)
      setVisible(false)
      hide()
      message.success(`导入成功，${importTotal}`)
      typeof onSuccess === 'function' && onSuccess()
    } catch (error) {
      console.log('err:', error)
      setUploading(false)
    }
  }

  return (
    <CustomModal
      open={visible}
      onCancel={hide}
      title="文件导入"
      onOk={handleSubmit}
      okButtonProps={{ loading: uploading }}
      width={640}
    >
      <Form layout="vertical">
        <Form.Item label="模板文件" required>
          <FileUpload
            ref={uploadRef}
            uploadProps={{
              accept: '.xlsx',
              children: (
                <>
                  <div className={styles.btn}>
                    <AddIcon style={{ color: '#031f47', marginRight: 5 }} />
                    点击导入模板文件
                  </div>
                  <div className={styles.text}>仅支持  xlsx 文件，单次最多上传 1 个</div>
                </>
              ),
            }}
            maxCount={1}
            onChange={(files) => {
              setFile(files[0])
              setError(false)
            }}
          />
          {error && <div className={styles.error}>请选择文件</div>}
        </Form.Item>
      </Form>
    </CustomModal>
  )
}

export default forwardRef(ImportProject)

ImportProject.propTypes = {
  onSuccess: PropTypes.func,
}
