import React, {useEffect, useState, forwardRef, useImperativeHandle} from 'react'
import { Tree, Input, Dropdown, Menu, Modal, Form, Empty, Spin } from 'antd'
import { PlusOutlined, FolderOpenFilled } from '@ant-design/icons'
const { Search } = Input
import styles from './index.module.less'
import fetch from '@utils/request'
import api from '@api'

const MenuTree = (props, ref) => {
  const [tree, setTree] = useState([])
  const [selectedKeys, setSelectedKeys] = useState([])
  const [record, setRecord] = useState({})
  const [isOpen, setIsOpen] = useState(false)
  const [loading, setLoading] = useState(true)
  const [title, setTitle] = useState(null)
  const [flag, setFlag] = useState(null)
  const [form] = Form.useForm()

  const treeLoop = (data, parentKey) => {
    data.forEach((item, index) => {
      item.key = parentKey?`${parentKey}-${index}`:`${item.level}-${index}`
      item.childrenList = item.children.concat(item.businessDataList || [])
      if (item.children) {
        treeLoop(item.children, item.key)
      }
    })
    return data
  }

  // 获取目录列表
  const getTree = async(e) => {
    try {
      const res = await fetch({
        method: 'POST',
        url: api.menuApi.treeList,
        data: {
          showData: props.type === 'standardVocabulary' ? 0 : 1,
          keyword: e,
          type: props.type,
          ...props.extraParams
        }
      })
      if (res && res.length>0) {
        const result = treeLoop(res)
        setTree(result)
      } else {
        setTree([])
      }
    } finally {
      setLoading(false)
    }
  }

  const refreshTree = () => {
    getTree()
  }

  useEffect(() => {
    // 屏蔽右键浏览器默认行为
    document.getElementById('menuTree').oncontextmenu = function(e) {
      e = e || window.event
      return false
    }

    props.type && getTree()
  }, [props?.type])

  // 搜索
  const onSearch = (e) => {
    getTree(e)
  }

  // 节点选择
  const onSelect = (key, e) => {
    if (!key.length){
      return
    }
    props.treeSelect(e)
    setSelectedKeys(key)
  }

  const onSelectClear = () => {
    setSelectedKeys([])
  }

  useEffect(() => {
    setSelectedKeys(props.defaultSelectedKeys)
  }, [])

  useImperativeHandle(ref, () => ({
    onSelectClear,
    refreshTree
  }))

  const handleCancel = () => {
    setIsOpen(false)
    form.resetFields()
  }

  const onFinish = async() => {
    const values = form.getFieldValue()
    if (flag === '1' || flag === '2') {
      await fetch({
        method: 'POST',
        url: api.menuApi.add,
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          id: record.code,
          type: props.type,
          name: values.name,
          path: record.path,
          parentId: record.parentCode,
          isSameLevel: flag - 1
        }
      })
      getTree()
      handleCancel()
    } else if (flag === '3') {
      await fetch({
        method: 'POST',
        url: api.menuApi.update,
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          id: record.code,
          type: props.type,
          path: record.path,
          name: values.name
        }
      })
      getTree()
      handleCancel()
    }
  }

  const onEdit = (e, name) => {
    setFlag(e)
    setTitle(e === '3' ? '编辑' : '新增')
    // 编辑填充标题
    if (e === '3') {
      form.setFieldsValue({
        'name': name
      })
    }
    setIsOpen(true)
  }

  const onDel = async(record) => {
    await fetch({
      method: 'POST',
      url: api.menuApi.delete,
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        id: record.code,
        type: props.type,
        path: record.path
      }
    })
    getTree()
  }

  const onClick = (e, record) => {
    e.domEvent.stopPropagation()
    setRecord(record)
    switch (e.key) {
      case '4':
        onDel(record)
        // 删除
        break
      default:
        onEdit(e.key, record.name)
    }
  }

  const authItems = (record) => {
    let cacheItems = []
    let items = [
      {
        label: '新增同级',
        key: '1',
      },
      {
        label: '新增子级',
        key: '2',
        disabled: record.level > 3
      },
      {
        label: '编辑',
        key: '3',
        disabled: record.name === '全部' && record.level === 1
      },
      {
        label: '删除',
        key: '4',
        disabled: record.name === '全部' && record.level === 1
      }
    ]

    props.authItemKeys && items.forEach((item, index) => {
      if (props?.authItemKeys[index]) {
        cacheItems.push(item)
      }
    })
    return cacheItems
  }

  const menuItems = (record) => (
    <Menu
      items={authItems(record)}
      onClick={(e) => onClick(e, record)}
    />
  )

  const titleRender = (nodeData) => {
    return (
      <>
        {nodeData.isCategory && <Dropdown
          dropdownRender={() => menuItems(nodeData)}
          trigger={['contextMenu']}
        >
          <div>
            {props.type === 'standardVocabulary' && nodeData.isCategory && <FolderOpenFilled style={{ marginRight: '4px' }} />}
            {nodeData.name}
            {props.showNumber && <span> ({nodeData.number})</span>}
          </div>
        </Dropdown>}
        {!nodeData.isCategory && <div>
          {props.type === 'standardVocabulary' && nodeData.isCategory && <FolderOpenFilled style={{ marginRight: '4px' }} />}
          {nodeData.name}
        </div>}
      </>
    )
  }

  // const dropEnter = (info) => {
  //   const dropKey = info.node.code
  //   const dragKey = info.dragNode.code
  //   const dropPos = info.node.pos.split('-')
  //   const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1])
  //   const loop = (data, key, callback) => {
  //     for (let i = 0; i < data.length; i++) {
  //       if (data[i].code === key) {
  //         return callback(data[i], i, data)
  //       }
  //       if (data[i].children) {
  //         loop(data[i].children, key, callback)
  //       }
  //     }
  //   }
  //   const data = [...tree]

  //   // Find dragObject
  //   let dragObj
  //   loop(data, dragKey, (item, index, arr) => {
  //     arr.splice(index, 1)
  //     dragObj = item
  //   })
  //   if (!info.dropToGap) {
  //     // Drop on the content
  //     loop(data, dropKey, (item) => {
  //       item.children = item.children || []
  //       // where to insert 示例添加到头部，可以是随意位置
  //       item.children.unshift(dragObj)
  //     })
  //   } else if (
  //     (info.node.props.children || []).length > 0 &&
  //     // Has children
  //     info.node.props.expanded &&
  //     // Is expanded
  //     dropPosition === 1 // On the bottom gap
  //   ) {
  //     loop(data, dropKey, (item) => {
  //       item.children = item.children || []
  //       // where to insert 示例添加到头部，可以是随意位置
  //       item.children.unshift(dragObj)
  //       // in previous version, we use item.children.push(dragObj) to insert the
  //       // item to the tail of the children
  //     })
  //   } else {
  //     let ar = []
  //     let i
  //     loop(data, dropKey, (_item, index, arr) => {
  //       ar = arr
  //       i = index
  //     })
  //     if (dropPosition === -1) {
  //       ar.splice(i, 0, dragObj)
  //     } else {
  //       ar.splice(i + 1, 0, dragObj)
  //     }
  //   }
  //   setTree(data)
  // }

  const onDrop = async(info) => {
    if (!info.dragNode.isCategory) {
      await fetch({
        method: 'POST',
        url: api.menuApi.moveBusinessData,
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          isChildLevel: info.dropToGap ? false : true,
          code: info.dragNode.code,
          locateId: info.dropPosition === -1 ? null : (info.node.isCategory ? info.node.categoryId : null),
          locateCode: info.dropPosition === -1 ? null : (info.node.isCategory ? null : info.node.code),
          isCategory: info.node.isCategory ? true : false
        }
      })
      getTree()
      return
    }
    await fetch({
      method: 'POST',
      url: api.menuApi.move,
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        dropToGap: info.dropToGap ? 0 : 1,
        locateId: info.dropPosition === -1 ? null : info.node.code,
        locateParentId: info.dropPosition === -1 ? null : info.node.parentCode,
        locateSort: info.dropPosition === -1 ? null : info.node.sort,
        locatePath: info.dropPosition === -1 ? null : info.node.path,
        moveId: info.dragNode.code,
        movePath: info.dragNode.path,
        moveParentId: info.dragNode.parentCode,
        type: props.type
      }
    })
    getTree()
    // dropEnter(info)
  }

  return (
    <div id="menuTree" className={styles.main}>
      {props.title && <div className={styles.mainTitle}>
        <span> {props.title}</span>
        {tree && tree.length === 0 &&
          <div className={styles.mainTitleAdd} onClick={() => onEdit('1')}><PlusOutlined /></div>
        }
      </div>}
      {props.search && (
        <div className={styles.mainSearch}>
          <Search
            placeholder="搜索"
            size="middle"
            allowClear
            onSearch={onSearch}
            style={{
              width: '100%',
            }}
          />
        </div>
      )}
      <div className={styles.treeBox}>
        {loading && <div className={styles.treeSpin}><Spin /></div>}
        {!loading && tree.length > 0 && <Tree
          className={styles.tree}
          treeData={tree}
          draggable={props.draggable === false?
            false
            :{
              icon: false,
              nodeDraggable: n => !(n.level ===1 && n.name === '全部')
            }}
          fieldNames={{
            children: 'childrenList'
          }}
          titleRender={titleRender}
          onDrop={onDrop}
          onSelect={onSelect}
          height={props.height}
          selectedKeys={selectedKeys}
        />}
        {!loading && tree.length === 0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
      </div>
      <Modal title={title} open={isOpen} onOk={() => form.submit()} onCancel={handleCancel}>
        <Form
          form ={form}
          name="treeForm"
          labelCol={{
            span: 6,
          }}
          wrapperCol={{
            span: 16,
          }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            label="目录名称"
            name="name"
            rules={[
              {
                required: true,
                message: '请输入目录名称',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}

export default forwardRef(MenuTree)
