/*
* 数据控件
* */

import React, {useEffect, useState} from 'react'
import {Form, Select} from 'antd'
import api from '@api'
import fetch from '@utils/request'
const DataWidget = ({namePath, ...restProps}) => {
  const form = Form.useFormInstance()
  const [metaData, setMetaData] = useState(null)
  const [metaSourceData, setMetaSourceData] = useState(null)
  // eslint-disable-next-line no-unused-vars
  const [defaultSourceItems, setDefaultSourceItems] = useState()
  const fetchMetaList = async () => {
    const data = await fetch({
      method: 'POST',
      url: api.tagApi.listDataElement,
      data: {
        keyword: ''
      },
      headers: {
        'Content-Type': 'application/json'
      },
    })
    setMetaData(data)
  }

  const fetchMetaSource = async () => {
    const data = await fetch({
      method: 'POST',
      url: api.tagApi.listDataSource,
      data: {
        keyword: ''
      },
      headers: {
        'Content-Type': 'application/json'
      },
    })
    setMetaSourceData(data)
  }

  const metaDataChanged = (newValue) => {
    const curMetaItem = metaData.filter((item) => item.id === newValue)
    form.setFieldValue([...namePath, 'sourceTableFields'], curMetaItem[0]?.dataSourceNames)
    restProps.asyncData()
  }

  useEffect(() => {
    fetchMetaList()
    fetchMetaSource()
  }, [restProps.itemKey])
  console.count('render')
  return (
    <>
      <Form.Item
        label='选择数据元素'
        name={[...namePath, 'metaId']}
      >
        <Select
          fieldNames={{
            label: 'elementName',
            value: 'id',
          }}
          showSearch={true}
          optionFilterProp='elementName'
          options={metaData}
          placeholder="选择数据元素"
          onChange={metaDataChanged}
        />
      </Form.Item>
      <Form.Item
        label='选择数据来源'
        name={[...namePath, 'sourceTableFields']}
      >
        <Select
          mode='multiple'
          optionFilterProp='tableColumnComment'
          fieldNames={{
            label: 'tableColumnComment',
            value: 'tableColumnName',
          }}
          options={metaSourceData}
          placeholder="选择数据来源"
          autoClearSearchValue={false}
        />
      </Form.Item>
    </>
  )
}

export default DataWidget
