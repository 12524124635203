/*
* 取算法属性控件
* */

import {Form, Select} from 'antd'
import React, {useEffect, useMemo, useState} from 'react'
import fetch from '@utils/request'
import api from '@api'
import {findVal} from '@utils/common'
import {useSelector} from 'react-redux'

const AlgAttrsWidget = ({namePath, ...restProps}) => {
  // eslint-disable-next-line no-unused-vars
  const form = Form.useFormInstance()
  const [aiModelProperties, setAiModelProperties] = useState([])
  const [preNode, setPreNode] = useState([])
  const tagAlgNode = useSelector((state) => state.app.tagAlgNode)
  const fetchAiProperties = async () => {
    const preNode = restProps.findPreNode(restProps.itemKey)
    let params = {}
    if (preNode && (preNode[0]?.type === 'AI')) {
      setPreNode(preNode)
      const modelCode = findVal(preNode[0], 'modelCode')
      params.modelCode = modelCode
    }

    const res = await fetch({
      method: 'GET',
      url: api.tagApi.aiModelProperties,
      params
    })
    setAiModelProperties(res)
  }

  useMemo(() => {
    if (tagAlgNode && tagAlgNode.key === preNode[0]?.key) {
      form.setFieldValue([...namePath, 'key'], '')
    }
  }, [tagAlgNode])

  useEffect(() => {
    fetchAiProperties()
  }, [])
  return (
    <>
      <Form.Item
        label="取算法输出"
        name={[...namePath, 'key']}
      >
        <Select
          placeholder="请选择算法属性"
          showSearch={true}
          optionFilterProp='name'
          options={aiModelProperties}
          fieldNames={{label: 'name', value: 'code'}}
        />
      </Form.Item>
    </>
  )
}

export default AlgAttrsWidget
