import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
  useEffect,
} from 'react'
import { Form, Select, Modal, Spin } from 'antd'
import PropTypes from 'prop-types'
import { ReactComponent as AddIcon } from '@assets/images/icons/import_add.svg'
import CustomModal from '@components/customModal'
import FileUpload from '@components/fileUpload'
import fetch from '@utils/request'
import api from '@api'
import styles from './index.module.less'
import { InfoCircleOutlined } from '@ant-design/icons'

const ImportDict = ({ onSuccess }, ref) => {
  const uploadRef = useRef(null)
  const [visible, setVisible] = useState(false)
  const [error, setError] = useState('')
  const [warn, setWarn] = useState('')
  const [exist, setExist] = useState(true)
  const [file, setFile] = useState(null)
  const [uploading, setUploading] = useState(false)
  const [options, setOptions] = useState([])
  const [showBtn, setShowBtn] = useState(false)
  const [checkLoading, setCheckLoading] = useState(false)
  const [form] = Form.useForm()

  const hide = () => {
    uploadRef.current.clear()
    setFile(null)
    setUploading(false)
    setVisible(false)
    setError('')
    setWarn('')
    setExist(true)
    setShowBtn(false)
    form.resetFields()
  }
  const show = () => {
    setVisible(true)
  }
  useImperativeHandle(ref, () => ({
    show,
    hide,
  }))

  // 获取目录列表
  const getOptions = async (e) => {
    const res = await fetch({
      method: 'POST',
      url: api.menuApi.treeList,
      data: {
        showData: 0,
        type: 'standardVocabulary',
      },
    })
    if (res && res.length > 0) {
      setOptions(res.map((item) => ({ label: item.name, value: item.code })))
    }
  }

  useEffect(() => {
    if (!visible) return
    getOptions()
  }, [visible])

  const handleFile = async (files) => {
    if (files.length === 0) {
      setError('')
      setWarn('')
      setExist(true)
      setShowBtn(false)
      return
    }
    setFile(files[0])
    // 文件大于5M，提示文件最大为5M
    if (files[0].size > 5 * 1024 * 1024) {
      setError('文件大小超过5M，请重新选择')
      return
    }
    setCheckLoading(true)
    const formData = new FormData()
    formData.append('file', files[0])
    const res = await fetch({
      url: api.dictionaryApi.dictCheck,
      method: 'POST',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    setCheckLoading(false)
    if (!res) {
      setShowBtn(false)
      return
    }
    const { exist, message } = res
    setExist(exist)
    if (exist) {
      setWarn(message)
      setShowBtn(true)
    } else {
      setError(message)
      setShowBtn(!message)
    }
  }

  const handleSubmit = async (values) => {
    if (!file) {
      setError('请选择文件')
      return
    }
    if (error) {
      return
    }
    setUploading(true)
    const formData = new FormData()
    formData.append('file', file)
    if (values.generalContentId) {
      formData.append('generalContentId', values.generalContentId)
    }
    try {
      const res = await fetch({
        url: api.dictionaryApi.importIkgDict,
        data: formData,
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      setFile(null)
      setVisible(false)
      hide()
      const { dictName, exist, version } = res
      Modal.success({
        title: '导入成功',
        footer: () => null,
        content: `已${
          exist ? '更新' : '创建'
        }${dictName}字典（版本号${version}）`,
        closable: true,
        okButtonProps: {
          style: {
            display: 'none',
          },
        },
      })
      typeof onSuccess === 'function' && onSuccess()
    } catch (error) {
      setUploading(false)
    }
  }

  return (
    <CustomModal
      open={visible}
      onCancel={hide}
      title="导入字典"
      onOk={() => form.submit()}
      okButtonProps={{
        loading: uploading,
        disabled: !showBtn || uploading,
      }}
      width={640}
    >
      <Form layout="vertical" form={form} onFinish={handleSubmit}>
        <Form.Item>
          <Spin spinning={checkLoading} >
            <FileUpload
              ref={uploadRef}
              uploadProps={{
                accept: '.xlsx',
                children: (
                  <>
                    <div className={styles.btn}>
                      <AddIcon style={{ color: '#031f47', marginRight: 5 }} />
                    点击导入字典
                    </div>
                    <div className={styles.text}>
                    仅支持 .xlsx 文件，单次上传 1 个文件
                    </div>
                  </>
                ),
              }}
              maxCount={1}
              onChange={handleFile}
              error={!!error}
            /></Spin>
          {error && <div className={styles.error}>{error}</div>}
          {exist && warn && (
            <div className={styles.warn}>
              <InfoCircleOutlined />
              <div className={styles.txt}>{warn}</div>
            </div>
          )}
        </Form.Item>
        {!exist && !error && (
          <Form.Item
            label="选择目录"
            name="generalContentId"
            rules={[
              {
                required: true,
                message: '请选择目录',
              },
            ]}
          >
            <Select options={options} />
          </Form.Item>
        )}
      </Form>
    </CustomModal>
  )
}

export default forwardRef(ImportDict)

ImportDict.propTypes = {
  onSuccess: PropTypes.func,
}
