import React, { useState, useEffect } from 'react'
import { Tree, Empty, Button } from 'antd'
import { CaretDownOutlined } from '@ant-design/icons'
import fetch from '@utils/request'
import api from '@api'
import styles from './index.module.less'
import {getKeys} from '@utils/common'
import Highlighter from 'react-highlight-words'
import ClipboardJS from 'clipboard'
import scale from '@utils/auto-rem'


// 获取当前节点所有子孙节点的key
const getChildrenKeys = (node) => {
  const result = []
  const loop = (children) => {
    if (children) {
      children.forEach((item) => {
        result.push(item.key)
        loop(item.children, result)
      })
    }
  }
  loop(node.children, result)
  return result
}

const IcdTree = (props) => {
  const [treeData, setTreeData] = useState(props.tree)
  const [expandedKeys, setExpandedKeys] = useState([])
  const [selectedKeys, setSelectedKeys] = useState([])

  const updateTreeData = (list, key, children) =>
    list.map((node) => {
      if (node.key === key) {
        return { ...node, children }
      }

      if (node.children) {
        return {
          ...node,
          children: updateTreeData(node.children, key, children),
        }
      }

      return node
    })

  const onLoadData = async (e) => {
    const {content, totalCount} = await fetch({
      method: 'POST',
      url: api.dictionaryApi.listDirectDescendant,
      data: {
        parentCode: e.key,
        dictionaryCode: props.dictionary.code,
      },
    })
    if (totalCount && totalCount > 0) {
      setTreeData((origin) => updateTreeData(origin, e.key, content))
    }
  }

  const onExpand = (expandedKeysValue, { expanded, node }) => {
    if (expanded) {
      setExpandedKeys(expandedKeysValue)
    } else {
      const childrenKeys = getChildrenKeys(node)
      const newExpandKeys = expandedKeysValue.filter(
        (key) => !childrenKeys.includes(key)
      )
      setExpandedKeys(newExpandKeys)
    }
  }

  const onCopy = ({title, key}, showCode) => {
    let copyText = ClipboardJS.copy(`${title}`)
    if (showCode) {
      copyText = ClipboardJS.copy(`${title}(${key})`)
    }
    ClipboardJS.copy(copyText)
  }

  // 节点选择
  const onSelect = (key, e) => {
    if (!key.length){
      return
    }
    props.treeSelect(e)
    setSelectedKeys(key)
  }
  useEffect(() => {
    setTreeData(props.tree)
    if (props.onExpanded) {
      const keys = getKeys(props.tree)
      setExpandedKeys(keys)
    } else {
      setExpandedKeys([])
    }
  }, [props.tree])



  const TreeCom = () => {
    if (props.tree.length > 0) {
      return (
        <Tree
          height={props.height || 600*scale}
          expandedKeys={expandedKeys}
          selectedKeys={selectedKeys}
          onExpand={onExpand}
          switcherIcon={<CaretDownOutlined />}
          loadData={onLoadData}
          treeData={treeData}
          onSelect={onSelect}
          titleRender={(data) => {
            const leven = (
              <div className={styles.nodeWrapper}>
                {props.highlight
                  ? <Highlighter
                    highlightClassName="YourHighlightClass"
                    searchWords={Array.from(new String(props?.highlight))}
                    autoEscape={true}
                    textToHighlight={data.title}
                  />:
                  <span>{data.title}</span>
                }
                {props.dictionary.showCode && <span> ({data.key})</span>}
                <Button type='primary' size='small' className={styles.copy} ghost onClick={() => {
                  onCopy(data, props.dictionary.showCode)
                }}>复制</Button>
              </div>
            )
            return <div>{leven}</div>
          }}
          {...props.bounds}
          autoExpandParent={true}
          onRightClick={(event, node) => {
            console.log('node', node)
          }}
        />
      )
    } else {
      return <Empty />
    }
  }

  return <TreeCom />
}

export default IcdTree
