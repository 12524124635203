/*
* 脚本控件 -- TreeSelect
* */

import {Form, Input, Select, Tooltip} from 'antd'
import React, {useEffect} from 'react'
import {QuestionCircleOutlined} from '@ant-design/icons'

const mentionText = 'SQL样例: \n' +
    '\n' +
    '-- 查询结果必须包含patient_id -- 以下为一个示例 select patient_id from dwd.dwd_patient_info where EXTRACT ( YEAR FROM age( CURRENT_DATE, date_of_birth ) ) >= 18' +
    '\n' +
    'Javascript样例: \n' +
    '\n' +
    '/**\n' +
    ' * 输出包含"肿瘤"的诊断名称\n' +
    ' */\n' +
    'function demo2_1(dwd) {\n' +
    '  let dwd_diagnosises = dwd.dwd_diagnosis;\n' +
    '  let result = [];\n' +
    '  if (!dwd_diagnosises) {\n' +
    '    return result;\n' +
    '  }\n' +
    '  for (let i = 0; i < dwd_diagnosises.length; i++) {\n' +
    '    let r = dwd_diagnosises[i];\n' +
    '    if (r.standard_diag_name && r.standard_diag_name.indexOf(\'肿瘤\') > -1) {\n' +
    '      result.push({\n' +
    '        traceDatas: [\n' +
    '          {\n' +
    '            "tableName": "dwd_diagnosis",\n' +
    '            "columnName": "standard_diag_name",\n' +
    '            "recordId": r.record_id,\n' +
    '            "indexNo": r.index_no,\n' +
    '            "hit": true,\n' +
    '            "hitValue": r.standard_diag_name\n' +
    '          }\n' +
    '        ],\n' +
    '        outputs: [r.standard_diag_name],\n' +
    '        hits: [true]\n' +
    '      });\n' +
    '    }\n' +
    '  }\n' +
    '  return result;\n' +
    '}\n' +
    'demo2_1(${dwdTableMap});'
const ScriptWidget = ({namePath, ...restProps}) => {
  const renderLabel = () => {
    return (
      <Tooltip title={mentionText} overlayClassName='tooltip-opt' autoAdjustOverflow={false}>
        <span style={{marginRight: '4px'}}>脚本输入</span><QuestionCircleOutlined />
      </Tooltip>
    )
  }
  useEffect(() => {
    //
  }, [restProps.itemKey])

  return (
    <>
      <Form.Item
        label="脚本介绍"
        name={[...namePath, 'name']}
      >
        <Input/>
      </Form.Item>

      <Form.Item
        label="脚本类型"
        name={[...namePath, 'type']}
      >
        <Select
          placeholder="请选择脚本类型"
          options={[{
            label: 'JavaScript',
            value: 'js'
          }, {
            label: 'SQL',
            value: 'sql'

          }]}
        />
      </Form.Item>
      <Form.Item
        label={renderLabel()}
        name={[...namePath, 'script']}
      >
        <Input.TextArea placeholder={mentionText} showCount style={{
          height: 300,
        }}/>
      </Form.Item>
    </>
  )
}

export default ScriptWidget
