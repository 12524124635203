/*
* 算法控件 -- TreeSelect
* */

import {Form, Select} from 'antd'
import React, {useEffect, useState} from 'react'
import fetch from '@utils/request'
import api from '@api'
import {setTagAlgNode} from '@store/app'
import {useDispatch} from 'react-redux'


const AlgWidget = ({namePath, ...restProps}) => {
  const [aiList, setAiList] = useState([])
  const dispatch = useDispatch()

  const fetchAiList = async () => {
    const res = await fetch({
      method: 'GET',
      url: api.tagApi.aiList,
    })
    setAiList(res)
  }

  useEffect(() => {
    fetchAiList()
  }, [restProps.itemKey])
  return (
    <>
      <Form.Item
        label="选择AI模型"
        name={[...namePath, 'modelCode']}
      >
        <Select
          placeholder="请选择AI模型"
          showSearch={true}
          optionFilterProp='modelName'
          options={aiList}
          onSelect={(value) => {
            dispatch(setTagAlgNode({key: restProps.itemKey, value}))
          }}
          fieldNames={{label: 'modelName', value: 'modelCode'}}
        />
      </Form.Item>
    </>
  )
}

export default AlgWidget
