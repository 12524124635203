/*
* 项目管理
* */
const projectApi = {
  getProList: '/kbms/project/list', // 项目列表查询
  savePro: '/kbms/project/save', // 保存-更新项目
  getLabelCategoryByType: '/kbms/project/getLabelCategoryByType', // 获取项目标签分类
  getLabelByCategory: '/kbms/project/getLabelByCategory', // 获取项目标签列表
  getLabelMap: '/kbms/project/getLabelMap', // 获取项目标签 - 弹框
  saveLabel: '/kbms/project/saveLabel', // 保存项目标签
  projectTask: '/kbms/projectTask/list', // 所有任务列表
  downloadProjectExportTask: '/kbms/projectTask/downloadProjectExportTask', // 下载导出任务
  ruleExport: '/kbms/rule/export', // 导出项目标签文件
  ruleImport: '/kbms/rule/import', // 导入项目标签文件
  deleteProjectExportTask: '/kbms/projectTask/deleteProjectExportTask', // 删除导出任务
  detail: '/kbms/projectTask/detail', // 获取导出任务详情
  save: '/kbms/projectTask/save', // 创建任务
  deleteProject: 'kbms/project/deleteProject', // 删除项目
  // CRF表单对接esource
  crfList: '/kbms/project/crf/list', // CRF表单列表
  crfListForTargetFieldId: '/kbms/project/crf/crfListForTargetFieldId', // CRF表单列表--数据验证
  crfBind: '/kbms/project/crf/bind', // CRF表单-关联标签(全量更新)
  crfDraftBind: '/kbms/project/crf/draftBind', // CRF表单-预关联标签(未点击保存)
  crfRuleList: '/kbms/project/crf/field/rule/list', // CRF表单字段已关联标签列表
  crfField: '/kbms/project/crf/field', // CRF表单字段输出方式

  crfValid: '/kbms/project//crf/valid', // CRF表单字段数据验证
  crfOnline: '/kbms/project//crf/online', // CRF表单字段发布
  crfDelete: '/kbms/project/crf/delete', // CRF表单-删除失效
  subjectList: '/kbms/project/crf/subjectList', // CRF表单-患者编号
  addRuleClass: '/kbms/project/crf/addRuleClass', // CRF表单-添加规则
  updateRuleClass: '/kbms/project/crf/updateRuleClass', // CRF表单-修改规则
  deleteRuleClass: '/kbms/project/crf/deleteRuleClass', // CRF表单-删除规则

  ruleCrfProjectExport: '/kbms/rule/crf/project/export', // 导出crf项目标签文件
  ruleCrfProjectImport: '/kbms/rule/crf/project/import', // 导入crf项目标签文件
}
export default projectApi
