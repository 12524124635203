import React from 'react'
import { Breadcrumb } from 'antd'
import {Link, useLocation} from 'react-router-dom'

import {getNameByKey} from '@utils/common'
import styles from './index.module.css'
import routesConfig from '@routes/routes'

const PrimeBreadcrumb = () => {
  const { privateRoutes } = routesConfig
  const routes = privateRoutes()
  const location = useLocation()
  const pathSnippets = location.pathname.split('/').filter((i) => i)
  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join('/')}`
    let name = ''
    if (routes && routes.length){
      name = getNameByKey(url, routes[0]?.children, 'path', 'label')
      if (url==='/tagConfig/feedback'){
        name = '用户反馈'
      }
    }
    if (index === 0) {
      return (
        <Breadcrumb.Item key={url}>{name}</Breadcrumb.Item>
      )
    } else {
      return (
        <Breadcrumb.Item key={url}>
          <Link to={url} state={location.state}>{name}</Link>
        </Breadcrumb.Item>
      )
    }
  })
  const breadcrumbItems = [
    <Breadcrumb.Item key="home">
      {/* <Link to="/">主页</Link> */}
    </Breadcrumb.Item>,
  ].concat(extraBreadcrumbItems)

  return (
    <Breadcrumb className={styles.breadcrumbWrapper}>{breadcrumbItems}</Breadcrumb>
  )
}

export default PrimeBreadcrumb
