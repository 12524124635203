import React, { useState, useEffect, useRef } from 'react'
import { Button, Space, Tooltip, Table, Switch, Modal, message } from 'antd'
import { EditOutlined, KeyOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import styles from './index.module.less'
import UserModal from './components/userModal'
import usePagination from '@hooks/usePagination'
import fetch from '@utils/request'
import api from '@api'
import { permissionStyle, permissionAuth } from '@utils/permission'

const Account = () => {
  const userRef = useRef(null)
  const [list, setList] = useState([])
  const [page, setPage, setTotal, pagination] = usePagination()

  const getList = async() => {
    const pager = {
      pageNumber: page.pageIndex,
      pageSize: page.pageSize,
    }
    const {content, totalCount} = await fetch({
      method: 'GET',
      url: api.accountApi.userList,
      params: {
        ...pager
      }
    })
    if (content && content.length>0) {
      setList(content.map(item => {
        return {
          ...item,
          key: item.id,
          roleId: String(item.roleId),
          status: item.status? true : false
        }
      }))
      setTotal(totalCount)
    }
  }
  // 新增账号
  const headClick = () => {
    userRef.current.handleOpen(true)
    userRef.current.handleValue({})
  }

  // 编辑账号
  const onEdit = (record) => {
    userRef.current.handleOpen()
    userRef.current.handleValue(record)
  }

  // 重置密码
  const onReset = (record) => {
    Modal.confirm({
      title: '重置密码 ?',
      icon: <ExclamationCircleOutlined />,
      content: `重置${record.name}密码，新密码将重置为原始密码`,
      width: '700px',
      onOk: async() => {
        await fetch({
          method: 'PUT',
          url: api.accountApi.repwd,
          data: {
            id: record.id
          }
        })
        message.success('重置成功')
        getList()
      }
    })
  }

  // 启用，禁用
  const onSwitchClick = async(record) => {
    await fetch({
      method: 'PUT',
      url: api.accountApi.status,
      data: {
        id: record.id,
        status: record.status ? 0 : 1
      }
    })
    message.success('设置成功')
    getList()
  }

  const handleTableChange = (pagination, filters, sorter, extra) => {
    setPage({
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
    })
    // fetchData()
  }

  const handleOk = () => {
    getList()
  }

  useEffect(() => {
    getList()
  }, [page])

  const columns = [
    {
      title: '用户名',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: '姓名',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '手机号',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: '角色',
      dataIndex: 'roleName',
      key: 'roleName',
    },
    {
      title: '状态',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => {
        return (
          <Switch checkedChildren="已启用" unCheckedChildren="已禁用" disabled={!permissionAuth('EDIT_USER_STATUS') || record.isSuper === true} checked={text} onClick={()=>onSwitchClick(record)} />
        )
      }
    },
    {
      title: '操作',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Tooltip mouseEnterDelay="2" title="编辑">
            <a onClick={() => onEdit(record)} style={permissionStyle('EDIT_USER')}><EditOutlined /></a>
          </Tooltip>
          <Tooltip mouseEnterDelay="2" title="重置密码">
            <a onClick={() => onReset(record)} style={permissionStyle('RESET_USER_PASSWORD')}><KeyOutlined /></a>
          </Tooltip>
        </Space>
      ),
    },
  ]

  return (
    <>
      {/* 标题栏 */}
      <div className="head">
        <h3 className="head-title">账号设置</h3>
        <Button type="primary" onClick={headClick} style={permissionStyle('ADD_USER')}>新增账号</Button>
      </div>
      {/* 表格 */}
      <div className={styles.tableSpace}>
        <Table
          columns={columns}
          dataSource={list}
          pagination={pagination}
          onChange={handleTableChange}
        />
      </div>
      <UserModal ref={userRef} handleOk={handleOk} />
    </>
  )
}

export default Account
