import React, { useState, useContext, useEffect } from 'react'
import { Table } from 'antd'
import { formatDateFromTimestamp, withEmptyRender } from '@utils/common'
import fetch from '@utils/request'
import api from '@api'
import { patientDiagTypeMap } from '@constants/patient'
import usePagination from '@hooks/usePagination'
import PatientContext from '../context'

const Diagnosis = ({ targetPage, render }) => {
  const { activeVisit, projectId, patientId } = useContext(PatientContext)
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [page, setPage, setTotal, pagination] = usePagination({
    initialPage: {
      pageIndex: targetPage || 1,
      pageSize: 10,
    },
  })
  const columns = withEmptyRender([
    {
      title: '诊断类型',
      dataIndex: 'patient_source',
      render: (value, record) => render(patientDiagTypeMap[value] || '-', record),
    },
    {
      title: '标准诊断名称',
      dataIndex: 'standard_diag_name',
      render,
    },
    {
      title: '标准诊断编码',
      dataIndex: 'standard_diag_code',
      render,
    },
    {
      title: '源诊断名称',
      dataIndex: 'origin_diag_name',
      render,
    },
    {
      title: '源诊断编码',
      dataIndex: 'origin_diag_code',
      render,
    },
    {
      title: '诊断时间',
      dataIndex: 'diag_time',
      render: (text, record) => render(formatDateFromTimestamp(text) || '-', record),
    },
  ])

  const handleTableChange = (pagination) => {
    setPage({
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
    })
  }
  const fetchRecord = async () => {
    const pager = {
      pageNumber: page.pageIndex,
      pageSize: page.pageSize,
    }
    setLoading(true)
    const { indexNo, type } = activeVisit
    const { content, totalCount } = await fetch({
      url: api.patientApi.patientRecords,
      method: 'GET',
      params: {
        patientId,
        indexNo,
        type: 7,
        category: type,
        proId: projectId,
        ...pager,
      },
    })
    setData(content)
    setLoading(false)
    setTotal(totalCount)
  }

  useEffect(() => {
    if (patientId) {
      fetchRecord()
    }
  }, [activeVisit, page])

  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        rowKey={(record) => record.id}
        size="small"
        pagination={pagination}
        loading={loading}
        onChange={handleTableChange}
        scroll={{ x: 'max-content' }}
      />
    </>
  )
}

export default Diagnosis
