// eslint-disable-next-line no-unused-vars
import React, {useEffect, useState, forwardRef, useImperativeHandle, useRef} from 'react'
import {Button, message, Modal,} from 'antd'
import {useWindowSize} from '@hooks/useLayout'
import TreeTransfer from './treeTransfer'
import fetch from '@utils/request'
import api from '@api'
import DebounceSelect from '@components/DebounceSelect'
import styles from './index.module.less'
const NewLabelSelectModal = (props, ref) => {
  // eslint-disable-next-line no-unused-vars
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [,, scale] = useWindowSize()
  const [targetKeys, setTargetKeys] = useState([])
  // eslint-disable-next-line no-unused-vars
  const [targetNodes, setTargetNodes] = useState([])
  // eslint-disable-next-line no-unused-vars
  const [treeData, setTreeData] = useState([])
  const [searchText, setSearchText] = useState('')
  // eslint-disable-next-line no-unused-vars
  const [searchOutText, setSearchOutText] = useState(null)
  // eslint-disable-next-line no-unused-vars
  const [triggerSearch, setTriggerSearch] = useState(false)

  // 关键词搜索

  // 已选中的树节点
  let selectedNodes = []
  /*
* 递归树，获取已选中的树节点
* */
  function targetTreeNodes (treeNodes = [], selectedKeys) {
    selectedNodes = treeNodes.filter( ({ children, ...props }) => {
      return selectedKeys.includes(props.id)
    })
  }

  const onChange = (keys, transferDataSource) => {
    setTargetKeys(keys)
    targetTreeNodes(transferDataSource, keys)
    setTargetNodes(selectedNodes)
  }
  const handleOk = () => {
    if (!targetNodes.length) {
      return message.warn('请先选择标签！')
    }

    setIsModalVisible(false)
  }
  const handleCancel = () => {
    setIsModalVisible(false)
  }
  const handleOpen = (e) => {
    setIsModalVisible(true)
  }

  const getCategory = async() => {
    const res = await fetch({
      method: 'GET',
      url: api.tagApi.stockRule,
      data: {
        pid: ''
      }
    })
    res.map((item) => {
      item.isLeaf = item.leaf
    })
    setTreeData(res)
  }
  async function fetchSearchList(searchText) {
    const res = await fetch({
      method: 'GET',
      url: api.tagApi.stockRuleRecommend,
      params: {
        keyword: searchText,
      },
    })
    return res.map((item) => ({
      label: item,
      value: item
    }))
  }
  const searchForm = () => {
    return (
    // <div className={styles.searchForm}>
    // <Search allowClear placeholder="请输入关键词" onSearch={handleChange} />
      <div className={styles.searchSection}>
        <div className={styles.searchTitle}>标签名称：</div>
        <DebounceSelect
          value={searchText}
          placeholder="输入关键词搜索..."
          fetchOptions={fetchSearchList}
          onChange={(newValue) => {
            setSearchText(newValue)
          }}
          onSelect={(newValue) => {
            setSearchOutText(newValue)
            setTriggerSearch(true)
          }}
          style={{
            width: `${210*scale}px`,
            marginRight: `${12*scale}px`,
          }}
        />
        <Button type="primary" onClick={() => {
          setSearchOutText(searchText)
          if (!searchText) {
            // setExpandedKeys([])
            setTreeData([])
            setTriggerSearch(false)
          } else {
            setTriggerSearch(true)
          }

        }}>搜索</Button>
      </div>
    // </div>
    )
  }
  useEffect(() => {
    isModalVisible && getCategory()
  }, [isModalVisible])
  useImperativeHandle(ref, () => ({
    handleOpen
  }))

  return (
    <Modal
      title="分配标签"
      width={640*scale}
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      {searchForm()}
      <TreeTransfer
        targetKeys={targetKeys}
        onChange={onChange}
        keyword={searchOutText}/>
    </Modal>
  )
}

export default forwardRef(NewLabelSelectModal)
