import React from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import styles from '@views/tag/engineV2/components/index.module.less'
import { ReactComponent as EmptyIcon } from '@assets/images/icons/icon_empty.svg'
import {connect} from 'react-redux'
import { FieldArray, formValueSelector} from 'redux-form'
import DragItem from '@views/tag/engineV2/components/dragItem'
import ItemWidget from '@views/tag/engineV2/components/widget/item'

const grid = 0
const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? 'lightblue' : '#fff',
  padding: grid,
  width: '100%'
})
const getItemStyle = (isDragging, draggableStyle, highLight, color, showFlag) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  // change background colour if dragging
  background: isDragging ? 'lightgreen' : '#fff',

  // styles we need to apply on draggables
  ...draggableStyle,
  border: highLight && `1px solid ${color}`,
  display: showFlag? 'none': 'block'
})
const renderWidgets = ({ fields, selectedNode, nodeDataArray, findPreNode}) => {
  const onDragEnd = (result) => {
    if (!result.destination) {
      return
    }
    fields.move(result.source.index, result.destination.index)
  }

  return (
    <div className={styles.canvasProps}>
      <div className={styles.canvasPropsTitle}>控件属性</div>
      <div className={styles.dragList}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <>
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  {fields.map((field, index) => (
                    <Draggable key={field} draggableId={field} index={index}>
                      {(provided, snapshot) => {
                        const fieldItem = fields.get(index)
                        return (
                          <DragItem
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style,
                              fieldItem.key === selectedNode?.key,
                              // false,
                              fieldItem.fill,
                              fieldItem.type === 'START'
                            )}
                            color={fieldItem.fill}
                            title={fieldItem.text}>
                            <ItemWidget key={fieldItem.key} namePathPrefix={`${field}.data.v2Expression`} type={fieldItem.type} itemKey={fieldItem.key} findPreNode={findPreNode}/>
                          </DragItem>
                        )
                      }}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              </>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>

  )
}

let DragList = ({source, selectedNode, nodeDataArray, linkDataArray, findPreNode}) => {
  if (!nodeDataArray?.length) {
    return (
      <div className={styles.canvasProps}>
        <div className={styles.canvasPropsTitle}>控件属性</div>
        <div className={styles.dragListEmpty}>
          <div><EmptyIcon/></div>
          <div style={{color: 'rgba(0, 0, 0, 0.25)'}}>暂无数据</div>
        </div>
      </div>
    )
  }

  return (
    <FieldArray name="nodeDataArray" findPreNode={findPreNode} nodeDataArray={nodeDataArray} selectedNode={selectedNode} component={renderWidgets} />
  )
}


const selector = formValueSelector('tag') // <-- same as form name
const mapStateToProps = (state) => {
  const nodeDataArray = selector(state, 'nodeDataArray')
  const linkDataArray = selector(state, 'linkDataArray')
  return {
    nodeDataArray,
    linkDataArray,
  }
}


DragList = connect(mapStateToProps, null)(DragList)

export default DragList
