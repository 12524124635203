import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { store, persistor as persist } from './store/index'
import { PersistGate } from 'redux-persist/integration/react'
import { ConfigProvider } from 'antd'
import zhCN from 'antd/es/locale/zh_CN'
import './index.less'
import '@themes/index.less'
import '@utils/auto-rem'
// import 'antd/dist/antd.less';
import App from './App'
import './public-path'

// ======================================== React 18以上版本, 引入 <React.StrictMode />时，useEffect(()>{}, [])会触发两次,暂时移除StrictMode模式   ========================================
let instance = null

// 设置微应用 Modal、Message、Notification rootPrefixCls。
const appName = localStorage.getItem('QIANKUN_APP')
if (appName){
  ConfigProvider.config({
    prefixCls: appName
  })
}
// const originFn = document.body.appendChild.bind(document.body)

// 解决微应用中弹窗挂载到主应用的问题
// const resetPopupContainer = (container) => {
//   const whiteList = ['ant-modal-root', 'ant-notification', 'ant-message']
//   document.body.appendChild = function (node) {
//     const target = node.firstChild || node
//     if (whiteList.includes(target.className)) {
//       container.querySelector('#root').appendChild(node)
//     } else {
//       originFn(node)
//     }
//   }
// }

function render(props) {
  const { container } = props
  // 改变子应用弹窗挂载节点
  // if (container) {
  //   resetPopupContainer(container)
  // }

  const root = ReactDOM.createRoot(
    container
      ? container.querySelector('#root')
      : document.querySelector('#root')
  )
  root.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persist}>
        <BrowserRouter basename="/kbms">
          <ConfigProvider locale={zhCN}>
            <App />
          </ConfigProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  )
  instance = root
}

if (!window.__POWERED_BY_QIANKUN__) {
  render({})
}

export async function bootstrap() {
  console.log('react app bootstraped')
}

export async function mount(props) {
  console.log('props from main framework', props)
  render(props)
}

export async function unmount(props) {
  console.log('props from main framework', props)
  instance.unmount()
  instance = null
  // document.body.appendChild = originFn
}

// window.appRender = render
