/*
* 脚本控件 -- TreeSelect
* */

import {Tooltip} from 'antd'
import React from 'react'
import {QuestionCircleOutlined} from '@ant-design/icons'
import {Field} from 'redux-form'
import {AInput, AInputTextArea, ASelect} from '@components/redux-form-antd'


const mentionSQLText = '-- 单表-患者基本信息表SQL配置示例:\n' +
    '-- 必返回字段：patient_id\n' +
    '-- 可返回字段：命中溯源字段（表名.字段名）\n' +
    'select\n' +
    '    patient_id,\n' +
    '    gender as "dwd_patient_info.gender",\n' +
    '    date_of_birth as "dwd_patient_info.date_of_birth"\n' +
    'from dwd_patient_info\n' +
    'where gender ~ \'男\' and date_part(\'year\',age(date_of_birth)) >= 18\n' +
    '\n' +
    '-- 单表-患者关联信息表SQL配置示例:\n' +
    '-- 必返回字段：index_no\n' +
    '-- 可返回字段：命中溯源字段（表名.字段名）\n' +
    'select index_no,standard_diag_name as "dwd_diagnosis.standard_diag_name" from dwd_diagnosis where standard_diag_name ~ \'高血压\'\n' +
    '\n' +
    '-- 多表-多表联合SQL示例：\n' +
    '-- 必返回字段：index_no\n' +
    '-- 可返回字段：命中溯源字段（表名.字段名）\n' +
    'select dwd_diagnosis.index_no,\n' +
    '       standard_diag_name as "dwd_diagnosis.standard_diag_name",\n' +
    '       gender as "dwd_patient_info.gender",\n' +
    '       date_part(\'year\',age(date_of_birth)) as "dwd_patient_info.date_of_birth"\n' +
    'from dwd_patient_info inner join dwd_diagnosis on dwd_patient_info.patient_id = dwd_diagnosis.patient_id\n' +
    'where gender ~ \'男\' and date_part(\'year\',age(date_of_birth)) >= 18 and standard_diag_name ~ \'室上速\''
const mentionJSText = '\nJavascript样例: \n' +
    '\n' +
    '/**\n' +
    ' * 输出包含"肿瘤"的诊断名称\n' +
    ' */\n' +
    'function demo2_1(dwd) {\n' +
    '  let dwd_diagnosises = dwd.dwd_diagnosis;\n' +
    '  let result = [];\n' +
    '  if (!dwd_diagnosises) {\n' +
    '    return result;\n' +
    '  }\n' +
    '  for (let i = 0; i < dwd_diagnosises.length; i++) {\n' +
    '    let r = dwd_diagnosises[i];\n' +
    '    if (r.standard_diag_name && r.standard_diag_name.indexOf(\'肿瘤\') > -1) {\n' +
    '      result.push({\n' +
    '        traceDatas: [\n' +
    '          {\n' +
    '            "tableName": "dwd_diagnosis",\n' +
    '            "columnName": "standard_diag_name",\n' +
    '            "recordId": r.record_id,\n' +
    '            "indexNo": r.index_no,\n' +
    '            "hit": true,\n' +
    '            "hitValue": r.standard_diag_name\n' +
    '          }\n' +
    '        ],\n' +
    '        outputs: [r.standard_diag_name],\n' +
    '        hits: [true]\n' +
    '      });\n' +
    '    }\n' +
    '  }\n' +
    '  return result;\n' +
    '}\n' +
    'demo2_1(${dwdTableMap});'
const ScriptWidget = ({namePath}) => {
  const renderLabel = () => {
    return (
      <Tooltip title={mentionSQLText + mentionJSText} overlayClassName='tooltip-opt' autoAdjustOverflow={false}>
        <span style={{marginRight: '4px'}}>脚本输入</span><QuestionCircleOutlined />
      </Tooltip>
    )
  }
  return (
    <>
      <Field
        label="脚本介绍"
        name={`${namePath}.name`}
        component={AInput}
        placeholder="请输入脚本介绍"
      />
      <Field
        label="脚本类型"
        name={`${namePath}.type`}
        component={ASelect}
        value={null}
        placeholder="请选择脚本类型"
        options={[{
          label: 'JavaScript',
          value: 'js'
        }, {
          label: 'SQL',
          value: 'sql'

        }]}
        onFocus={(e) => e.preventDefault()}
        onBlur={(e) => e.preventDefault()}
      />

      <Field
        label={renderLabel()}
        name={`${namePath}.script`}
        component={AInputTextArea}
        placeholder={mentionSQLText + mentionJSText}
        showCount
        style={{
          height: 300,
        }}
      />
    </>
  )
}

export default ScriptWidget
