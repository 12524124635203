import React, { useImperativeHandle, forwardRef, useState } from 'react'
import { Form, Modal, Select, Input, message } from 'antd'
import fetch from '@utils/request'
import api from '@api'
// import { CheckOutlined } from '@ant-design/icons'

const Publish = ({ onSuccess }, ref) => {
  const [form] = Form.useForm()
  const [visible, setVisible] = useState(false)
  const [cancel, setCancel] = useState(false)
  const [projects, setProjects] = useState([]) // 项目列表
  // const [pubProjects, setPubProjects] = useState([]) // 已发布项目列表
  const fetchProjects = async (published) => {
    const res = await fetch({
      url: api.aeApi.getProjectList,
      method: 'POST',
    })
    // setPubProjects(published)
    setProjects(
      res.filter((item) => {
        return published.findIndex((i) => i.value === item.value) === -1
      })
    )
  }

  const hide = () => {
    form.resetFields()
    setVisible(false)
  }
  const show = (item, cancel) => {
    setVisible(true)
    setCancel(cancel)
    if (!item) return
    form.setFieldsValue(item)
    let published = []
    if (item.projectVoList && item.projectVoList.length) {
      published = item.projectVoList.map((item) => {
        return {
          label: item.projectName,
          value: item.projectId,
        }
      })
    }
    if (cancel) {
      setProjects(published)
    } else {
      fetchProjects(published)
    }
  }
  useImperativeHandle(ref, () => ({
    show,
  }))

  const handleOk = async (values) => {
    const { projectCodes, id } = values
    const url = cancel ? api.aeApi.cancelPush : api.aeApi.push
    await fetch({
      url,
      method: 'POST',
      data: {
        projectCodes: projectCodes.join(','),
        id,
      },
    })
    hide()
    message.success(cancel ? '取消成功' : '发布成功')
    typeof onSuccess === 'function' && onSuccess()
  }

  return (
    <Modal
      open={visible}
      onCancel={hide}
      title={cancel ? '取消发布' : '发布项目'}
      width={640}
      onOk={() => form.submit()}
    >
      <Form
        form={form}
        labelCol={{
          style: {
            width: 120,
          },
        }}
        onFinish={handleOk}
      >
        <Form.Item
          label="项目名称"
          name="projectCodes"
          rules={[
            {
              required: true,
              message: '请选择项目名称',
            },
          ]}
        >
          <Select
            placeholder="请选择项目名称"
            options={projects}
            mode="multiple"
            allowClear
            optionFilterProp="label"
            filterOption={(input, option) =>
              option.label.indexOf(input.trim()) >= 0
            }
            // dropdownRender={(node) => {
            //   if (cancel) {
            //     return node
            //   } else {
            //     return (
            //       <div>
            //         {pubProjects.map((p) => {
            //           return (
            //             <div
            //               key={p.projectId}
            //               style={{
            //                 padding: '5px 12px',
            //                 display: 'flex',
            //                 justifyContent: 'space-between',
            //                 background: '#f5f5f5',
            //                 alignItems: 'center',
            //               }}
            //             >
            //               <span>{p.label}</span>
            //               <CheckOutlined />
            //             </div>
            //           )
            //         })}
            //         {node}
            //       </div>
            //     )
            //   }
            // }}
          />
        </Form.Item>
        <Form.Item name="id" hidden>
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default forwardRef(Publish)
