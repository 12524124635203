/*
* 标签控件
* */

import React, {useRef} from 'react'
import LabelSelectModal from '@views/project/components/labelSelectModaV1'
import {change, Field} from 'redux-form'
import {connect} from 'react-redux'
import {AInput, ASelect} from '@components/redux-form-antd'
import styles from '@views/project/components/index.module.less'
let TagWidget = ({namePath, changeArray}) => {
  const labelSelectRef = useRef(null)
  /*
  * 回显选中的标签
  * */
  const onSure = (targetNodes) => {
    changeArray(
      'tag',
      `${namePath}.ruleName`,
      targetNodes.map(item => item.name)[0]
    )
    changeArray(
      'tag',
      `${namePath}.ruleVersion`,
      targetNodes.map(item => item.version)[0]
    )
    changeArray(
      'tag',
      `${namePath}.ruleId`,
      targetNodes.map(item => item.id)[0]
    )
  }
  return (
    <>
      <Field
        label="选择标签"
        className={styles.select}
        name={`${namePath}.ruleName`}
        component={ASelect}
        dropdownStyle={{'display': 'none'}}
        showSearch={false}
        placeholder="请选择标签"
        fieldNames={{label: 'name', value: 'id'}}
        onFocus={(e) => e.preventDefault()}
        onBlur={(e) => e.preventDefault()}
        onClick={() => labelSelectRef.current.handleOpen()}
      />
      <Field
        name={`${namePath}.ruleId`}
        component={AInput}
        itemStyle={{display: 'none'}}
      />
      <LabelSelectModal ref={labelSelectRef} onSure={onSure} />
    </>
  )
}

const mapDispatchToProps = {
  // NOTE: This MUST be aliased or it will not work. Thanks Jack!
  changeArray: change
}

TagWidget = connect(null, mapDispatchToProps)(TagWidget)

export default TagWidget
