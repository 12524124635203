const omopApi = {
  getOmopList: '/kbms/omop/mapping/list', // 获取omop列表
  auditOmop: '/kbms/omop/mapping/audit', // 审核omop
  listDirectDescendant: '/kbms/omop/dictionary/listDirectDescendant', // 获取omop字典
  searchItemRecommend: '/kbms/omop/dictionary/searchItemRecommend', // 搜索推荐
  searchItemPage: '/kbms/omop/dictionary/searchItemPage', // 搜索
  editMapping: '/kbms/omop/mapping/update', // 编辑
  audit: '/kbms/omop/mapping/audit', // 提交审核
}

export default omopApi
