import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react'
import { Modal, Form, message, Select } from 'antd'
import fetch from '@utils/request'
import api from '@api'
import { exportCsv } from '@utils/export'
import {useWindowSize} from '@hooks/useLayout'

const EditModal = (props, ref) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [projectList, setProjectList] = useState([])
  const [selectedProject, setSelectedProject] = useState({})
  const [form] = Form.useForm()
  const [,, scale] = useWindowSize()
  const handleOpen = (e) => {
    setIsModalVisible(true)
  }

  const handleValue = (e) => {
    form.setFieldsValue(e)
  }

  const handleOk = async () => {
    await form.submit()
  }

  const handleCancel = () => {
    setIsModalVisible(false)
    form.resetFields()
  }
  // eslint-disable-next-line no-unused-vars
  const onDoenload = async () => {
    try {
      const res = await fetch({
        method: 'GET',
        url: api.mappingApi.export,
        params: {
          projectName: selectedProject?.name,
        },
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      if (res.code === 0) {
        message.error(res.message)
      } else {
        exportCsv(selectedProject?.name, res)
        message.success('导出成功!')
      }
    } catch (err) {}
  }
  const onFinish = async (values) => {
    onDoenload()
    props.handleOk()
    handleCancel()
  }

  const selectedChange = (value, option) => {
    setSelectedProject(option)
  }

  useImperativeHandle(ref, () => ({
    handleOpen,
    handleValue,
  }))

  useEffect(() => {
    setProjectList(props.projectList)
  }, [props.projectList])

  return (
    <Modal
      title="导出数据"
      visible={isModalVisible}
      width={720*scale}
      okText="确定"
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <Form
        form={form}
        labelCol={{
          span: 6,
        }}
        wrapperCol={{
          span: 16,
        }}
        onFinish={onFinish}
      >
        <Form.Item
          label="医院"
          name="projectName"
          rules={[
            {
              required: true,
              message: '请选择医院',
            },
          ]}
        >
          <Select
            placeholder="请选择"
            options={projectList}
            onChange={selectedChange}
            fieldNames={{
              label: 'name',
              value: 'code',
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default forwardRef(EditModal)
